import { Accordion, AccordionTab } from 'primereact/accordion';
import React from 'react';
import { AccordionFormIntructions } from '../StyledComponent';
import { Button } from 'react-bootstrap';
import { Types } from '../../constant/action-type';
import { useDispatch } from '../../context';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDurationFromFirstLogin } from '../../utils';
import { CHECK_IN_TIME } from '../../constant';

interface OnboardingProcessInformationProps {
  activeIndex?: any[];
  setIsShowHelpCenter: Function;
  setShowHelpCenter: Function;
}

const OnboardingProcessInformation = (props: OnboardingProcessInformationProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const duration = getDurationFromFirstLogin();

  const { setIsShowHelpCenter, setShowHelpCenter } = props;
  const handlePlayVideo = (videoType: any) => {
    dispatch({
      type: Types.SET_HELP_CENTRE_STYLE,
      payload: {
        helpCentre: {
          isOpen: false,
          formInformation: {
            isShow: false,
            activeIndex: [],
          },
          onboardingProcessInformation: {
            isShow: false,
            activeIndex: [],
          },
          isShowButtonAllHelpTopic: false,
        },
      },
    });
    setTimeout(() => {
      dispatch({
        type: videoType,
        payload: {
          isOpen: true,
        },
      });
    }, 300);
  };
  const onStartUserTour = () => {
    if (location?.pathname !== '/') {
      navigate(`/`);
    }
    setIsShowHelpCenter(false);
    setShowHelpCenter(false);
    dispatch({
      type: Types.SET_SHOW_MODAL_CONFIRM_START_USER_TOUR,
      payload: { showModalConfirmStartTour: true },
    });
  };

  return (
    <>
      <AccordionFormIntructions role="tablist" aria-busy="true">
        <Accordion className="form-instructions" activeIndex={0}>
          <AccordionTab
            className="form-instructions-accordion-header"
            style={{ fontSize: '18px', fontWeight: '800', border: 'none' }}
            header="Onboarding Process"
          >
            <div role="tablist" aria-busy="true">
              <Accordion className="form-instructions sub-item" activeIndex={props.activeIndex} multiple>
                <AccordionTab
                  className="form-instructions-accordion-sub-header"
                  style={{ fontSize: '16px', fontWeight: '600' }}
                  header="Welcome Video"
                >
                  <div
                    className="mini-onboarding-video"
                    onClick={() => handlePlayVideo(Types.SET_MODAL_ONBOARD_VIDEO_STYLE)}
                  >
                    <iframe
                      frameBorder={0}
                      sandbox="false"
                      src="/videos/welcome.mp4"
                      allowFullScreen
                      height={200}
                      width={250}
                      title="welcome-video"
                      style={{ border: 'none', pointerEvents: 'none' }}
                      className="video-intro"
                    />
                  </div>
                </AccordionTab>
                <AccordionTab
                  className="form-instructions-accordion-sub-header"
                  style={{ fontSize: '16px', fontWeight: '600' }}
                  header="User Tour"
                >
                  <Button size="sm" variant="primary" style={{ fontSize: '14px' }} onClick={() => onStartUserTour()}>
                    Start User Tour
                  </Button>
                </AccordionTab>
                {duration.asDays() >= CHECK_IN_TIME && (
                  <AccordionTab
                    className="form-instructions-accordion-sub-header"
                    style={{ fontSize: '16px', fontWeight: '600' }}
                    header="Check in Video"
                  >
                    <div
                      className="mini-onboarding-video"
                      onClick={() => handlePlayVideo(Types.SET_SHOW_CHECK_IN_VIDEO)}
                    >
                      <iframe
                        frameBorder={0}
                        sandbox="false"
                        src="/videos/checkin.mp4"
                        allowFullScreen
                        height={200}
                        width={250}
                        title="checkin-video"
                        style={{ border: 'none', pointerEvents: 'none' }}
                        className="video-intro"
                      />
                    </div>
                  </AccordionTab>
                )}
              </Accordion>
            </div>
          </AccordionTab>
        </Accordion>
      </AccordionFormIntructions>
    </>
  );
};

export default OnboardingProcessInformation;
