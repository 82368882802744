/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorDto, ResponsiblePartyDto, SeverityDto, StatusDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Settings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get Project Statuses
   *
   * @tags project, status
   * @name GetProjectStatuses
   * @summary Project Status
   * @request GET:/settings/projects/statuses
   * @secure
   */
  getProjectStatuses = (params: RequestParams = {}) =>
    this.request<StatusDto[], ErrorDto>({
      path: `/settings/projects/statuses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get RAG Statuses
   *
   * @tags rag, status
   * @name GetRagStatuses
   * @summary RAG Status
   * @request GET:/settings/projects/ragStatuses
   * @secure
   */
  getRagStatuses = (params: RequestParams = {}) =>
    this.request<StatusDto[], ErrorDto>({
      path: `/settings/projects/ragStatuses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Task Statuses
   *
   * @tags task, status
   * @name GetTaskStatuses
   * @summary tasks Status
   * @request GET:/settings/tasks/statuses
   * @secure
   */
  getTaskStatuses = (params: RequestParams = {}) =>
    this.request<StatusDto[], ErrorDto>({
      path: `/settings/tasks/statuses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Milestones Statuses
   *
   * @tags milestones, status
   * @name GetMilestonesStatuses
   * @summary milestones Status
   * @request GET:/settings/milestones/statuses
   * @secure
   */
  getMilestonesStatuses = (params: RequestParams = {}) =>
    this.request<StatusDto[], ErrorDto>({
      path: `/settings/milestones/statuses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Issue Statuses
   *
   * @tags issues, status
   * @name GetIssueStatuses
   * @summary issues Status
   * @request GET:/settings/issues/statuses
   * @secure
   */
  getIssueStatuses = (params: RequestParams = {}) =>
    this.request<StatusDto[], ErrorDto>({
      path: `/settings/issues/statuses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Responsible Parties For Task
   *
   * @tags responsiblePartiesForTask
   * @name GetResponsiblePartiesForTask
   * @summary Responsible Parties for Task
   * @request GET:/settings/tasks/responsibleParties
   * @secure
   */
  getResponsiblePartiesForTask = (params: RequestParams = {}) =>
    this.request<ResponsiblePartyDto[], ErrorDto>({
      path: `/settings/tasks/responsibleParties`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Responsible Parties For Issue
   *
   * @tags responsiblePartiesForIssue
   * @name GetResponsiblePartiesForIssue
   * @summary Responsible Parties For Issue
   * @request GET:/settings/issues/responsibleParties
   * @secure
   */
  getResponsiblePartiesForIssue = (params: RequestParams = {}) =>
    this.request<ResponsiblePartyDto[], ErrorDto>({
      path: `/settings/issues/responsibleParties`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Severities
   *
   * @tags serverities
   * @name GetSeverities
   * @summary severities
   * @request GET:/settings/severities
   * @secure
   */
  getSeverities = (params: RequestParams = {}) =>
    this.request<SeverityDto[], ErrorDto>({
      path: `/settings/severities`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
