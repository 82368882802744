import { LabelInforNoneMargin } from '../../StyledComponent';
import { Row, Card, Col } from 'react-bootstrap';

const RowExpand = (data: any) => {
  return (
    <div className="px-3 py-2">
      <Row className="project-info detail-info issue-expand">
        <Col xl={2} lg={3} md={4} className="py-2">
          <LabelInforNoneMargin>Milestone Associated</LabelInforNoneMargin>
        </Col>
        <Col xl={10} lg={9} md={8} className="py-2">
          <Card.Text className="break-line font-14">{data?.milestoneAssociated || 'General project task'}</Card.Text>
        </Col>
      </Row>
      <Row className="project-info detail-info issue-expand">
        <Col xl={2} lg={3} md={4} className="py-2">
          <LabelInforNoneMargin>Task Description</LabelInforNoneMargin>
        </Col>
        <Col xl={10} lg={9} md={8} className="py-2">
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />
        </Col>
      </Row>
      <Row className="project-info detail-info issue-expand">
        <Col xl={2} lg={3} md={4} className="py-2">
          <LabelInforNoneMargin>Action Required</LabelInforNoneMargin>
        </Col>
        <Col xl={10} lg={9} md={8} className="py-2">
          <Card.Text className="break-line font-14">{data?.actionRequired}</Card.Text>
        </Col>
      </Row>
    </div>
  );
};

export default RowExpand;
