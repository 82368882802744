import { Types } from '../constant/action-type';
import { AccountDto, PageInfoDto } from '../accounts-api-client/data-contracts';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AccountType = {
  accountList?: {
    items?: AccountDto[];
    pageInfo?: PageInfoDto;
  };
  accountDetail?: AccountDto;
  accountSelected?: AccountDto;
};

type AccountPayload = {
  [Types.GET_ACCOUNT_DETAIL]: {
    account: AccountDto;
  };
  [Types.GET_ACCOUNT]: {
    accounts: {
      items?: AccountDto[];
      pageInfo?: PageInfoDto;
    };
  };
  [Types.SET_ACCOUNT_SELECTED]: {
    accountSelected: AccountDto;
  };
};

export type AccountActions = ActionMap<AccountPayload>[keyof ActionMap<AccountPayload>];

export const accountReducer = (state: AccountType, action: AccountActions) => {
  switch (action.type) {
    case Types.GET_ACCOUNT:
      const accountList = action.payload.accounts || [];
      return { ...state, accountList };
    case Types.GET_ACCOUNT_DETAIL:
      const account = action.payload.account || {};
      return { ...state, accountDetail: account };
    case Types.SET_ACCOUNT_SELECTED:
      const accountSelected = action.payload.accountSelected || {};
      return { ...state, accountSelected };
    default:
      return state;
  }
};
