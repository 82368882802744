import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { GlobalConfig } from '../global';

let request: AxiosInstance = axios.create();

request.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token') || '';
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  };
  config.baseURL = GlobalConfig().config?.REACT_APP_UPS_BASE_URL;
  return config;
});

export default request;
