/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AccountDto, AccountPageDto, ErrorDto, SitePageDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Accounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Account List
   *
   * @tags accounts
   * @name GetAccounts
   * @summary Account List
   * @request GET:/accounts
   * @secure
   */
  getAccounts = (
    query?: {
      search?: string;
      /** @example name:asc,website:desc,crmAcctId,activeStatus */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<AccountPageDto, any>({
      path: `/accounts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Single Account
   *
   * @tags account
   * @name GetAccount
   * @summary Account
   * @request GET:/accounts/{id}
   * @secure
   */
  getAccount = (id: string, params: RequestParams = {}) =>
    this.request<AccountDto, ErrorDto>({
      path: `/accounts/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Single Account By Crm User Id
   *
   * @tags account
   * @name GetAccountByCrmUserId
   * @summary Get Account By Crm User Id
   * @request GET:/accounts/crm/{id}
   * @secure
   */
  getAccountByCrmUserId = (id: string, params: RequestParams = {}) =>
    this.request<AccountDto, ErrorDto>({
      path: `/accounts/crm/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Site List
   *
   * @tags sites
   * @name GetSitesByAccount
   * @summary Site List
   * @request GET:/accounts/{account_id}/sites
   * @secure
   */
  getSitesByAccount = (
    accountId: string,
    query?: {
      /** @example abcxyz */
      search?: string;
      /** site status */
      siteStatus?: string[];
      /**
       * brand name
       * @example brand name
       */
      brandName?: string;
      /** @example name:asc,crmSiteRef:desc,brandName:asc,name,activeStatus */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<SitePageDto, ErrorDto>({
      path: `/accounts/${accountId}/sites`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
