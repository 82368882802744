import { InputTextarea } from 'primereact/inputtextarea';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { FormActionType, FormStateType } from '../../form-api-client/data-contracts';
import { useDispatch } from '../../context';
import { useToast } from '../../context/toast-context';
import { Forms } from '../../form-api-client/Forms';
import { getsCurrentAccount } from '../../utils/layout';
import { ROLE_TYPE, FORM_ACTION, FORM_STATUS, ROUTER, DEFINED_ERROR_CODE } from '../../constant';
import { get } from 'lodash';
import { Types } from '../../constant/action-type';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { getRole } from '../../utils';
import { ConfirmDialogStyled } from '../../components/StyledComponent';

const ACTION = {
  [FORM_ACTION.REJECT]: 'rejected',
  [FORM_ACTION.UNLOCK]: 'unlocked',
  [FORM_ACTION.APPROVE]: 'approved',
};

interface ModalProps {
  showModal: boolean;
  setShowModal: any;
  formId: string;
  form: any;
  setCommented: any;
  getFormDetail: any;
  formAction?: string;
}

const getFormStatus = (state: string) => {
  return get(FORM_STATUS, state, '');
};

const ApproveFormModal = ({
  showModal,
  setShowModal,
  formId,
  form,
  setCommented,
  getFormDetail,
  formAction = '',
}: ModalProps) => {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showDialog, setShowDialog] = useState(true);
  const dispatch = useDispatch();
  const toast = useToast();
  const role = getRole();
  const navigate = useNavigate();

  const formApi = new Forms();

  const onPutFormActionComment = async (action: FormActionType, formState: string) => {
    setLoading(true);
    if (!comment && action !== FormActionType.APPROVE) {
      setShowDialog(false);
      setIsSuccess(true);
      return;
    }
    const accountStored = JSON.parse(getsCurrentAccount() || '{}');
    await formApi
      .executeFormAction(formId, {
        action: action,
        payload: !comment
          ? {}
          : {
              comment: {
                formId: formId,
                content: comment,
                user: {
                  userId: accountStored?.id || '',
                  userName: role === ROLE_TYPE.THG ? 'THG - THG User' : 'Client User',
                },
              },
            },
      })
      .then((response: any) => {
        if (response?.data?.toState === formState) {
          dispatch({
            type: Types.GET_FORM_DETAIL,
            payload: {
              form: { ...form, state: formState },
            },
          });
          setComment('');
          setCommented(true);
          setShowDialog(false);
          setIsSuccess(true);
        }
      })
      .catch((error: any) => {
        let dataError = error?.response?.data;
        let messageDuplicateAction = `This form has been changed to ${getFormStatus(
          dataError?.transition?.fromState
        )}, form cannot be ${formState === FormStateType.APPROVED ? 'approved' : ACTION[formAction]} anymore.`;
        toast({
          code: dataError?.code || DEFINED_ERROR_CODE.ERR_CP_FROM_FE_ILLEGAL_FORM_STATE,
          message:
            dataError?.message && dataError?.code !== 'ERR_CP_FORM_C_0144'
              ? dataError?.message
              : messageDuplicateAction,
          type: 'error',
        });
        getFormDetail(formId);
        setLoading(false);
        setShowModal(false);
      });
  };

  // deepcode ignore AttrAccessOnNull
  const disableAction = !comment.trim();

  return (
    <>
      {isSuccess && (
        <ConfirmDialogStyled
          header={
            <div className="d-flex">
              <img src="/icon/check.svg" alt=""></img>
              Success
            </div>
          }
          show={isSuccess}
          btnCancel={{}}
          btnConfirm={{}}
          message={`Form has been ${ACTION[formAction]} successfully!`}
          footer={
            <div className="d-flex justify-content-end w-100">
              <Button
                variant="success"
                onClick={() => {
                  setShowModal(false);
                  navigate(`${ROUTER.MANAGEMENT_FORMS}`);
                }}
              >
                OK
              </Button>
            </div>
          }
        />
      )}
      {showDialog && (
        <ConfirmDialogStyled
          header={formAction}
          show={showModal}
          btnCancel={{ label: 'Cancel' }}
          btnConfirm={{ label: 'Approve', variant: 'primary' }}
          onHide={() => setShowModal(false)}
          onConfirm={() => onPutFormActionComment(FormActionType.APPROVE, FormStateType.APPROVED)}
          children={
            <InputTextarea
              className="p-input-area"
              style={{ width: '100%' }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              autoResize
              placeholder={`Please enter your comment (${
                formAction === FORM_ACTION.APPROVE ? 'optional' : 'required'
              })`}
              maxLength={65535}
              autoFocus
            />
          }
          footer={
            <>
              {loading && (
                <div className="d-flex justify-content-center w-100">
                  <Spinner />
                </div>
              )}
              {!loading && (
                <>
                  <Button className="me-1" variant="outline-primary" onClick={() => setShowModal(false)}>
                    Cancel
                  </Button>
                  {formAction === FORM_ACTION.APPROVE && (
                    <Button
                      className="ms-1"
                      variant="success"
                      onClick={() => onPutFormActionComment(FormActionType.APPROVE, FormStateType.APPROVED)}
                    >
                      Approve
                    </Button>
                  )}
                  {formAction === FORM_ACTION.UNLOCK && (
                    <Button
                      variant="primary"
                      className="ms-1"
                      onClick={() => onPutFormActionComment(FormActionType.UNLOCK, FormStateType.NEED_TO_BE_UPDATED)}
                      disabled={disableAction}
                    >
                      Unlock
                    </Button>
                  )}
                  {formAction === FORM_ACTION.REJECT && (
                    <Button
                      variant="danger"
                      className="ms-1"
                      onClick={() => onPutFormActionComment(FormActionType.REJECT, FormStateType.NEED_TO_BE_UPDATED)}
                      disabled={disableAction}
                    >
                      Reject
                    </Button>
                  )}
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default ApproveFormModal;
