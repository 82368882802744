import { Types } from '../constant/action-type';
import { FormTemplateConfigurationDto, FormTemplateDto } from '../form-api-client/data-contracts';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type FormTemplateType = {
  configuration?: FormTemplateConfigurationDto;
  formTemplate?: FormTemplateDto;
};

type FormTemplatePayload = {
  [Types.GET_FORM_TEMPLATE_CONFIG]: {
    configuration: FormTemplateConfigurationDto;
  };
  [Types.GET_FORM_TEMPLATE_DETAIL]: {
    formTemplate: FormTemplateDto;
  };
};

export type FormTemplateActions = ActionMap<FormTemplatePayload>[keyof ActionMap<FormTemplatePayload>];

export const formTemplateReducer = (state: FormTemplateType, action: FormTemplateActions) => {
  switch (action.type) {
    case Types.GET_FORM_TEMPLATE_CONFIG:
      const configuration = action.payload.configuration || {};
      return { ...state, configuration: configuration };
    case Types.GET_FORM_TEMPLATE_DETAIL:
      const formTemplate = action.payload.formTemplate || {};
      return { ...state, formTemplate: formTemplate };
    default:
      return state;
  }
};
