/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorDto, SiteDto, SitePageDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Sites<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Site List
   *
   * @tags sites
   * @name GetSites
   * @summary Site List Of All Accounts
   * @request GET:/sites
   * @secure
   */
  getSites = (
    query?: {
      /** account identifier */
      accountIds?: string[];
      /** @example abcxyz */
      search?: string;
      /** site status */
      siteStatus?: string[];
      /**
       * brand name
       * @example brand name
       */
      brandName?: string[];
      /** @example name:asc,crmSiteRef:desc,brandName:asc,name,activeStatus */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<SitePageDto, ErrorDto>({
      path: `/sites`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Single Site
   *
   * @tags site
   * @name GetSite
   * @summary Site
   * @request GET:/sites/{id}
   * @secure
   */
  getSite = (id: string, params: RequestParams = {}) =>
    this.request<SiteDto, ErrorDto>({
      path: `/sites/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
