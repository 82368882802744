export const checkDataUndefined = (data: any, defaultValue: any = '', row?: any) => {
  const dataValid = typeof data === 'function' ? data(defaultValue, row) : data;
  const convertDataToEmpty = defaultValue?.toString() === '0' ? '0' : '';

  return typeof data !== 'undefined' ? dataValid : defaultValue || convertDataToEmpty;
};

export const camelToTitle = (camelCase: string) => {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};
