import React from 'react';
import { get } from 'lodash';
import { STATUS_COLOR } from '../../constant';

interface StatusProps {
  status: string;
}
const StatusColor = ({ status = '' }: StatusProps) => {
  const statusColor = get(STATUS_COLOR, status.toUpperCase(), '');
  return (
    <div className="d-flex">
      <span className={`${statusColor} border border-light rounded-circle`} style={{ height: '25px', width: '25px' }} />
    </div>
  );
};

export default StatusColor;
