/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorDto, TaskDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Tasks<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get Subtasks by parent task
   *
   * @tags task
   * @name GetSubtasks
   * @summary Subtasks
   * @request GET:/tasks/{parentId}/subtasks
   * @secure
   */
  getSubtasks = (parentId: string, params: RequestParams = {}) =>
    this.request<TaskDto[], ErrorDto>({
      path: `/tasks/${parentId}/subtasks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
