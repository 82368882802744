import React, { useState, useLayoutEffect, useRef } from 'react';
import { Tooltip as PrimereactTooltip } from 'primereact/tooltip';
import { LEFT_MENU_STYLE } from '../../constant';
import { getsLeftMenuStyle } from '../../utils/layout';

interface TooltipProps {
  data: string;
  id: string;
  classname?: string;
  isLink?: boolean;
  link?: string;
  lastColumn?: boolean;
}

const Tooltip = ({ data = '', id, classname = '', isLink, link, lastColumn }: TooltipProps) => {
  const isExpandLeftMenu = getsLeftMenuStyle() !== LEFT_MENU_STYLE.CONDENSED;
  return (
    <div key={`${isExpandLeftMenu ? 'expand' : 'collapse'}-${id}`}>
      {isExpandLeftMenu && (
        <TooltipData
          data={data}
          id={`expand-${id}`}
          classname={classname}
          isLink={isLink}
          link={link}
          lastColumn={lastColumn}
        />
      )}
      {!isExpandLeftMenu && (
        <TooltipData
          data={data}
          id={`collapse-${id}`}
          classname={classname}
          isLink={isLink}
          link={link}
          lastColumn={lastColumn}
        />
      )}
    </div>
  );
};

const TooltipData = ({ data = '', id, isLink, link, lastColumn }: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isLeft, setIsLeft] = useState(true);
  const [isOverflow, setisOverflow] = useState(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current?.clientWidth < ref?.current?.scrollWidth) {
      if (lastColumn) {
        setIsLeft(ref.current?.clientWidth < ref?.current?.scrollWidth);
      } else {
        setIsLeft(ref.current?.clientWidth < ref?.current?.scrollWidth - 200);
      }
      setisOverflow(true);
    } else {
      setisOverflow(false);
    }
  }, [ref, lastColumn]);
  return (
    <div ref={ref} key={id}>
      {isOverflow && (
        <PrimereactTooltip
          target={`.tooltip-${id}`}
          content={isOverflow ? data : ''}
          position={`${isLeft ? 'left' : 'right'}`}
        />
      )}
      {!isLink && <div className={`tooltip-${id} ${isOverflow ? 'text-truncate' : ''}`}>{data}</div>}
      {isLink && (
        <div className={`tooltip-${id} ${isOverflow ? 'text-truncate' : ''}`}>
          <a href={link} target="_blank" rel="noreferrer noopener" id={id}>
            {data}
          </a>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
