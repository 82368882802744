import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accounts } from '../../accounts-api-client/Accounts';
import LayoutDetail from '../../components/layout-detail';
import { ErrorMessageAccountSelected, LabelInforNoneMargin, SpinnerStyled } from '../../components/StyledComponent';
import { Types } from '../../constant/action-type';
import { useAccountSelected, useDispatch, useSiteList } from '../../context';
import { handleErrorLogMessage, handleErrorStatus } from '../../utils';
import styled from 'styled-components';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { ERROR_MESSAGE_VIEW_CLIENT_PERMISSION, UNKNOWN_ERROR_CODE } from '../../constant';
import { useToast } from '../../context/toast-context';
import { SiteDto } from '../../accounts-api-client/data-contracts';

const FolderItemStyled = styled.div`
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding-right: 24px;
`;

const DetailInfoCustomSize = styled.span`
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const PreLoadDocumentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountSelected = useAccountSelected();
  const siteList = useSiteList();
  const [loading, setLoading] = useState(true);
  const [isAccountSelectedError, setIsAccountSelectedError] = useState(false);

  const accountApi = new Accounts();
  const toast = useToast();

  const fetchData = (id: string) => {
    accountApi
      .getSitesByAccount(id, {})
      .then((res) => {
        setLoading(false);
        const listSite = res.data;
        dispatch({
          type: Types.GET_SITE,
          payload: { sites: listSite },
        });
      })
      .catch((error) => {
        setLoading(false);
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
      });
  };

  useEffect(() => {
    if (accountSelected?.id) {
      fetchData(accountSelected?.id);
    } else {
      setLoading(false);
    }
  }, [accountSelected]);

  const renderAccountPublicFolder = () => {
    const folderId = accountSelected?.publicWorkdriveFolderId;
    if (!folderId) return null;

    return (
      <>
        <h3>Account</h3>
        <FolderItemStyled
          onClick={() =>
            navigate(`/management/documents/folder?accountId=${accountSelected.id}&folderName=${accountSelected.name}`)
          }
          className="col-2 mb-2"
        >
          <div className="text-center">
            <LabelInforNoneMargin>
              <img src="/folder_icon.png" alt="folder_icon" />
            </LabelInforNoneMargin>
          </div>
          <div className="text-center">
            <DetailInfoCustomSize>Public</DetailInfoCustomSize>
          </div>
        </FolderItemStyled>
      </>
    );
  };

  const setToSiteDocument = (item: SiteDto) => {
    navigate(`/management/documents/folder?siteId=${item?.id}&folderName=${item.name}`);
  };

  const renderProjectFolder = () => {
    return (
      <>
        <h3>Projects</h3>
        <div className="row">
          {siteList?.items
            ?.filter((item) => item.workdriveFolderId != null)
            ?.map((item) => (
              <FolderItemStyled key={item.id} onClick={() => setToSiteDocument(item)} className="col-2 mb-2">
                <div className="text-center">
                  <LabelInforNoneMargin>
                    <img src="/folder_icon.png" alt="folder_icon" />
                  </LabelInforNoneMargin>
                </div>
                <div className="text-center">
                  <DetailInfoCustomSize>{item.name}</DetailInfoCustomSize>
                </div>
              </FolderItemStyled>
            ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    let timeoutAccount = setTimeout(() => {
      const accountSelectedFromLocal = localStorage.getItem('accountSelected');
      if (!accountSelectedFromLocal) setIsAccountSelectedError(true);
    }, 3000);
    return () => clearTimeout(timeoutAccount);
  }, []);

  const checkAccountSelected = () => {
    if (!isAccountSelectedError)
      return (
        <LayoutDetail breadcrumbItems={[]} pageTitle={`Documents`} isLoading={false} layoutWidth="100%">
          <div>{renderAccountPublicFolder()}</div>
          <div>{renderProjectFolder()}</div>
        </LayoutDetail>
      );
    return <ErrorMessageAccountSelected>{ERROR_MESSAGE_VIEW_CLIENT_PERMISSION}</ErrorMessageAccountSelected>;
  };

  return (
    <>
      {loading ? (
        <SpinnerStyled>
          <Spinner />
        </SpinnerStyled>
      ) : (
        checkAccountSelected()
      )}
    </>
  );
};

export default PreLoadDocumentPage;
