import { DOWNLOAD_AREA, UNKNOWN_ERROR_CODE, UPLOAD_AREA } from '../constant';
import { handleErrorLogMessage } from '../utils';
import { FILE_MANAGEMENT_URL } from '../utils/baseUrl';
import { isEmpty } from 'lodash';

const applyBaseUrl = (page: any, toast: Function) => {
  page.elements.forEach((element: any) => {
    if (element.type === DOWNLOAD_AREA || element.type === UPLOAD_AREA) {
      element.properties.baseUrl = FILE_MANAGEMENT_URL;
      element.properties.handleErrorLogMessage = (error: any) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
      };
    }
  });
};

export const getLinkDownloadFile = (jsonFormTemplate: any, toast: Function) => {
  let jsonForm = { ...jsonFormTemplate };

  if (jsonForm?.pages && !isEmpty(jsonForm?.pages)) {
    Object.values(jsonForm?.pages)?.forEach((page: any) => {
      applyBaseUrl(page, toast);
    });
  } else {
    applyBaseUrl(jsonForm, toast);
  }

  return jsonForm;
};
