import { Types } from '../constant/action-type';
import { ProjectDto, PageInfoDto, TaskCount, MileStoneDto } from '../projects-api-client/data-contracts';

type ProjectMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type ProjectType = {
  projectList?: {
    items?: ProjectDto[];
    pageInfo?: PageInfoDto;
  };
  projectDetail?: ProjectDto;
  projectMilestones?: {
    items?: MileStoneDto[];
    pageInfo?: PageInfoDto;
  };
  projectIssues?: {
    items?: ProjectDto[];
    pageInfo?: PageInfoDto;
  };
  taskCount: TaskCount;
};

type ProjectPayload = {
  [Types.GET_PROJECT_DETAIL]: {
    project: ProjectDto;
  };
  [Types.GET_PROJECT]: {
    projects: {
      items?: ProjectDto[];
      pageInfo?: PageInfoDto;
    };
  };
  [Types.GET_PROJECT_MILESTONES]: {
    milestones: {
      items?: MileStoneDto[];
      pageInfo?: PageInfoDto;
    };
  };
  [Types.GET_PROJECT_ISSUES]: {
    issues: {
      items?: ProjectDto[];
      pageInfo?: PageInfoDto;
    };
  };
  [Types.GET_TASK_COUNT]: {
    taskCount: TaskCount;
  };
};

export type ProjectActions = ProjectMap<ProjectPayload>[keyof ProjectMap<ProjectPayload>];

export const projectReducer = (state: ProjectType, action: ProjectActions) => {
  switch (action.type) {
    case Types.GET_PROJECT:
      const projectList = action.payload.projects || [];
      return { ...state, projectList };
    case Types.GET_PROJECT_DETAIL:
      const project = action.payload.project || {};
      return { ...state, projectDetail: project };
    case Types.GET_PROJECT_MILESTONES:
      const projectMilestones = action.payload.milestones || {};
      return { ...state, projectMilestones };
    case Types.GET_TASK_COUNT:
      const taskCount = action.payload.taskCount || {};
      return { ...state, taskCount: taskCount };
    case Types.GET_PROJECT_ISSUES:
      const projectIssues = action.payload.issues || {};
      return { ...state, projectIssues };
    default:
      return state;
  }
};
