import React, { useEffect, useState } from 'react';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BsPlusSquare, BsDashSquare } from 'react-icons/bs';
import { findIndex, get } from 'lodash';
import SimplePaginator from './Paginator';
import { TABLE_EXPAND_TYPE, TASK_LEVEL_SIZE } from '../../constant';
import useAccessibility from '../../utils/useAccessibility';

interface ITable extends DataTableProps {
  columns: JSX.Element[];
  isExpand?: boolean;
  isSub?: boolean;
  noChil?: boolean;
  rowExpansionTemplate?: any;
  rowSubTemplate?: any;
  onClickSubTask?: any;
  level?: number;
}

function TableSubRow(props: ITable) {
  const {
    totalRecords,
    value,
    sortOrder,
    sortField,
    header,
    paginatorLeft,
    rows,
    first,
    onPage,
    loading,
    onSelectionChange,
    onSort,
    emptyMessage,
    columns,
    isExpand = false,
    isSub = false,
    noChil = false,
    rowExpansionTemplate = null,
    rowSubTemplate = null,
    onClickSubTask,
    level = 0,
  } = props;

  const handlePage = (event: any) => {
    localStorage.setItem('perPageTable', event.rows);
    if (onPage) {
      onPage(event);
    }
  };
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandType, setExpansType] = useState(TABLE_EXPAND_TYPE.EXPAND);
  const [subtaskData, setSubTaskData] = useState({});
  const { addAriaLabelToInput } = useAccessibility();

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToInput();
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const onExpand = async (e: any, data: any, type: string) => {
    e.stopPropagation();
    // @ts-ignore
    const expandedRowsTmp = expandType === type ? [...(expandedRows || [])] : [];
    setExpansType(type);
    const rowIndex = findIndex(expandedRowsTmp, (row: any) => row?.id === data?.id);
    if (rowIndex > -1) {
      expandedRowsTmp.splice(rowIndex, 1);
    } else {
      // @ts-ignore
      expandedRowsTmp.push(data);
      if (type === TABLE_EXPAND_TYPE.SUB) {
        const subData = await onClickSubTask(data?.id);
        const subTaskTmp = subtaskData;
        // @ts-ignore
        subTaskTmp[data?.id] = subData;
        setSubTaskData(subTaskTmp);
      }
    }

    // @ts-ignore
    setExpandedRows(expandedRowsTmp);
  };

  const checkIsExpand = (id: string) => {
    const rowIndex = findIndex(expandedRows, (row: any) => row?.id === id);
    return rowIndex > -1;
  };

  const getTemplate = (data: any) => {
    if (expandType === TABLE_EXPAND_TYPE.EXPAND) {
      return rowExpansionTemplate(data);
    }
    return rowSubTemplate(get(subtaskData, data?.id, []), onClickSubTask, columns, isSub);
  };

  return (
    <div className="cp-data-table">
      <DataTable
        {...props}
        totalRecords={totalRecords}
        value={value}
        scrollable
        responsiveLayout="scroll"
        sortOrder={sortOrder}
        sortField={sortField}
        header={header}
        paginatorLeft={paginatorLeft}
        paginator
        paginatorTemplate={SimplePaginator()}
        rows={rows}
        first={first}
        onPage={handlePage}
        emptyMessage={emptyMessage || 'No results found.'}
        loading={loading}
        lazy
        selectionMode="single"
        onSelectionChange={onSelectionChange}
        onSort={onSort}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        expandedRows={expandedRows}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rowExpansionTemplate={(data) => getTemplate(data)}
      >
        {isSub && (
          <Column
            body={(data) => (
              <div
                // className={`ps-${TASK_SUB_PADDING[level] - 1}`}
                onClick={(e) => onExpand(e, data, TABLE_EXPAND_TYPE.SUB)}
              >
                {!noChil && data?.hasSubTasks && (
                  <>
                    {checkIsExpand(data?.id) && expandType === TABLE_EXPAND_TYPE.SUB ? (
                      <BsDashSquare size={TASK_LEVEL_SIZE[level]} />
                    ) : (
                      <BsPlusSquare size={TASK_LEVEL_SIZE[level]} />
                    )}
                  </>
                )}
              </div>
            )}
            style={{ maxWidth: '47px' }}
          />
        )}
        {[...columns]}
        {isExpand && (
          <Column
            className="expand-row-toggle"
            body={(data) => (
              <button
                className="p-row-toggler"
                aria-label="Expand Row Toggle"
                onClick={(e) => onExpand(e, data, TABLE_EXPAND_TYPE.EXPAND)}
                aria-expanded={checkIsExpand(data?.id) && expandType === TABLE_EXPAND_TYPE.EXPAND}
              >
                <span className="p-row-toggler-icon pi pi-chevron-right" />
              </button>
            )}
            style={{ width: '45px', padding: 0, textAlign: 'center' }}
          />
        )}
      </DataTable>
    </div>
  );
}

export default React.memo(TableSubRow);
