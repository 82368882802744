import React, { useCallback, useEffect, useState } from 'react';
import { CardGroup, Row, Col, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useProjectMilestoneList, useDispatch } from '../../context';
import { LIST_MILESTONE_COLUMNS, TITLE_MILESTONE_TABLE, DATE_FORMAT, UNKNOWN_ERROR_CODE } from '../../constant';
import { Projects } from '../../projects-api-client/Projects';
import queryString from 'query-string';
import { Types } from '../../constant/action-type';
import { getPerPageTable, getSelectOptionAndArrayStringOption, handleErrorLogMessage } from '../../utils';
import Tooltip from '../../components/common/tooltip';
import { StatusDto } from '../../projects-api-client/data-contracts';
import { Settings } from '../../projects-api-client/Settings';
import CircleProgressBar from '../circle-progress';
import { Datatable, FormReactSelect } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { useToast } from '../../context/toast-context';
import useAccessibility from '../../utils/useAccessibility';

interface IParamSearchProject {
  milestoneStatuses: string[] | [];
  search: string;
  page: number;
  limit: number;
  sortBy: string;
  sortOrder?: number;
  sortField?: string;
}

interface MilestoneProps {
  projectId: string;
}

function Milestones({ projectId = '' }: MilestoneProps) {
  const perPageTable = getPerPageTable();
  const location = useLocation();
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '', milestoneStatuses } = paramFromUrl; //NOSONAR
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'desc' ? -1 : 1;
  const newMilestoneStatusesOption = getSelectOptionAndArrayStringOption(milestoneStatuses);
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField || 'startDate',
    sortOrder: sortOrder || 1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    milestoneStatuses: newMilestoneStatusesOption.arrayStringOption || [],
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState<any>([]);
  const [listOptions, setListOptions] = useState<any>([]);
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);
  const [isFilter, setIsFilter] = useState(false);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);

  const projectApi = new Projects();

  const projectSettingApi = new Settings();

  const projectMilestones = useProjectMilestoneList();
  const { addAriaLabelToTable } = useAccessibility();
  const dispatch = useDispatch();
  const toast = useToast();
  let navigate = useNavigate();

  const getProjectMilestoneStatus = async () => {
    await projectSettingApi
      .getMilestonesStatuses()
      .then((res) => {
        if (res?.data?.length) {
          const newListOptions = res.data.map((option: StatusDto) => {
            return {
              value: option.displayValue,
              label: option.displayValue,
            };
          });
          setListOptions(newListOptions);
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
      });
  };

  const MilestonesCol = LIST_MILESTONE_COLUMNS.map((acc) => {
    if (acc.field === TITLE_MILESTONE_TABLE.MILESTONE_NAME)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        className: 'max-width-none',
        style: { minWidth: '150px' },
        body: (data: any) => <Tooltip data={data.name} id={`name-${data.id}`} />,
      };
    if (acc.field === TITLE_MILESTONE_TABLE.START_DATE)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '145px', minWidth: '145px' },
        body: (data: any) => (
          <Tooltip data={moment(data.startDate).format(DATE_FORMAT) || ''} id={`startDate-${data.id}`} />
        ),
      };
    if (acc.field === TITLE_MILESTONE_TABLE.END_DATE)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '145px', minWidth: '145px' },
        body: (data: any) => (
          <Tooltip data={moment(data.endDate).format(DATE_FORMAT) || ''} id={`endDate-${data.id}`} />
        ),
      };
    if (acc.field === TITLE_MILESTONE_TABLE.DURATION)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '145px', minWidth: '145px' },
        body: (data: any) => <Tooltip data={`${data.duration} days`} id={`duration-${data.id}`} />,
      };
    if (acc.field === TITLE_MILESTONE_TABLE.STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '200px', minWidth: '200px' },
        body: (data: any) => data.status,
      };

    return {
      resizeable: true,
      field: acc.field,
      label: acc.header,
      sortable: true,
      className: 'max-width-none',
    };
  });

  const fetchData = (param: any) => {
    setIsLoadingTable(true);
    const {
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      milestoneStatuses: milestoneStatusesParam,
      first,
    } = param;
    if (milestoneStatusesParam.length > 0) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    setParametersSearch({
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      milestoneStatuses: milestoneStatusesParam,
      first,
    });
    const sortByParam = `${sortFieldParam}:${sortOrderParam === 1 ? 'asc' : 'desc'}`;
    const paramSubmit = {
      search: searchKey || '',
      limit: Number(rows) || 0,
      page: Number(pageParam) || 0,
      sortBy: sortByParam,
      milestoneStatuses: milestoneStatusesParam,
    };
    updateUrlSearch(paramSubmit);

    projectApi
      .getMilestonesByProject(projectId, paramSubmit)
      .then((res) => {
        const milestones = res.data;
        dispatch({
          type: Types.GET_PROJECT_MILESTONES,
          payload: { milestones },
        });
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
      })
      .finally(() => setIsLoadingTable(false));
  };

  // trigger function whenever table change
  const customFetch = useCallback((params: any) => {
    fetchData(params);
  }, []);

  const updateUrlSearch = (param: IParamSearchProject) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="orders-subtable">
        <CardGroup className="d-flex flex-row my-1 flex-nowrap">
          <div className="d-flex align-items-center">
            <CircleProgressBar sqSize={80} percent={parseFloat(data?.percentage) || 0} color="green" />
          </div>
          <div className="mx-2">
            <h4>Milestone Definition</h4>
            <div className="break-line">{data?.definition}</div>
          </div>
        </CardGroup>
      </div>
    );
  };

  useEffect(() => {
    getProjectMilestoneStatus();
    const projectStatusOptions = getSelectOptionAndArrayStringOption(milestoneStatuses);
    if (projectStatusOptions?.selectOption) {
      setSelectedProjectStatus(projectStatusOptions?.selectOption);
    }
  }, []);

  const onReset = () => {
    setSelectedProjectStatus([]);
    let paramSubmit: any = {};
    paramSubmit = {
      ...parametersSearch,
      searchKey: '',
      milestoneStatuses: [],
    };
    updateUrlSearch(paramSubmit);
    onFilter && onFilter({ milestoneStatuses: [] });
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };

  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );

  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);

  const onRowToggle = (e: any) => {
    setExpandedRows(e.data || undefined);
  };

  useEffect(() => {
    if (!showFilter) {
      setSelectedProjectStatus(newMilestoneStatusesOption.selectOption || []);
    }
  }, [showFilter, location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [projectMilestones]);

  return (
    <>
      {isFilter && (
        <h4 className="mb-3 datatable-title">{`Milestones (${projectMilestones?.pageInfo?.total}) - Filtered by: Milestone Status`}</h4>
      )}
      <Datatable
        fetchData={customFetch}
        totalRecords={projectMilestones?.pageInfo?.total || 0}
        data={projectMilestones?.items || []}
        isLoading={isLoadingTable}
        columns={MilestonesCol}
        tableName="Milestones"
        emptyMessage="No results found."
        initParams={parametersSearch}
        columnResizeMode="fit"
        sortField={parametersSearch.sortField}
        searchKey={parametersSearch.searchKey}
        isFilter={true}
        isHideShowingAllLabel={isFilter}
        onClickFilterButton={onClickFilterButton}
        onRowToggle={onRowToggle}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        isEnableExpandRow={true}
        scrollable
        expandedToggleColumnPosition={5}
      />
      <RightSidebar
        title="Filters"
        setShowRightSidebar={(value) => {
          setShowFilter(value);
        }}
        showRightSidebar={showFilter}
      >
        <div className="rightsidebar-content-height">
          <FormReactSelect
            value={selectedProjectStatus}
            onChange={setSelectedProjectStatus}
            name="milestoneStatuses"
            label="Milestone Status"
            placeholder="Select Milestone Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions}
            isMulti
          />
          <Row>
            <Col sm={6}>
              <Button
                className="w-100 justify-content-center"
                type="button"
                onClick={() => onReset()}
                variant="outline-primary"
              >
                Reset
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                type="button"
                className="w-100 justify-content-center"
                onClick={() => {
                  setShowFilter(false);
                  onFilterSidebar({
                    milestoneStatuses: selectedProjectStatus.map((field: any) => field.value),
                  });
                }}
              >
                Apply filter
              </Button>
            </Col>
          </Row>
        </div>
      </RightSidebar>
    </>
  );
}

export default Milestones;
