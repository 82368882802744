import { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Types } from '../../constant/action-type';
import { Accounts } from '../../accounts-api-client/Accounts';
import { useDispatch, useAccountDetail } from '../../context';
import { checkDynamicLink, convertTimeUTC, handleErrorLogMessage, handleErrorStatus } from '../../utils';
import { LinkUnderline, LabelInforNoneMargin, DetailInforCustomSize } from '../../components/StyledComponent';
import LayoutDetail from '../../components/layout-detail';
import { DATE_TIME_FORMAT, PERMISSIONS, ROUTER, UNKNOWN_ERROR_CODE } from '../../constant';
import { Button, FormInput } from '@thg-harveynash/hyper-shared-components';
import { usePermissions } from '../../context/auth-context';
import { GlobalConfig } from '../../global';
import { useToast } from '../../context/toast-context';

const AccountDetailPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  let params = useParams();
  const id = params && params.id ? params.id : '';
  const account = useAccountDetail();
  const accountApi = new Accounts();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissionList } = usePermissions();
  const canViewSiteList = permissionList.includes(PERMISSIONS.view_site_list_of_account);
  const toast = useToast();

  const breadcrumbItems = [
    { path: '/configuration/accounts', name: 'Accounts', active: false },
    { path: '', name: `${account?.name}`, active: true },
  ];

  const getAccountDetail = async (accountId: string) => {
    try {
      await accountApi
        .getAccount(accountId)
        .then((res) => {
          dispatch({
            type: Types.GET_ACCOUNT_DETAIL,
            payload: {
              account: res.data,
            },
          });
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
          navigate(ROUTER.ERROR_PAGE);
        });
    } catch (e) {
      navigate('/error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccountDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRedirectToSitesListScreen = () => {
    navigate(`/configuration/accounts/${id}/sites`);
  };

  const onChangePath = (path: string) => {
    navigate(path);
  };

  return (
    <LayoutDetail
      pageTitle={`${account?.name}: View Details`}
      breadcrumbItems={breadcrumbItems}
      onChangePathBreadCrumb={(path: string) => onChangePath(path)}
      isLoading={isLoading}
    >
      <Row className="account-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>CP Account ID</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{account?.id}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="account-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>CRM Account ID</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 crm-account-id">
          <DetailInforCustomSize>{account?.crmAcctId}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="account-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Account Name</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-name">
          <DetailInforCustomSize>
            <LinkUnderline
              className="link-redirect"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="account-name"
              role="link"
              href={`${GlobalConfig().config.REACT_APP_CRM_ZOHO_URL}${account?.organization?.domainName}/tab/Accounts/${
                account?.crmSysId
              }`}
            >
              {account?.name}
            </LinkUnderline>
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="account-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Website</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-website">
          <DetailInforCustomSize>
            <LinkUnderline
              aria-label="account-website"
              role="link"
              href={checkDynamicLink(account?.website)}
              rel="noopener noreferrer"
              target="_blank"
              className="link-redirect"
            >
              {account?.website}
            </LinkUnderline>
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="account-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Public Folder Workdrive ID</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-workdrive-id">
          <DetailInforCustomSize>
            {/* need to fix this */}
            {account?.publicWorkdriveFolderId || account?.workdriveFolderId}
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="account-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Workdrive Folder URL</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-workdrive-url">
          <DetailInforCustomSize>
            <LinkUnderline
              aria-label="account-workdrive-url"
              role="link"
              href={checkDynamicLink(account?.workdriveUrl)}
              rel="noopener noreferrer"
              target="_blank"
              className="link-redirect"
            >
              {account?.workdriveUrl}
            </LinkUnderline>
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="account-info detail-info align-items-center">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Active</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2">
          <FormInput
            className="min-height-none"
            name="activeAccount"
            type="switch"
            aria-label="account-site"
            checked
            disabled
          />
        </Col>
      </Row>
      <Card.Text className="text-end mb-0 account-create">
        <small>
          Created By: {account?.createdBy} | Created Date: {convertTimeUTC(account?.createdAt, DATE_TIME_FORMAT)}
        </small>
      </Card.Text>
      <Card.Text className="text-end account-modify">
        <small>
          Modified By: {account?.modifiedBy ? account?.modifiedBy : account?.createdBy} | Modified Date:{' '}
          {account?.modifiedAt
            ? convertTimeUTC(account?.modifiedAt, DATE_TIME_FORMAT)
            : convertTimeUTC(account?.createdAt, DATE_TIME_FORMAT)}
        </small>
      </Card.Text>
      <div className="form">
        <div className="form-action d-flex flex-row align-items-center justify-content-between">
          <Button type="button" variant="outline-primary" onClick={() => navigate(-1)}>
            Back
          </Button>
          <div className="group-actions d-flex flex-row align-items-center justify-content-end">
            {canViewSiteList && (
              <Button className="ms-2" onClick={onRedirectToSitesListScreen}>
                Sites
              </Button>
            )}
          </div>
        </div>
      </div>
    </LayoutDetail>
  );
};

export default AccountDetailPage;
