import React from 'react';
import classNames from 'classnames';
import {
  PaginatorCurrentPageReportOptions,
  PaginatorFirstPageLinkOptions,
  PaginatorLastPageLinkOptions,
  PaginatorNextPageLinkOptions,
  PaginatorPageLinksOptions,
  PaginatorPrevPageLinkOptions,
  PaginatorRowsPerPageDropdownOptions,
  PaginatorTemplateOptions,
} from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';

const dropdownOptions = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const Paginator = (): PaginatorTemplateOptions => {
  const dropdownItemsTrigger = () => {
    document.querySelectorAll('.p-dropdown-items').forEach((el) => {
      el.setAttribute('aria-label', 'Dropdown items');
    });
  };
  return {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
    PrevPageLink: (options: PaginatorPrevPageLinkOptions) => {
      const { className, onClick, disabled } = options;
      return (
        <button
          data-testid="btn-datatable-prev"
          type="button"
          className={className}
          onClick={onClick}
          disabled={disabled}
        >
          Previous
        </button>
      );
    },
    NextPageLink: (options: PaginatorNextPageLinkOptions) => {
      const { className, onClick, disabled } = options;
      return (
        <button
          data-testid="btn-datatable-next"
          type="button"
          className={className}
          onClick={onClick}
          disabled={disabled}
        >
          Next
        </button>
      );
    },
    PageLinks: (options: PaginatorPageLinksOptions) => {
      const { className, onClick, page, view, totalPages } = options;
      if ((view.startPage === page && view.startPage !== 0) || (view.endPage === page && page + 1 !== totalPages)) {
        const class_name = classNames(className, { 'p-disabled': true });

        return (
          <span className={class_name} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={className} onClick={onClick}>
          {page + 1}
        </button>
      );
    },
    FirstPageLink: (_options: PaginatorFirstPageLinkOptions) => null,
    LastPageLink: (_options: PaginatorLastPageLinkOptions) => null,
    RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
      const { value, onChange } = options;
      const onClick = () => {
        setTimeout(() => {
          dropdownItemsTrigger();
        }, 300);
      };
      return (
        <div className="d-flex align-items-center ms-2">
          <Dropdown
            value={value}
            options={dropdownOptions}
            onChange={onChange}
            dropdownIcon="p-dropdown-icon"
            ariaLabel="Rows Per Page"
            appendTo="self"
            title="Rows Per Page"
            onFocus={onClick}
          />
          <span className="p-paginator-text ms-2">Per page</span>
        </div>
      );
    },
    CurrentPageReport: (_options: PaginatorCurrentPageReportOptions) => {
      return <></>;
    },
    JumpToPageInput: undefined,
  };
};
export default Paginator;
