import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Tooltip from '../common/tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LIST_ISSUE_COLUMNS,
  TITLE_ISSUE_TABLE,
  DATE_FORMAT,
  DATE_FILTER_FORMAT,
  UNKNOWN_ERROR_CODE,
} from '../../constant';
import { ResponsiblePartyDto, SeverityDto, StatusDto } from '../../projects-api-client/data-contracts';
import { Projects } from '../../projects-api-client/Projects';
import { Settings } from '../../projects-api-client/Settings';
import {
  getPerPageTable,
  handleErrorStatus,
  convertTime,
  getValueOption,
  getSelectOptionAndArrayStringOption,
  getMilestoneByIds,
  handleErrorLogMessage,
} from '../../utils';
import queryString from 'query-string';
import StatusColor from '../common/status-color';
import { Datatable, FormReactSelect } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { Button, Row, Col, Card, Overlay, Tooltip as TooltipBoostrap } from 'react-bootstrap';
import { LabelInforNoneMargin } from '../StyledComponent';
import useAccessibility from '../../utils/useAccessibility';
import { useToast } from '../../context/toast-context';

interface IParamSearchProject {
  milestoneIds: string[] | [];
  responsibleParties: string[] | [];
  severities: string[] | [];
  statuses?: string[] | [];
  search: string;
  page: number;
  limit: number;
  sortBy: string;
}

interface IssuesProps {
  projectId: string;
}
const IssueTracker = ({ projectId = '' }: IssuesProps) => {
  const location = useLocation();
  const { addAriaLabelToTable } = useAccessibility();
  const paramFromUrl = queryString.parse(location.search);
  const perPageTable = getPerPageTable();
  const defaultSortField = 'status';
  const { search, limit, page, sortBy = '', milestoneIds, responsibleParties, severities, statuses } = paramFromUrl; //NOSONAR
  const [listOptions, setListOptions] = useState<any>({
    milestone: [],
    statuses: [],
    responsibleParties: [],
    severities: [],
  });
  const newMilestoneOption = getSelectOptionAndArrayStringOption(milestoneIds);
  const newResponsibleParties = getSelectOptionAndArrayStringOption(responsibleParties);
  const newSeverities = getSelectOptionAndArrayStringOption(severities);
  const newIssueStatus = getSelectOptionAndArrayStringOption(statuses);
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0] || defaultSortField;
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
  const [expandedRows, setExpandedRows] = useState<any[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [milestone, setMilestone] = useState<any>(getMilestoneByIds(milestoneIds, listOptions.milestone));
  const [reParty, setReParty] = useState<any>(newResponsibleParties.selectOption);
  const [severity, setSeverity] = useState<any>(newSeverities.selectOption);
  const [issueStatus, setIssueStatus] = useState<any>(newIssueStatus.selectOption);
  const [isFilter, setIsFilter] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField,
    sortOrder: sortBySplit[1] ? sortOrder : 1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    first: limit && page ? Number(limit) * Number(page) : 0,
    milestoneIds: newMilestoneOption.arrayStringOption || [],
    responsibleParties: newResponsibleParties.arrayStringOption || [],
    severities: newSeverities.arrayStringOption || [],
    statuses: newIssueStatus.arrayStringOption || [],
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [showInfor, setShowInfor] = useState(false);
  useEffect(() => {
    if (isFetch) {
      const newParamFromUrl = queryString.parse(location.search);
      const { milestoneIds: milestoneIdsState } = newParamFromUrl;
      setMilestone(getMilestoneByIds(milestoneIdsState, listOptions.milestone));
    }
  }, [parametersSearch, isFetch]);

  const projectApi = new Projects();
  const projectSettingApi = new Settings();
  let navigate = useNavigate();
  const toast = useToast();

  const handleErrorLogProject = (error: any) => {
    handleErrorStatus(error?.status);
    handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
  };

  const getlistOptions = async () => {
    let newListOption = listOptions;
    await projectApi
      .getMilestonesByProject(projectId)
      .then((res) => {
        if (res?.data) {
          const milestoneOptions = res.data.items?.map((option) => {
            return {
              value: option.id,
              label: option.name,
            };
          });
          newListOption = {
            ...newListOption,
            milestone: milestoneOptions,
          };
        }
      })
      .catch((error) => {
        handleErrorLogProject(error);
      });
    await projectSettingApi
      .getIssueStatuses()
      .then((res) => {
        if (res?.data?.length) {
          const issueStatusOptions = res.data.map((option: StatusDto) => getValueOption(option.displayValue));
          newListOption = {
            ...newListOption,
            statuses: issueStatusOptions,
          };
        }
      })
      .catch((error) => {
        handleErrorLogProject(error);
      });
    await projectSettingApi
      .getResponsiblePartiesForIssue()
      .then((res) => {
        if (res?.data?.length) {
          const responsiblePartiesOptions = res.data.map((option: ResponsiblePartyDto) => getValueOption(option.value));
          newListOption = {
            ...newListOption,
            responsibleParties: responsiblePartiesOptions,
          };
        }
      })
      .catch((error) => {
        handleErrorLogProject(error);
      });
    await projectSettingApi
      .getSeverities()
      .then((res) => {
        if (res?.data?.length) {
          const severitiesOptions = res.data.map((option: SeverityDto) => getValueOption(option.value));
          newListOption = {
            ...newListOption,
            severities: severitiesOptions,
          };
        }
      })
      .catch((error) => {
        handleErrorLogProject(error);
      });
    setListOptions(newListOption);
    setIsFetch(true);
  };

  const currentDate = moment().format(DATE_FILTER_FORMAT);

  const target = useRef(null);

  const listIssueCol = LIST_ISSUE_COLUMNS.map((issue) => {
    switch (issue.field) {
      case TITLE_ISSUE_TABLE.ISSUE_NAME:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.name} id={`title-${data?.id}`} />,
          className: 'max-width-none',
          style: { minWidth: '160px' },
        };
      case TITLE_ISSUE_TABLE.DUE_DATE:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          body: (data: any) => (
            <Tooltip data={convertTime(data?.dueDate, DATE_FORMAT) || ''} id={`dueDate-${data?.id}`} />
          ),
          style: { maxWidth: '150px', minWidth: '150px' },
        };
      case TITLE_ISSUE_TABLE.AREA:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.area} id={`type-${data?.id}`} />,
          className: 'max-width-none',
          style: { minWidth: '120px' },
        };
      case TITLE_ISSUE_TABLE.SUB_AREA:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.subArea} id={`type-${data?.id}`} />,
          className: 'max-width-none',
          style: { minWidth: '200px' },
        };
      case TITLE_ISSUE_TABLE.RESPONSIBLE_PARTY:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.responsibleParty} id={`responsibleParty-${data?.id}`} />,
          style: { maxWidth: '170px', minWidth: '170px' },
        };
      case TITLE_ISSUE_TABLE.SEVERITY:
        return {
          resizeable: true,
          field: issue.field,
          label: (
            <span ref={target} onMouseOver={() => setShowInfor(true)} onMouseLeave={() => setShowInfor(false)}>
              {issue.header}
            </span>
          ),
          sortable: true,
          body: (data: any) => <Tooltip data={data?.severity} id={`severities-${data?.id}`} />,
          style: { maxWidth: '150px', minWidth: '150px' },
          ref: target,
          headerClassName: 'severities-header',
        };
      case TITLE_ISSUE_TABLE.ISSUE_STATUS:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.status} id={`status-${data?.id}`} />,
          style: { maxWidth: '160px', minWidth: '160px' },
        };
      case TITLE_ISSUE_TABLE.RAG_STATUS:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: false,
          body: (data: any) => (
            <StatusColor
              status={
                moment(moment(data?.dueDate).format(DATE_FILTER_FORMAT)).isBefore(currentDate) &&
                data?.status !== 'Resolved'
                  ? 'Red'
                  : 'Green'
              }
            />
          ),
          style: { maxWidth: '130px', minWidth: '130px' },
        };
      default:
        return {
          resizeable: true,
          field: issue.field,
          label: issue.header,
          sortable: true,
          style: { width: '120px' },
          className: 'max-width-none',
        };
    }
  });

  const updateUrlSearch = (param: IParamSearchProject) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  useEffect(() => {
    getlistOptions();
  }, []);

  const [issuesData, setIssuesData] = useState<any>({});
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);

  const getTableData = async (param: any) => {
    setIsLoadingTable(true);
    const {
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      first,
      milestoneIds: milestoneIdsParam,
      responsibleParties: responsiblePartiesParam,
      severities: severitiesParam,
      statuses: statusesParam,
    } = param;
    if (
      milestoneIdsParam.length > 0 ||
      responsiblePartiesParam.length > 0 ||
      severitiesParam.length > 0 ||
      statusesParam.length > 0
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    const newParam = { rows, page: pageParam, sortField: sortFieldParam, sortOrder: sortOrderParam, searchKey, first };
    setParametersSearch(newParam);
    const sortByParam = `${sortFieldParam}:${sortOrderParam === 1 ? 'asc' : 'desc'}`;
    const paramSubmit = {
      search: searchKey,
      limit: rows,
      page: pageParam,
      sortBy: sortByParam,
      milestoneIds: milestoneIdsParam,
      responsibleParties: responsiblePartiesParam,
      severities: severitiesParam,
      statuses: statusesParam,
    };
    updateUrlSearch(paramSubmit);
    projectApi
      .getIssuesByProject(projectId, paramSubmit)
      .then((res) => {
        setIssuesData(res.data);
        setIsLoadingTable(false);
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
        setIsLoadingTable(false);
      });
  };

  const fetchData = useCallback((params: any) => {
    getTableData(params);
  }, []);

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="orders-subtable">
        <div className="mx-2">
          <Row className="project-info detail-info issue-expand">
            <Col xl={2} lg={3} md={4} className="py-2">
              <LabelInforNoneMargin>Milestone Associated</LabelInforNoneMargin>
            </Col>
            <Col xl={10} lg={9} md={8} className="py-2">
              <Card.Text className="break-line font-14">{data?.milestoneName}</Card.Text>
            </Col>
          </Row>
          <Row className="project-info detail-info issue-expand">
            <Col xl={2} lg={3} md={4} className="py-2">
              <LabelInforNoneMargin>Issue Description</LabelInforNoneMargin>
            </Col>
            <Col xl={10} lg={9} md={8} className="py-2  font-14">
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </Col>
          </Row>
          <Row className="project-info detail-info issue-expand">
            <Col xl={2} lg={3} md={4} className="py-2">
              <LabelInforNoneMargin>Action Required</LabelInforNoneMargin>
            </Col>
            <Col xl={10} lg={9} md={8} className="py-2">
              <Card.Text className="break-line font-14">{data?.actionRequired}</Card.Text>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const onRowToggle = (e: any) => {
    setExpandedRows(e.data || undefined);
  };
  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);
  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );
  const onReset = () => {
    onFilterSidebar({
      milestoneIds: [],
      responsibleParties: [],
      severities: [],
      statuses: [],
    });
    setMilestone([]);
    setReParty([]);
    setSeverity([]);
    setIssueStatus([]);
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };
  const renderFilterText = () => {
    let filterText = [
      milestone.length > 0 ? 'Milestone' : undefined,
      reParty.length > 0 ? ' Responsible Party' : undefined,
      severity.length > 0 ? ' Severity' : undefined,
      issueStatus.length > 0 ? ' Issue Status' : undefined,
    ];
    return filterText.filter((item) => item).join();
  };

  useEffect(() => {
    if (!showFilter) {
      setMilestone(getMilestoneByIds(milestoneIds, listOptions.milestone) || []);
      setReParty(newResponsibleParties.selectOption || []);
      setSeverity(newSeverities.selectOption || []);
      setIssueStatus(newIssueStatus.selectOption || []);
    }
  }, [showFilter, location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [issuesData]);

  return (
    <>
      {isFilter && (
        <h4 className="mb-3 datatable-title">{`Issue Tracker (${
          issuesData?.pageInfo?.total
        }) - Filtered by: ${renderFilterText()}`}</h4>
      )}
      <div>
        <Overlay key="tooltip-top-uploaded" placement="top" show={showInfor} target={target.current}>
          <TooltipBoostrap id="severities-header">
            <Card.Text className="break-line">Low – No impact to project or its launch date</Card.Text>
            <Card.Text className="break-line">
              Medium – If not resolved with 5 working days will have impact on project and launch date
            </Card.Text>
            <Card.Text className="break-line">
              High - If not resolved with 2 working days will have impact on project and launch date
            </Card.Text>
          </TooltipBoostrap>
        </Overlay>
      </div>
      <Datatable
        fetchData={fetchData}
        totalRecords={issuesData?.pageInfo?.total || 0}
        data={issuesData?.items || []}
        isLoading={isLoadingTable}
        columns={listIssueCol}
        tableName="Issue Tracker"
        emptyMessage="No results found."
        initParams={parametersSearch}
        columnResizeMode="fit"
        sortField={parametersSearch.sortField}
        searchKey={parametersSearch.searchKey}
        isFilter={true}
        onRowToggle={onRowToggle}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onClickFilterButton={onClickFilterButton}
        isHideShowingAllLabel={isFilter}
        isEnableExpandRow={true}
        scrollable
        expandedToggleColumnPosition={8}
      />
      <RightSidebar
        title="Filters"
        setShowRightSidebar={(value) => {
          setShowFilter(value);
        }}
        showRightSidebar={showFilter}
      >
        <div className="rightsidebar-content-height">
          <FormReactSelect
            value={milestone}
            onChange={setMilestone}
            name="milestones"
            label="Milestone"
            placeholder="Select Milestones"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.milestone}
            isMulti
          />
          <FormReactSelect
            value={reParty}
            onChange={setReParty}
            name="responsibleParty"
            label="Responsible Party"
            placeholder="Select Responsible Party"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.responsibleParties}
            isMulti
          />
          <FormReactSelect
            value={severity}
            onChange={setSeverity}
            name="severity"
            label="Severity"
            placeholder="Select Severity"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.severities}
            isMulti
          />
          <FormReactSelect
            value={issueStatus}
            onChange={setIssueStatus}
            name="status"
            label="Issue Status"
            placeholder="Select Issue Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.statuses}
            isMulti
          />
          <Row>
            <Col sm={6}>
              <Button
                className="w-100 justify-content-center"
                type="button"
                onClick={() => onReset()}
                variant="outline-primary"
              >
                Reset
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                type="button"
                className="w-100 justify-content-center"
                onClick={() => {
                  setShowFilter(false);
                  onFilterSidebar({
                    search: '',
                    milestoneIds: milestone.map((field: any) => field.value),
                    responsibleParties: reParty.map((field: any) => field.value),
                    severities: severity.map((field: any) => field.value),
                    statuses: issueStatus.map((field: any) => field.value),
                  });
                }}
              >
                Apply filter
              </Button>
            </Col>
          </Row>
        </div>
      </RightSidebar>
    </>
  );
};
export default IssueTracker;
