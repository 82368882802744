import { Types } from '../constant/action-type';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type ProductType = {
  id: number;
  name: string;
  price: number;
};

type ProductPayload = {
  [Types.GET_PRODUCT]: {
    products: Array<ProductType>;
  };
};

export type ProductActions = ActionMap<ProductPayload>[keyof ActionMap<ProductPayload>];

export const productReducer = (state: ProductType[], action: ProductActions) => {
  // eslint-disable-next-line prettier/prettier
  switch (action.type) { //NOSONAR
    case Types.GET_PRODUCT:
      const products = action.payload.products || [];
      return [...products];
    default:
      return state;
  }
};
