import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sites } from '../../accounts-api-client/Sites';
import { Brands } from '../../accounts-api-client/Brands';
import { PERMISSIONS, SITES_PAGE_COLUMNS, TITLE_SITES_PAGE_TABLE, UNKNOWN_ERROR_CODE } from '../../constant';
import { Types } from '../../constant/action-type';
import { useDispatch, useLeftMenuStyle, useSiteList } from '../../context';
import {
  getListIdShowTooltipInPrimeTable,
  getPerPageTable,
  getSelectOptionAndArrayStringOption,
  handleErrorStatus,
  handleErrorLogMessage,
} from '../../utils';
import { ParametersSearchI } from '../account-page';
import queryString from 'query-string';
import { Settings } from '../../accounts-api-client/Settings';
import { BrandDto, SiteStatusDto } from '../../accounts-api-client/data-contracts';
import { Tooltip as PrimereactTooltip } from 'primereact/tooltip';
import {
  Button,
  CommonActionBar,
  Datatable,
  FormInputContainer,
  FormReactSelect,
} from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import AsyncSelect from 'react-select/async';
import { usePermissions } from '../../context/auth-context';
import { ClearIndicator } from '../../components/common/ClearIndicator';
import { GlobalConfig } from '../../global';
import useAccessibility from '../../utils/useAccessibility';
import { useToast } from '../../context/toast-context';
import { Filters } from '../../accounts-api-client/Filters';

export interface ICol {
  field: string;
  header: string;
}

// eslint-disable-next-line prettier/prettier
function SitesPage() { //NOSONAR
  const location = useLocation();
  const perPageTable = getPerPageTable();
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '', accountIds, siteStatuses, brandName } = paramFromUrl; //NOSONAR
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
  const leftMenuStyle = useLeftMenuStyle();
  const newOption = getSelectOptionAndArrayStringOption(siteStatuses);
  const newOptionBrand = getSelectOptionAndArrayStringOption(brandName);
  const newOptionAccount = getSelectOptionAndArrayStringOption(accountIds);
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);
  const { addAriaLabelToTable } = useAccessibility();
  const { permissionList } = usePermissions();
  const canViewDetails = permissionList.includes(PERMISSIONS.view_site_details);

  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField || 'crmSiteRef',
    sortOrder: sortOrder || -1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    siteStatuses: newOption.arrayStringOption || [],
    accountIds: newOptionAccount.arrayStringOption || [],
    brandName: newOptionBrand.arrayStringOption || [],
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const handleFilterField = () => {
    let filterText = [
      newOption.arrayStringOption.length > 0 ? 'Site Status' : '',
      newOptionAccount.arrayStringOption.length > 0 ? ' Account' : '',
      newOptionBrand.arrayStringOption.length > 0 ? ' Brand Name' : '',
    ];
    return filterText.filter((item) => item).join();
  };
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(newOption.selectOption || []);
  const [listOptions, setListOptions] = useState<any>([]);
  const [listSelectedBrand, setListSelectedBrand] = useState<any>([]);
  const [brandOptions, setBrandOptions] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [listTooltipActive, setListTooltipActive] = useState<Array<string>>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteList = useSiteList();

  const siteApi = new Sites();
  const brandApi = new Brands();
  const accountFilterApi = new Filters();
  const siteStatusApi = new Settings();
  const toast = useToast();

  const [selectedAccount, setSelectedAccount] = useState<any>(newOptionAccount.selectOption || []);
  const [isFilter, setIsFilter] = useState(false);

  const loadAccountOptions = (inputValue: string, callback: (options: any) => void) => {
    let params = {
      name: inputValue,
      limit: 25,
    };
    accountFilterApi.getMiniAccounts(params).then((response: any) => {
      const optionsItem: any = [];
      response.data.items?.forEach((account: any) => {
        optionsItem.push({
          value: account.id,
          label: account.name,
        });
      });
      setTimeout(() => {
        callback(optionsItem);
      }, 1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const getAccountFromUrl = async (accoutIds: any) => {
    if (!accoutIds || accoutIds?.length === 0) {
      return;
    }
    let query = {
      ids: accoutIds,
    };
    accountFilterApi
      .getMiniAccounts(query)
      .then((res) => {
        if (res?.data?.items && res?.data?.items.length) {
          const accountByIds = res?.data?.items.map((account: any) => ({
            value: account.id,
            label: account.name,
          }));
          setSelectedAccount(accountByIds);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
      });
  };

  const getSiteStatus = async () => {
    await siteStatusApi
      .getSiteStatus()
      .then((res) => {
        if (res?.data?.length) {
          const newListOptions = res.data.map((option: SiteStatusDto) => {
            return {
              value: option.displayValue,
              label: option.displayValue,
            };
          });
          setListOptions(newListOptions);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
      });
  };

  const getBrand = async () => {
    await brandApi
      .getBrands()
      .then((res) => {
        if (res?.data?.items?.length) {
          const newListOptions = res.data.items.map((option: BrandDto) => {
            return {
              value: option.name,
              label: option.name,
            };
          });
          setBrandOptions(newListOptions);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
      });
  };

  const fetchData = (params?: any) => {
    setIsLoadingTable(true);
    const {
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      siteStatuses: siteStatusesParam,
      accountIds: accoutIdsParam,
      brandName: brandNameParam,
    } = params;
    if (siteStatusesParam.length > 0 || accoutIdsParam.length > 0 || brandNameParam?.length > 0) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    localStorage.setItem('perPageTable', rows);
    setParametersSearch({
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      siteStatuses: siteStatusesParam,
      accountIds: accoutIdsParam,
      brandName: brandNameParam,
    });
    const sortByParam = `${sortFieldParam}:${sortOrderParam === 1 ? 'asc' : 'desc'}`;
    const paramSubmit = {
      search: searchKey,
      limit: rows,
      page: pageParam,
      sortBy: sortByParam,
      siteStatuses: siteStatusesParam,
      accountIds: accoutIdsParam,
      brandName: brandNameParam,
    };
    updateUrlSearch(paramSubmit);
    siteApi
      .getSites(paramSubmit)
      .then((res) => {
        const listSite = res.data;
        dispatch({
          type: Types.GET_SITE,
          payload: { sites: listSite },
        });
        setIsLoadingTable(false);
      })
      .catch((error) => {
        setIsLoadingTable(false);
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
      });
  };
  useEffect(() => {
    getBrand();
    getSiteStatus();
    getAccountFromUrl(accountIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter, location]);
  const callBackFetchData = useCallback((params: any) => {
    fetchData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    const newArrTooltipsActive = getListIdShowTooltipInPrimeTable([1, 2]);
    setListTooltipActive(newArrTooltipsActive);
  }, [siteList, leftMenuStyle]);

  const siteNameAndZohoProjectBodyTemplate = (rowData: any, isRenderSiteName?: boolean, siteField?: string) => {
    const fieldDisplay = isRenderSiteName ? rowData?.name : rowData?.projectId;
    const idActiveTooltip = `${rowData.id}-${isRenderSiteName ? '1' : '2'}`;
    return (
      <a
        href={
          isRenderSiteName
            ? `${GlobalConfig().config.REACT_APP_CRM_ZOHO_URL}${rowData?.organization?.domainName}/tab/${
                rowData?.moduleName
              }/${rowData?.crmSysId}`
            : rowData?.projectUrl || '#'
        }
        target="_blank"
        rel="noreferrer noopener"
        aria-label={rowData?.name}
        id={siteField ? `${rowData.id}-${rowData?.name}-${siteField}` : `${rowData.id}-${rowData?.name}`}
      >
        <PrimereactTooltip
          target={`.site-name-${idActiveTooltip}`}
          content={listTooltipActive.includes(idActiveTooltip) ? fieldDisplay : ''}
          position="top"
        />
        <u className={`site-name-${idActiveTooltip}`}>{fieldDisplay}</u>
      </a>
    );
  };

  const listSitesCol = SITES_PAGE_COLUMNS.map((site: ICol) => {
    switch (site.field) {
      case TITLE_SITES_PAGE_TABLE.NAME:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => siteNameAndZohoProjectBodyTemplate(data, true, site.field),
          className: 'max-width-none',
          style: { minWidth: '200px' },
        };
      case TITLE_SITES_PAGE_TABLE.SITE_STATUS:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          style: { maxWidth: '150px', minWidth: '150px' },
        };
      case TITLE_SITES_PAGE_TABLE.ACTIVE_STATUS:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => (
            <>
              <i
                className="mdi mdi-circle me-1"
                style={{ fontSize: 10, color: data?.activeStatus ? 'green' : '#C42C21' }}
              />
              {data?.activeStatus ? 'Active' : 'Inactive'}
            </>
          ),
          style: { maxWidth: '130px', minWidth: '130px' },
        };
      case TITLE_SITES_PAGE_TABLE.ACCOUNT:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => data?.account?.name,
          className: 'max-width-none',
          style: { minWidth: '150px' },
        };
      case TITLE_SITES_PAGE_TABLE.BRAND_NAME:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => data?.brand?.name,
          className: 'max-width-none',
          style: { minWidth: '200px' },
        };
      case TITLE_SITES_PAGE_TABLE.PROJECT_ID:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => siteNameAndZohoProjectBodyTemplate(data),
          className: 'max-width-none',
          style: { minWidth: '260px' },
        };
      default:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          className: 'max-width-none',
          style: { minWidth: '220px' },
        };
    }
  });

  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  const onReset = () => {
    onFilter &&
      onFilter({
        siteStatuses: [],
        accountIds: [],
        brandName: [],
      });
    setSelectedOption([]);
    setListSelectedBrand([]);
    setSelectedAccount([]);
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };

  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );
  const onSelectRow = (event: any) => {
    navigate(`/configuration/sites/${event.data.id}/accounts/${event.data.account.id}`);
  };
  const rightbarRef = useRef(null);
  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);

  useEffect(() => {
    if (!showFilter) {
      setSelectedOption(newOption.selectOption || []);
      setListSelectedBrand(newOptionBrand.selectOption || []);
      if (accountIds) {
        getAccountFromUrl(accountIds);
      } else setSelectedAccount([]);
    }
  }, [showFilter, location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [siteList]);

  return (
    <>
      <CommonActionBar pageTitle="Sites" isVisibleButton={false} breadcrumbItems={[]} />
      <div className="page-content">
        <RightSidebar
          title="Filters"
          setShowRightSidebar={(value) => {
            setShowFilter(value);
          }}
          showRightSidebar={showFilter}
        >
          <div ref={rightbarRef} className="site-rightbar-ref" id="site-rightbar-ref">
            <FormReactSelect
              value={selectedOption}
              name="siteStatus"
              label="Site Status"
              labelContainerClass="mb-1"
              labelGrid={12}
              containerClass="mb-3"
              options={listOptions}
              placeholder="Select Site Status"
              onChange={setSelectedOption}
              isMulti
            />
            <FormInputContainer containerClass="form-react-select mb-3" labelGrid={12} label="Account">
              <AsyncSelect
                isMulti
                id="select-account"
                inputId="select-account-input"
                instanceId="account"
                value={selectedAccount}
                onChange={setSelectedAccount}
                cacheOptions={true}
                defaultOptions={true}
                loadOptions={loadAccountOptions}
                placeholder="Select Account"
                aria-label="Select Account"
                classNamePrefix="react-select"
                components={{ ClearIndicator }}
                className="form_group_control"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 100000 }) }}
              />
            </FormInputContainer>
            <FormReactSelect
              value={listSelectedBrand}
              name="brandStatus"
              label="Brand Name"
              labelContainerClass="mb-1"
              labelGrid={12}
              containerClass="mb-3"
              options={brandOptions}
              placeholder="Select Brand Name"
              onChange={setListSelectedBrand}
              isMulti
            />
            <Row>
              <Col sm={6}>
                <Button
                  className="w-100 justify-content-center"
                  type="button"
                  onClick={() => onReset()}
                  variant="outline-primary"
                >
                  Reset
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  type="submit"
                  className="w-100 justify-content-center"
                  onClick={() => {
                    setShowFilter(false);
                    onFilterSidebar({
                      search: '',
                      siteStatuses: selectedOption.map((option: { value: string }) => option.value),
                      accountIds: selectedAccount.map((option: { value: string }) => option.value),
                      brandName: listSelectedBrand.map((option: { value: string }) => option.value),
                    });
                  }}
                >
                  Apply filter
                </Button>
              </Col>
            </Row>
          </div>
        </RightSidebar>
        {isFilter && (
          <h4 className="mb-3 datatable-title">{`Sites (${
            siteList?.pageInfo?.total
          }) - Filtered by: ${handleFilterField()}`}</h4>
        )}
        <Datatable
          fetchData={callBackFetchData}
          totalRecords={siteList?.pageInfo?.total || 0}
          data={siteList?.items || []}
          isLoading={isLoadingTable}
          {...(canViewDetails && { onRowSelect: onSelectRow })}
          columns={listSitesCol}
          tableName="sites"
          emptyMessage="No results found."
          initParams={parametersSearch}
          columnResizeMode="fit"
          sortField={parametersSearch.sortField}
          searchKey={parametersSearch.searchKey}
          isFilter={true}
          onClickFilterButton={onClickFilterButton}
          isHideShowingAllLabel={isFilter}
          scrollable
        />
      </div>
    </>
  );
}

export default SitesPage;
