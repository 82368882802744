import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getPerPageTable, convertTime, handleErrorLogMessage } from '../../utils';
import Tooltip from '../../components/common/tooltip';
import {
  DATE_FORMAT,
  LIST_FORM_TEMPLATE_COLUMNS,
  PERMISSIONS,
  ROUTER,
  TITLE_FORM_TEMPLATE_TABLE,
  UNKNOWN_ERROR_CODE,
} from '../../constant';
import { FormTemplates } from '../../form-api-client/FormTemplates';
import { CommonActionBar, Datatable } from '@thg-harveynash/hyper-shared-components';
import styled from 'styled-components';
import { usePermissions } from '../../context/auth-context';
import useAccessibility from '../../utils/useAccessibility';
import { useToast } from '../../context/toast-context';

const DataTableStyled = styled.div`
  .default-hide {
    min-width: 120px !important;
    max-width: 120px !important;
  }
`;

export interface ParametersSearchI {
  search: string;
  page: number;
  limit: number;
  sortBy: string;
  sortOrder?: number;
  sortField?: string;
}

const FormTemplate = () => {
  const location = useLocation();
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '' } = paramFromUrl; //NOSONAR
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
  const { addAriaLabelToTable } = useAccessibility();
  const { permissionList } = usePermissions();
  const canViewTemplate = permissionList.includes(PERMISSIONS.view_form_template_configurations);
  const canPreviewTemplate = permissionList.includes(PERMISSIONS.preview_form_template);
  const navigate = useNavigate();
  const perPageTable = getPerPageTable();
  const [formData, setformData] = useState({
    pageInfo: { total: 0 },
    items: [],
  });
  const toast = useToast();
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField || 'name',
    sortOrder: sortBySplit[1] ? sortOrder : 1,
    page: Number(page) || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    first: limit && page ? Number(limit) * Number(page) : 0,
  });

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const formManagementApi = new FormTemplates();

  const onSelectRow = (event: any) => {
    if (canViewTemplate) {
      navigate(`${ROUTER.CONFIGURATION_FORM_DETAIL.replace(':id', event.data.id)}/configuration`);
    }
  };

  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  const listFormTemplateCol = LIST_FORM_TEMPLATE_COLUMNS.map((acc) => {
    if (acc.field === TITLE_FORM_TEMPLATE_TABLE.FORM_TEMPLATE)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => <Tooltip data={data?.name} id={`name-${data?.id}`} />,
        className: 'max-width-none',
        style: { minWidth: '250px' },
      };

    if (acc.field === TITLE_FORM_TEMPLATE_TABLE.FORM_TEMPLATE_STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => (
          <Tooltip
            data={`${data?.state.toUpperCase() === 'UNPUBLISHED' ? 'Unpublished' : 'Published'}`}
            id={`state - ${data?.id}`}
          />
        ),
        style: { maxWidth: '240px', minWidth: '240px' },
      };

    if (acc.field === TITLE_FORM_TEMPLATE_TABLE.LAST_UPDATE)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => (
          <Tooltip data={convertTime(data?.publishedDate, DATE_FORMAT) || ''} id={`publishedDate - ${data?.id}`} />
        ),
        style: { maxWidth: '230px', minWidth: '230px' },
      };

    if (acc.field === TITLE_FORM_TEMPLATE_TABLE.ACTIVE_STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => (
          <>
            <i
              className="mdi mdi-circle me-1"
              style={{ fontSize: 10, color: data?.activeStatus ? 'green' : '#C42C21' }}
            />
            {data?.activeStatus ? 'Active' : 'Inactive'}
          </>
        ),
        style: { maxWidth: '130px', minWidth: '130px' },
      };

    return {
      resizeable: true,
      field: acc.field,
      label: acc.header,
      sortable: true,
    };
  });

  const rowActionButtons = (row: any) => {
    return [
      ...(canPreviewTemplate
        ? [
            {
              key: 'preview',
              label: 'Preview',
              className: 'row-action-button',
              variant: 'outline-primary',
              onClick: () => {
                navigate(`/configuration/form-templates/${row?.id}/preview`);
              },
            },
          ]
        : []),
    ];
  };

  const getTableData = async (params: any) => {
    setIsLoadingTable(true);
    const { rows, page: pageParam, sortField: sortFieldParam, sortOrder: sortOrderParam, searchKey, first } = params;
    localStorage.setItem('perPageTable', rows);
    setParametersSearch({
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      first,
    });
    const sortByParam = `${sortFieldParam}:${sortOrderParam === 1 ? 'asc' : 'desc'}`;
    const paramSubmit = {
      search: searchKey,
      limit: rows,
      page: pageParam,
      sortBy: sortByParam,
    };
    updateUrlSearch(paramSubmit);
    let formDataRes;
    try {
      formDataRes = await formManagementApi.getFormTemplates(paramSubmit);
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
    }
    setIsLoadingTable(false);
    // @ts-ignore
    setformData(formDataRes?.data);
  };

  const fetchData = useCallback((params: any) => {
    getTableData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [formData]);

  return (
    <>
      <CommonActionBar pageTitle="Form Templates" isVisibleButton={false} breadcrumbItems={[]} />
      <div className="page-content">
        <DataTableStyled>
          <Datatable
            fetchData={fetchData}
            totalRecords={formData?.pageInfo?.total || 0}
            data={formData?.items || []}
            isLoading={isLoadingTable}
            onRowSelect={onSelectRow}
            columns={listFormTemplateCol}
            tableName="form templates"
            emptyMessage="No results found."
            initParams={parametersSearch}
            columnResizeMode="fit"
            searchKey={parametersSearch.searchKey}
            rowActionButtons={rowActionButtons}
            scrollable
          />
        </DataTableStyled>
      </div>
    </>
  );
};

export default FormTemplate;
