import { Column } from 'primereact/column';
import { DataTable as PDataTable } from 'primereact/datatable';
import React, { forwardRef } from 'react';

import { Card } from 'react-bootstrap';
import { IDatatable } from './datatable.type';
import BulkAction from './BulkAction';
import ShowingAllLabel from './ShowingAllLabel';

const Datatable = forwardRef((props: IDatatable) => {
  const {
    dataKey = 'id',
    columns,
    bulkactions = [],
    loading,
    totalRecords,
    paginator = true,
    sortField,
    sortOrder,
    emptyMessage = 'No results found',
    rowExpansionTemplate,
    expandedRows,
    selectionMode,
    selection,
    onSelectionChange,
    value,
    rows,
    onSort,
    metaKeySelection,
    isExpand = false,
  } = props;

  return (
    <div className="datatable-wrapper">
      <ShowingAllLabel {...props} />
      <BulkAction selectedRows={selection || []} bulkactions={bulkactions} />
      <Card>
        <Card.Body style={{ padding: 16 }}>
          <PDataTable
            totalRecords={totalRecords}
            value={value}
            dataKey={dataKey}
            scrollable
            selection={selection}
            responsiveLayout="scroll"
            sortOrder={sortOrder}
            sortField={sortField}
            paginator={paginator}
            rows={rows}
            emptyMessage={emptyMessage || 'No results found.'}
            loading={loading}
            lazy
            selectionMode={selectionMode}
            onSelectionChange={onSelectionChange}
            onSort={onSort}
            metaKeySelection={metaKeySelection}
            expandedRows={expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            {columns}
            {isExpand && <Column expander className="px-0" style={{ maxWidth: '40px' }} />}
          </PDataTable>
        </Card.Body>
      </Card>
    </div>
  );
});

export default Datatable;
