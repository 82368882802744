import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FORM_INSTRUCTION } from '../../constant';
import { AccordionFormIntructions, PreTitle } from '../StyledComponent';

interface FormInformationProps {
  activeIndex?: any[];
}

const FormInformation = (props: FormInformationProps) => {
  return (
    <>
      <AccordionFormIntructions role="tablist" aria-busy="true">
        <Accordion className="form-instructions" activeIndex={0} style={{ marginTop: '20px' }}>
          <AccordionTab
            className="form-instructions-accordion-header"
            style={{ fontSize: '18px', fontWeight: '800' }}
            header="Forms"
          >
            <div role="tablist" aria-busy="true">
              <Accordion className="form-instructions" activeIndex={props.activeIndex} multiple>
                <AccordionTab
                  className="form-instructions-accordion-sub-header"
                  style={{ fontSize: '16px', fontWeight: '600' }}
                  header="Forms Completion Instructions"
                >
                  <PreTitle className="form-instructions-accordion-text" style={{ fontSize: '14px' }}>
                    {FORM_INSTRUCTION}
                  </PreTitle>
                </AccordionTab>
                <AccordionTab
                  className="form-instructions-accordion-sub-header"
                  style={{ fontSize: '16px', fontWeight: '600' }}
                  header="Form Status"
                >
                  <PreTitle className="form-instructions-accordion-text" style={{ fontSize: '14px' }}>
                    <>
                      <p>
                        <b>Action required</b> - Action Required by client on the document
                      </p>
                      <p>
                        <b>Draft</b> - draft version of document. Not yet submitted to THG
                      </p>
                      <p>
                        <b>Need to be updated</b> - Client is required to provide updates and/or further information on
                        the document
                      </p>
                      <p>
                        <b>Requested to resubmit</b> - Client request to THG to resubmit the document with new
                        information
                      </p>
                      <p>
                        <b>Submitted</b> - Submitted by the Client to THG for review
                      </p>
                      <p>
                        <b>Approved</b> - Document has been reviewed and accepted by THG. No further action required
                      </p>
                    </>
                  </PreTitle>
                </AccordionTab>
              </Accordion>
            </div>
          </AccordionTab>
        </Accordion>
      </AccordionFormIntructions>
    </>
  );
};

export default FormInformation;
