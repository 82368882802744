import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../../accounts-api-client/Profile';
import { AccountProfileDto } from '../../accounts-api-client/data-contracts';
import LayoutDetail from '../../components/layout-detail';
import { getRole, handleErrorLogMessage, handleErrorStatus } from '../../utils';
import {
  LabelInforNoneMargin,
  SectionArea,
  TitleSection,
  DetailInforCustomSize,
} from '../../components/StyledComponent';
import { PERMISSIONS, ROLE_TYPE, ROUTER, UNKNOWN_ERROR_CODE } from '../../constant';
import { useAccountSelected } from '../../context';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { usePermissions } from '../../context/auth-context';
import { useToast } from '../../context/toast-context';

const AccountProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const accountSelected = useAccountSelected();
  const [accountProfile, setAccountProfile] = useState<AccountProfileDto>();
  const toast = useToast();

  //permission
  const role = getRole();
  const { permissionList } = usePermissions();
  const isViewRelatedSites = role === ROLE_TYPE.CLIENT && permissionList.includes(PERMISSIONS.view_related_sites);

  const accountApi = new Profile();
  const getAccountProfile = async (accountId: string) => {
    setIsLoading(true);
    try {
      await accountApi
        .getAccountProfile(accountId)
        .then((res) => {
          setAccountProfile(res.data);
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACP_C_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (accountSelected?.id) {
      getAccountProfile(accountSelected?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected]);
  return (
    <LayoutDetail pageTitle="Account Profile" breadcrumbItems={[]} isLoading={isLoading}>
      <Card.Body>
        <SectionArea className="d-flex justify-content-between">
          <TitleSection className="m-0" style={{ fontSize: '26px' }}>
            General
          </TitleSection>
        </SectionArea>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Account Name</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.name}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Industry</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.industry}</DetailInforCustomSize>
          </Col>
        </Row>
        <SectionArea className="d-flex justify-content-between mt-3">
          <TitleSection className="m-0" style={{ fontSize: '26px' }}>
            Contact
          </TitleSection>
        </SectionArea>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Main Contact</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.leadContact?.name}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Main Contact Email</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.mainContactEmail}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Main Contact Phone</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.mainContactPhone}</DetailInforCustomSize>
          </Col>
        </Row>
        <SectionArea className="d-flex justify-content-between mt-3">
          <TitleSection className="m-0" style={{ fontSize: '26px' }}>
            Address
          </TitleSection>
        </SectionArea>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Billing Street</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.billingStreet}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Billing City</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.billingCity}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Billing State</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.billingState}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Billing Code</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.billingCode}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="account-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Billing Country</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 account-id">
            <DetailInforCustomSize>{accountProfile?.billingCountry}</DetailInforCustomSize>
          </Col>
        </Row>
        <div className="form mt-3">
          <div className="form-action d-flex flex-row align-items-center justify-content-between">
            <Button type="button" variant="outline-primary" onClick={() => navigate(-1)}>
              Back
            </Button>
            <div className="group-actions d-flex flex-row align-items-center justify-content-end">
              {isViewRelatedSites ? (
                <Button className="ms-2" onClick={() => navigate(ROUTER.RELATED_SITES)}>
                  Related Sites
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </Card.Body>
    </LayoutDetail>
  );
};

export default AccountProfile;
