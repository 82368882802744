import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { Projects } from '../../../projects-api-client/Projects';
import { Tasks } from '../../../projects-api-client/Tasks';
import queryString from 'query-string';
import {
  getPerPageTable,
  getStringSearch,
  getSelectOptionAndArrayStringOption,
  handleErrorLogMessage,
} from '../../../utils';
import PTableSubRow from '../../../components/table/TableSubRow';
import { TaskPageDto } from '../../../projects-api-client/data-contracts';
import { ActionTaskColHyper } from './ActionTaskCol';
import RowExpand from './RowExpand';
import RowSub from './RowSub';
import FilterTask from './FilterTask';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import CustomizeColumnDropdown from './CustomizeColumnDropDown';
import { TITLE_TASK_TABLE, UNKNOWN_ERROR_CODE } from '../../../constant';
import { useToast } from '../../../context/toast-context';
interface IParamSearchTask {
  search: string;
  page: number;
  limit: number;
  sortBy: string;
  milestoneExtIds: string[] | [];
  responsibleParties: string[] | [];
  ragStatuses: string[] | [];
  statuses: string[] | [];
  dueDateFrom: any;
  dueDateTo: any;
}

interface ActionProps {
  projectId: string;
}

function ActionTasks({ projectId = '' }: ActionProps) {
  const perPageTable = getPerPageTable();
  const location = useLocation();
  const paramFromUrl = queryString.parse(location.search);
  const {
    sortBy = '' || 'taskKey',
    dueDateFrom = '',
    dueDateTo = '',
    milestoneExtIds,
    responsibleParties,
    ragStatuses,
    statuses,
  } = paramFromUrl; //NOSONAR
  const navigate = useNavigate();
  const [first, setFirst] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [actionTasks, setActionTasks] = useState<TaskPageDto>({});
  const [keySearch, setKeySearch] = useState<string>('');
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
  const [dates, setDates] = useState<Date | Date[] | undefined>(undefined);
  const newMilestoneOption = getSelectOptionAndArrayStringOption(milestoneExtIds);
  const newResponsibleParties = getSelectOptionAndArrayStringOption(responsibleParties);
  const newRagStatuses = getSelectOptionAndArrayStringOption(ragStatuses);
  const newStatus = getSelectOptionAndArrayStringOption(statuses);
  const [milestones, setMilestone] = useState<any>([]);
  const [reParty, setReParty] = useState<any>(newResponsibleParties.selectOption);
  const [RAGStatus, setRAGStatus] = useState<any>(newRagStatuses.selectOption);
  const [taskStatus, setTaskStatus] = useState<any>(newStatus.selectOption);
  const [isFilter, setIsFilter] = useState(false);
  const [parametersSearch, setParametersSearch] = useState<IParamSearchTask>({
    search: '',
    page: 1,
    limit: perPageTable,
    sortBy: 'taskKey',
    milestoneExtIds: newMilestoneOption.arrayStringOption || [],
    responsibleParties: newResponsibleParties.arrayStringOption || [],
    ragStatuses: newRagStatuses.arrayStringOption || [],
    statuses: newStatus.arrayStringOption || [],
    dueDateFrom: dueDateFrom || '',
    dueDateTo: dueDateTo || '',
  });
  const [displayCustomize, setDisplayCustomize] = useState(ActionTaskColHyper);
  const columns = useMemo(() => {
    return displayCustomize.map((el) => {
      return (
        <Column {...el} key={el.field} header={el.label} sortable={el.sortable} className={classNames(el.className)} />
      );
    });
  }, [displayCustomize]);

  const isHaveSubRow = (data: any) => {
    const doubleRow = data?.filter(
      (col: any) => col.key === TITLE_TASK_TABLE.TASK_NAME || col.key === TITLE_TASK_TABLE.TASK_LIST
    );
    return doubleRow?.some((row: any) => !row?.hidden);
  };

  const projectApi = new Projects();

  const taskApi = new Tasks();

  const toast = useToast();

  const handleSearch = () => {
    const newParam = {
      ...parametersSearch,
      search: `${keySearch.trim()}`,
    };
    setParametersSearch(newParam);
    updateUrlSearch(newParam);
    fetchData(newParam);
  };

  const handleRefreshTable = () => {
    const newParam = {
      ...parametersSearch,
    };
    setKeySearch('');
    setParametersSearch(newParam);
    updateUrlSearch(newParam);
    fetchData(newParam);
  };
  const handleChange = (event?: any, isEnter?: boolean) => {
    if (isEnter) {
      if (event.keyCode === 13) {
        handleSearch();
      }
    } else {
      handleSearch();
    }
  };

  const searchHeader = (
    <>
      <div className={classNames('w-100')} style={{ marginBottom: 16 }}>
        <div className="d-flex justify-content-between ">
          <div className="d-flex align-items-center">
            <section className="search-wrapper d-flex align-items-center me-2">
              <div className="search-input">
                <i className="mdi mdi-magnify" />
                <Form.Control
                  data-testid="input-datatable-search"
                  aria-label="search"
                  placeholder="Search ..."
                  type="text"
                  // ref={inputRef}
                  onKeyUp={(ev: any) => handleChange(ev, true)}
                  onChange={(e: any) => setKeySearch(e.target.value)}
                />
              </div>
            </section>
            <Button
              onClick={() => setShowFilter(true)}
              variant="outline-primary"
              data-testid="btn-datatable-filter"
              data-test="btnFilter"
              style={{ height: 40, fontSize: 16, fontWeight: 700 }}
            >
              <i className="mdi mdi-filter me-1" style={{ fontSize: 14 }} />
              <span>Filters</span>
            </Button>
          </div>
          <div className="d-flex">
            <CustomizeColumnDropdown
              defaultColumns={ActionTaskColHyper}
              columnsDisplay={displayCustomize}
              onCustomizeColumn={(value: any) => setDisplayCustomize(value)}
            />
            <Button
              className="btn-refresh align-items-center"
              data-testid="btn-datatable-refresh"
              data-test="btnRefresh"
              aria-label="refresh"
              variant="outline-primary"
              onClick={handleRefreshTable}
            >
              <i className="mdi mdi-refresh me-0" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  const actionTaskCount = actionTasks.items ? actionTasks?.items?.length : 0;
  const paginatorLeft = (
    <div className="p-paginator-left">
      Showing action tasks {(parametersSearch.page - 1) * parametersSearch.limit + (actionTaskCount > 0 ? 1 : 0)} to{' '}
      {(parametersSearch.page - 1) * parametersSearch.limit + actionTaskCount} of {actionTasks?.pageInfo?.total}
    </div>
  );
  const renderFilterText = () => {
    let filterText = [
      dates ? 'Due Date' : undefined,
      milestones.length > 0 ? ' Milestone' : undefined,
      reParty.length > 0 ? ' Responsible Party' : undefined,
      RAGStatus.length > 0 ? ' RAG Status' : undefined,
      taskStatus.length > 0 ? ' Task Status' : undefined,
    ];
    return filterText.filter((item) => item).join();
  };
  const fetchData = (param?: IParamSearchTask) => {
    setIsLoadingTable(true);
    const paramSubmit = param
      ? { ...param, page: param.page - 1 }
      : { ...parametersSearch, search: encodeURI(keySearch), page: parametersSearch.page - 1 };
    let { sortBy: sortByAddTaskList } = paramSubmit;
    sortByAddTaskList = `taskList.sequence:desc,${sortByAddTaskList}`;
    if (
      paramSubmit.dueDateFrom !== '' ||
      paramSubmit.dueDateTo !== '' ||
      paramSubmit.milestoneExtIds.length > 0 ||
      paramSubmit.responsibleParties.length > 0 ||
      paramSubmit.ragStatuses.length > 0 ||
      paramSubmit.statuses.length > 0
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    projectApi
      .getTasksByProject(projectId, { ...paramSubmit, sortBy: sortByAddTaskList })
      .then((res) => {
        setActionTasks(res.data);
        setIsLoadingTable(false);
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
        setIsLoadingTable(false);
      });
  };
  const getSubTask = async (taskId: string) => {
    let subData: any;
    try {
      subData = await taskApi.getSubtasks(taskId);
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
    }
    return subData?.data || [];
  };

  const updateUrlSearch = (param: IParamSearchTask) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  const handleChangePage = (event: any) => {
    const newParam = {
      ...parametersSearch,
      page: event.page + 1,
      limit: event.rows,
    };
    setFirst(event.first);
    setParametersSearch(newParam);
    updateUrlSearch(newParam);
    fetchData(newParam);
  };

  const handleSort = (event: any) => {
    const newSortBy = `${event.sortField}:${event.sortOrder === 1 ? 'asc' : 'desc'}`;
    const newParam = {
      ...parametersSearch,
      sortBy: newSortBy,
    };
    setParametersSearch(newParam);
    updateUrlSearch(newParam);
    fetchData(newParam);
  };

  const getParamFromUrl = () => {
    const paramFromUrlProps = queryString.parse(location.search);
    const {
      search,
      limit,
      page,
      sortBy: paramSortBy,
      milestoneExtIds: milestoneExtIdsParams,
      ragStatuses: ragStatusesParams,
      responsibleParties: responsiblePartiesParams,
      statuses: statusesParams,
      dueDateFrom: dueDateFromParam = '',
      dueDateTo: dueDateToParam = '',
    } = paramFromUrlProps;
    const newParamSearch = {
      search: search ? String(search) : '',
      limit: Number(limit),
      page: Number(page),
      sortBy: paramSortBy ? String(paramSortBy) : '',
      milestoneExtIds: getSelectOptionAndArrayStringOption(milestoneExtIdsParams).arrayStringOption,
      ragStatuses: getSelectOptionAndArrayStringOption(ragStatusesParams).arrayStringOption,
      responsibleParties: getSelectOptionAndArrayStringOption(responsiblePartiesParams).arrayStringOption,
      statuses: getSelectOptionAndArrayStringOption(statusesParams).arrayStringOption,
      dueDateFrom: dueDateFromParam,
      dueDateTo: dueDateToParam,
    };
    setFirst(Number(limit) * (Number(page) - 1));
    setKeySearch(getStringSearch(search));
    fetchData(newParamSearch);
    setParametersSearch(newParamSearch);
  };

  useEffect(() => {
    if (location.search) {
      getParamFromUrl();
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(actionTasks) && (
        <FilterTask
          projectId={projectId}
          parametersSearch={parametersSearch}
          updateUrlSearch={updateUrlSearch}
          setParametersSearch={setParametersSearch}
          handleFilter={fetchData}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          dates={dates}
          setDates={setDates}
          milestones={milestones}
          setMilestone={setMilestone}
          reParty={reParty}
          setReParty={setReParty}
          RAGStatus={RAGStatus}
          setRAGStatus={setRAGStatus}
          taskStatus={taskStatus}
          setTaskStatus={setTaskStatus}
        />
      )}
      {isFilter ? (
        <h4 className="mb-3 datatable-title">{`Action Tasks (${
          actionTasks?.pageInfo?.total
        }) - Filtered by: ${renderFilterText()}`}</h4>
      ) : (
        <h2 className="mb-3 datatable-title">
          Action Tasks <span style={{ fontWeight: 'normal' }}>({actionTasks?.pageInfo?.total})</span>
        </h2>
      )}
      <Card>
        <Card.Body style={{ padding: 16 }}>
          {searchHeader}
          <PTableSubRow
            totalRecords={actionTasks?.pageInfo?.total || 0}
            value={actionTasks?.items || []}
            sortOrder={sortOrder}
            sortField={sortField}
            paginatorLeft={paginatorLeft}
            rows={parametersSearch.limit}
            first={first}
            onPage={handleChangePage}
            loading={isLoadingTable}
            onSort={handleSort}
            columns={columns}
            rowExpansionTemplate={RowExpand}
            rowSubTemplate={RowSub}
            isExpand={true}
            isSub={isHaveSubRow(displayCustomize)}
            onClickSubTask={getSubTask}
            level={0}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default ActionTasks;
