import { SelectOption } from '@thg-harveynash/hyper-shared-components/build/components/Form/FormInput.type';
import { ClearIndicatorProps } from 'react-select';

export const ClearIndicator = (props: ClearIndicatorProps<SelectOption, true>) => {
  const {
    children = <></>,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      {children}
    </div>
  );
};
