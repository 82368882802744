import React, { useEffect, useState } from 'react';
import { ROUTER, UNKNOWN_ERROR_CODE } from '../../constant';
import { FormEngine } from '@thg-harveynash/cp-form-engine-ui-v2';
import { useNavigate, useParams } from 'react-router-dom';
import { getLinkDownloadFile } from '../../services/form-download';
import { FormTemplates } from '../../form-api-client/FormTemplates';
import { handleErrorStatus, handleErrorLogMessage } from '../../utils';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { CommonActionBar } from '@thg-harveynash/hyper-shared-components';
import { Card, Row, Col } from 'react-bootstrap';
import { SpinnerStyled } from '../../components/StyledComponent';
import { isEmpty } from 'lodash';
import { useToast } from '../../context/toast-context';

function FormTemplatePreviewPage() {
  let params = useParams();
  let formId = params && params.id ? params.id : '';
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [dataTemplate, setDataTemplate] = useState<any>({});

  const formManagementApi = new FormTemplates();

  const toast = useToast();

  const getLatestFormTemplate = async () => {
    await formManagementApi
      .getConfiguration(formId)
      .then((resConfiguration) => {
        formManagementApi
          .getLatestFormTemplate(formId)
          .then((res) => {
            const templateFiles = resConfiguration?.data?.templateFiles;
            const resDataTemplate = res?.data;
            if (isEmpty(templateFiles)) {
              setDataTemplate(resDataTemplate);
              return;
            }
            const elementsTemplate = resDataTemplate?.template?.elements;
            const keyDownload = Object.keys(templateFiles)?.[0];
            const newElementsTemplate = elementsTemplate?.map((ele: any) => {
              if (ele?.key === keyDownload) {
                return {
                  ...ele,
                  properties: {
                    ...ele.properties,
                    resourceId: templateFiles?.[keyDownload],
                  },
                };
              }
              return ele;
            });
            setDataTemplate({
              ...resDataTemplate,
              template: {
                ...resDataTemplate.template,
                elements: newElementsTemplate,
              },
            });
          })
          .catch((error) => {
            handleErrorStatus(error?.status);
            handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
          })
          .finally(() => setIsLoading(false));
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      });
  };

  useEffect(() => {
    getLatestFormTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const breadcrumbItems = [
    { path: `${ROUTER.CONFIGURATION_FORM}`, name: 'Form Templates', active: false },
    {
      path: `${ROUTER.CONFIGURATION_FORM_CONFIGURATION.replace(':id', formId)}`,
      name: dataTemplate?.template?.title || '',
      active: false,
    },
    { path: '', name: 'Preview', active: true },
  ];

  const onChangePath = (path: string) => {
    navigate(path);
  };

  return (
    <>
      {isLoading ? (
        <SpinnerStyled>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>
          <CommonActionBar
            pageTitle={`${dataTemplate?.template?.title}: Preview Form`}
            isVisibleButton={false}
            breadcrumbItems={breadcrumbItems}
            onChangePath={onChangePath}
          />
          <div className="page-content">
            <Row>
              <Col className="mx-auto d-flex justify-content-center">
                <Card className="form-container form-template">
                  {!isEmpty(dataTemplate?.template) && (
                    <FormEngine
                      jsonFormTemplate={getLinkDownloadFile(dataTemplate?.template, toast)}
                      jsonRule={dataTemplate?.rules || []}
                      isPreview
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default FormTemplatePreviewPage;
