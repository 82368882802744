/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PongMessage {
  message?: string;
}

export interface BaseDto {
  activeStatus: boolean;
  /** @format date-time */
  createdAt: string;
  createdBy: string;
  /** @format date-time */
  modifiedAt?: string;
  modifiedBy?: string;
}

export interface ExtFileDto {
  extId?: string;
  versionInfo?: UploadedFileVersionInfo;
}

export interface ExtFileInfoDto {
  name?: string;
  extension?: string;
  type?: string;
}

export interface UploadProgressDto {
  messageCode?: string;
}

export interface DataTemplateDto {
  name?: string;
  description?: string;
}

export interface DataTemplateResponseDto {
  dataTemplateId?: string;
  name?: string;
}

export interface UploadedFileVersionInfo {
  versionId?: string;
  versionNumber?: string;
}

export interface DocumentPageDto {
  items: DocumentDto[];
  /**
   * @format int32
   * @default 0
   */
  privateFileCount?: number;
}

export interface DocumentDto {
  resourceId?: string;
  name?: string;
  size?: string;
  isFolder?: boolean;
  extension?: string;
  /** @format date-time */
  lastUpdated?: string;
}

export interface BreadcrumbsDto {
  resourceId?: string;
  name?: string;
  baseParentId?: string;
  resType?: string;
}

export interface SettingRequestDto {
  value?: string;
}

export interface SettingResponseDto {
  value?: string;
  key?: string;
}

export type SettingDto = BaseDto & {
  value?: string;
  key?: string;
};

export interface ErrorDto {
  code?: string;
  message?: string;
  /** @format date-time */
  timestamp?: string;
}

export enum ExtSystemKey {
  ZOHO_WORKDRIVE = 'ZOHO_WORKDRIVE',
  ZOHO_ACCOUNT = 'ZOHO_ACCOUNT',
  ZOHO_ONE_ACCOUNT = 'ZOHO_ONE_ACCOUNT',
}

export enum SettingKey {
  PrivateDataTemplateId = 'private_data_template_id',
}

export interface DownloadedZipFileProgressDto {
  downloadLink?: string;
  wmsKey?: string;
}
