import React, { useState, useEffect } from 'react';
import { Header } from '@thg-harveynash/hyper-shared-components';
import { ProfileDropdown } from '@thg-harveynash/cp-hyper-ui-comps';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import RightSidebar from './right-sidebar';
import { storeRightMenuStyle, storeCurrentAccount, getsCurrentAccount } from '../utils/layout';
import { useAuthInfo, useKeyCloak, usePermissions } from '../context/auth-context';
import { useDispatch, useIsFormChanged } from '../context';
import { PERMISSIONS, ROLE_TYPE, ROUTER, UNKNOWN_ERROR_CODE } from '../constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accounts } from '../accounts-api-client/Accounts';
import { SimpleAccountDto } from '../accounts-api-client/data-contracts';
import { useWarningModal } from '../context/warning-modal-context';
import { Types } from '../constant/action-type';
import { useToast } from '../context/toast-context';
import { getRole, handleErrorLogMessage } from '../utils';
import { useGetUserPermissionQuery } from '../apis/endpoints/authorizations';
import { AvatarStyled } from '../components/StyledComponent';
import IdleTimerPortal from '../components/idle-timer/IdleTimerPortal';
import { GlobalConfig } from '../global';

export interface RightSideBarProps {
  isShowRightMenu?: boolean;
  setIsShowRightMenu?: any;
  handleShowAlllHelpTopic?: any;
  setLoadingSignOut: React.Dispatch<React.SetStateAction<boolean>>;
}

const DefaultHeader = (props: RightSideBarProps) => {
  const [isShowRightMenu, setIsShowRightMenu] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<SimpleAccountDto>({});
  const [accounts, setAccounts] = useState<SimpleAccountDto[]>([]);

  const { setLoadingSignOut } = props;

  const navigate = useNavigate();
  const auth = useAuthInfo();
  const keyCloak = useKeyCloak();
  const modal = useWarningModal();
  const dispatch = useDispatch();
  const toast = useToast();
  const isFormChanged = useIsFormChanged();
  const [mode, setMode] = useState('cp');
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname?.includes('ups')) setMode('ups');
    else setMode('cp');
  }, [location]);

  const role = getRole();

  const accountApi = new Accounts();

  const userName = () => {
    if (!auth?.family_name && !auth?.given_name) return auth?.email;
    if (!auth?.given_name) return auth?.family_name;
    if (!auth?.family_name) return auth?.given_name;
    return `${auth?.given_name} ${auth?.family_name}`;
  };

  const avartarName = () => {
    if (!auth?.family_name && !auth?.given_name) return auth?.email?.slice(0, 1);
    if (!auth?.family_name) return auth?.given_name?.slice(0, 1);
    if (!auth?.given_name) return auth?.family_name?.slice(0, 1);
    return auth?.given_name?.slice(0, 1) + auth?.family_name?.slice(0, 1);
  };

  const setAccountSelect = (account: SimpleAccountDto) => {
    dispatch({
      type: Types.SET_ACCOUNT_SELECTED,
      payload: {
        accountSelected: account,
      },
    });
    localStorage.setItem('accountSelected', JSON.stringify(account));
  };

  const fetchAccount = async () => {
    await accountApi
      .getAccounts({
        page: 0,
        limit: 1000,
        sortBy: 'name:asc',
      })
      .then((res) => {
        setAccounts(res?.data?.items || []);
        const accountStored = getsCurrentAccount();
        if (accountStored) {
          const accountSaved = JSON.parse(accountStored);
          // @ts-ignore
          setCurrentAccount(accountSaved);
          setAccountSelect(accountSaved);
          return;
        }
        const account = res?.data?.items?.[0];
        if (account) {
          setCurrentAccount(account);
          setAccountSelect(account);
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
      });
  };

  //permission
  const { permissionList } = usePermissions();
  const isViewMainHelp = role === ROLE_TYPE.CLIENT && permissionList.includes(PERMISSIONS.view_main_help);
  const isViewAccProfile = role === ROLE_TYPE.CLIENT && permissionList.includes(PERMISSIONS.view_account_profile);
  const isViewClientList = role === ROLE_TYPE.CLIENT && permissionList.includes(PERMISSIONS.view_client_list);

  // mode application
  const isUpsMode = mode === 'ups';
  const isThgUser = role === ROLE_TYPE.THG;

  const { data } = useGetUserPermissionQuery({ appCode: 'ups' });

  useEffect(() => {
    if (isViewClientList) {
      fetchAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectLogoutToHome = () => {
    setLoadingSignOut(true);
    setTimeout(() => {
      localStorage.clear();
      keyCloak?.logout();
    }, 500);
  };

  const handleLogout = () => {
    if (isFormChanged) {
      modal({
        message:
          '<b>Are you sure you want to leave?</b><br></br>\n\nAny changes you have made will not be saved. Please ensure you submit or save your data before leaving the page.',
        changeAccount: () => {
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
          setTimeout(() => {
            redirectLogoutToHome();
          }, 10);
        },
      });
    } else {
      dispatch({
        type: Types.GET_FORM_DIRTY_STATUS,
        payload: {
          isFormChanged: false,
        },
      });
      redirectLogoutToHome();
    }
  };

  const handleChangePassword = () => {
    window.location.assign(
      `${GlobalConfig().config.REACT_APP_URL_KEYCLOAK}/realms/client-portal/account/#/security/signingin`
    );
  };

  const renderMenuItems = () => {
    let menuItems: any[] = [];
    if (isViewAccProfile) {
      menuItems = [
        ...menuItems,
        {
          label: 'Account Profile',
          icon: 'mdi mdi-account-group',
          onClick: () => navigate('/account-profile'),
        },
      ];
    }
    if (isUpsMode && isThgUser) {
      menuItems = [
        ...menuItems,
        {
          label: 'Client Portal',
          icon: 'mdi mdi-autorenew',
          onClick: () => {
            localStorage.setItem('mode', 'cp');
            navigate('/home');
          },
        },
      ];
    }
    if (!isUpsMode && isThgUser && data && data?.data?.length) {
      menuItems = [
        ...menuItems,
        {
          label: 'User Permissions',
          icon: 'mdi mdi-autorenew',
          onClick: () => {
            localStorage.setItem('mode', 'ups');
            navigate('/ups/home');
          },
        },
      ];
    }
    return [
      ...menuItems,
      {
        label: 'Change Password',
        icon: 'mdi mdi-key-variant',
        onClick: handleChangePassword,
      },
      {
        label: 'Sign out',
        icon: 'mdi mdi-logout',
        onClick: handleLogout,
      },
    ];
  };

  const changeRightMenu = (isExpand: boolean) => {
    storeRightMenuStyle(isExpand);
    setIsShowRightMenu(isExpand);
  };

  const chooseAccount = (account: SimpleAccountDto) => {
    const isChangeAccount = currentAccount.id !== account.id;
    if (isFormChanged) {
      modal({
        message:
          '<b>Are you sure you want to leave?</b><br></br>\n\nAny changes you have made will not be saved. Please ensure you submit or save your data before leaving the page.',
        changeAccount: () => {
          setCurrentAccount(account);
          storeCurrentAccount(account);
          setAccountSelect(account);
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
        },
      });
    } else {
      setCurrentAccount(account);
      storeCurrentAccount(account);
      setAccountSelect(account);
      dispatch({
        type: Types.GET_FORM_DIRTY_STATUS,
        payload: {
          isFormChanged: false,
        },
      });
    }

    //Back to Home when change account
    if (isChangeAccount) {
      setTimeout(() => {
        navigate(ROUTER.HOME);
      }, 100);
    }
  };

  return (
    <>
      <Header
        end={
          <>
            {isViewClientList && (
              <div className="me-2" data-tut="react_tour__account">
                <DropdownButton variant="light" title={currentAccount?.name || ''}>
                  {accounts.map((item: SimpleAccountDto) => (
                    <Dropdown.Item key={item?.id} onClick={() => chooseAccount(item)}>
                      {item?.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            )}
            {isViewMainHelp && (
              <ul className="notification-list">
                <li className="notification-item-list">
                  <button
                    aria-label="help-topic"
                    className="nav-link dropdown-toggle end-bar-toggle arrow-none btn btn-link shadow-none"
                    onClick={props.handleShowAlllHelpTopic}
                  >
                    <i className="dripicons-question noti-icon"></i>
                  </button>
                </li>
              </ul>
            )}
            <ul className="notification-list">
              <li className="notification-item-list">
                <button
                  onClick={() => changeRightMenu(!isShowRightMenu)}
                  aria-label="setting"
                  className="nav-link dropdown-toggle end-bar-toggle arrow-none btn btn-link shadow-none"
                >
                  <i className="dripicons-gear noti-icon"></i>
                </button>
              </li>
            </ul>
            <ProfileDropdown
              username={userName()}
              menuItems={renderMenuItems()}
              userImage={<AvatarStyled className="avatar-styled">{avartarName()?.toUpperCase()}</AvatarStyled>}
            />
          </>
        }
      />
      <RightSidebar isShowRightMenu={isShowRightMenu} setIsShowRightMenu={changeRightMenu} />
      <IdleTimerPortal Keycloak={keyCloak} />
    </>
  );
};
export default DefaultHeader;
