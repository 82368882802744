import { Types } from '../constant/action-type';

type TourMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type TourType = {
  showTour: boolean;
  showModalConfirmStartTour: boolean;
};

type TourPayload = {
  [Types.SET_SHOW_TOUR]: {
    showTour: boolean;
  };
  [Types.SET_SHOW_MODAL_CONFIRM_START_USER_TOUR]: {
    showModalConfirmStartTour: boolean;
  };
};

export type TourActions = TourMap<TourPayload>[keyof TourMap<TourPayload>];

export const tourReducer = (state: TourType, action: TourActions) => {
  switch (action.type) {
    case Types.SET_SHOW_TOUR:
      const showTour = action.payload.showTour;
      return { ...state, showTour };
    case Types.SET_SHOW_MODAL_CONFIRM_START_USER_TOUR:
      const showModalConfirmStartTour = action.payload.showModalConfirmStartTour;
      return { ...state, showModalConfirmStartTour };
    default:
      return state;
  }
};
