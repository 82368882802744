// @flow
import React, { ReactElement, useState } from 'react';
import { RightSideBarProps } from './header';
import styled from 'styled-components';
import {
  changeLayoutStyle,
  getsLayoutMode,
  storeLayoutMode,
  getLeftMenuMode,
  storeLeftMenuMode,
  changeLeftMenuMode,
} from '../utils/layout';
import { LAYOUT_MODE, LEFT_MENU_MODE } from '../constant';
import { FormInput, RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { RightSideBarLabel } from '../components/StyledComponent';

const CustomHr = styled.hr`
  border: 1px solid #a3a3a3;
  margin-bottom: 0.5rem;
`;

const RightSideBar = (props: RightSideBarProps): ReactElement<any> => {
  const { isShowRightMenu, setIsShowRightMenu } = props;
  const layoutMode = getsLayoutMode();
  const leftMenuMode = getLeftMenuMode();
  const [switchMode, setSwitchMode] = useState(layoutMode);
  const [switchLeftMenuMode, setSwitchLeftMenuMode] = useState(leftMenuMode);

  const handleChangeStyle = (value: string) => {
    setSwitchMode(value);
    storeLayoutMode(value);
    changeLayoutStyle(value);
  };

  const handleChangeLeftStyle = (value: string) => {
    setSwitchLeftMenuMode(value);
    storeLeftMenuMode(value);
    changeLeftMenuMode(value);
  };

  return (
    <>
      <RightSidebar title="Settings" setShowRightSidebar={setIsShowRightMenu} showRightSidebar={isShowRightMenu}>
        <div className="rightbar-content h-100">
          <div className="pt-2">
            <RightSideBarLabel>Color Scheme</RightSideBarLabel>
            <CustomHr />
            <div className="my-2">
              <FormInput
                labelGrid={7}
                id="layout-scheme-light-mode"
                name="layout"
                type="switch"
                label="Light Mode"
                reverse
                aria-label="light-mode"
                value={LAYOUT_MODE.LIGHT}
                onChange={(e) => handleChangeStyle(e.target.value)}
                checked={switchMode === LAYOUT_MODE.LIGHT}
                className="display-content"
              />
            </div>
            <div className="my-2">
              <FormInput
                labelGrid={7}
                id="layout-scheme-dark-mode"
                name="layout"
                type="switch"
                label="Dark Mode"
                reverse
                aria-label="dark-mode"
                value={LAYOUT_MODE.DARK}
                onChange={(e) => handleChangeStyle(e.target.value)}
                checked={switchMode === LAYOUT_MODE.DARK}
                className="display-content"
              />
            </div>
          </div>
          <div className="pt-2">
            <RightSideBarLabel>Left Sidebar Color</RightSideBarLabel>
            <CustomHr />
            <div className="my-2">
              <FormInput
                labelGrid={7}
                id="layout-sidebar-light-mode"
                name="theme"
                type="switch"
                label="Light"
                reverse
                aria-label="light-mode"
                value={LEFT_MENU_MODE.LIGHT}
                onChange={(e) => handleChangeLeftStyle(e.target.value)}
                checked={switchLeftMenuMode === LEFT_MENU_MODE.LIGHT}
                className="display-content"
              />
            </div>
            <div className="my-2">
              <FormInput
                labelGrid={7}
                id="layout-sidebar-dark-mode"
                name="theme"
                type="switch"
                label="Dark"
                reverse
                aria-label="dark-mode"
                value={LEFT_MENU_MODE.DARK}
                onChange={(e) => handleChangeLeftStyle(e.target.value)}
                checked={switchLeftMenuMode === LEFT_MENU_MODE.DARK}
                className="display-content"
              />
            </div>
          </div>
        </div>
      </RightSidebar>
    </>
  );
};

export default RightSideBar;
