import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CONFIRM_MESSAGE, FORM_STATE, PERMISSIONS, ROUTER, UNKNOWN_ERROR_CODE, WARNING_MESSAGE } from '../../constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Configuration } from '../../components/form-detail';
import { Types } from '../../constant/action-type';
import { FormTemplates } from '../../form-api-client/FormTemplates';
import { handleErrorLogMessage, handleErrorStatus } from '../../utils';
import { useDispatch, useFormTemplateConfig, useFormTemplateDetail } from '../../context';
import { useToast } from '../../context/toast-context';
import { FormTemplateState } from '../../form-api-client/data-contracts';
import LayoutDetail from '../../components/layout-detail';
import { ConfirmDialogStyled, PreTitle } from '../../components/StyledComponent';
import { usePermissions } from '../../context/auth-context';
import { FormInput } from '@thg-harveynash/hyper-shared-components';
import useAccessibility from '../../utils/useAccessibility';

function FormTemplatDetailPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false); //NOSONAR
  const toast = useToast();
  let params = useParams();
  const dispatch = useDispatch();
  const id = params && params.id ? params.id : '';
  const navigate = useNavigate();
  const location = useLocation();
  const { addAriaLabelToForm } = useAccessibility();
  const { permissionList } = usePermissions();
  const canPreviewTemplate = permissionList.includes(PERMISSIONS.preview_form_template);
  const canUpdateTemplate = permissionList.includes(PERMISSIONS.edit_form_template_configurations);
  const [isShowModalConfirmPublish, setIsShowModalConfirmPublish] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [isShowWarningMes, setIsShowWarningMes] = useState(false);
  const [selectedApproverConfiguration, setSelectedApproverConfiguration] = useState<any>([]);

  const onPreview = () => {
    navigate(`/configuration/form-templates/${id}/preview`);
  };

  const activeKey = location.pathname.split('/')?.[4];
  const formDetailTabs = [
    // { key: 'builder', title: 'Form Builder', component: <FormBuilder formID={id} /> },
    {
      key: 'configuration',
      title: 'Configurations',
      component: <Configuration formID={id} setSelectedApproverConfiguration={setSelectedApproverConfiguration} />,
    },
    // { key: 'mapper', title: 'Data Integration', component: <DataIntegration formID={id} /> },
  ];
  const formManagementApi = new FormTemplates();
  const formTemplateDetail = useFormTemplateDetail();
  const formTemplateConfig = useFormTemplateConfig();
  const publishedForm = formTemplateDetail?.state === FORM_STATE.PUBLISHED;
  const breadcrumbItems = [
    { path: `${ROUTER.CONFIGURATION_FORM}`, name: 'Form Templates', active: false },
    { path: '', name: `${formTemplateDetail?.name}`, active: true },
  ];

  const getFormTemplateConfig = async (formTemplateId: string) => {
    try {
      await formManagementApi
        .getFormTemplate(formTemplateId)
        .then((res) => {
          dispatch({
            type: Types.GET_FORM_TEMPLATE_DETAIL,
            payload: {
              formTemplate: res.data,
            },
          });
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          navigate(ROUTER.ERROR_PAGE);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFormTemplateConfig(id);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToForm();
    }, 300);
    return () => clearTimeout(timer);
  }, [formDetailTabs]);

  const onChangeTogglePublish = () => {
    if (selectedApproverConfiguration.length === 0) {
      setIsShowWarningMes(true);
      return;
    }
    setIsShowModalConfirmPublish(true);
  };

  const handleConfirmPublish = async () => {
    setIsLoadingPublish(true);
    let data = {
      state: FormTemplateState.PUBLISHED,
    };
    await formManagementApi
      .putFormTemplateState(formTemplateConfig?.templateId || '', data)
      .then((res) => {
        if (res?.data?.state === FORM_STATE.PUBLISHED) {
          dispatch({
            type: Types.GET_FORM_TEMPLATE_DETAIL,
            payload: {
              formTemplate: { ...formTemplateDetail, state: FORM_STATE.PUBLISHED },
            },
          });
          setIsShowModalConfirmPublish(false);
          setIsLoadingPublish(false);
          toast({ message: 'Form template has been published successfully!', type: 'success' });
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      });
  };

  const onChangePath = (path: string) => {
    navigate(path);
  };

  const PageHeader = () => {
    return (
      <>
        <h3>Form Template Configurations</h3>
        <div className="d-flex align-items-center h-auto justify-content-end">
          {canPreviewTemplate && (
            <Button onClick={onPreview} className="mx-3">
              Preview
            </Button>
          )}
          <FormInput
            label={`${publishedForm ? 'Published' : 'Unpublished'}`}
            labelGrid={1}
            name="publishFormTemplate"
            type="switch"
            aria-label="dark-mode"
            onChange={onChangeTogglePublish}
            checked={publishedForm}
            className="mx-1"
            disabled={publishedForm || !canUpdateTemplate}
          />
        </div>
      </>
    );
  };

  const HeaderConfirmPublish = (
    <div className="d-flex align-items-center">
      <img src="/icon/info.svg" alt=""></img>Confirmation
    </div>
  );

  return (
    <>
      <LayoutDetail
        pageTitle={`${formTemplateDetail?.name}: Edit Configurations`}
        breadcrumbItems={breadcrumbItems}
        onChangePathBreadCrumb={(path: string) => onChangePath(path)}
        isLoading={isLoading}
        pageHeader={<PageHeader />}
      >
        <Row className="d-flex justify-content-between">
          <ConfirmDialogStyled
            show={isShowModalConfirmPublish}
            header={HeaderConfirmPublish}
            onCancel={() => setIsShowModalConfirmPublish(false)}
            onHide={() => setIsShowModalConfirmPublish(false)}
            onConfirm={handleConfirmPublish}
            isLoading={isLoadingPublish}
            btnCancel={{
              label: undefined,
              icon: undefined,
              iconSrc: undefined,
              variant: undefined,
            }}
            btnConfirm={{
              label: 'Yes',
              icon: undefined,
              iconSrc: undefined,
              variant: 'success',
            }}
          >
            <b>Are you sure you want to publish this form template?</b>
            <PreTitle>{CONFIRM_MESSAGE}</PreTitle>
          </ConfirmDialogStyled>
          <ConfirmDialogStyled
            show={isShowWarningMes}
            onCancel={() => setIsShowWarningMes(false)}
            onHide={() => setIsShowWarningMes(false)}
            footer={
              <div className="d-flex justify-content-end w-100">
                <Button className="ms-1 px-3" variant="danger" onClick={() => setIsShowWarningMes(false)}>
                  OK
                </Button>
              </div>
            }
          >
            <b>Cannot publish this form template!</b>
            <PreTitle>{WARNING_MESSAGE}</PreTitle>
          </ConfirmDialogStyled>
          <Col lg={12}></Col>
          <Col lg={12}>{formDetailTabs.find((tab) => activeKey.includes(tab.key))?.component}</Col>
        </Row>
      </LayoutDetail>
    </>
  );
}

export default FormTemplatDetailPage;
