import React, { useEffect, useState, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Calendar } from 'primereact/calendar';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Projects } from '../../../projects-api-client/Projects';
import { Settings } from '../../../projects-api-client/Settings';
import { DATE_CALENDAR_FORMAT, UNKNOWN_ERROR_CODE } from '../../../constant';
import { getDueDate, getFilterValues, getMilestoneByIds, handleErrorLogMessage } from '../../../utils';
import { FormReactSelect } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { useToast } from '../../../context/toast-context';

interface FilterProp {
  projectId: string;
  parametersSearch: any;
  updateUrlSearch: any;
  setParametersSearch: any;
  handleFilter?: any;
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<any>>;
  dates: any;
  setDates: React.Dispatch<React.SetStateAction<any>>;
  milestones: any;
  setMilestone: React.Dispatch<React.SetStateAction<any>>;
  reParty: any;
  setReParty: React.Dispatch<React.SetStateAction<any>>;
  RAGStatus: any;
  setRAGStatus: React.Dispatch<React.SetStateAction<any>>;
  taskStatus: any;
  setTaskStatus: React.Dispatch<React.SetStateAction<any>>;
}

const FilterTask = ({
  projectId,
  parametersSearch,
  updateUrlSearch,
  setParametersSearch,
  handleFilter,
  showFilter,
  setShowFilter,
  dates,
  setDates,
  milestones,
  setMilestone,
  reParty,
  setReParty,
  RAGStatus,
  setRAGStatus,
  taskStatus,
  setTaskStatus,
}: FilterProp) => {
  const location = useLocation();
  const [isFetch, setIsFetch] = useState(false);
  const [listOptions, setListOptions] = useState<any>({
    milestones: [],
    RAGStatus: [],
    taskStatus: [],
    responsibleParties: [],
  });

  const toast = useToast();
  const rightbarRef = useRef(null);
  const projectApi = new Projects();
  const projectSettingApi = new Settings();
  const onReset = () => {
    (() => {
      setDates(undefined);
      setMilestone([]);
      setReParty([]);
      setRAGStatus([]);
      setTaskStatus([]);
    })();
    const newParam = {
      ...parametersSearch,
      search: '',
      milestoneExtIds: [],
      responsibleParties: [],
      ragStatuses: [],
      statuses: [],
      dueDateFrom: '',
      dueDateTo: '',
    };
    updateUrlSearch(newParam);
    setParametersSearch(newParam);
    handleFilter(newParam);
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };
  const handleApplyfilter = () => {
    const newParam = {
      ...parametersSearch,
      search: '',
      milestoneExtIds: getFilterValues(milestones),
      responsibleParties: getFilterValues(reParty),
      ragStatuses: getFilterValues(RAGStatus),
      statuses: getFilterValues(taskStatus),
      dueDateFrom: getDueDate(dates, true),
      dueDateTo: getDueDate(dates, false) !== 'Invalid date' ? getDueDate(dates, false) : '',
    };
    setShowFilter(false);
    updateUrlSearch(newParam);
    setParametersSearch(newParam);
    handleFilter(newParam);
  };
  const fetchData = async () => {
    const param = {
      page: 0,
      limit: 1000,
    };
    let milestonesOption: any;
    let ragStatusOption: any;
    let taskStatusOption: any;
    let taskResPartiesOption: any;
    try {
      milestonesOption = await projectApi.getMilestonesByProject(projectId, param);
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
    }
    try {
      ragStatusOption = await projectSettingApi.getRagStatuses();
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
    }
    try {
      taskStatusOption = await projectSettingApi.getTaskStatuses();
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
    }
    try {
      taskResPartiesOption = await projectSettingApi.getResponsiblePartiesForTask();
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
    }

    let dummyOption = {
      milestones: (milestonesOption?.data?.items || []).map((mile: any) => {
        return {
          value: mile.extSysId,
          label: mile.name,
        };
      }),
      RAGStatus: (ragStatusOption?.data || []).map((rag: any) => {
        return {
          value: rag.displayValue,
          label: rag.displayValue,
        };
      }),
      taskStatus: (taskStatusOption?.data || []).map((task: any) => {
        return {
          value: task.displayValue,
          label: task.displayValue,
        };
      }),
      responsibleParties: (taskResPartiesOption?.data || []).map((resParty: any) => {
        return {
          value: resParty.value,
          label: resParty.value,
        };
      }),
    };
    setListOptions(dummyOption);
    setIsFetch(true);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetch) {
      const paramFromUrl = queryString.parse(location.search);
      const { dueDateFrom = '', dueDateTo = '', milestoneExtIds } = paramFromUrl;
      if (dueDateFrom && dueDateTo) {
        setDates([new Date(dueDateFrom.toString()), new Date(dueDateTo.toString())]);
      }
      setMilestone(getMilestoneByIds(milestoneExtIds, listOptions.milestones));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersSearch, isFetch]);
  return (
    <>
      <RightSidebar title="Filters" setShowRightSidebar={(value) => setShowFilter(value)} showRightSidebar={showFilter}>
        <div className="rightsidebar-content-height" ref={rightbarRef}>
          <div className="form_group mb-3">
            <Row>
              <Col sm={12} className="form_group_label_container mb-1">
                <label className="form_group_label form-label">Due Date</label>
              </Col>
              <div>
                <Calendar
                  id="range"
                  value={dates}
                  onChange={(e) => setDates(e.value)}
                  selectionMode="range"
                  readOnlyInput
                  className="w-100 p-calendar p-calendar-custom"
                  style={{ height: '38px' }}
                  placeholder="Select Date Range"
                  dateFormat={DATE_CALENDAR_FORMAT}
                  appendTo={rightbarRef.current}
                />
              </div>
            </Row>
          </div>
          <FormReactSelect
            value={milestones}
            onChange={setMilestone}
            name="milestones"
            label="Milestone"
            placeholder="Select Milestones"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.milestones}
            isMulti
          />
          <FormReactSelect
            value={reParty}
            onChange={setReParty}
            name="responsibleParty"
            label="Responsible Party"
            placeholder="Select Responsible Party"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.responsibleParties}
            isMulti
          />
          <FormReactSelect
            value={RAGStatus}
            onChange={setRAGStatus}
            name="ragStatus"
            label="RAG Status"
            placeholder="Select RAG Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.RAGStatus}
            isMulti
          />
          <FormReactSelect
            value={taskStatus}
            onChange={setTaskStatus}
            name="taskStatus"
            label="Task Status"
            placeholder="Select Task Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.taskStatus}
            isMulti
          />
          <Row>
            <Col sm={6}>
              <Button
                className="w-100 justify-content-center"
                type="button"
                onClick={() => onReset()}
                variant="outline-primary"
              >
                Reset
              </Button>
            </Col>
            <Col sm={6}>
              <Button type="button" className="w-100 justify-content-center" onClick={() => handleApplyfilter()}>
                Apply filter
              </Button>
            </Col>
          </Row>
        </div>
      </RightSidebar>
    </>
  );
};

export default FilterTask;
