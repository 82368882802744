import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountList, useDispatch, useLeftMenuStyle } from '../../context';
import { LIST_ACCOUNTS_COLUMNS, PERMISSIONS, TITLE_ACC_TABLE, UNKNOWN_ERROR_CODE } from '../../constant';
import { Accounts } from '../../accounts-api-client/Accounts';
import queryString from 'query-string';
import { Types } from '../../constant/action-type';
import {
  checkDynamicLink,
  getListIdShowTooltipInPrimeTable,
  getPerPageTable,
  handleErrorLogMessage,
} from '../../utils';
import { Tooltip as PrimereactTooltip } from 'primereact/tooltip';
import { CommonActionBar, Datatable } from '@thg-harveynash/hyper-shared-components';
import { usePermissions } from '../../context/auth-context';
import { GlobalConfig } from '../../global';
import useAccessibility from '../../utils/useAccessibility';
import { useToast } from '../../context/toast-context';

export interface ParametersSearchI {
  search: string;
  page: number;
  limit: number;
  sortBy: string;
  sortOrder?: number;
  sortField?: string;
}

function ListAccounts() {
  const location = useLocation();
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '' } = paramFromUrl; //NOSONAR
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;

  const perPageTable = getPerPageTable();
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField || 'crmAcctId',
    sortOrder: sortOrder || -1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [listTooltipActive, setListTooltipActive] = useState<Array<string>>([]);

  const accountApi = new Accounts();

  const account = useAccountList();
  const leftMenuStyle = useLeftMenuStyle();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { addAriaLabelToTable } = useAccessibility();
  const { permissionList } = usePermissions();
  const canViewDetails = permissionList.includes(PERMISSIONS.view_account_details);
  const toast = useToast();

  const AccNameAndWebsiteBodyTemplate = (rowData: any, type: string, isRenderAccName?: boolean) => {
    const fieldDisplay = isRenderAccName ? rowData?.name : rowData?.website;
    const idActiveTooltip = `${rowData.id}-${isRenderAccName ? '1' : '2'}`;
    return (
      <a
        href={
          isRenderAccName
            ? `${GlobalConfig().config.REACT_APP_CRM_ZOHO_URL}${rowData?.organization?.domainName}/tab/Accounts/${
                rowData?.crmSysId
              }`
            : checkDynamicLink(rowData?.website)
        }
        target="_blank"
        rel="noreferrer"
        id={`${rowData.id}-${type}`}
      >
        <PrimereactTooltip
          target={`.acc-name-${idActiveTooltip}`}
          content={listTooltipActive.includes(idActiveTooltip) ? fieldDisplay : ''}
          position="top"
        />
        <u className={`acc-name-${idActiveTooltip}`}>{fieldDisplay}</u>
      </a>
    );
  };

  const listAccountsCol = LIST_ACCOUNTS_COLUMNS.map((acc) => {
    if (acc.field === TITLE_ACC_TABLE.NAME)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => AccNameAndWebsiteBodyTemplate(data, 'name', true),
        className: 'max-width-none',
      };

    if (acc.field === TITLE_ACC_TABLE.WEBSITE)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => AccNameAndWebsiteBodyTemplate(data, 'website'),
        className: 'max-width-none',
      };

    if (acc.field === TITLE_ACC_TABLE.ACTIVE_STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        body: (data: any) => (
          <>
            <i
              className="mdi mdi-circle me-1"
              style={{ fontSize: 10, color: data?.activeStatus ? 'green' : '#C42C21' }}
            />
            {data?.activeStatus ? 'Active' : 'Inactive'}
          </>
        ),
        style: { maxWidth: '140px', minWidth: '140px' },
      };
    return {
      resizeable: true,
      field: acc.field,
      label: acc.header,
      sortable: true,
      className: 'max-width-none',
    };
  });

  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  const onRowSelect = (event: any) => {
    navigate(`/configuration/accounts/${event.data.id}`);
  };

  useLayoutEffect(() => {
    const newArrTooltipsActive = getListIdShowTooltipInPrimeTable([1, 2]);
    setListTooltipActive(newArrTooltipsActive);
  }, [account, leftMenuStyle]);

  const getTableData = async (params: any) => {
    setIsLoadingTable(true);
    const { rows, page: pageData, sortField: sortFieldData, sortOrder: sortOrderData, searchKey, first } = params;
    setParametersSearch({ rows, page: pageData, sortField: sortFieldData, sortOrder: sortOrderData, searchKey, first });
    const sortByData = `${sortFieldData}:${sortOrderData === 1 ? 'asc' : 'desc'}`;
    const paramSubmit = {
      search: searchKey,
      limit: rows,
      page: pageData,
      sortBy: sortByData,
    };
    localStorage.setItem('perPageTable', rows);
    updateUrlSearch(paramSubmit);
    accountApi
      .getAccounts(paramSubmit)
      .then((res) => {
        const listAccount = res.data;
        dispatch({
          type: Types.GET_ACCOUNT,
          payload: { accounts: listAccount },
        });
        setIsLoadingTable(false);
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
        setIsLoadingTable(false);
      });
  };
  const fetchData = useCallback((params: any) => {
    getTableData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [account]);

  return (
    <>
      <CommonActionBar pageTitle="Accounts" isVisibleButton={false} breadcrumbItems={[]} />
      <div className="page-content">
        <Datatable
          fetchData={fetchData}
          totalRecords={account?.pageInfo?.total || 0}
          data={account?.items || []}
          isLoading={isLoadingTable}
          {...(canViewDetails && { onRowSelect: onRowSelect })}
          columns={listAccountsCol}
          tableName="accounts"
          emptyMessage="No results found."
          initParams={parametersSearch}
          columnResizeMode="fit"
          sortField={parametersSearch.sortField}
          searchKey={parametersSearch.searchKey}
          scrollable
        />
      </div>
    </>
  );
}

export default ListAccounts;
