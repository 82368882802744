// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, ProgressBar } from 'react-bootstrap';
import Dropzone, { FileRejection, ErrorCode } from 'react-dropzone';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { MAX_FILE_SIZE_IN_BYTES, MIN_FILE_SIZE_IN_BYTES, UPLOADING_FILE_MESSAGE } from '../../constant';
const DropzoneStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 2rem 0;
  border: 2px dashed #666666;
`;

interface FileUploaderProps {
  onFileChange?: (files: any) => void;
  multiple: boolean;
  accept: any;
  required: boolean;
  selectedFiles: any[] | never[];
  helperText: string;
  isUploadingFile: boolean;
  readOnly?: boolean;
}

const FileUploader = ({
  onFileChange,
  multiple,
  accept,
  required,
  selectedFiles,
  helperText,
  isUploadingFile,
  readOnly,
}: FileUploaderProps) => {
  const [selectedUploadFiles, setSelectedUploadFiles] = useState<any[]>(selectedFiles);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    setSelectedUploadFiles(selectedFiles);
  }, [selectedFiles]);
  const handleRejectFiles = (fileRejections: FileRejection[]) => {
    let errorCode = fileRejections.length > 0 ? fileRejections[0].errors[0].code : '';
    setShowError(true);
    switch (errorCode) {
      case ErrorCode.FileInvalidType:
        setErrorMessage('Upload supported file types only.');
        break;
      case ErrorCode.TooManyFiles:
        setErrorMessage('You can upload 1 file only.');
        break;
      case ErrorCode.FileTooSmall:
        setErrorMessage('Could not upload 0 byte file.');
        break;
      case ErrorCode.FileTooLarge:
        setErrorMessage('Exceed the maximum upload file size.');
        break;
      default:
        break;
    }
  };

  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = (files: any) => {
    if (!multiple && files.length === 0) return;

    files.forEach((file: any) => {
      let fileUploadStatusId = uuidv4();
      Object.assign(file, {
        preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
        formattedSize: formatBytes(file.size),
        fileUploadStatusId: fileUploadStatusId,
        isUploadSuccess: undefined,
      });
    });

    let allFiles: any = [];

    if (multiple) {
      allFiles = [...selectedUploadFiles];
    } else {
      allFiles = [];
    }
    allFiles.push(...files);
    setSelectedUploadFiles(allFiles);
    setShowError(false);

    if (onFileChange) onFileChange(allFiles);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  /*
   * Removes the selected file
   */
  const removeFile = (fileIndex: number) => {
    const newFiles = [...selectedUploadFiles];
    newFiles.splice(fileIndex, 1);
    setSelectedUploadFiles(newFiles);
    if (newFiles.length === 0 && required) {
      setErrorMessage('Upload a file here.');
      setShowError(true);
    }
    if (onFileChange) onFileChange(newFiles);
  };

  useEffect(() => {
    if (readOnly) {
      window.addEventListener(
        'dragover',
        function (e) {
          e.preventDefault();
        },
        false
      );
      window.addEventListener(
        'drop',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }
  }, [readOnly]);

  return (
    <>
      <Dropzone
        multiple={multiple}
        accept={accept}
        minSize={MIN_FILE_SIZE_IN_BYTES}
        maxSize={MAX_FILE_SIZE_IN_BYTES}
        disabled={readOnly}
        onDrop={handleAcceptedFiles}
        onDropRejected={(fileRejections: FileRejection[]) => handleRejectFiles(fileRejections)}
      >
        {({ getRootProps, getInputProps }: any) => (
          <DropzoneStyled {...getRootProps()} style={{ cursor: readOnly ? 'not-allowed' : 'pointer' }}>
            <div>
              <input {...getInputProps()} />
              <i className="h3 dripicons-cloud-upload d-flex justify-content-center"></i>
              <h5>Drop files here or click to upload.</h5>
            </div>
          </DropzoneStyled>
        )}
      </Dropzone>

      {showError && <div className="text-danger mt-1 font-12">{errorMessage}</div>}
      {selectedUploadFiles.length > 0 && (
        <div className="dropzone-previews mt-3 mb-2">
          {(selectedUploadFiles || []).map((f, i) => {
            let progressPercent = 0;
            if (isUploadingFile && f.isUploadSuccess === undefined) progressPercent = 20;
            else if (f.isUploadSuccess === true) progressPercent = 100;
            return (
              <Card className="mt-1 mb-0 shadow-none border" key={i + '-file'}>
                <div className="p-2">
                  <Row className="align-items-center">
                    {f.preview && (
                      <Col className="col-auto">
                        <img data-dz-thumbnail="" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                      </Col>
                    )}
                    {!f.preview && (
                      <Col className="col-auto">
                        <i className="dripicons-document-new" style={{ fontSize: 28 }} />
                      </Col>
                    )}
                    <Col className="ps-0">
                      {f.name}
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="text-end">
                      {!isUploadingFile && (
                        <span role="button" aria-label="Remove File" className="p-1" onClick={() => removeFile(i)}>
                          <i className="dripicons-cross" aria-label={`remove-file-${f.name}`} />
                        </span>
                      )}
                    </Col>
                  </Row>
                  {f.isUploadSuccess === false && (
                    <span style={{ color: '#fa5c7c' }}>{f?.errorMessage ? f.errorMessage : 'Upload Failed'}</span>
                  )}
                  {isUploadingFile && (
                    <ProgressBar
                      className="mb-1 mt-1 justify-content-start"
                      now={progressPercent}
                      label={`${progressPercent}%`}
                    />
                  )}
                  {isUploadingFile && f.isUploadSuccess === undefined && <span>{UPLOADING_FILE_MESSAGE}</span>}
                </div>
              </Card>
            );
          })}
        </div>
      )}

      <p className="font-12 mb-1">{helperText}</p>
    </>
  );
};

FileUploader.defaultProps = {
  showPreview: true,
};

export default FileUploader;
