import { Skeleton } from 'primereact/skeleton';
import styled from 'styled-components';
import { Nav, Col, Modal } from 'react-bootstrap';
import { ConfirmDialog, FormInput } from '@thg-harveynash/hyper-shared-components';
import Datatable from './Datatable';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import Tour from 'reactour';

export const LinkUnderline = styled.a`
  text-decoration: underline;
  color: var(--ct-link-color) !important;
`;

export const CenterStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomSizeSpan = styled.span`
  font-size: 0.9375rem;
`;

export const LabelInforNoneMargin = styled.h5`
  margin: 0px !important;
  color: var(--ct-form-label-color);
  font-size: 14px;
`;

export const DetailInforCustomSize = styled.span`
  font-size: 14px;
`;

export const InputInTableStyled = styled.input`
  height: 38px;
  border-radius: 4px 0 0 4px !important;
  :enabled:focus {
    box-shadow: none !important;
  }
  :enabled:hover,
  :enabled:focus {
    border-color: #dee2e6;
  }
`;

export const ButtonInTableStyled = styled.button`
  height: 38px;
  font-size: 15px;
  border-radius: 0 4px 4px 0px !important;
  :focus {
    box-shadow: none;
  }
`;

export const ButtonDangerStyled = styled.button`
  border-radius: 4px;
  background-color: #fa5c7c;
  border: 1px solid #fa5c7c;
  width: 75px;
  height: 36px;
  color: #fff;
`;

export const ButtonSuccessStyled = styled(ButtonDangerStyled)`
  background-color: #0acf97;
  border: 1px solid #0acf97;
`;

export const ButtonInfoStyled = styled(ButtonDangerStyled)`
  background-color: #605bff;
  border: 1px solid #605bff;
  width: auto;
`;

export const ButtonWarning = styled(ButtonDangerStyled)`
  background-color: #ffbc00;
  border: 1px solid #ffbc00;
`;

export const ButtonNoBorderStyled = styled(ButtonDangerStyled)`
  background-color: transparent;
  border: 0px;
  width: auto;
  color: var(--ct-body-color);
`;

export const AccordionStyled = styled.div`
  margin-bottom: 24px;
  .p-accordion-header-text {
    font-size: 18px;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    font-size: 16px;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border-radius: 4px;
  }
  .p-accordion .p-accordion-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export const AccordionFormIntructions = styled.div`
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border-width: 0px 0px 1px 0px !important;
    border-style: solid;
    border-color: #dee2e6;
    background: none !important;
    border-radius: 0 !important;
    padding: 1rem 1.25rem 12px 0rem !important;
  }
  .p-accordion .p-accordion-content {
    border: none !important;
    font-weight: normal;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
  }
`;

export const SpinnerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
`;

export const SaveFormSpinnerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: white;
`;

export const LabelFilterStyled = styled.label`
  font-size: 14px;
`;

export const LabelFontNormalStyled = styled.label`
  font-size: 14px;
  font-weight: normal;
`;

export const FormInputNoStyleValid = styled(FormInput)`
  background-image: none !important;
`;

export const MultiSelectStyle = styled.div`
  .multi-valid {
    border: 1px solid #0acf97 !important;
  }
  .multi-error {
    border-color: #fa5c7c !important;
  }
  .multi-error .p-placeholder,
  .multi-error .p-multiselect-trigger-icon {
    color: #fa5c7c !important;
  }
`;

export const ModalFooterStyled = styled(Modal.Footer)`
  border: none !important;
  .disable-button-style {
    cursor: not-allowed;
  }
`;

export const ModalHeadertyled = styled(Modal.Header)`
  padding: 25px 25px 0px;
`;

export const TabsStyled = styled(Nav)`
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #dee2e6;
  .nav-link {
    font-weight: bold;
  }
  .nav-link.active {
    background-color: var(--layout-primary-system-color);
  }
`;

export const DropdownIcon = () => (
  <svg
    height="20"
    width="20"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    className="css-tj5bde-Svg dropdown-select"
  >
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
  </svg>
);

export const LazyLoadSkeleton = (options: any) => (
  <div className="flex align-items-center p-2" style={{ height: '48px' }}>
    <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
  </div>
);

export const PreTitle = styled.pre`
  font-size: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-bottom: 0px;
`;

export const TitleSection = styled.p`
  font-weight: 700;
  font-size: 26px;
`;

export const SectionArea = styled.div`
  border-bottom: 1px solid #dbdddf;
  margin-bottom: 1rem;
`;

export const IconSort = styled.span`
  font-size: 20px !important;
`;

export const SortIconStyle = styled.div`
  .pi-sort-comment {
    height: 40px;
    position: absolute;
    top: -2px;
    transform: rotate(45deg);
    font: normal normal normal 24px/1 'Material Design Icons', sans-serif;
    &::before {
      content: '\\F19B7' !important;
      position: absolute;
      left: 5px;
      top: 7px;
    }

    &::after {
      content: '\\F19B5' !important;
      position: absolute;
      left: 0;
      top: 13px;
    }
  }
  .pi-sort-comment-up {
    &::after {
      color: var(--ct-primary);
    }
  }
  .pi-sort-comment-down {
    &::before {
      color: var(--ct-primary);
    }
  }
`;

export const Font14Bold = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const Font16Bold = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const Font14 = styled.span`
  font-size: 14px;
`;

export const ColInputForm = styled(Col)`
  .col-sm-12 {
    padding: 0 !important;
  }
  .row {
    margin: auto;
  }
`;

export const ConfirmDialogStyled = styled(ConfirmDialog)`
  .modal-header {
    margin-top: 25px;
    margin-bottom: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    .btn-close {
      display: contents;
      margin-right: 0px;
    }
  }
`;

export const ConfirmDialogUploadFile = styled(ConfirmDialogStyled)`
  .modal-body {
    padding-bottom: 0;
  }
  .modal-footer {
    padding: 5px 25px 25px;
    button {
      margin: 0;
    }
  }
`;

export const SpinnerInButtonStyled = styled.span`
  margin: 2px 24px;
  .avatar-sm {
    height: 18px;
    width: 18px;
  }
  div {
    color: white !important;
  }
`;

export const HomePageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 140px);
  .welcome-session {
    width: 80%;
    h2 {
      font-size: 45px;
    }
    p {
      margin-bottom: 0.5rem;
    }
    .home-button {
      height: 50px;
    }
    .p-title {
      font-size: 26px;
    }
    .p-des {
      font-size: 14px;
    }
  }

  @media (max-width: 1750px) {
    .welcome-session {
      width: 90%;
    }
    .home-button {
      height: 60px !important;
      font-size: 16px;
    }
  }
`;

export const ButtonActionArea = styled.div`
  height: 30px;
`;

export const DataTableStyled = styled(Datatable)`
  td {
    max-width: none;
  }
`;

export const SpinnerStyledBulkAction = styled(Spinner)`
  width: 1.5rem !important;
  height: 1.5rem !important;
`;

export const TourStyle = styled(Tour)`
  background-color: var(--ct-modal-content-bg);
`;

export const RightSideBarLabel = styled.h5`
  color: var(--ct-form-label-color);
  margin-top: 0.375rem;
`;

export const AvatarStyled = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #1f5b8f;
  margin-right: 9px;
  font-style: normal;
  font-weight: lighter;
  line-height: 33px;
  text-align: center;
`;

export const ErrorMessageAccountSelected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
`;
