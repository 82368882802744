import React from 'react';
import { Footer } from '@thg-harveynash/cp-hyper-ui-comps';
import { APP_VERSION, COPY_RIGHT_YEAR } from '../constant/config';
import { getRole } from '../utils';
import { ROLE_TYPE, ROUTER } from '../constant';
import { useNavigate } from 'react-router-dom';

const DefaultFooter = () => {
  const role = getRole();
  const navigate = useNavigate();
  const renderTermsAndPolicy = () => (
    <div className="d-flex" style={{ gap: '10rem' }}>
      <span onClick={() => navigate(ROUTER.TERM_OF_USE)} className="footer-link">
        Terms of use
      </span>
      <span onClick={() => navigate(ROUTER.PRIVACY_POLICY)} className="footer-link">
        Privacy Policy
      </span>
    </div>
  );
  return (
    <Footer
      start={<span>Version: {APP_VERSION}</span>}
      middle={role === ROLE_TYPE.CLIENT ? renderTermsAndPolicy() : undefined}
      end={<span>© THG Ingenuity {COPY_RIGHT_YEAR}</span>}
    />
  );
};
export default DefaultFooter;
