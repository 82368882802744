import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { BiX } from 'react-icons/bi';

interface ModalVideoProps {
  isShowVideo: boolean;
  handleCloseVideo: () => void;
}

const ModalCheckinVideo = ({ isShowVideo, handleCloseVideo }: ModalVideoProps) => {
  return (
    <Modal show={isShowVideo} centered>
      <Modal.Body
        className="d-flex justify-content-center align-items-center welcome-modal"
        style={{ background: '#6d747e', padding: 0 }}
      >
        <>
          <iframe
            frameBorder={0}
            src="/videos/checkin.mp4"
            allowFullScreen
            width={765}
            height={400}
            title="checkin-video"
            style={{ border: 'none' }}
            className="video-intro"
          />
          <BiX className="icon-close-video" size={25} onClick={handleCloseVideo} />
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCheckinVideo;
