/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SimpleAccountPageDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Filters<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Account List
   *
   * @tags mini-accounts
   * @name GetMiniAccounts
   * @summary Account List
   * @request GET:/filters/accounts
   * @secure
   */
  getMiniAccounts = (
    query?: {
      name?: string;
      ids?: string[];
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<SimpleAccountPageDto, any>({
      path: `/filters/accounts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
