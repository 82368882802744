import React, { useEffect, useState } from 'react';
import ModalPortal from '../modal/ModalPortal';
import { KeycloakInstance } from 'keycloak-js';
import { accountsInstance, projectsInstance, fileInstance, formInstance, userInstance } from '../../services/instance';
import { IDLE_TIME } from '../../constant';
import { useDispatch } from '../../context';
import { Types } from '../../constant/action-type';

interface IdleTimerPortalI {
  Keycloak: KeycloakInstance | null;
}

function IdleTimerPortal(props: IdleTimerPortalI) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [countDown, setCountDown] = useState(59);
  const [isRunCountDown, setIsRunCountDown] = useState(false);
  const [idleTime, setIdleTime] = useState(IDLE_TIME);

  const { Keycloak } = props;

  const onSingOut = () => {
    localStorage.clear();
    Keycloak?.logout();
  };

  useEffect(() => {
    let intervalCountDown: any;
    if (countDown === 0) {
      onSingOut();
      return;
    }
    if (isRunCountDown) {
      intervalCountDown = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalCountDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown, isRunCountDown]);

  useEffect(() => {
    let intervalIdleTime: any;
    if (idleTime === 0) {
      dispatch({
        type: Types.GET_FORM_DIRTY_STATUS,
        payload: {
          isFormChanged: false,
        },
      });
      setShow(true);
      setIsRunCountDown(true);
      return;
    }
    intervalIdleTime = setInterval(() => {
      setIdleTime((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(intervalIdleTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleTime]);

  const bodyModalTimeout = (
    <div>
      <b>Your session is about to expire!</b>
      <br />
      <span>
        You will be logged out in {countDown} {countDown <= 1 ? 'second' : 'seconds'}.
      </span>
      <br />
      <span>Do you want to stay signed in?</span>
    </div>
  );
  const handleConfirmKeepSignIN = () => {
    setShow(false);
    setIsRunCountDown(false);
    setCountDown(59);
    setIdleTime(IDLE_TIME);
  };

  [accountsInstance, projectsInstance, fileInstance, formInstance, userInstance].forEach((instance) => {
    instance.interceptors.request.use(async (config) => {
      // ignores api get profile
      // api get profile always trigger when refresh token keycloak
      if (config?.url !== 'profile') {
        setIdleTime(IDLE_TIME);
      }
      return config;
    });
  });

  return (
    <>
      <ModalPortal
        title="Session Timeout"
        isShowModal={show}
        children={bodyModalTimeout}
        confirmText="Yes, Keep me signed in"
        cancelText="No, Sign me out"
        onCancel={onSingOut}
        onConfirm={handleConfirmKeepSignIN}
      />
    </>
  );
}
export default IdleTimerPortal;
