import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const ErrorPage = () => {
  return (
    <>
      <h4>404 Error</h4>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col lg={4}>
              <div className="text-center">
                <img src="/icon/file-searching.svg" height="90" alt="" />
                <h4 className="text-uppercase text-danger mt-3">Page Not Found</h4>
                <p className="text-muted mt-3">The page you are trying to access is not available.</p>

                <Link className="btn btn-info mt-3" to="/">
                  Go to Home page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
