import React, { useRef, useLayoutEffect, useState } from 'react';
import { OverlayTrigger, Tooltip as TooltipBT } from 'react-bootstrap';

const TooltipBoostrap = ({ data = '' }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setisOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current?.clientWidth < ref?.current?.scrollWidth) {
      setisOverflow(true);
    } else {
      setisOverflow(false);
    }
  }, [ref]);

  return (
    <div ref={ref}>
      {isOverflow ? (
        <OverlayTrigger
          key="tooltip-top-uploaded"
          placement="top"
          overlay={<TooltipBT id="name-header">{data}</TooltipBT>}
        >
          <span className="text-overflow" ref={ref}>
            {data}
          </span>
        </OverlayTrigger>
      ) : (
        <span>{data}</span>
      )}
    </div>
  );
};

export default TooltipBoostrap;
