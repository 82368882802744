import PTableSubRow from '../../../components/table/TableSubRow';
import RowExpand from './RowExpand';

const RowSubThird = (data: any, _onClickSubTask: () => void, columns: any, isSub: any) => {
  return (
    <div className="p-sub-table">
      <PTableSubRow
        resizableColumns
        totalRecords={data.length}
        value={data}
        columns={columns}
        rows={1000}
        rowExpansionTemplate={RowExpand}
        isExpand={true}
        isSub={isSub}
        noChil={true}
        level={3}
      />
    </div>
  );
};

export default RowSubThird;
