import { Types } from '../constant/action-type';

type HelpCentreMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type HelpCentreType = {
  helpCentre?: {
    isOpen?: boolean;
    formInformation: {
      isShow?: boolean;
      activeIndex?: number[];
    };
    onboardingProcessInformation: {
      isShow?: boolean;
      activeIndex?: number[];
    };
    isShowButtonAllHelpTopic?: boolean;
  };
};

type HelpCentrePayload = {
  [Types.SET_HELP_CENTRE_STYLE]: {
    helpCentre: {
      isOpen: boolean;
      formInformation: {
        isShow: boolean;
        activeIndex: number[];
      };
      onboardingProcessInformation: {
        isShow: boolean;
        activeIndex: number[];
      };
      isShowButtonAllHelpTopic: boolean;
    };
  };
};

export type HelpCentreActions = HelpCentreMap<HelpCentrePayload>[keyof HelpCentreMap<HelpCentrePayload>];

export const helpCentreReducer = (state: HelpCentreType, action: HelpCentreActions) => {
  if (action.type === Types.SET_HELP_CENTRE_STYLE) {
    const helpCentre = action.payload.helpCentre || {};
    return { helpCentre };
  }
  return state;
};
