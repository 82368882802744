export const ROUTER = {
  HOME: '/',
  HOME_PAGE: '/home',
  LOGIN_PAGE: '/login',
  ERROR_PAGE: '/error',
  NON_PERMISSION_PAGE: '/non-permission',
  MANAGEMENT_PROJECT: '/management/projects',
  MANAGEMENT_PROJECT_DETAIL_OVERVIEW: '/management/projects/:id/overview',
  MANAGEMENT_PROJECT_DETAIL_ACTION_TRACKER: '/management/projects/:id/action-tracker',
  MANAGEMENT_PROJECT_DETAIL_MILESTONES: '/management/projects/:id/milestones',
  MANAGEMENT_PROJECT_DETAIL_ACTION_TASKS: '/management/projects/:id/action-tasks',
  MANAGEMENT_PROJECT_DETAIL_ISSUE_TRACKER: '/management/projects/:id/issue-tracker',
  MANAGEMENT_FORMS: '/management/forms',
  MANAGEMENT_FORMS_DETAIL: '/management/forms/:id',
  MANAGEMENT_DOCUMENTS: '/management/documents/folder',
  MANAGEMENT_PRELOAD_DOCUMENTS: '/management/documents',
  CONFIGURATION_ROLES: '/configuration/roles',
  CONFIGURATION_CLIENT_USER: '/configuration/client-users',
  CONFIGURATION_USER: '/configuration/users',
  CONFIGURATION_USER_DETAIL: '/configuration/users/:id',
  CONFIGURATION_ACCOUNT: '/configuration/accounts',
  CONFIGURATION_ACCOUNT_DETAIL: '/configuration/accounts/:id',
  CONFIGURATION_SITE: '/configuration/accounts/:account_id/sites',
  CONFIGURATION_SITE_DETAIL: '/configuration/accounts/:account_id/sites/:site_id',
  CONFIGURATION_SITE_LIST: '/configuration/sites',
  CONFIGURATION_SITE_PAGE_DETAIL: '/configuration/sites/:site_id/accounts/:account_id',
  CONFIGURATION_FORM: '/configuration/form-templates',
  CONFIGURATION_FORM_DETAIL: '/configuration/form-templates/:id',
  CONFIGURATION_FORM_BUILDER: '/configuration/form-templates/:id/builder',
  CONFIGURATION_FORM_CONFIGURATION: '/configuration/form-templates/:id/configuration',
  CONFIGURATION_FORM_MAPPER: '/configuration/form-templates/:id/mapper',
  CONFIGURATION_FORM_PREVIEW: '/configuration/form-templates/:id/preview',
  CONFIGURATION_THG_USER: '/configuration/thg-users',
  HELP: '/help',
  ACCOUNT_PROFILE: '/account-profile',
  RELATED_SITES: '/related-sites',
  TERM_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
};
