interface CircleProgressBarProps {
  sqSize: number;
  percent: number;
  color: string;
}

function CircleProgressBar(props: CircleProgressBarProps) {
  const { sqSize, percent, color } = props;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - sqSize / 20) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percent) / 100;
  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${sqSize / 20}px`}
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${sqSize / 20}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          stroke: color,
        }}
      />
      <text style={{ fontSize: sqSize / 5, fill: color }} x="50%" y="50%" dy=".3em" textAnchor="middle">
        {`${percent}%`}
      </text>
    </svg>
  );
}

export default CircleProgressBar;
