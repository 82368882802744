/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommentDto,
  CommentPageDto,
  ErrorDto,
  FormActionType,
  FormDataDto,
  FormDto,
  FormPageDto,
  FormStateType,
  TransitionDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Forms<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Form  List
   *
   * @tags forms
   * @name GetForms
   * @summary Get Form List
   * @request GET:/forms
   * @secure
   */
  getForms = (
    query?: {
      search?: string;
      projectIds?: string[];
      states?: FormStateType[];
      /** @example "name:asc,state:desc,publishedDate:asc,status:desc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
      accountIds?: string[];
      hasData?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<FormPageDto, any>({
      path: `/forms`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Form By Id
   *
   * @tags form, detail
   * @name GetFormDetail
   * @summary Form Detail
   * @request GET:/forms/{id}
   * @secure
   */
  getFormDetail = (
    id: string,
    query?: {
      /** identifier */
      includes?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<FormDto, ErrorDto>({
      path: `/forms/${id}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Form Data By Id
   *
   * @tags form, data
   * @name GetFormData
   * @summary GET Form Data
   * @request GET:/forms/{id}/data
   * @secure
   */
  getFormData = (id: string, params: RequestParams = {}) =>
    this.request<FormDataDto, ErrorDto>({
      path: `/forms/${id}/data`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Replace Form Data
   *
   * @tags form, data, value
   * @name ReplaceFormData
   * @summary Replace Form Data
   * @request PUT:/forms/{id}/data
   * @secure
   */
  replaceFormData = (id: string, data: FormDataDto, params: RequestParams = {}) =>
    this.request<FormDataDto, ErrorDto>({
      path: `/forms/${id}/data`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Update Form State
   *
   * @tags form-state
   * @name UpdateFormState
   * @summary update Form State
   * @request PUT:/forms/{id}/state
   * @secure
   */
  updateFormState = (
    id: string,
    data: {
      state?: FormStateType;
    },
    query?: {
      /** @example "action=APPROVE, action=REJECT, action=UNLOCK, action=SAVE, action=REQUESTING_TO_RESUBMIT, action=SUBMIT" */
      action?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<
      {
        state?: FormStateType;
        oldState?: FormStateType;
      },
      ErrorDto
    >({
      path: `/forms/${id}/state`,
      method: 'PUT',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Create comment associating to individual form
   *
   * @tags form-comments
   * @name CreateComment
   * @summary create Comments
   * @request POST:/forms/{id}/comments
   * @secure
   */
  createComment = (id: string, data: CommentDto, params: RequestParams = {}) =>
    this.request<CommentDto, ErrorDto>({
      path: `/forms/${id}/comments`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Comment List
   *
   * @tags form
   * @name GetCommentsByFormId
   * @summary Get Comment List
   * @request GET:/forms/{id}/comments
   * @secure
   */
  getCommentsByFormId = (
    id: string,
    query?: {
      /** @example "createdAt:asc, createdAt:desc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<CommentPageDto, ErrorDto>({
      path: `/forms/${id}/comments`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Change Form State
   *
   * @tags forms
   * @name ExecuteFormAction
   * @summary Form State Changed
   * @request POST:/forms/{id}/actions
   * @secure
   */
  executeFormAction = (
    id: string,
    data: {
      action: FormActionType;
      payload?: {
        data?: FormDataDto;
        comment?: CommentDto;
      };
    },
    params: RequestParams = {},
  ) =>
    this.request<TransitionDto, ErrorDto>({
      path: `/forms/${id}/actions`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

    getInitialForms = (
        query?: {
            search?: string;
            projectIds?: string[];
            states?: FormStateType[];
            /** @example "name:asc,state:desc,publishedDate:asc,status:desc" */
            sortBy?: string;
            /** @format int32 */
            page?: number;
            /** @format int32 */
            limit?: number;
            accountIds?: string[];
            hasData?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<FormPageDto, any>({
            path: `/forms/initial`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
}
