/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ErrorDto {
  code?: string;
  message?: string;

  /** @format date-time */
  timestamp?: string;
}

export interface PongMessage {
  message?: string;
}

export type DataMapperDto = BaseDto & {
  fieldKey?: string;
  type?: string;
  crmFieldKey?: string;
  dataType?: string;
  dataFormat?: string;
  subType?: string;
  mandatory?: boolean;
};

export type UserDto = BaseDto & {
  id?: string;
  username?: string;
  status?: UserStatusType;
  email?: string;
  firstName?: string;
  lastName?: string;
  type?: string;
  zohoCrmId?: string;
  accountId?: string;
  name?: string;
  role?: string;
  zohoCrmAccount?: ZohoCrmAccountDto;
  attributes?: Record<string, any>;
};

export interface ZohoCrmAccountDto {
  zohoCrmAccountId?: string;
  zohoCrmAccountName?: string;
}

export interface BaseDto {
  activeStatus: boolean;

  /** @format date-time */
  createdAt: string;
  createdBy: string;

  /** @format date-time */
  modifiedAt?: string;
  modifiedBy?: string;
}

export enum EventType {
  COLLECT_CRM_CONTACT = 'COLLECT_CRM_CONTACT',
  COLLECT_CRM_USER = 'COLLECT_CRM_USER',
}

export enum EmailVerifiedType {
  VERIFIED = 'VERIFIED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export enum UserStatusType {
  LOCKED = 'LOCKED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNCONFIRMED = 'UNCONFIRMED',
}

export interface EventDto {
  event: EventType;

  /** @format date-time */
  timestamp?: string;
}

export enum ExtSystemKey {
  ZOHO_CRM = 'ZOHO_CRM',
  ZOHO_PROJECTS = 'ZOHO_PROJECTS',
  ZOHO_WORKDRIVE = 'ZOHO_WORKDRIVE',
  ZOHO_ACCOUNT = 'ZOHO_ACCOUNT',
  ZOHO_DESK = 'ZOHO_DESK',
}

export enum GroupType {
  THG_USER = 'THG_USER',
  THG_CLIENT = 'THG_CLIENT',
}

export interface UserPageDto {
  items?: UserDto[];
  pageInfo?: PageInfoDto;
}

export interface PageInfoDto {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  total?: number;
  hasNext?: boolean;
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface UserActionRequestDto {
  userId?: string;
  action?: UserAction;
  payload?: object;
}

export enum UserAction {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  UNLOCKED = 'UNLOCKED',
}
