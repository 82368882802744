import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import React from 'react';
import { SpinnerStyled } from './../StyledComponent';
import { CommonActionBar } from '@thg-harveynash/hyper-shared-components';
import { Card, Row, Col } from 'react-bootstrap';

export interface BreadcrumbItems {
  path: string;
  name: string;
  active: boolean;
}

interface LayoutDetailProps {
  breadcrumbItems: Array<BreadcrumbItems> | undefined;
  onChangePathBreadCrumb?: (path: string) => void | undefined;
  pageTitle?: string;
  pageHeader?: any;
  isLoading: boolean;
  children: React.ReactNode | undefined;
  className?: string;
  layoutWidth?: string;
}

const LayoutDetail = (layoutDetailProps: LayoutDetailProps) => {
  const {
    pageTitle,
    breadcrumbItems,
    onChangePathBreadCrumb,
    isLoading,
    pageHeader,
    layoutWidth = '960px',
  } = layoutDetailProps;
  return (
    <>
      {isLoading ? (
        <SpinnerStyled>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>
          <CommonActionBar
            pageTitle={pageTitle}
            isVisibleButton={false}
            breadcrumbItems={breadcrumbItems}
            onChangePath={onChangePathBreadCrumb}
          />
          <div className="page-content">
            <Row>
              <Col className="d-flex justify-content-center">
                <Card className="form-container" style={{ width: layoutWidth }}>
                  {pageHeader && (
                    <Card.Header>
                      <div className="d-flex justify-content-between align-items-center">{pageHeader}</div>
                    </Card.Header>
                  )}
                  <Card.Body>{layoutDetailProps.children}</Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default LayoutDetail;
