import React from 'react';
import { Container, Breadcrumb } from 'react-bootstrap';
import styled from 'styled-components';
import { BsHourglassBottom } from 'react-icons/bs';
import { SpinnerStyled } from '../../components/StyledComponent';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { ROUTER } from '../../constant';
import { useNavigate } from 'react-router';

const DescriptionStyled = styled.p`
  font-size: 20px;
  text-align: center;
  margin-top: 16px;
  font-weight: 600;
  color: rgb(108, 106, 126);
`;

const PendingPageStyled = styled.div`
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IDisplayPendingRTD {
  isLoading?: boolean;
  name?: string;
}

function DisplayPendingRTD(props: IDisplayPendingRTD) {
  const { isLoading, name } = props;
  const navigate = useNavigate();

  return (
    <Container fluid>
      {isLoading ? (
        <SpinnerStyled>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate(ROUTER.MANAGEMENT_PROJECT)}>Projects</Breadcrumb.Item>
            <Breadcrumb.Item active>{name}</Breadcrumb.Item>
          </Breadcrumb>
          <PendingPageStyled>
            <div>
              <div className="d-flex justify-content-center">
                <BsHourglassBottom style={{ fontSize: 150 }} />
              </div>
              <DescriptionStyled>
                Your Project Plan is being created. Once it’s ready, you will be able to view it here.
              </DescriptionStyled>
            </div>
          </PendingPageStyled>
        </>
      )}
    </Container>
  );
}

export default DisplayPendingRTD;
