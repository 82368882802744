import { Types } from '../constant/action-type';
import { FormDto, PageInfoDto } from '../form-api-client/data-contracts';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type FormType = {
  formList?: {
    items?: FormDto[];
    pageInfo?: PageInfoDto;
  };
  formDetail?: FormDto;
  isFormChanged?: boolean;
};

type FormPayload = {
  [Types.GET_FORM]: {
    forms: {
      items?: FormDto[];
      pageInfo?: PageInfoDto;
    };
  };
  [Types.GET_FORM_DETAIL]: {
    form: FormDto;
  };
  [Types.GET_FORM_DIRTY_STATUS]: {
    isFormChanged: boolean;
  };
};

export type FormActions = ActionMap<FormPayload>[keyof ActionMap<FormPayload>];

export const formReducer = (state: FormType, action: FormActions) => {
  switch (action.type) {
    case Types.GET_FORM:
      const formList = action.payload.forms || [];
      return { ...state, formList };
    case Types.GET_FORM_DETAIL:
      const form = action.payload.form || {};
      return { ...state, formDetail: form };
    case Types.GET_FORM_DIRTY_STATUS:
      const isFormChanged = action.payload.isFormChanged || false;
      return { ...state, isFormChanged: isFormChanged };
    default:
      return state;
  }
};
