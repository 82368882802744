import { Navigate } from 'react-router-dom';
import { ROUTER } from '../constant';
import { usePermissions } from '../context/auth-context';
import { getRole } from '../utils';
interface RouterProps {
  children: any;
  permissions?: string[];
  role?: string;
}

function PrivateRoute({ children, permissions = [], role }: RouterProps) {
  const { permissions: userPermissions } = usePermissions();
  //check permisson
  const isHasPermission = userPermissions.some((userPermisison: any) => {
    return permissions.includes(userPermisison?.code);
  });

  if (role) {
    const currentRole = getRole();
    if (isHasPermission && currentRole === role) {
      return children;
    }
  } else {
    if (isHasPermission) {
      return children;
    }
  }

  return <Navigate to={ROUTER.NON_PERMISSION_PAGE} />;
}

export default PrivateRoute;
