import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from '@thg-harveynash/hyper-shared-components';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  CustomSizeSpan,
  LabelFontNormalStyled,
  LabelInforNoneMargin,
  SectionArea,
  SpinnerStyled,
  TitleSection,
  ColInputForm,
  MultiSelectStyle,
  FormInputNoStyleValid,
} from '../StyledComponent';
import {
  FORM_LEVEL,
  FORM_STATE,
  OPTIONS_YES_AND_NO,
  PERMISSIONS,
  INVALID_SELECT_APPROVERS,
  UNKNOWN_ERROR_CODE,
  GENERAL_ERROR_MESSAGE,
} from '../../constant';
import { useNavigate } from 'react-router-dom';
import { FormTemplates } from '../../form-api-client/FormTemplates';
import { Types } from '../../constant/action-type';
import { useDispatch, useFormTemplateConfig, useFormTemplateDetail, useIsFormChanged } from '../../context';
import { useToast } from '../../context/toast-context';
import {
  getSelectOptionAndArrayStringOption,
  getSelectOptionAndArrayStringOptionForMultiple,
  getToken,
  getValueOption,
  handleErrorLogMessage,
} from '../../utils';
import { FormTemplateConfigurationDto } from '../../form-api-client/data-contracts';
import { Settings } from '../../accounts-api-client/Settings';
import { External } from '../../file-api-client/External';
import { AdhocFormsRequiredDto, ExtSystemKey, UsersDepartmentsDto } from '../../accounts-api-client/data-contracts';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { usePrompt } from '../../utils/prompt';
import {
  createDataTemplate,
  fetchDataTemplate,
  hadleRenameDataTemplate,
  handleCreateDataTemplate,
  renameDataTemplate,
  updateFormConfig,
  updateFormTemplateConfiguration,
} from './form-detail.logic';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { isEmpty, remove } from 'lodash';
import { usePermissions } from '../../context/auth-context';
import { userInstance } from '../../services/instance';
import FileUploader from '../file-upload';
import axios from 'axios';
import { FILE_MANAGEMENT_URL } from '../../utils/baseUrl';
import { GlobalConfig } from '../../global';

// eslint-disable-next-line prettier/prettier
const Configuration = (_props: any) => { //NOSONAR
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { formID, setSelectedApproverConfiguration } = _props;
  const { permissionList } = usePermissions();
  const canUpdateTemplate = permissionList.includes(PERMISSIONS.edit_form_template_configurations);
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState('standard');
  const [isAuto, setIsAuto] = useState<boolean>(false);
  const [approveProcess, setApproveProcess] = useState<any>(OPTIONS_YES_AND_NO[0]);
  const [formData, setFormData] = useState<FormTemplateConfigurationDto>({ templateId: '', level: 'ACCOUNT' });
  const [selectedApprover, setSelectedApprover] = useState<any>([]);
  const [selectedResubmit, setSelectedResubmit] = useState<any>([]);
  const [selectedAdhoc, setSelectedAdhoc] = useState<any>([]);
  const formTemplateConfig = useFormTemplateConfig();
  const [dataTemplateId, setDataTemplateId] = useState<string>('');
  const [dataTemplateName, setDataTemplateName] = useState<string>('');
  const [defaultDataTemplateName, setDefaultDataTemplateName] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [approvedWorkingDays, setApprovedWorkingDays] = useState('2');
  const isFormChanged = useIsFormChanged();
  const formManagementApi = new FormTemplates();
  const externalApi = new External();
  const [adhocConditionsList, setAdhocConditionsList] = useState<any>([]);
  const [approverList, setApproverList] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<any>([]);
  const [isHasUploadForm, setIsHasUploadForm] = useState(false);
  const [objDownLoad, setObjDownload] = useState<any>({
    oldObjDownLoad: {},
    newObjDownLoad: {},
  });
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [isChangeFile, setIsChangeFile] = useState<boolean>(false);
  const getFiltersApi = new Settings();
  const token = getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const baseUrlGetFile = `${FILE_MANAGEMENT_URL}/external/fileInfo/${ExtSystemKey.ZOHO_WORKDRIVE}/resources/`;
  usePrompt(
    '<b>Are you sure you want to leave?</b><br></br>\n\nAny changes you have made will not be saved. Please ensure you submit or save your data before leaving the page.',
    isFormChanged
  );

  const getLatestFormTemplate = async (id: string, templateFiles: { [key: string]: string } | {}) => {
    const resourceIdFromTemplateFiles = Object.values(templateFiles)?.[0];
    await formManagementApi
      .getLatestFormTemplate(id)
      .then((res) => {
        let eleDownload: any;
        const elements = res?.data?.template?.elements;
        const sectionTemplateDownloadIndex = elements?.findIndex(
          (ele) => ele.properties?.title === 'Template Download'
        );
        if (sectionTemplateDownloadIndex !== undefined) {
          eleDownload =
            elements?.[sectionTemplateDownloadIndex + 1 === undefined ? -1 : sectionTemplateDownloadIndex + 1];
        }
        const resourceId = eleDownload?.properties?.resourceId;
        const newResourceId = resourceIdFromTemplateFiles ? resourceIdFromTemplateFiles : resourceId;

        // get infor file
        if (newResourceId) {
          axios
            .get(`${baseUrlGetFile}${newResourceId}`, config)
            .then((resData: { data: any }) => {
              if (resData?.data) {
                let file = resData?.data;
                setObjDownload({
                  oldObjDownLoad: eleDownload,
                  newObjDownLoad: file,
                });
                setFileUpload([file]);
                setIsHasUploadForm(true);
              }
            })
            .catch((error: any) => {
              setObjDownload({
                oldObjDownLoad: eleDownload,
                newObjDownLoad: {},
              });
              handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
            });
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      });
  };

  useEffect(() => {
    setSelectedApproverConfiguration(selectedApprover);
  }, [selectedApprover, setSelectedApproverConfiguration]);

  const formTemplateDetail = useFormTemplateDetail();

  const publishedForm =
    formTemplateDetail?.state === (FORM_STATE.PUBLISHED || FORM_STATE.ACTION_REQUIRED) ? true : false;

  const getAdhocFormsConditions = async () => {
    await getFiltersApi
      .getAdhocFormsConditions()
      .then((res) => {
        if (res?.data?.length) {
          const newAdhocConditionsList = res.data.map((option: AdhocFormsRequiredDto) => {
            return getValueOption(option.displayValue);
          });
          setAdhocConditionsList(newAdhocConditionsList);
        }
      })
      .catch((error: any) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      });
  };

  const getValueUsersDepartmentsOption = (displayValue?: string, actualValue?: string) => {
    return {
      name: displayValue,
      code: actualValue,
    };
  };

  const getUsersDepartments = async () => {
    await userInstance
      .get('settings/users/departments')
      .then((res) => {
        if (res?.data?.length) {
          const newUsersDepartmentsList = res.data.map((option: UsersDepartmentsDto) => {
            return getValueUsersDepartmentsOption(option.displayValue, option.actualValue);
          });
          setApproverList(newUsersDepartmentsList);
        }
      })
      .catch((error: any) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
      });
  };

  const setFormTemplateConfig = (dataConfig: FormTemplateConfigurationDto) => {
    dispatch({
      type: Types.GET_FORM_TEMPLATE_CONFIG,
      payload: {
        configuration: dataConfig,
      },
    });
  };

  const getFormTemplateConfig = async (formTemplateId: string) => {
    try {
      await formManagementApi
        .getConfiguration(formTemplateId)
        .then((res) => {
          setFormData(res.data);
          getLatestFormTemplate(formID, res?.data?.templateFiles || {});
          setFormTemplateConfig(res.data);
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        });
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
    }
    setIsLoading(false);
  };

  const updateDataFormConfig = (data: any) => {
    toast({ message: 'Your change has been saved successfully!', type: 'success' });
    setFormData(data);
    setFormTemplateConfig(data);
    dispatch({
      type: Types.GET_FORM_DIRTY_STATUS,
      payload: {
        isFormChanged: false,
      },
    });
  };

  const onError = (code: string, mes: string) => {
    toast({
      code: code || UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN,
      message: mes || GENERAL_ERROR_MESSAGE,
      type: 'error',
    });
  };

  const getDataTemplate = async (extSystemKey: string, id: any) => {
    const dataTemplateRes = await fetchDataTemplate(extSystemKey, id);
    if (dataTemplateRes.success) {
      const dataTemplateNameRes = dataTemplateRes?.response?.data?.name;
      setDataTemplateName(dataTemplateNameRes);
      setDefaultDataTemplateName(dataTemplateNameRes);
      return;
    }

    if (!dataTemplateRes.success || dataTemplateRes?.response?.status === 401) {
      setDataTemplateName('');
      setDefaultDataTemplateName('');
      handleErrorLogMessage(dataTemplateRes?.response, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
    }
  };

  const handleUpdateFormConfig = (newData: any, approvers: any[]) => {
    setDefaultDataTemplateName(newData?.workdriveDataTemplate || '');
    if (approveProcess.value === 'Yes' && !validated) {
      newData.approvedWorkingDays = approvedWorkingDays;
    }
    newData.allowResubmit = selectedResubmit.value === 'Yes' ? true : false;
    newData.approvers = approvers;
    if (formType === 'adhoc') {
      newData.adhocCondition = selectedAdhoc.value || selectedAdhoc[0]?.value;
    }
    updateFormConfig(
      updateFormTemplateConfiguration(formID, newData, setIsLoading, setIsUploadingFile),
      updateDataFormConfig,
      onError
    );
  };

  // eslint-disable-next-line prettier/prettier
  const handleSubmit = async () => { //NOSONAR
    if (
      (isAuto && !dataTemplateName) ||
      selectedApprover.length === 0 ||
      (formType === 'adhoc' && selectedAdhoc.length === 0) ||
      (!fileUpload?.length && isHasUploadForm)
    ) {
      setValidated(true);
    } else if (!isAuto) {
      handleAddDataTemplate();
    } else {
      await externalApi
        .getDataTemplate(ExtSystemKey.ZOHO_WORKDRIVE, dataTemplateId)
        .then(() => {
          if (dataTemplateName === defaultDataTemplateName) {
            handleAddDataTemplate(dataTemplateId);
          } else
            hadleRenameDataTemplate(
              renameDataTemplate(ExtSystemKey.ZOHO_WORKDRIVE, dataTemplateId, { name: dataTemplateName }),
              handleAddDataTemplate,
              onError
            );
        })
        .catch((error) => {
          if (error?.response?.status === 401 || error?.response?.status === 405) {
            handleCreateDataTemplate(
              createDataTemplate(ExtSystemKey.ZOHO_WORKDRIVE, { name: dataTemplateName }),
              handleAddDataTemplate,
              onError
            );
          } else {
            handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
          }
        });
    }
  };

  // eslint-disable-next-line prettier/prettier
  const handleAddDataTemplate = (id?: string | undefined) => { //NOSONAR
    let approvers: any[] = [];
    if (selectedApprover.length) {
      selectedApprover.forEach((option: { name: string }) => {
        approvers.push(option.name);
      });
    }
    let newData: any = {
      templateId: formID,
      level: formData?.level,
    };
    if (id) {
      newData.workdriveDataTemplate = dataTemplateName;
      newData.workdriveDataTemplateId = id;
    }
    if (!isChangeFile) {
      handleUpdateFormConfig(newData, approvers);
      return;
    }
    setIsUploadingFile(true);
    const elementValue = fileUpload?.[0];
    let myHeaders: any = new Headers();
    // @ts-ignore
    myHeaders.append('x-parent-id', GlobalConfig().config.REACT_APP_WORKDRIVE_TEMPLATE_FOLDER_ID);
    myHeaders.append('x-file-name', elementValue.name);
    myHeaders.append('Content-Type', elementValue.type);
    myHeaders.append('Authorization', `Bearer ${token}`);
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: elementValue,
    };
    fetch(`${FILE_MANAGEMENT_URL}/external/upload/${ExtSystemKey.ZOHO_WORKDRIVE}/resources`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Request failed with status code ${response?.status}`);
      })
      .then((data) => {
        if (!data?.extId) {
          let fileUploadErr = fileUpload[0];
          fileUploadErr.isUploadSuccess = false;
          setFileUpload([fileUploadErr]);
          return;
        }

        let fileUploadSuccess = fileUpload[0];
        fileUploadSuccess.isUploadSuccess = true;
        setFileUpload([fileUploadSuccess]);
        const keyDownload = objDownLoad.oldObjDownLoad?.key;
        if (keyDownload) {
          newData.templateFiles = { [keyDownload]: data?.extId };
        }
        if (fileUpload?.[0]?.isUploadSuccess) {
          setTimeout(() => {
            handleUpdateFormConfig(newData, approvers);
          }, 300);
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
      });
  };

  useEffect(() => {
    getFormTemplateConfig(formID);
    getAdhocFormsConditions();
    getUsersDepartments();
  }, []);

  useEffect(() => {
    if (dataTemplateId) {
      getDataTemplate(ExtSystemKey.ZOHO_WORKDRIVE, dataTemplateId);
    } else setDataTemplateName('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId]);
  useEffect(() => {
    setFormType(formTemplateConfig?.adhocCondition ? 'adhoc' : 'standard');
    const resubmitOptions = getSelectOptionAndArrayStringOption(formTemplateConfig?.allowResubmit ? 'Yes' : 'No');
    const approverOptions = getSelectOptionAndArrayStringOptionForMultiple(
      formTemplateConfig?.approvers ? formTemplateConfig?.approvers : []
    );
    const adhocOptions = getSelectOptionAndArrayStringOption(
      formTemplateConfig?.adhocCondition ? formTemplateConfig?.adhocCondition : []
    );
    setSelectedResubmit(resubmitOptions.selectOption[0]);
    setSelectedApprover(approverOptions.selectOption);
    setSelectedAdhoc(adhocOptions.selectOption);
    if (formTemplateConfig?.createdAt !== formTemplateConfig?.modifiedAt) {
      const newApprovalProcess = formTemplateConfig?.approvedWorkingDays ? 'Yes' : 'No';
      setApproveProcess({ value: newApprovalProcess, label: newApprovalProcess });
    }
    setApprovedWorkingDays(String(formTemplateConfig?.approvedWorkingDays || 2));
    setIsAuto(!!formTemplateConfig?.workdriveDataTemplate);
    setDataTemplateId(formTemplateConfig?.workdriveDataTemplateId || '');
  }, [formTemplateConfig]);
  const handleSelectAllApprover = () => {
    setValidated(false);
    dispatch({
      type: Types.GET_FORM_DIRTY_STATUS,
      payload: {
        isFormChanged: true,
      },
    });
    if (selectedApprover.length !== approverList?.length) {
      setSelectedApprover(approverList);
    } else {
      const approverOptions = getSelectOptionAndArrayStringOptionForMultiple([]);
      setSelectedApprover(approverOptions.selectOption);
    }
  };

  const panelHeaderTemplate = (option: any) => {
    if (option) {
      return (
        <div className="p-multiselect-header">
          <div className="p-multiselect-filter-container d-flex m-0">
            <Checkbox
              inputId="approve-header"
              checked={selectedApprover.length === approverList?.length}
              onChange={handleSelectAllApprover}
            />
            <LabelFontNormalStyled htmlFor="approve-header" className="mx-1">
              Select all
            </LabelFontNormalStyled>
          </div>
        </div>
      );
    }
  };

  const removeApproverInSelectedList = (value: any) => {
    let _selectedApprover = [...selectedApprover];
    remove(_selectedApprover, function (o: any) {
      return o.name === value.option.name;
    });
    setSelectedApprover(_selectedApprover);
    value.event.stopPropagation();
  };

  const selectedApproverTemplate = (option: any) => {
    if (option) {
      return (
        <div className="selected-item selected-item-value">
          <div onClick={(e) => e.stopPropagation()}>
            <span>{option.name}</span>
          </div>
          <span className="p-multiselect-token-icon">
            <svg
              height="14"
              width="14"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg time-icon"
              onClick={(event) => removeApproverInSelectedList({ event, option })}
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </span>
        </div>
      );
    }
    return 'Select Department';
  };

  const onChangeRadioButton = (e: any, key: string) => {
    dispatch({
      type: Types.GET_FORM_DIRTY_STATUS,
      payload: {
        isFormChanged: true,
      },
    });
    const newFormData = { ...formData, level: e.value };
    setFormData(newFormData);
    setFormTemplateConfig(newFormData);
    if (key === FORM_LEVEL.ACCOUNT) {
      setSelectedAdhoc([]);
    }
  };

  const onChangeFormType = (e: any, type?: string) => {
    dispatch({
      type: Types.GET_FORM_DIRTY_STATUS,
      payload: {
        isFormChanged: true,
      },
    });
    setFormType(e.value);
    if (type === 'standard') {
      setSelectedAdhoc([]);
      setFormTemplateConfig({ ...formData, adhocCondition: '' });
    }
  };

  const onSelectAdhoc = (e: { value: string }) => {
    setFormTemplateConfig({ ...formData, adhocCondition: e.value });
  };

  const handleChangeApprovedWorkingDays = (e: any) => {
    dispatch({
      type: Types.GET_FORM_DIRTY_STATUS,
      payload: {
        isFormChanged: true,
      },
    });
    const value = e.target.value;
    const reg = /^\d+$/;
    if (Number(value) > 20 || Number(value) <= 0 || !reg.test(value)) {
      setApprovedWorkingDays('2');
      return;
    }
    setApprovedWorkingDays(e.target.value);
  };

  const customizedRegister = {
    onChange: handleChangeApprovedWorkingDays,
    value: approvedWorkingDays,
    max: 20,
    min: 1,
  };

  const selectAdhocClassName = validated && selectedAdhoc.length === 0 ? 'select-error' : '';

  const optionLabelAdhoc = () => {
    if (!adhocConditionsList.length) return <option value="">No options found</option>;
    else if (!selectedAdhoc[0]?.value) return <option value="">-Select-</option>;
    return '';
  };

  const renderYesNoOption = () => {
    return OPTIONS_YES_AND_NO?.map((item: any) => (
      <option key={item?.value} value={item?.value}>
        {item?.label}
      </option>
    ));
  };

  const approverHelpTextRender =
    approveProcess?.value === 'No'
      ? INVALID_SELECT_APPROVERS
      : 'You need to select at least one approver for this form.';
  const extension = objDownLoad?.newObjDownLoad?.extension;
  const helperTextFileUpload = `${
    extension ? `Allow the following file types: ${extension}` : 'Allow all file types'
  }. Maximum upload file size: 100 MB`;
  const fileExceptExtension = extension ? `.${extension}` : '';

  const handleChangeFile = (file: any) => {
    setIsChangeFile(true);
    dispatch({
      type: Types.GET_FORM_DIRTY_STATUS,
      payload: {
        isFormChanged: true,
      },
    });
    setFileUpload(file);
    if (!isEmpty(file)) setIsHasUploadForm(true);
    else setIsHasUploadForm(false);
  };

  return (
    <>
      {isLoading ? (
        <SpinnerStyled>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>
          <Form validated={validated} className="form-configuration">
            <SectionArea>
              <TitleSection className="m-0">Form Level</TitleSection>
            </SectionArea>
            <Row className="form-info detail-info">
              <Col lg={12}>
                <LabelInforNoneMargin>Select level for this form template (Account or Project)</LabelInforNoneMargin>
              </Col>
              <Col lg={12} className="account-id">
                <div className="mb-2">
                  <div className="my-2 d-flex align-items-center">
                    <FormInputNoStyleValid
                      type="radio"
                      id="account"
                      value="ACCOUNT"
                      name="form-level"
                      labelGrid={2}
                      aria-label="form-level-account"
                      onChange={(e) => {
                        onChangeRadioButton(e.target, FORM_LEVEL.ACCOUNT);
                        dispatch({
                          type: Types.GET_FORM_DIRTY_STATUS,
                          payload: {
                            isFormChanged: true,
                          },
                        });
                      }}
                      checked={formData?.level === FORM_LEVEL.ACCOUNT}
                      className="mx-1"
                      disabled={publishedForm || !canUpdateTemplate}
                    />
                    <LabelFontNormalStyled htmlFor="account">Account</LabelFontNormalStyled>
                  </div>
                  <div className="my-2 d-flex align-items-center">
                    <FormInputNoStyleValid
                      type="radio"
                      id="project"
                      value="PROJECT"
                      name="form-level"
                      aria-label="form-level-project"
                      onChange={(e) => {
                        onChangeRadioButton(e.target, FORM_LEVEL.PROJECT);
                        dispatch({
                          type: Types.GET_FORM_DIRTY_STATUS,
                          payload: {
                            isFormChanged: true,
                          },
                        });
                      }}
                      checked={formData?.level === FORM_LEVEL.PROJECT}
                      className="mx-1"
                      disabled={publishedForm || !canUpdateTemplate}
                    />
                    <LabelFontNormalStyled htmlFor="project">Project</LabelFontNormalStyled>
                  </div>
                </div>
                {formData?.level === FORM_LEVEL.PROJECT && (
                  <>
                    <Card.Body className="mt-n4 mx-2">
                      <div className="my-2 d-flex align-items-center">
                        <FormInputNoStyleValid
                          type="radio"
                          value="standard"
                          name="form-type"
                          id="form-type-standard"
                          aria-label="form-type-standard"
                          onChange={(e) => {
                            onChangeFormType(e.target, 'standard');
                            dispatch({
                              type: Types.GET_FORM_DIRTY_STATUS,
                              payload: {
                                isFormChanged: true,
                              },
                            });
                          }}
                          checked={formType === 'standard'}
                          className="mx-1"
                          disabled={publishedForm || !canUpdateTemplate}
                        />
                        <LabelFontNormalStyled htmlFor="standard">Standard</LabelFontNormalStyled>
                      </div>
                      <div className="my-2 d-flex align-items-center">
                        <FormInputNoStyleValid
                          type="radio"
                          value="adhoc"
                          name="form-type"
                          id="form-type-adhoc"
                          aria-label="form-type-adhoc"
                          onChange={(e) => {
                            onChangeFormType(e.target);
                            dispatch({
                              type: Types.GET_FORM_DIRTY_STATUS,
                              payload: {
                                isFormChanged: true,
                              },
                            });
                          }}
                          checked={formType === 'adhoc'}
                          className="mx-1"
                          disabled={publishedForm || !canUpdateTemplate}
                        />
                        <LabelFontNormalStyled htmlFor="adhoc">Adhoc</LabelFontNormalStyled>
                      </div>
                    </Card.Body>
                  </>
                )}
              </Col>
            </Row>
            {formType === 'adhoc' && formData?.level === FORM_LEVEL.PROJECT && (
              <Row className="form-info detail-info">
                <Col lg={5}>
                  <LabelInforNoneMargin>Show this form if “Adhoc Forms Required” has</LabelInforNoneMargin>
                </Col>
                <ColInputForm lg={7}>
                  <FormInputNoStyleValid
                    required
                    name="select-adhoc"
                    aria-label="select-adhoc"
                    type="select"
                    label=""
                    value={selectedAdhoc[0]?.value}
                    className={`react-select ${selectAdhocClassName}`}
                    disabled={publishedForm || !adhocConditionsList.length || !canUpdateTemplate}
                    onChange={(e) => {
                      onSelectAdhoc(e.target);
                      dispatch({
                        type: Types.GET_FORM_DIRTY_STATUS,
                        payload: {
                          isFormChanged: true,
                        },
                      });
                    }}
                  >
                    {optionLabelAdhoc}
                    {adhocConditionsList?.map((item: any) => (
                      <option key={item?.value} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                  </FormInputNoStyleValid>
                  {validated && selectedAdhoc.length === 0 && (
                    <div className="multi-invalid-feedback">Select a choice.</div>
                  )}
                </ColInputForm>
              </Row>
            )}
            <SectionArea>
              <TitleSection className="m-0 mt-3 d-flex align-items-center">
                File Uploading &nbsp;
                <LabelFontNormalStyled htmlFor="auto">
                  <OverlayTrigger
                    key="tooltip-top-uploaded"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top-uploaded">
                        To ensure you can easily find the document in the WorkDrive if it has been renamed by the client
                        – you can tag the document with the file name. Eg Content NLF
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill className="infor-icon" size={18} />
                    </span>
                  </OverlayTrigger>
                </LabelFontNormalStyled>
              </TitleSection>
            </SectionArea>
            <Row className="form-info detail-info">
              <Col lg={5}>
                <LabelInforNoneMargin>Add this tag name to the file when uploaded by client</LabelInforNoneMargin>
              </Col>
              <Col lg={7}>
                <FormInputNoStyleValid
                  className="display-content"
                  name="tag-name"
                  key="checkbox-tag-name"
                  type="checkbox"
                  checked={isAuto}
                  disabled={!canUpdateTemplate}
                  aria-label="checkbox-input"
                  onChange={() => {
                    if (isAuto) {
                      setValidated(false);
                    }
                    dispatch({
                      type: Types.GET_FORM_DIRTY_STATUS,
                      payload: {
                        isFormChanged: true,
                      },
                    });
                    setIsAuto(!isAuto);
                  }}
                />
              </Col>
            </Row>
            {isAuto && (
              <Row className="form-info detail-info mt-2">
                <Col lg={5}>
                  <LabelInforNoneMargin>Tag Name</LabelInforNoneMargin>
                </Col>
                <ColInputForm lg={7}>
                  <Form.Group controlId="dataTemplateName">
                    <FormInputNoStyleValid
                      type="text"
                      name="tagNameInput"
                      aria-label="tagNameInput"
                      maxLength={255}
                      required
                      value={dataTemplateName}
                      disabled={!canUpdateTemplate}
                      onChange={(e) => {
                        dispatch({
                          type: Types.GET_FORM_DIRTY_STATUS,
                          payload: {
                            isFormChanged: true,
                          },
                        });
                        setDataTemplateName(e.target.value);
                      }}
                    />
                    {validated && !dataTemplateName && (
                      <div className="multi-invalid-feedback">Enter a value for this field.</div>
                    )}
                  </Form.Group>
                </ColInputForm>
              </Row>
            )}
            <SectionArea>
              <TitleSection className="m-0 mt-3">Approval Process</TitleSection>
            </SectionArea>
            <Row className="form-info detail-info">
              <Col lg={5}>
                <LabelInforNoneMargin>Approve this form automatically</LabelInforNoneMargin>
              </Col>
              <ColInputForm lg={7} className="col-input-form">
                <FormInputNoStyleValid
                  name="select-approve"
                  aria-label="select-approve"
                  type="select"
                  value={approveProcess?.value}
                  className="react-select select-approve-auto"
                  disabled={!canUpdateTemplate}
                  onChange={(e) => {
                    let value = e.target.value;
                    dispatch({
                      type: Types.GET_FORM_DIRTY_STATUS,
                      payload: {
                        isFormChanged: true,
                      },
                    });
                    setApproveProcess({ value: value, label: value });
                  }}
                >
                  {renderYesNoOption()}
                </FormInputNoStyleValid>
              </ColInputForm>
            </Row>
            {approveProcess.value === 'Yes' && (
              <Row className="form-info detail-info mt-2">
                <Col lg={5}>
                  <LabelInforNoneMargin>Form will be approved automatically after</LabelInforNoneMargin>
                </Col>
                <Col lg={7}>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="number"
                      key="number"
                      {...customizedRegister}
                      disabled={!canUpdateTemplate}
                      aria-label="number-working-days"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">working days</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            )}
            <Row className="form-info detail-info mt-2">
              <Col lg={5}>
                <LabelInforNoneMargin>Allow Client user to resubmit this form</LabelInforNoneMargin>
              </Col>
              <ColInputForm lg={7} className="col-input-form">
                <FormInputNoStyleValid
                  name="select-allow-resubmit"
                  aria-label="select-allow-res"
                  type="select"
                  value={selectedResubmit?.value}
                  disabled={!canUpdateTemplate}
                  className="react-select"
                  onChange={(e) => {
                    let value = e.target.value;
                    dispatch({
                      type: Types.GET_FORM_DIRTY_STATUS,
                      payload: {
                        isFormChanged: true,
                      },
                    });
                    setSelectedResubmit({ value: value, label: value });
                  }}
                >
                  {renderYesNoOption()}
                </FormInputNoStyleValid>
              </ColInputForm>
            </Row>
            <Row className="form-info detail-info mt-2">
              <Col lg={5}>
                <LabelInforNoneMargin>Approver</LabelInforNoneMargin>
              </Col>
              <ColInputForm lg={7} className="col-input-form">
                <Form.Group className="d-flex flex-column" controlId="isApproved">
                  <MultiSelectStyle>
                    <MultiSelect
                      display="chip"
                      value={selectedApprover}
                      options={approverList}
                      aria-label="select-approver"
                      onChange={(e) => {
                        setValidated(false);
                        setSelectedApprover(e.value);
                        dispatch({
                          type: Types.GET_FORM_DIRTY_STATUS,
                          payload: {
                            isFormChanged: true,
                          },
                        });
                      }}
                      optionLabel="name"
                      placeholder="Select who can approve/unlock the form"
                      maxSelectedLabels={approverList?.length}
                      panelHeaderTemplate={panelHeaderTemplate}
                      selectedItemTemplate={selectedApproverTemplate}
                      className={`approver-select ${validated && selectedApprover.length === 0 && 'multi-error'} ${
                        validated && selectedApprover.length && 'multi-valid'
                      }`}
                      disabled={!canUpdateTemplate}
                    />
                    {validated && selectedApprover.length === 0 && (
                      <div className="multi-invalid-feedback">{approverHelpTextRender}</div>
                    )}
                  </MultiSelectStyle>
                </Form.Group>
              </ColInputForm>
            </Row>

            {!!objDownLoad.oldObjDownLoad?.key && (
              <Row>
                <SectionArea className="mb-3">
                  <TitleSection className="m-0 mt-3">Template Download</TitleSection>
                </SectionArea>
                <div>
                  <FileUploader
                    helperText={helperTextFileUpload}
                    required
                    multiple={false}
                    accept={fileExceptExtension}
                    selectedFiles={fileUpload}
                    isUploadingFile={isUploadingFile}
                    onFileChange={(file: any) => handleChangeFile(file)}
                  />
                </div>
              </Row>
            )}

            <SectionArea>
              <TitleSection className="m-0 mt-3">Status</TitleSection>
            </SectionArea>
            <Row className="form-info detail-info">
              <Col lg={5}>
                <LabelInforNoneMargin>Active</LabelInforNoneMargin>
              </Col>
              <ColInputForm lg={7} className="col-input-form">
                <FormInputNoStyleValid
                  name="activeFormTemplate"
                  type="switch"
                  aria-label="active-form-template"
                  checked={formTemplateConfig?.activeStatus}
                  className="min-height-none"
                  disabled
                />
              </ColInputForm>
            </Row>

            <div className="form mt-2">
              <div className="form-action d-flex flex-row align-items-center justify-content-between">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="px-3 py-1 back-button"
                  onClick={() => navigate('/configuration/form-templates')}
                  disabled={isUploadingFile}
                >
                  <CustomSizeSpan>Cancel</CustomSizeSpan>
                </Button>
                <div className="group-actions d-flex flex-row align-items-center justify-content-end">
                  {canUpdateTemplate && (
                    <Button
                      size="sm"
                      variant="primary"
                      className="mx-2 px-3 py-1 sites-button"
                      onClick={handleSubmit}
                      disabled={isUploadingFile}
                    >
                      <CustomSizeSpan>Save</CustomSizeSpan>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </>
  );
};
export default Configuration;
