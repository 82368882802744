import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { BiX } from 'react-icons/bi';
import { Types } from '../../constant/action-type';
import { useDispatch } from '../../context';

interface ModalVideoProps {
  isShowModalVideo: boolean;
  setShowModalVideo: Function;
}

const ModalVideo = (props: ModalVideoProps) => {
  const dispatch = useDispatch();
  const { isShowModalVideo, setShowModalVideo } = props;

  const handleCloseVideo = () => {
    const notShowPopupTour = localStorage.getItem('notShowPopupTour');
    if (!notShowPopupTour) {
      dispatch({
        type: Types.SET_SHOW_MODAL_CONFIRM_START_USER_TOUR,
        payload: { showModalConfirmStartTour: true },
      });
    }
    localStorage.setItem('notShowPopupTour', 'Yes');
    setShowModalVideo();
  };
  return (
    <Modal show={isShowModalVideo} centered>
      <Modal.Body
        className="d-flex justify-content-center align-items-center welcome-modal"
        style={{ background: '#6d747e', padding: 0 }}
      >
        <>
          <iframe
            frameBorder={0}
            src="/videos/welcome.mp4"
            allowFullScreen
            width={765}
            height={400}
            title="welcome-video"
            style={{ border: 'none' }}
            className="video-intro"
          />
          <BiX className="icon-close-video" size={25} onClick={handleCloseVideo} />
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVideo;
