import { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Types } from '../../constant/action-type';
import { Sites } from '../../accounts-api-client/Sites';
import { useDispatch, useSiteDetail } from '../../context';
import { checkDynamicLink, convertTimeUTC, handleErrorLogMessage, handleErrorStatus } from '../../utils';
import { LinkUnderline, LabelInforNoneMargin, DetailInforCustomSize } from '../../components/StyledComponent';
import LayoutDetail from '../../components/layout-detail';
import { DATE_FORMAT, DATE_TIME_FORMAT, ROUTER, UNKNOWN_ERROR_CODE } from '../../constant';
import moment from 'moment';
import { Button, FormInput } from '@thg-harveynash/hyper-shared-components';
import { GlobalConfig } from '../../global';
import { useToast } from '../../context/toast-context';

interface SiteDetailPageI {
  isFromAccountDetail?: boolean;
}

const SiteDetailPage = ({ isFromAccountDetail = false }: SiteDetailPageI) => {
  const [isLoading, setIsLoading] = useState(true);
  const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
  let params = useParams();
  const site = useSiteDetail();
  const siteApi = new Sites();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onChangePath = (path: string) => {
    navigate(path);
  };
  const toast = useToast();
  const getSiteDetail = async (siteId: string, accountId: string) => {
    try {
      await siteApi
        .getSite(siteId)
        .then((res) => {
          dispatch({
            type: Types.GET_SITE_DETAIL,
            payload: {
              site: res.data,
            },
          });
          const breadcrumbAcount = isFromAccountDetail
            ? [
                { path: '/configuration/accounts', name: 'Accounts', active: false },
                { path: `/configuration/accounts/${accountId}`, name: res.data?.account?.name, active: false },
                { path: `/configuration/accounts/${accountId}/sites`, name: 'Sites', active: false },
                { path: '', name: `${res.data?.name}`, active: true },
              ]
            : [
                { path: '/configuration/sites', name: 'Sites', active: false },
                { path: '', name: `${res.data?.name}`, active: true },
              ];
          const breadcrumbSite = [{ path: `/configuration/sites`, name: 'Sites' }];
          setBreadcrumbItems(accountId ? breadcrumbAcount : breadcrumbSite);
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          navigate(ROUTER.ERROR_PAGE);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const siteId = params && params.site_id ? params.site_id : '';
    const accountId = params && params.account_id ? params.account_id : '';
    getSiteDetail(siteId, accountId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutDetail
      breadcrumbItems={breadcrumbItems}
      pageTitle={`${site?.name}: View Details`}
      isLoading={isLoading}
      onChangePathBreadCrumb={(path: string) => onChangePath(path)}
    >
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>CP Site ID</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-id">
          <DetailInforCustomSize>{site?.id}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>CRM Site Reference</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-reference">
          <DetailInforCustomSize>{site?.crmSiteRef}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Site Name</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-name">
          <DetailInforCustomSize>
            <LinkUnderline
              target="_blank"
              rel="noreferrer noopener"
              href={`${GlobalConfig().config.REACT_APP_CRM_ZOHO_URL}${site?.organization?.domainName}/tab/${
                site?.moduleName
              }/${site?.crmSysId}`}
            >
              {site?.name}
            </LinkUnderline>
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Brand Name</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-name">
          <DetailInforCustomSize>{site?.brand?.name}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Roll Out Type</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-roll-out-type">
          <DetailInforCustomSize>{site?.rollOutType}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Category</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-category">
          <DetailInforCustomSize>{site?.category}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Territory</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-territory">
          <DetailInforCustomSize>{site?.territory}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Site Status</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-status">
          <DetailInforCustomSize>{site?.siteStatus}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Site Launch Locale</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-launch-locale">
          <DetailInforCustomSize>{site?.launchLocale}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Planned Live Date</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-planned-live-date">
          <DetailInforCustomSize>
            {site?.plannedLiveDate ? moment(site?.plannedLiveDate).format(DATE_FORMAT) : ''}
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Actual Go Live Date</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-actual-go-live-date">
          <DetailInforCustomSize>
            {site?.actualLiveDate ? moment(site?.actualLiveDate).format(DATE_FORMAT) : ''}
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Zoho Projects Project ID</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-zoho-projects-project-id">
          <LinkUnderline target="_blank" rel="noreferrer noopener" href={`${site?.projectUrl}`}>
            <DetailInforCustomSize>{site?.projectId}</DetailInforCustomSize>
          </LinkUnderline>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Workdrive Folder URL</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-workdrive-folder-url">
          <DetailInforCustomSize>
            <LinkUnderline href={checkDynamicLink(site?.workdriveUrl)} rel="noopener noreferrer" target="_blank">
              {site?.workdriveUrl}
            </LinkUnderline>
          </DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>PM User</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-pm-user">
          <DetailInforCustomSize>{site?.pm?.name}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Senior PM</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 site-senior-pm">
          <DetailInforCustomSize>{site?.seniorPm?.name}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="site-info detail-info align-items-center">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Active</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2">
          <FormInput
            className="min-height-none"
            name="activeSite"
            type="switch"
            aria-label="active-site"
            checked
            disabled
          />
        </Col>
      </Row>
      <Card.Text className="text-end mb-0 site-create">
        <small>
          Created By: {site?.createdBy} | Created Date: {convertTimeUTC(site?.createdAt, DATE_TIME_FORMAT)}
        </small>
      </Card.Text>
      <Card.Text className="text-end site-modify">
        <small>
          Modified By: {site?.modifiedBy ? site?.modifiedBy : site?.createdBy} | Modified Date:{' '}
          {site?.modifiedAt
            ? convertTimeUTC(site?.modifiedAt, DATE_TIME_FORMAT)
            : convertTimeUTC(site?.createdAt, DATE_TIME_FORMAT)}
        </small>
      </Card.Text>
      <div className="form">
        <div className="form-action d-flex flex-row align-items-center justify-content-between">
          <Button type="button" variant="outline-primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
      </div>
    </LayoutDetail>
  );
};

export default SiteDetailPage;
