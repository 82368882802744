import { useEffect, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  FormStateDisplay,
  FORM_STATUS,
  ROLE_TYPE,
  ROUTER,
  FORM_ACTION,
  UPLOAD_AREA,
  PERMISSIONS,
  DATE_TIME_FORMAT,
  UNKNOWN_ERROR_CODE,
  GENERAL_ERROR_MESSAGE,
  DEFINED_ERROR_CODE,
} from '../../constant';
import { CommonActionBar, Button } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { FormEngine } from '@thg-harveynash/cp-form-engine-ui-v2';
import { useNavigate, useParams } from 'react-router-dom';
import { getLinkDownloadFile } from '../../services/form-download';
import { FILE_MANAGEMENT_URL } from '../../utils/baseUrl';
import { FormTemplates } from '../../form-api-client/FormTemplates';
import { getRole, handleErrorStatus, getToken, convertTime, handleErrorLogMessage } from '../../utils';
import { Card, Row, Col } from 'react-bootstrap';
import { Types } from '../../constant/action-type';
import { useDispatch, useFormDetail, useIsFormChanged } from '../../context';
import { Forms } from '../../form-api-client/Forms';
import {
  LabelInforNoneMargin,
  SectionArea,
  SpinnerStyled,
  TitleSection,
  ConfirmDialogStyled,
  PreTitle,
  Font16Bold,
  IconSort,
  SortIconStyle,
  Font14,
  Font14Bold,
} from '../../components/StyledComponent';
import { FormStateType, FormActionType, CommentDto } from '../../form-api-client/data-contracts';
import { isEmpty } from 'lodash';
import { ExtSystemKey } from '../../file-api-client/data-contracts';
import { useToast } from '../../context/toast-context';
import { getsCurrentAccount } from '../../utils/layout';
import { usePrompt } from '../../utils/prompt';
import { External } from '../../file-api-client/External';
import ApproveFormModal from './ApproveFormModal';
import { useAuthInfo, usePermissions } from '../../context/auth-context';
import { BsChat } from 'react-icons/bs';

let uploadedFileStatus: any = [];
let uploadFailedFileCount: number = 0;

// eslint-disable-next-line prettier/prettier
function FormManagementDetailPage() {
  //NOSONAR
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [dataTemplate, setDataTemplate] = useState<any>({});
  const [template, setTemplate] = useState<any>({});
  const [errors, setErrors] = useState<any>(null);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSinglePage, setIsSinglePage] = useState(false);
  const [refetchTime, setRefetchTime] = useState(5);
  const [isStop, setIsStop] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [onFocus, setOnFocus] = useState(true);
  const [comment, setComment] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [dataSave, setDataSave] = useState<any>({});
  const [commented, setCommented] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [formAction, setFormAction] = useState('');
  const [dataTemplateId, setDataTemplateId] = useState<string | undefined>('');
  const [formConfiguration, setformConfiguration] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('createdAt:asc');
  const [commentedData, setCommentedData] = useState<CommentDto[]>([]);
  const params = useParams();
  let id = params && params.id ? params.id : '';
  const isFormChanged = useIsFormChanged();
  const form = useFormDetail();
  const formApi = new Forms();
  const token = getToken();
  const auth = useAuthInfo();

  const externalApi = new External();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = getRole();
  const { permissionList } = usePermissions();
  const canEditDetails =
    permissionList.includes(PERMISSIONS.edit_client_form_details) ||
    permissionList.includes(PERMISSIONS.initial_access);
  const canApproveReject =
    permissionList.includes(PERMISSIONS.approve_thg_form) && formConfiguration?.approvers?.includes(auth?.departments);
  const showApproveRejectButtons = canApproveReject && role === ROLE_TYPE.THG;
  const showSaveSubmitButtons = canEditDetails && role === ROLE_TYPE.CLIENT;

  const isReadOnlyForm =
    !canEditDetails ||
    role === ROLE_TYPE.THG ||
    [FormStateType.SUBMITTED, FormStateType.REQUESTED_TO_RESUBMIT, FormStateType.APPROVED]?.includes(
      form?.state || FormStateType.DRAFT
    );

  const formManagementApi = new FormTemplates();
  usePrompt(
    '<b>Are you sure you want to leave?</b><br></br>\n\nAny changes you have made will not be saved. Please ensure you submit or save your data before leaving the page.',
    isFormChanged
  );

  const fetchComment = async () => {
    setLoading(true);
    let data: any;
    try {
      data = await formApi.getCommentsByFormId(id, { sortBy: sort, page: 0, limit: 20 });
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
    }
    setPage(data?.data?.pageInfo?.page || 0 + 1);
    // @ts-ignore
    setCommentedData(data?.data?.items);
    // @ts-ignore
    setTotalComments(data?.data?.pageInfo?.total);
    // @ts-ignore
    setHasNext(data?.data?.pageInfo?.hasNext);
    setLoading(false);
  };

  const onLoadMore = async () => {
    const data = await formApi.getCommentsByFormId(id, { sortBy: sort, page: page, limit: 20 });
    setPage(data?.data?.pageInfo?.page || 0 + 1);
    // @ts-ignore
    setCommentedData([...comments, ...data?.data?.items]);
    // @ts-ignore
    setHasNext(data?.data?.pageInfo?.hasNext);
  };

  useEffect(() => {
    fetchComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, commented]);

  const totalCommentsDisplay = totalComments < 10 ? totalComments : '9⁺';

  const getHeader = () => {
    return (
      <div className="d-flex justify-content-start align-items-center mb-1">
        <Font16Bold>Comments ({totalCommentsDisplay})</Font16Bold>
        <SortIconStyle
          className="pointer mx-1"
          onClick={() => setSort(`${sort === 'createdAt:asc' ? 'createdAt:desc' : 'createdAt:asc'}`)}
        >
          {sort === 'createdAt:asc' && <IconSort className="pi-sort-comment pi-sort-comment-up" />}
          {sort === 'createdAt:desc' && <IconSort className="pi-sort-comment pi-sort-comment-down" />}
        </SortIconStyle>
      </div>
    );
  };

  const getFormDetail = async (formId: string) => {
    try {
      await formApi
        .getFormDetail(formId)
        .then((res) => {
          dispatch({
            type: Types.GET_FORM_DETAIL,
            payload: {
              form: res.data,
            },
          });
          if (res?.data?.state !== form?.state) setIsStop(true);
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          navigate(ROUTER.ERROR_PAGE);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
  };

  const getFormTemplateConfig = (formTemplateId: string) => {
    formManagementApi.getConfiguration(formTemplateId).then((res) => {
      setformConfiguration(res?.data);
      if (
        form?.state === FormStateType.SUBMITTED ||
        form?.state === FormStateType.REQUESTED_TO_RESUBMIT ||
        form?.state === FormStateType.APPROVED
      ) {
        // @ts-ignore
        setAllowSubmit(res?.data?.allowResubmit);
      }
      setDataTemplateId(res?.data?.workdriveDataTemplateId);
    });
  };

  const getFormTemplate = async (formTemplateId: string, version: number) => {
    await formManagementApi
      .getFormTemplateVersionByVersion(formTemplateId, version)
      .then((res) => {
        setDataTemplate(res?.data);
        if (res?.data?.template?.elements) {
          setIsSinglePage(true);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      });
    setIsLoading(false);
  };

  const getFormData = async (formId: string) => {
    await formApi
      .getFormData(formId)
      .then((res) => {
        let templateId = res?.data?.template?.templateId;
        let templateVersion = res?.data?.template?.version;
        if (templateId && templateVersion) getFormTemplate(templateId, templateVersion);
        if (res?.data?.value && Object.values(res?.data?.value).length > 0) {
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
          setDataSave(res?.data?.value);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const elementsTemplate = dataTemplate?.template?.elements;
      const templateFiles = formConfiguration?.templateFiles;
      if (!isEmpty(elementsTemplate) && !isEmpty(templateFiles)) {
        const keyDownload = Object.keys(templateFiles)?.[0];
        const newElementsTemplate = elementsTemplate?.map((ele: any) => {
          if (ele?.key === keyDownload) {
            return {
              ...ele,
              properties: {
                ...ele.properties,
                resourceId: templateFiles?.[keyDownload],
              },
            };
          }
          return ele;
        });
        setTemplate({
          ...dataTemplate.template,
          elements: newElementsTemplate,
        });
        return;
      }
      if (dataTemplate?.template) {
        setTemplate(dataTemplate?.template);
      }
    }, 100);
  }, [dataTemplate, formConfiguration]);

  const saveFormData = async (formId: string, data: any) => {
    try {
      await formApi
        .executeFormAction(formId, {
          action: FormActionType.SAVE,
          payload: {
            data: data,
          },
        })
        .then(async () => {
          toast({ message: 'Your change has been saved successfully!', type: 'success' });
          setRefetchTime(1);
          setIsStop(false);
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        });
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
    }
    setIsLoadingSubmit(false);
    setIsSaveLoading(false);
  };

  const handleErrorMes = (code: string, mes: string) => {
    if (mes === 'Failed to fetch') {
      toast({
        code: code || DEFINED_ERROR_CODE.ERR_CP_FROM_FE_TRY_AGAIN,
        message:
          'The server encountered a temporary error and could not complete your request. Please try again in 30 seconds.',
        type: 'error',
      });
      return;
    }
    toast({
      code: code || UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN,
      message: mes || GENERAL_ERROR_MESSAGE,
      type: 'error',
    });
  };

  const handleSaveFormData = async (data: any) => {
    setIsSaveLoading(true);
    await formApi
      .getFormDetail(id)
      .then((res) => {
        const lastestState: FormStateType = res?.data?.state || FormStateType.DRAFT;
        if (
          [FormStateType.SUBMITTED, FormStateType.APPROVED, FormStateType.REQUESTED_TO_RESUBMIT].includes(
            lastestState
          ) &&
          showSaveSubmitButtons
        ) {
          toast({
            code: DEFINED_ERROR_CODE.ERR_CP_FROM_FE_ILLEGAL_FORM_STATE,
            message: `This form has been ${FormStateDisplay[lastestState]}, you cannot save this form until it’s unlocked by THG.`,
            type: 'error',
          });
          getFormDetail(id);
          getFormData(id);
          return;
        }
        if (
          !isSinglePage &&
          form?.state !== FormStateType.SUBMITTED &&
          form?.state !== FormStateType.APPROVED &&
          form?.state !== FormStateType.REQUESTED_TO_RESUBMIT
        ) {
          let saveData = {
            formId: id,
            formTemplateVersionId: dataTemplate?.id,
            value: data,
          };
          if (data) saveFormData(id, saveData);
          else setIsSaveLoading(false);
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
        }
      })
      .catch((error) => {
        let dataError = error?.response?.data;
        handleErrorMes(dataError?.code, dataError?.message);
        setIsSaveLoading(false);
      });
  };

  useEffect(() => {
    if (refetchTime < 5 && !isStop) {
      setTimeout(() => {
        getFormDetail(id);
        getFormData(id);
        setRefetchTime(refetchTime + 1);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTime, isStop]);

  useEffect(() => {
    if (form?.templateId) {
      getFormTemplateConfig(form?.templateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const getForm = async () => {
    uploadFailedFileCount = 0;
    setTimeout(async () => {
      try {
        await getFormDetail(id);
      } catch (error: any) {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      }
      try {
        await getFormData(id);
      } catch (error: any) {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
      }
    }, 1000);
  };

  useEffect(() => {
    getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createMessage = () => {
    if (!errors) return '';
    // const listKeyError = Object.keys(errors);
    let mesErrors =
      'You have missed some required fields within the form, these have been highlighted in red within the form. Please put an answer or N/A if not required before submitting.';
    // listKeyError.forEach((keyErr: string) => {
    //   mesErrors += keyErr === 'singlePage' ? '' : `\n\n${keyErr}`;
    //   errors[keyErr]?.forEach((error: { message: string; title: string; type: string }) => {
    //     mesErrors += `\n • ${error.title.trim()}: ${error.message}`;
    //   });
    // });
    return (
      <>
        <strong>Cannot submit this form!</strong>
        <br />
        {mesErrors}
      </>
    );
  };
  const uploadFilePromise = (elementValue: any, dataElement: any): unknown =>
    new Promise((resolve, reject) => {
      let myHeaders = new Headers();
      myHeaders.append('x-parent-id', form?.workdriveId ? form?.workdriveId : '');
      myHeaders.append('x-file-name', elementValue.name);
      myHeaders.append('Content-Type', elementValue.type);
      myHeaders.append('x-data-template-id', dataTemplateId ? dataTemplateId : '');
      myHeaders.append('Authorization', `Bearer ${token}`);
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: elementValue,
      };

      fetch(`${FILE_MANAGEMENT_URL}/external/upload/${ExtSystemKey.ZOHO_WORKDRIVE}/resources`, requestOptions)
        .then((response: any) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          let fileStatus;
          if (data && data?.extId) {
            resolve({ [dataElement.key]: data?.extId });
            fileStatus = {
              isUploadSuccess: true,
              fileUploadStatusId: elementValue.fileUploadStatusId,
              extId: data?.extId,
              isChecked: true,
            };
          } else {
            uploadFailedFileCount++;
            reject('error');
            fileStatus = {
              fileUploadStatusId: elementValue.fileUploadStatusId,
              message: data?.message,
              isUploadSuccess: false,
              isChecked: true,
            };
            toast({
              code: data?.code || UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN,
              message: data?.message || GENERAL_ERROR_MESSAGE,
              type: 'error',
            });
          }
          uploadedFileStatus.push(fileStatus);
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
          uploadFailedFileCount++;
          reject('error');
          setIsLoadingSubmit(false);
        });
    });

  const checkMultipleUploads = (elementKey: string) => {
    let elementUpload: any = dataTemplate?.template?.elements.find(function (e: any) {
      return e.key === elementKey;
    });
    if (elementUpload?.properties?.multiple) {
      return true;
    }
    return false;
  };

  const setUploadedFailedFileToLocalStorage = () => {
    localStorage.setItem('uploadedFileStatus', JSON.stringify([...uploadedFileStatus]));
  };

  // eslint-disable-next-line prettier/prettier
  const handleUploadFileToResources = async (data: any) => {
    //NOSONAR
    if (!data) return;
    setIsLoadingSubmit(true);
    let promiseUploadArray: any[] = [];
    data.forEach((dataElement: any) => {
      if (dataElement.value && dataElement.value.length > 0)
        dataElement.value.forEach((elementValue: any) => {
          if (!isEmpty(elementValue.path)) {
            promiseUploadArray.push(uploadFilePromise(elementValue, dataElement));
          }
        });
    });
    Promise.allSettled(promiseUploadArray)
      .then(async (listNewValue) => {
        let listKeyValue: any = { ...dataSave };
        setUploadedFailedFileToLocalStorage();
        listNewValue.forEach((element: any) => {
          if (element?.status === 'rejected') return;
          if (checkMultipleUploads(Object.keys(element.value)[0])) {
            listKeyValue = {
              ...listKeyValue,
              [Object.keys(element.value)[0]]:
                listKeyValue[Object.keys(element.value)[0]] !== undefined
                  ? listKeyValue[Object.keys(element.value)[0]] + ',' + Object.values(element.value)[0]
                  : Object.values(element.value)[0],
            };
          } else {
            listKeyValue = { ...listKeyValue, [Object.keys(element.value)[0]]: Object.values(element.value)[0] };
          }
        });
        if (form?.state === FormStateType.SUBMITTED) {
          setShowComment(true);
          return;
        }

        if (isEmpty(listKeyValue)) {
          setIsLoadingSubmit(false);
        } else {
          if (uploadFailedFileCount) {
            let dataSaveForm = {
              formId: id,
              formTemplateVersionId: dataTemplate?.id,
              value: listKeyValue,
            };
            toast({
              code: DEFINED_ERROR_CODE.ERR_CP_FILE_FE_FILE_UPLOAD_FAILED,
              message: `${uploadFailedFileCount} file(s) were not uploaded. Form is not submitted yet.`,
              type: 'error',
            });
            await saveFormData(id, dataSaveForm);
          } else {
            await handleSubmitForm(listKeyValue, true);
          }
        }
        getForm();
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        setIsLoadingSubmit(false);
        getForm();
      });
  };

  const handleCheckLastestState = (lastestStateValue: FormStateType) => {
    if (
      [FormStateType.SUBMITTED, FormStateType.APPROVED, FormStateType.REQUESTED_TO_RESUBMIT].includes(lastestStateValue)
    ) {
      if (getSubmitText() === 'Request to resubmit' && FormStateType.SUBMITTED !== lastestStateValue) {
        toast({
          code: DEFINED_ERROR_CODE.ERR_CP_FROM_FE_ILLEGAL_FORM_STATE,
          message: `This form has been ${FormStateDisplay[lastestStateValue]}, you cannot request to resubmit this form until it’s unlocked by THG.`,
          type: 'error',
        });
        getFormDetail(id);
        getFormData(id);
        setCommented(true);
        return true;
      }
      if (getSubmitText() !== 'Request to resubmit') {
        toast({
          code: DEFINED_ERROR_CODE.ERR_CP_FROM_FE_ILLEGAL_FORM_STATE,
          message: `This form has been ${FormStateDisplay[lastestStateValue]}, you cannot submit this form until it’s unlocked by THG.`,
          type: 'error',
        });
        getFormDetail(id);
        getFormData(id);
        return true;
      }
    }
    return false;
  };

  const resetUploadedFileStatusStorage = () => {
    localStorage.removeItem('uploadedFileStatus');
    uploadedFileStatus = [];
  };

  // eslint-disable-next-line prettier/prettier
  const handleCheckTypeSubmit = async (data: any) => {
    // NOSONAR
    resetUploadedFileStatusStorage();
    await formApi
      .getFormDetail(id)
      .then(async (res) => {
        const lastestState = res?.data?.state || FormStateType.DRAFT;
        if (showSaveSubmitButtons && handleCheckLastestState(lastestState)) return;

        if (lastestState !== FormStateType.APPROVED && lastestState !== FormStateType.REQUESTED_TO_RESUBMIT) {
          if (dataTemplate && dataTemplate?.template?.submitText === 'Close') {
            navigate(ROUTER.MANAGEMENT_FORMS);
            return;
          }
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
          let isHasUploadFile = false;
          if (dataTemplate?.template?.elements) {
            dataTemplate?.template?.elements.forEach((element: any) => {
              if (element.type === UPLOAD_AREA) {
                isHasUploadFile = true;
              }
            });
          }
          if (lastestState === FormStateType.SUBMITTED && getSubmitText() === 'Request to resubmit') {
            setShowComment(true);
            return;
          }
          if (isHasUploadFile) {
            if (form?.workdriveId) {
              await externalApi
                .getFileInfoByExt(ExtSystemKey.ZOHO_WORKDRIVE, form?.workdriveId)
                .then(async () => {
                  await handleUploadFileToResources(data);
                })
                .catch(() => {
                  toast({
                    code: DEFINED_ERROR_CODE.ERR_CP_FILE_FE_NO_WORDRIVE_FOLDER,
                    message:
                      'There is an issue with the destination to upload files. Please contact THG to get the support',
                    type: 'error',
                  });
                });
            } else {
              toast({
                code: DEFINED_ERROR_CODE.ERR_CP_FILE_FE_NO_WORDRIVE_FOLDER,
                message:
                  'There is an issue with the destination to upload files. Please contact THG to get the support',
                type: 'error',
              });
            }
          } else {
            handleSubmitForm(data, false);
          }
        }
      })
      .catch((error) => {
        let dataError = error?.response?.data;
        handleErrorMes(dataError?.code, dataError?.message);
      });
  };

  const postComment = async () => {
    const accountStored = JSON.parse(getsCurrentAccount() || '{}');
    try {
      await formApi.executeFormAction(id, {
        action: FormActionType.REQUESTING_TO_RESUBMIT,
        payload: {
          comment: {
            formId: id,
            content: comment,
            user: {
              userId: accountStored?.id,
              userName: role === ROLE_TYPE.THG ? 'THG - THG User' : 'Client User',
            },
          },
        },
      });
    } catch (error: any) {
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
    }
    setIsLoadingSubmit(false);
    setShowComment(false);
    toast({ message: 'Your change has been saved successfully!', type: 'success' });
    setComment('');
    setCommented(true);
    navigate(`${ROUTER.MANAGEMENT_FORMS}`);
  };

  const handleSubmitForm = async (data: { [key: string]: string }[], isDataFromUploadForm: boolean) => {
    if (!data) return;
    setIsLoadingSubmit(true);
    let newValue: any = {};
    if (!isDataFromUploadForm)
      data.forEach((item: { [key: string]: string }) => {
        newValue = { ...newValue, [Object.values(item)[0]]: Object.values(item)[1] };
      });
    else newValue = data;
    const saveData = {
      formId: id,
      formTemplateVersionId: dataTemplate?.id,
      value: newValue,
    };
    await formApi
      .executeFormAction(id, {
        action: FormActionType.SUBMIT,
        payload: {
          data: saveData,
        },
      })
      .then((res: any) => {
        if (res?.data?.toState === FormStateType.SUBMITTED && !uploadFailedFileCount) {
          dispatch({
            type: Types.GET_FORM_DETAIL,
            payload: {
              form: { ...form, state: FormStateType.SUBMITTED },
            },
          });
          setShowModalSuccess(true);
          setIsLoadingSubmit(false);
        } else {
          uploadFailedFileCount = 0;
          setIsLoadingSubmit(false);
        }
      })
      .catch((error: any) => {
        if (error?.message === 'Failed to fetch') {
          toast({
            code: DEFINED_ERROR_CODE.ERR_CP_FROM_FE_TRY_AGAIN,
            message:
              'The server encountered a temporary error and could not complete your request. Please try again in 30 seconds.',
            type: 'error',
          });
        } else {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        }
        setIsLoadingSubmit(false);
      });
  };

  const onSendRequest = async () => {
    await formApi
      .getFormDetail(id)
      .then(async (res) => {
        const lastestState: FormStateType = res?.data?.state || FormStateType.DRAFT;
        if (
          showSaveSubmitButtons &&
          getSubmitText() === 'Request to resubmit' &&
          FormStateType.SUBMITTED !== lastestState
        ) {
          toast({
            code: DEFINED_ERROR_CODE.ERR_CP_FROM_FE_ILLEGAL_FORM_STATE,
            message: `This form has been ${FormStateDisplay[lastestState]}, you cannot request to resubmit this form until it’s unlocked by THG.`,
            type: 'error',
          });
          setShowComment(false);
          setCommented(true);
          getFormDetail(id);
          getFormData(id);
          return;
        }
        postComment();
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
        setIsLoadingSubmit(false);
      });
  };

  const conCancelModalSuccess = () => {
    setShowModalSuccess(false);
    navigate(ROUTER.MANAGEMENT_FORMS);
    resetUploadedFileStatusStorage();
  };

  useEffect(() => {
    return () => {
      resetUploadedFileStatusStorage();
    };
  }, []);

  const breadcrumbItems = [
    { path: `${ROUTER.MANAGEMENT_FORMS}`, name: 'Forms', active: false },
    { path: '', name: `${form?.title}`, active: true },
  ];

  const onChangePath = (path: string) => {
    navigate(path);
  };

  const getSubmitText = () => {
    if (dataTemplate && dataTemplate?.template?.submitText) {
      return dataTemplate?.template?.submitText;
    }
    return form?.state === FormStateType.SUBMITTED ? 'Request to resubmit' : 'Submit';
  };

  useEffect(() => {
    setTimeout(() => {
      setOnFocus(showComment);
    }, 0);
  }, [showComment]);

  const HeaderSubmitedSuccess = (
    <div className="d-flex align-items-center">
      <img src="/icon/check.svg" alt=""></img>Success
    </div>
  );

  const rolePageTitle = showSaveSubmitButtons ? 'Edit' : 'Review';
  const classSaveLoadingArea = isSaveLoading ? 'd-none' : '';
  const formStatusDisplay = form?.state ? FORM_STATUS[form?.state] : '';

  return (
    <>
      {isLoading ? (
        <SpinnerStyled>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>
          <CommonActionBar
            pageTitle={`${form?.title}: ${rolePageTitle} Form`}
            isVisibleButton={false}
            breadcrumbItems={breadcrumbItems}
            onChangePath={onChangePath}
          />
          <div className="page-content">
            <Row>
              <Col className="d-flex justify-content-center flex-column">
                <Card className="form-container">
                  {isSaveLoading && (
                    <SpinnerStyled>
                      <Spinner />
                    </SpinnerStyled>
                  )}
                  <div className={classSaveLoadingArea}>
                    <Card className="mb-0">
                      <Card.Body>
                        <SectionArea className="d-flex justify-content-between">
                          <TitleSection className="m-0" style={{ fontSize: '26px' }}>
                            General
                          </TitleSection>
                          <div
                            onClick={() => {
                              setVisible(true);
                              fetchComment();
                            }}
                            className="pointer"
                            style={{ position: 'relative' }}
                          >
                            <BsChat size={25} />
                            <div
                              style={{
                                position: 'absolute',
                                top: '2px',
                                fontSize: '14px',
                                width: '25px',
                                textAlign: 'center',
                              }}
                            >
                              {totalCommentsDisplay}
                            </div>
                          </div>
                        </SectionArea>
                        <Row className="detail-infor font-14">
                          <Col lg={6}>
                            <Row className="detail-info">
                              <Col lg={4} className="p-2 form-info">
                                <LabelInforNoneMargin>Account:</LabelInforNoneMargin>
                              </Col>
                              <Col lg={8} className="p-2 account-name">
                                {form?.accountName}
                              </Col>
                            </Row>
                            <Row className="detail-info">
                              <Col lg={4} className="p-2 form-info">
                                <LabelInforNoneMargin>Form Status:</LabelInforNoneMargin>
                              </Col>
                              <Col lg={8} className="p-2 form-state">
                                {formStatusDisplay}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row className="detail-info">
                              <Col lg={4} className="p-2 form-info">
                                <LabelInforNoneMargin>Project:</LabelInforNoneMargin>
                              </Col>
                              <Col lg={8} className="p-2 project-name">
                                {form?.projectName}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <div style={{ marginTop: '1px' }}>
                      {!isEmpty(template) && (
                        <FormEngine
                          jsonFormTemplate={getLinkDownloadFile({ ...template }, toast)}
                          jsonRule={dataTemplate?.rules || []}
                          formStatus={form?.state}
                          onError={(error: any) => {
                            setErrors(error);
                          }}
                          onSubmit={
                            showSaveSubmitButtons &&
                            form?.state !== FormStateType.REQUESTED_TO_RESUBMIT &&
                            form?.state !== FormStateType.APPROVED
                              ? handleCheckTypeSubmit
                              : undefined
                          }
                          isLoading={isLoadingSubmit}
                          onSave={
                            showSaveSubmitButtons &&
                            !isSinglePage &&
                            form?.state !== FormStateType.SUBMITTED &&
                            form?.state !== FormStateType.APPROVED &&
                            form?.state !== FormStateType.REQUESTED_TO_RESUBMIT
                              ? handleSaveFormData
                              : undefined
                          }
                          onChange={(isChangedField: boolean) => {
                            dispatch({
                              type: Types.GET_FORM_DIRTY_STATUS,
                              payload: {
                                isFormChanged: isChangedField,
                              },
                            });
                          }}
                          actionText={getSubmitText()}
                          isPreview={role === ROLE_TYPE.THG || !allowSubmit}
                          readOnly={isReadOnlyForm}
                          data={dataSave}
                        />
                      )}
                    </div>
                  </div>
                  {errors && !isSinglePage && (
                    <ConfirmDialogStyled
                      show={errors}
                      onCancel={() => setErrors(null)}
                      onHide={() => setErrors(null)}
                      onConfirm={() => setErrors(null)}
                      footer={
                        <div className="d-flex justify-content-end w-100">
                          <Button className="ms-1 px-3" variant="danger" onClick={() => setErrors(null)}>
                            OK
                          </Button>
                        </div>
                      }
                    >
                      <PreTitle>{createMessage()}</PreTitle>
                    </ConfirmDialogStyled>
                  )}
                  {showModalSuccess && (
                    <ConfirmDialogStyled
                      show={showModalSuccess}
                      header={HeaderSubmitedSuccess}
                      onCancel={() => conCancelModalSuccess()}
                      onHide={() => conCancelModalSuccess()}
                      onConfirm={() => conCancelModalSuccess()}
                      footer={
                        <div className="d-flex justify-content-end w-100">
                          <Button className="ms-1 px-3" variant="success" onClick={() => conCancelModalSuccess()}>
                            OK
                          </Button>
                        </div>
                      }
                    >
                      Data has been submitted to THG successfully!
                    </ConfirmDialogStyled>
                  )}
                </Card>
                {showApproveRejectButtons && (
                  <div className="d-flex justify-content-center">
                    {form?.state &&
                      [FormStateType.APPROVED, FormStateType.REQUESTED_TO_RESUBMIT].includes(form?.state) &&
                      allowSubmit && (
                        <Button
                          variant="primary"
                          onClick={() => {
                            setShowApprove(true);
                            setFormAction(FORM_ACTION.UNLOCK);
                          }}
                          className="my-2 me-2"
                        >
                          Unlock
                        </Button>
                      )}
                    {form?.state && form?.state === FormStateType.SUBMITTED && (
                      <Button
                        variant="danger"
                        onClick={() => {
                          setShowApprove(true);
                          setFormAction(FORM_ACTION.REJECT);
                        }}
                        className="my-2 me-2"
                      >
                        Reject
                      </Button>
                    )}
                    {form?.state &&
                      [FormStateType.SUBMITTED, FormStateType.REQUESTED_TO_RESUBMIT].includes(form?.state) && (
                        <Button
                          variant="success"
                          className="my-2"
                          onClick={() => {
                            setShowApprove(true);
                            setFormAction(FORM_ACTION.APPROVE);
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <RightSidebar title="Comments" setShowRightSidebar={setVisible} showRightSidebar={visible}>
            {getHeader()}
            <div>
              {commentedData.map((cmt) => (
                <div key={cmt.id} className="mb-2">
                  <Font14Bold className="my-0">{cmt.createdBy}</Font14Bold> <br />
                  <Font14Bold className="my-0 py-1">{convertTime(cmt.createdAt, DATE_TIME_FORMAT)}</Font14Bold>
                  <div>
                    <Font14>{cmt.content}</Font14>
                  </div>
                </div>
              ))}
              {hasNext && (
                <div className="d-flex justify-content-center">
                  <Button size="sm" variant="success" onClick={onLoadMore} disabled={loading}>
                    Load more
                  </Button>
                </div>
              )}
              {!totalComments && (
                <div>
                  <Font14 className="d-flex justify-content-center">No comment!</Font14>
                </div>
              )}
            </div>
          </RightSidebar>
        </>
      )}
      <ConfirmDialogStyled
        header="Request to resubmit form"
        show={showComment}
        btnCancel={{ label: 'Cancel' }}
        btnConfirm={{ label: 'Approve', variant: 'primary' }}
        onHide={() => setShowComment(false)}
        onConfirm={onSendRequest}
        children={
          onFocus && (
            <InputTextarea
              className="p-input-area"
              style={{ width: '100%' }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              autoResize
              placeholder="Please enter your comment (required)"
              maxLength={65535}
              autoFocus
            />
          )
        }
        footer={
          <>
            <Button className="me-1" variant="outline-primary" onClick={() => setShowComment(false)}>
              Cancel
            </Button>
            <Button className="ms-1" variant="primary" onClick={onSendRequest} disabled={!comment.trim()}>
              Send request
            </Button>
          </>
        }
      />
      {showApprove && (
        <ApproveFormModal
          showModal={showApprove}
          setShowModal={setShowApprove}
          formId={id}
          form={form}
          setCommented={setCommented}
          getFormDetail={getFormDetail}
          formAction={formAction}
        />
      )}
    </>
  );
}

export default FormManagementDetailPage;
