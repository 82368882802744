import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Profile } from '../../accounts-api-client/Profile';
import { SitePageDto } from '../../accounts-api-client/data-contracts';
import { getPerPageTable, handleErrorStatus, handleErrorLogMessage } from '../../utils';
import { RELATED_SITE_COLUMN, ROUTER, TITLE_RELATED_SITE_TABLE, UNKNOWN_ERROR_CODE } from '../../constant';
import { ParametersSearchI } from '../account-page';
import queryString from 'query-string';
import { useAccountSelected } from '../../context';
import Tooltip from '../../components/common/tooltip';
import { Datatable, CommonActionBar } from '@thg-harveynash/hyper-shared-components';
import { useToast } from '../../context/toast-context';
import useAccessibility from '../../utils/useAccessibility';
export interface ICol {
  field: string;
  header: string;
}
const RelatedSites = () => {
  const perPageTable = getPerPageTable();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { addAriaLabelToTable } = useAccessibility();
  const accountSelected = useAccountSelected();
  const [relatedSites, setRelatedSites] = useState<SitePageDto>();
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '' } = paramFromUrl; //NOSONAR
  const renderSort = (sortByFromUrl: string | (string | null)[] | null) => {
    if (sortByFromUrl !== '') {
      const sortBySplit = (sortByFromUrl as string).split(':');
      const sortField = sortBySplit[0];
      const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
      return { sortField, sortOrder };
    } else return { sortField: '', sortOrder: -1 };
  };
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: renderSort(sortBy).sortField || 'name',
    sortOrder: renderSort(sortBy).sortOrder || 1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    first: limit && page ? Number(limit) * Number(page) : 0,
  });

  const accountApi = new Profile();
  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };
  const listSitesCol = RELATED_SITE_COLUMN.map((site: any) => {
    switch (site.field) {
      case TITLE_RELATED_SITE_TABLE.SITE_NAME:
        return {
          key: site.field,
          field: site.field,
          label: site.header,
          sortable: true,
          className: 'max-width-none',
          style: { minWidth: '200px' },
          body: (data: any) => (
            <>
              <Tooltip data={data.name} id={`siteName-${data.id}`} />
            </>
          ),
        };
      case TITLE_RELATED_SITE_TABLE.TERRITORY:
        return {
          key: site.field,
          field: site.field,
          label: site.header,
          sortable: true,
          style: { maxWidth: '200px', minWidth: '200px' },
          body: (data: any) => data?.territory,
        };
      case TITLE_RELATED_SITE_TABLE.CATEGORY:
        return {
          key: site.field,
          field: site.field,
          label: site.header,
          sortable: true,
          style: { maxWidth: '250px', minWidth: '250px' },
          body: (data: any) => data?.category,
        };
      case TITLE_RELATED_SITE_TABLE.LAUNCH_DOMAIN:
        return {
          key: site.field,
          field: site.field,
          label: site.header,
          sortable: true,
          className: 'max-width-none',
          style: { minWidth: '220px' },
          body: (data: any) => data?.launchDomain,
        };
      default:
        return {
          key: site.field,
          field: site.field,
          label: site.header,
          sortable: true,
          className: 'max-width-none',
        };
    }
  });

  const fetchData = (param?: any) => {
    setIsLoadingTable(true);
    let paramSubmit: any = {};
    if (param) {
      const { rows, page: pageParam, sortField, sortOrder, searchKey, first } = param;
      localStorage.setItem('perPageTable', rows);
      const sortByParam = `${sortField}:${sortOrder === 1 ? 'asc' : 'desc'}`;
      paramSubmit = {
        limit: Number(rows) || 0,
        page: Number(pageParam) || 0,
        search: searchKey,
        sortBy: sortByParam,
      };
      setParametersSearch({ rows, page: pageParam, sortField, sortOrder, searchKey, first });
      updateUrlSearch(paramSubmit);
    }
    const accountSelectedFromLocal = localStorage.getItem('accountSelected') || '';
    const accountSelectedId = JSON.parse(accountSelectedFromLocal)?.id;
    accountApi
      .getSitesByAccountFromAccountProfile(accountSelectedId, paramSubmit)
      .then((res) => {
        setRelatedSites(res.data);
        setIsLoadingTable(false);
      })
      .catch((error) => {
        handleErrorStatus(error.status);
        setIsLoadingTable(false);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
      });
  };

  const callBackFetchData = useCallback(
    (params: any) => {
      fetchData(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountSelected]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [relatedSites]);

  const breadcrumbItems = [
    {
      name: 'Account Profile',
      path: ROUTER.ACCOUNT_PROFILE,
    },
    {
      name: 'Related Sites',
      path: ROUTER.RELATED_SITES,
      active: true,
    },
  ];

  return (
    <>
      <CommonActionBar
        pageTitle="Sites"
        isVisibleButton={false}
        breadcrumbItems={breadcrumbItems}
        onChangePath={(path: string) => navigate(path)}
      />
      <div className="page-content">
        <Datatable
          fetchData={callBackFetchData}
          totalRecords={relatedSites?.pageInfo?.total || 0}
          data={relatedSites?.items || []}
          isLoading={isLoadingTable}
          columns={listSitesCol}
          tableName="sites"
          emptyMessage="No results found."
          initParams={parametersSearch}
          columnResizeMode="fit"
          sortField={parametersSearch.sortField}
          searchKey={parametersSearch.searchKey}
          scrollable
        />
      </div>
    </>
  );
};

export default RelatedSites;
