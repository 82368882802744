import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import { CURRENT_APP_CODE, ErrorMessageUserProfile, ERROR_MESSAGE_USER_PROFILE_DES, ROLE_TYPE } from '../constant';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import jwt_decode from 'jwt-decode';
import { get } from 'lodash';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { ConfirmDialogStyled, PreTitle, SpinnerStyled } from '../components/StyledComponent';
import { upsInstance, userInstance } from '../services/instance';
import moment from 'moment';
import { GlobalConfig } from '../global';
import { Button } from 'react-bootstrap';
import { CLIENT_ERRORS } from '../constant/error-status';

type AuthProps = React.PropsWithChildren<{}>;

interface InitState {
  auth: any;
  keyCloak: any;
  permissions: any;
}

const initialState: InitState = {
  auth: {},
  keyCloak: null,
  permissions: [],
};

const AuthContext = React.createContext(initialState);

function AuthProvider({ children }: AuthProps) {
  const [state, setState] = useState({
    ...initialState,
  });
  const [loading, setLoading] = useState(true);
  const [isErrorProfileUser, setIsErrorProfileUser] = useState(false);
  const [keycloakValue, setKeycloakValue] = useState<KeycloakInstance | null>(null);

  const afterLoginKeycloak = async (keycloak: KeycloakInstance) => {
    const { token = '' } = keycloak;
    const decoded = jwt_decode(token);
    const role = get(decoded, 'groups', '')?.[0] === '/thg-user' ? ROLE_TYPE.THG : ROLE_TYPE.CLIENT;
    if (role === ROLE_TYPE.CLIENT) {
      let response: any;
      try {
        response = await userInstance.get('profile');
      } catch (error: any) {
        if (error && error?.response?.status === CLIENT_ERRORS[2]) setIsErrorProfileUser(true);
      }
      const userInfo = await response?.data;
      localStorage.setItem('isFirstTimeLogin', userInfo?.firstTimeLoginStatus);
      localStorage.setItem('firstTimeLoginAt', moment(userInfo?.firstTimeLoginAt).format('DD/MM/YYYY HH:mm:ss'));
      localStorage.setItem('termsOfUseAgreementStatus', userInfo?.termsOfUseAgreementStatus);
      localStorage.setItem('userId', userInfo?.id);
    }
    localStorage.setItem('role', role);
    localStorage.setItem('token', token);
    const responsePermission = await upsInstance.get('authz/users/permissions', {
      params: {
        appCode: CURRENT_APP_CODE,
      },
    });
    const userPermissions = await responsePermission.data;
    setState({ ...state, keyCloak: keycloak, auth: decoded, permissions: userPermissions });
    setKeycloakValue(keycloak);
    setLoading(false);
  };

  const updateKeycloak = () => {
    keycloakValue
      ?.updateToken(300)
      .then(() => {
        afterLoginKeycloak(keycloakValue);
      })
      .catch(() => {
        keycloakValue?.logout();
      });
  };

  useEffect(() => {
    if (keycloakValue) {
      let interval: any;
      interval = setInterval(() => {
        updateKeycloak();
      }, 270000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloakValue]);

  useLayoutEffect(() => {
    const keycloak: KeycloakInstance = new (Keycloak as any)({
      url: GlobalConfig().config.REACT_APP_URL_KEYCLOAK,
      realm: GlobalConfig().config.REACT_APP_REALM,
      clientId: GlobalConfig().config.REACT_APP_CLIENT_ID,
    });

    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: true,
        checkLoginIframeInterval: 1,
        pkceMethod: 'S256',
        scope: 'profile openid',
      })
      .then(() => {
        if (keycloak?.token) {
          afterLoginKeycloak(keycloak);
          localStorage.setItem('token', keycloak?.token);
        }
      })
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserProfileError = () => {
    keycloakValue?.logout();
    setIsErrorProfileUser(false);
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={state}>
      {loading ? (
        <SpinnerStyled style={{ height: '100vh' }}>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>{children}</>
      )}
      <ConfirmDialogStyled
        show={isErrorProfileUser}
        onHide={() => handleUserProfileError()}
        footer={
          <div className="d-flex justify-content-end w-100">
            <Button className="ms-1 px-3" variant="danger" onClick={() => handleUserProfileError()}>
              OK
            </Button>
          </div>
        }
      >
        <PreTitle>{ErrorMessageUserProfile}</PreTitle>
        <p className="mt-2">{ERROR_MESSAGE_USER_PROFILE_DES}</p>
      </ConfirmDialogStyled>
    </AuthContext.Provider>
  );
}

const useAuthInfo = () => {
  const { auth } = useContext(AuthContext);
  return auth;
};

const useKeyCloak = () => {
  const { keyCloak } = useContext(AuthContext);
  return keyCloak;
};

const usePermissions = () => {
  const { permissions } = useContext(AuthContext);
  const permissionList = permissions?.map((permission: any) => permission?.code);
  return {
    permissions,
    permissionList,
  };
};

export { AuthProvider, useAuthInfo, useKeyCloak, usePermissions };
export default AuthContext;
