import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useWarningModal } from '../context/warning-modal-context';
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker: any, when: boolean) {
  const {
    navigator: {
      block = () => {
        // This is intentional
      },
    } = {},
  }: any = useContext(NavigationContext);
  useEffect(() => {
    if (!when) return;
    const unblock = block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it.
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message: any, when = true) {
  const modal = useWarningModal();

  const blocker = useCallback(
    (tx: any) => {
      modal({
        message: message,
        target: tx,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message]
  );

  useBlocker(blocker, when);
}
