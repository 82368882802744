/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PongMessage {
  message?: string;
}

export interface PageInfoDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  total?: number;
  hasNext?: boolean;
}

export interface InstallFormTemplateDto {
  formTemplate?: FormTemplateDto;
  formTemplateVersion?: FormTemplateVersionDto;
  configuration?: FormTemplateConfigurationDto;
  mapper?: FormMapperDto;
}

export interface FormTemplatePageDto {
  items?: FormTemplateDto[];
  pageInfo?: PageInfoDto;
}

export interface CommentPageDto {
  items?: CommentDto[];
  pageInfo?: PageInfoDto;
}

export type FormTemplateDto = BaseLongDto & {
  id?: string;
  name?: string;
  state?: FormTemplateState;
  /** @format date-time */
  publishedDate?: string;
  configuration?: FormTemplateConfigurationDto;
  latestVersion?: FormTemplateVersionDto;
};

export type FormTemplateVersionDto = BaseLongDto & {
  id?: string;
  templateId?: string;
  version?: number;
  template?: TemplateDto;
  rules?: RuleDto[];
};

export interface FormTemplateVersionRequestDto {
  templateId: string;
  template: TemplateDto;
  rules?: RuleDto[];
}

export interface TemplateDto {
  id?: string;
  version?: string;
  title?: string;
  savingValidationRequired?: boolean;
  submitText?: string;
  description?: string;
  order?: number;
  elements?: ElementDto[];
  pages?: string;
}

export interface FormPageDto {
  items?: FormDto[];
  pageInfo?: PageInfoDto;
}

export interface FormPageRequestDto {
  items?: FormDto[];
  pageInfo?: PageInfoDto;
  templateId?: string;
  formTemplateVersionId?: string;
}

export interface FormUpdatedRequestDto {
  form?: FormDto;
  formTemplateVersionId?: string;
}

export interface PageDto {
  key?: string;
  title?: string;
  /** @format int32 */
  order?: number;
  elements?: ElementDto[];
}

export interface ElementDto {
  key?: string;
  type?: string;
  dataType?: string;
  visible?: boolean;
  properties?: Record<string, any>;
  validationRule?: ValidationRuleDto;
}

export interface ValidationRuleDto {
  required?: ValidationBooleanDto;
  maxLength?: ValidationIntegerDto;
  minLength?: ValidationIntegerDto;
  pattern?: ValidationStringDto;
  acceptFileTypes?: string;
}

export interface ValidationBooleanDto {
  value?: boolean;
  message?: string;
}

export interface ValidationIntegerDto {
  value?: number;
  message?: string;
}

export interface ValidationStringDto {
  value?: string;
  message?: string;
}

export interface RuleDto {
  condition?: ConditionDto;
  action?: ActionDto;
}

export interface ConditionDto {
  fieldKey?: string;
  operator?: Operator;
  argument?: object;
}

export interface ActionDto {
  method?: string;
  param?: object;
}

export interface ExecuteDto {
  method?: MethodKey;
  param?: object;
}

export type FormMapperDto = BaseLongDto & {
  id?: string;
  objectType?: string;
  templateVersionId?: string;
  mapper: MapperDto;
};

export type MapperDto = string;

export interface FieldMapperModelDto {
  fieldName?: string;
  dataType?: string;
}

export enum MethodKey {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export enum Operator {
  EQ = 'EQ',
  IN = 'IN',
  LIKE = 'LIKE',
}

export enum FormTemplateState {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface FormTemplateStateDto {
  state?: FormTemplateState;
  /** @format date-time */
  publishedDate?: string;
}

export enum FormConfigurationLevel {
  ACCOUNT = 'ACCOUNT',
  PROJECT = 'PROJECT',
}

export type DataMapperDto = AbstractDto & {
  fieldKey?: string;
  type?: string;
  extFieldKey?: string;
  dataType?: string;
  dataFormat?: string;
  subType?: string;
  mandatory?: boolean;
};

export type SettingDto = AbstractDto & {
  key?: string;
  type?: string;
  value?: string;
};

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortItemDto {
  field?: string;
  type?: SortType;
}

export interface QueryParamDto {
  search?: string;
  sortBy?: SortItemDto;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  limit?: number;
}

export interface ErrorDto {
  code?: string;
  message?: string;
  /** @format date-time */
  timestamp?: string;
}

export type BaseLongDto = AbstractDto & {
  /** @format int64 */
  id?: number;
};

export interface AbstractDto {
  activeStatus?: boolean;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string;
  /** @format date-time */
  modifiedAt?: string;
  modifiedBy?: string;
}

export type FormTemplateConfigurationDto = AbstractDto & {
  id?: string;
  templateId: string;
  level?: FormConfigurationLevel | string;
  adhocCondition?: string;
  workdriveDataTemplate?: string;
  workdriveDataTemplateId?: string;
  approvers?: string[];
  allowResubmit?: boolean;
  /** @format int32 */
  approvedWorkingDays?: number;
  templateFiles?: { [key: string]: string };
};

export type FormDto = AbstractDto & {
  id?: string;
  title?: string;
  templateId?: string;
  level?: FormConfigurationLevel;
  siteId?: string;
  siteCrmId?: string;
  accountId?: string;
  accountCrmId?: string;
  projectId?: string;
  projectExtId?: string;
  projectName?: string;
  linkedExternalId?: string;
  workdriveUrl?: string;
  workdriveId?: string;
  accountName?: string;
  /** @format date-time */
  submittedAt?: string;
  submittedBy?: string;
  /** @format date-time */
  approvedAt?: string;
  approvedBy?: string;
  linkedExternalObjectType?: ExternalObjectType;
  state?: FormStateType;
  data?: FormDataDto;
};

export type FormDataDto = AbstractDto & {
  id?: string;
  formId?: string;
  formTemplateVersionId?: string;
  value?: Record<string, any>;
  template?: TemplateVersionInfo;
};

export interface TemplateVersionInfo {
  templateId?: string;
  /** @format int32 */
  version?: number;
}

export type FormDataSnapshotDto = AbstractDto & {
  id?: string;
  formId?: string;
  formTemplateVersionId?: string;
  /** @format date-time */
  dataLastModifiedAt?: string;
  value?: Record<string, any>;
};

export interface FormStateDto {
  type: FormStateType;
  name?: string;
  /** @format int32 */
  sequence?: number;
}

export enum FormStateType {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  DRAFT = 'DRAFT',
  NEED_TO_BE_UPDATED = 'NEED_TO_BE_UPDATED',
  REQUESTED_TO_RESUBMIT = 'REQUESTED_TO_RESUBMIT',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
}

export enum FormActionType {
  SAVE = 'SAVE',
  SUBMIT = 'SUBMIT',
  REQUESTING_TO_RESUBMIT = 'REQUESTING_TO_RESUBMIT',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  UNLOCK = 'UNLOCK',
}

export enum ExternalObjectType {
  ZOHO_ACCOUNT = 'ZOHO_ACCOUNT',
  ZOHO_SITE = 'ZOHO_SITE',
  ZOHO_PROJECT = 'ZOHO_PROJECT',
}

export interface GenerateFormTemplateRequestDto {
  templateId?: string;
}

export interface FormStateUpdatedRequestDto {
  formId?: string;
  newState?: FormStateType;
  action?: FormActionType;
}

export interface CollectAccountToConcreateFormRequestDto {
  templateId?: string;
  pageRequest?: PageRequestDto;
}

export interface CollectProjectToConcreateFormRequestDto {
  templateId?: string;
  pageRequest?: PageRequestDto;
}

export interface GenerateFormForNewAccountRequestDto {
  account?: AccountDto;
  pageRequest?: PageRequestDto;
}

export interface GenerateFormForNewProjectRequestDto {
  project?: ProjectDto;
  pageRequest?: PageRequestDto;
}

export interface FilterProjectRequestDto {
  templateId?: string;
  configuration?: FormTemplateConfigurationDto;
  projectDto?: ProjectDto;
  templateName?: string;
  latestTemplateVersion?: string;
}

export interface FilterAccountRequestDto {
  templateId?: string;
  configuration?: FormTemplateConfigurationDto;
  accountDto?: AccountDto;
  templateName?: string;
  latestTemplateVersion?: string;
}

export type ProjectDto = BaseDto & {
  id?: string;
  extSysId?: string;
  siteRef?: string;
  portalId?: string;
  projectKey?: string;
  projectName?: string;
  status?: string;
  /** @format date */
  publishedToPortal?: string;
  territory?: string;
  /** @format date */
  goLiveDate?: string;
  /** @format date */
  startDate?: string;
  pmName?: string;
  pmEmail?: string;
  seniorName?: string;
  seniorPmEmail?: string;
  ragStatus?: string;
  percent?: string;
  rollOutType?: string;
  currentMilestone?: string;
  launchUrl?: string;
};

export type AccountDto = BaseDto & {
  id?: string;
  crmSysId?: string;
  crmAcctId?: string;
  name?: string;
  portalActive?: boolean;
  website?: string;
  workdriveUrl?: string;
  workdriveFolderId?: string;
};

export interface BaseDto {
  activeStatus: boolean;
  /** @format date-time */
  createdAt: string;
  createdBy: string;
  /** @format date-time */
  modifiedAt?: string;
  modifiedBy?: string;
}

export interface PageRequestDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  limit?: number;
}

export interface TransitionDto {
  fromState?: FormStateType;
  toState?: FormStateType;
}

export enum ExtSystemKey {
  ZOHO_CRM = 'ZOHO_CRM',
  ZOHO_PROJECTS = 'ZOHO_PROJECTS',
  ZOHO_WORKDRIVE = 'ZOHO_WORKDRIVE',
  ZOHO_ACCOUNT = 'ZOHO_ACCOUNT',
  ZOHO_DESK = 'ZOHO_DESK',
}

export interface FormError {
  errors?: Record<string, string>;
}

export interface StateChangedError {
  message?: string;
  transition?: TransitionDto;
}

export type CommentDto = AbstractDto & {
  id?: string;
  formId: string;
  /**
   * @minLength 1
   * @maxLength 65535
   */
  content: string;
  user: UserDto;
};

export interface UserDto {
  userId: string;
  userName: string;
  userEmail?: string;
}

export interface FormAutoApprovalRequestDto {
  formId?: string;
  /** @format date-time */
  triggerTime?: string;
}

export interface FormActionRequestDto {
  formId?: string;
  action?: FormActionType;
  formData?: FormDataDto;
  comment?: CommentDto;
}

export enum EventType {
  SCANNING_SUBMITTED_FORM = 'SCANNING_SUBMITTED_FORM',
}

export interface EventDto {
  event: EventType;
  /** @format date-time */
  timestamp?: string;
}
