/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AdhocFormsRequiredDto, ErrorDto, SiteStatusDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Settings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get Site Status
   *
   * @tags site, status
   * @name GetSiteStatus
   * @summary Site Status
   * @request GET:/settings/sites/statuses
   * @secure
   */
  getSiteStatus = (params: RequestParams = {}) =>
    this.request<SiteStatusDto[], ErrorDto>({
      path: `/settings/sites/statuses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Adhoc Forms Conditions
   *
   * @tags site, Adhoc, Froms, Conditions
   * @name GetAdhocFormsConditions
   * @summary Adhoc Forms Conditions
   * @request GET:/settings/sites/adhocFormsConditions
   * @secure
   */
  getAdhocFormsConditions = (params: RequestParams = {}) =>
    this.request<AdhocFormsRequiredDto[], ErrorDto>({
      path: `/settings/sites/adhocFormsConditions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
