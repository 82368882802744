import { External } from '../../file-api-client/External';
import { FormTemplateConfigurationDto } from '../../form-api-client/data-contracts';
import { FormTemplates } from '../../form-api-client/FormTemplates';

const externalApi = new External();

const formManagementApi = new FormTemplates();

export const fetchDataTemplate = async (extSystemKey: string, id: any) => {
  let dataTemplate: any = {
    success: false,
    response: {},
  };
  await externalApi
    .getDataTemplate(extSystemKey, id)
    .then((res) => {
      dataTemplate = { success: true, response: res };
    })
    .catch((error) => {
      dataTemplate = { success: false, response: error };
    });

  return dataTemplate;
};

export const renameDataTemplate = async (extSystemKey: string, id: string, dataTemplate: any) => {
  let response: any = { success: false, data: {} };
  await externalApi
    .renameDataTemplate(extSystemKey, id, dataTemplate)
    .then((res) => {
      response = { success: true, data: res?.data };
    })
    .catch((error) => {
      response = { success: false, data: error };
    });
  return response;
};

export const hadleRenameDataTemplate = async (data: any, handleAddDataTemplate: Function, onError: Function) => {
  const newData = await data;
  if (newData.success) {
    handleAddDataTemplate(newData?.data?.dataTemplateId);
    return true;
  }
  let resData = newData?.data?.response?.data;
  onError(resData?.code, resData?.message);
  return newData?.data?.error?.message;
};

export const updateFormTemplateConfiguration = async (
  formTemplateId: string,
  data: FormTemplateConfigurationDto,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsUploadingFile: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let dataForm: any = { success: false, response: {} };
  setIsLoading(true);
  await formManagementApi
    .updateFormTemplateConfiguration(formTemplateId, data)
    .then((res) => {
      dataForm = { success: true, response: res };
    })
    .catch((error) => {
      dataForm = { success: false, response: error };
    });
  setIsLoading(false);
  setIsUploadingFile(false);
  return dataForm;
};

export const updateFormConfig = async (dataForm: any, updateDataFormConfig: Function, onError: Function) => {
  const newDataForm = await dataForm;
  if (newDataForm.success) {
    updateDataFormConfig(newDataForm?.response?.data);
    return true;
  }
  const errorData = newDataForm?.response?.response?.data;
  onError(errorData?.code, errorData?.message);
  return errorData?.message;
};

export const createDataTemplate = async (extSystemKey: string, dataTemplate: any) => {
  let response = { success: false, data: {} };
  await externalApi
    .createDataTemplate(extSystemKey, dataTemplate)
    .then((res) => {
      response = { success: true, data: res?.data };
    })
    .catch((error) => {
      response = { success: false, data: { error: error?.response?.data } };
    });
  return response;
};

export const handleCreateDataTemplate = async (data: any, handleAddDataTemplate: Function, onError: Function) => {
  const newData = await data;
  if (newData?.success) {
    handleAddDataTemplate(newData?.data?.dataTemplateId);
    return true;
  }
  const mesError =
    newData?.data?.error?.message === 'Duplicate dataTemplateName found'
      ? 'This name already exists. Please try another name'
      : newData?.data?.error?.message;
  onError(newData?.data?.error?.code, mesError);
  return mesError;
};
