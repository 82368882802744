import React from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutDetail from '../../components/layout-detail';
import { Button } from '@thg-harveynash/hyper-shared-components';
import PrivacyData from './data.json';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div className="privacy-body">
      <LayoutDetail pageTitle="Privacy Notice" breadcrumbItems={[]} isLoading={false}>
        <div className="privacy-container">
          <div className="section-area">
            <span className="title-section">Privacy Notice</span>
          </div>
          <div className="d-flex justify-content-end mb-2">Effective Date: {PrivacyData?.effectiveDate}</div>
          <div dangerouslySetInnerHTML={{ __html: PrivacyData?.openingQuote }} />
          <ol type="1">
            {PrivacyData?.mainContent?.map((contentItem) => (
              <div key={contentItem?.title}>
                <li>
                  <strong>{contentItem?.title}</strong>
                </li>
                <div dangerouslySetInnerHTML={{ __html: contentItem?.content }} />
              </div>
            ))}
          </ol>
        </div>
        <div className="form">
          <div className="form-action d-flex flex-row align-items-center justify-content-between">
            <Button type="button" variant="outline-primary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </div>
        </div>
      </LayoutDetail>
    </div>
  );
};

export default PrivacyPolicy;
