import React from 'react';
import LeftTabPanel from '../../components/left-tab-panel';
import LayoutDetail from '../../components/layout-detail';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { useNavigate } from 'react-router-dom';

const TermOfUse = () => {
  const navigate = useNavigate();
  return (
    <LayoutDetail pageTitle="PORTAL TERMS OF USE" breadcrumbItems={[]} isLoading={false}>
      <div className="section-area">
        <span className="title-section">PORTAL TERMS OF USE</span>
      </div>
      <div style={{ marginTop: '1.5rem' }} className="layout-body-scroll">
        <LeftTabPanel />
      </div>
      <div className="form">
        <div className="form-action d-flex flex-row align-items-center justify-content-between">
          <Button type="button" variant="outline-primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
      </div>
    </LayoutDetail>
  );
};

export default TermOfUse;
