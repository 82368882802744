import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FaRegFolder, FaRegFile } from 'react-icons/fa';
import { Checkbox } from 'primereact/checkbox';
import { convertTime, downloadFile, getToken, handleErrorLogMessage, handleErrorStatus } from '../../utils';
import {
  DATE_FORMAT,
  DOCUMENTS_COLUMNS,
  TITLE_DOCUMENT_TABLE,
  FILE_INSTRUCTION_ACCEPT_ALL,
  FILE_INSTRUCTION_ACCEPT_SPECIFIC,
  FILE_UPLOAD,
  UPLOAD_NEW_VERSION,
  UPLOAD,
  PERMISSIONS,
  UNKNOWN_ERROR_CODE,
  GENERAL_ERROR_MESSAGE,
  DEFINED_ERROR_CODE,
} from '../../constant';
import { FILE_MANAGEMENT_URL } from '../../utils/baseUrl';
import { ExtDocument } from '../../file-api-client/ExtDocument';
import { useAccountSelected } from '../../context';
import { ButtonInfoStyled, DataTableStyled, ConfirmDialogUploadFile } from '../../components/StyledComponent';
import FileUploader from '../../components/file-upload/index';
import { ExtSystemKey, SiteDto } from '../../accounts-api-client/data-contracts';
import { useToast } from '../../context/toast-context';
import { isEmpty, findIndex, filter, find } from 'lodash';
import { CommonActionBar, Button } from '@thg-harveynash/hyper-shared-components';
import axios from 'axios';
import QueryString from 'qs';
import Tooltip from '../../components/common/tooltip';
import TooltipBoostrap from '../../components/common/tooltipBoostrap';
import { usePermissions } from '../../context/auth-context';
import { Accounts } from '../../accounts-api-client/Accounts';

const LIMIT_ITEM = 50;

export interface BreadcrumbItems {
  href: string | undefined;
  name: string | undefined;
  resourceId: string | undefined;
}

export interface ParametersSearchI {
  limit?: number;
  sortBy?: string;
  offset?: number;
}

// eslint-disable-next-line prettier/prettier
const Documents = () => { //NOSONAR
  const navigate = useNavigate();
  const location = useLocation();
  const [sortBy, setSortBy] = useState('lastUpdated:desc');
  const [offset, setOffset] = useState(0);
  const accountSelected = useAccountSelected();
  const accountApi = new Accounts();
  const [siteDocumentSelected, setSiteDocumentSelected] = useState<SiteDto>();
  const [hasMore, setHasMore] = useState(false);
  const [documentsData, setdocumentsData] = useState<any>([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const toast = useToast();
  const [isShowModalUploadFiles, setIsShowModalUploadFiles] = useState<boolean>(false);
  const [isUploadMultiple, setIsUploadMultiple] = useState<boolean>(true);
  const [acceptUploadFile, setAcceptUploadFile] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [instructionsUploadFile, setInstructionsUploadFile] = useState<string>('');
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [confirmModalText, setConfirmModalText] = useState<string>('');
  const [modalTitleName, setModalTitleName] = useState('');
  const [isUploadedFiles, setIsUploadedFiles] = useState<boolean>(false);
  const [getLinkZipFileCount, setGetLinkZipFileCount] = useState(0);
  const [loadingGetLinkZipFile, setLoadingGetLinkZipFile] = useState(false);
  const token = getToken();
  const { permissionList } = usePermissions();
  const canUploadFile = permissionList.includes(PERMISSIONS.upload_file);
  const canUploadNewVersion = permissionList.includes(PERMISSIONS.upload_new_version);
  const canDownloadFile = permissionList.includes(PERMISSIONS.download_file);
  const paramFromUrl = queryString.parse(location.search);
  const folderName = paramFromUrl?.folderName;
  let idFromUrl: any = paramFromUrl?.accountId || paramFromUrl?.siteId || paramFromUrl?.projectId;
  if (Object.keys(paramFromUrl)?.length > 2 || !idFromUrl) {
    toast({ code: UNKNOWN_ERROR_CODE.ERR_UNKNOWN, message: GENERAL_ERROR_MESSAGE, type: 'error' });
    navigate('/management/documents');
  }
  let folderType: any;
  if (paramFromUrl?.siteId) {
    folderType = 'sites';
  }
  if (paramFromUrl?.accountId) {
    folderType = 'accounts';
  }
  if (paramFromUrl?.projectId) {
    folderType = 'projects';
  }

  const openUploadFileModal = () => {
    setIsShowModalUploadFiles(true);
    setAcceptUploadFile('');
    setConfirmModalText(UPLOAD);
    setModalTitleName(FILE_UPLOAD);
    setInstructionsUploadFile(FILE_INSTRUCTION_ACCEPT_ALL);
    setIsUploadMultiple(true);
  };
  const openUpdateFileModal = (fileType: string, fileName: string) => {
    setIsShowModalUploadFiles(true);
    setAcceptUploadFile(`.${fileType}`);
    setModalTitleName(fileName);
    setConfirmModalText(UPLOAD_NEW_VERSION);
    setInstructionsUploadFile(FILE_INSTRUCTION_ACCEPT_SPECIFIC);
    setIsUploadMultiple(false);
  };
  const handleFileChange = (files: any) => {
    setSelectedFiles(files);
  };
  const onCancelUploadModal = () => {
    setSelectedFiles([]);
    setIsUploadedFiles(false);
    setIsUploadingFile(false);
    setIsShowModalUploadFiles(false);
    fetchData({ offset: 0 }, false, false);
  };
  const handleUploadFile = async () => {
    if (isUploadingFile || selectedFiles.length === 0) return;
    setIsUploadingFile(true);
    let promiseUploadArray: any[] = [];
    selectedFiles.forEach((file: any) => {
      if (!isEmpty(file.path)) {
        promiseUploadArray.push(uploadFilePromise(file));
      }
    });
    await Promise.all(promiseUploadArray).then((result) => {
      let successUploadedFile = result.filter((res) => res.extId);
      setIsUploadedFiles(true);
      if (successUploadedFile.length > 0) {
        switch (confirmModalText) {
          case UPLOAD_NEW_VERSION:
            toast({ message: 'Version uploaded successfully!', type: 'success' });
            break;
          case UPLOAD:
            toast({ message: `${successUploadedFile.length} file(s) have been uploaded`, type: 'success' });
            break;
          default:
            break;
        }
      }
    });
  };

  const fetchSiteListDoc = (id: string) => {
    accountApi
      .getSitesByAccount(id, {})
      .then((res) => {
        const listSite = res?.data?.items;
        let selectedSite;
        if (listSite)
          selectedSite = find(listSite, function (s) {
            return s.id === idFromUrl;
          });
        setSiteDocumentSelected(selectedSite);
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
      });
  };

  useEffect(() => {
    if (accountSelected?.id) {
      fetchSiteListDoc(accountSelected?.id);
    }
  }, [accountSelected]);

  const uploadFilePromise = (
    elementValue: any
  ): unknown => //NOSONAR
    new Promise((resolve) => {
      let myHeaders: any = new Headers();
      let isUpdateNewVersion = confirmModalText === UPLOAD_NEW_VERSION;
      // @ts-ignore
      myHeaders.append(
        'x-parent-id',
        folderType === 'sites' ? siteDocumentSelected?.workdriveFolderId : accountSelected?.publicWorkdriveFolderId
      );
      myHeaders.append('x-file-name', isUpdateNewVersion ? modalTitleName : elementValue.name);
      myHeaders.append('Content-Type', elementValue.type);
      myHeaders.append('x-override-name-exist', isUpdateNewVersion.toString());
      myHeaders.append('Authorization', `Bearer ${token}`);
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: elementValue,
      };

      fetch(`${FILE_MANAGEMENT_URL}/external/upload/${ExtSystemKey.ZOHO_WORKDRIVE}/resources`, requestOptions)
        .then((response: any) => {
          if (response.status === 413) {
            resolve('');
            setUploadDocumentStatus(false, elementValue.fileUploadStatusId);
          }
          return response.text();
        })
        .then((result) => {
          if (!result) {
            resolve('');
            setUploadDocumentStatus(false, elementValue.fileUploadStatusId);
          } else {
            let data = JSON.parse(result);
            if (result && data && data.extId) {
              resolve({ extId: data, fileUploadStatusId: elementValue.fileUploadStatusId });
              setUploadDocumentStatus(true, elementValue.fileUploadStatusId);
            } else {
              resolve(data);
              setUploadDocumentStatus(false, elementValue.fileUploadStatusId, data?.message);
            }
          }
        });
    });

  const setUploadDocumentStatus = (status: boolean, fileUploadStatusId: string, errorMessage?: string) => {
    let selectedFilesArray = [...selectedFiles];
    selectedFilesArray.forEach((file: any) => {
      if (file.fileUploadStatusId === fileUploadStatusId) {
        file.isUploadSuccess = status;
        file.errorMessage = errorMessage || '';
      }
    });
    setSelectedFiles(selectedFilesArray);
  };

  const fileManagementApi = new ExtDocument();

  const fetchData = async (params: ParametersSearchI, isLoadMore: boolean, isLocationChange: boolean) => {
    const paramSubmit = { offset, limit: LIMIT_ITEM, sortBy, ...params };
    setIsLoadingTable(true);
    // @ts-ignore;
    try {
      const documentsDataRes = await fileManagementApi.getFilesAndFolders(folderType, idFromUrl, paramSubmit);
      // @ts-ignore
      let documentsDataResArray: any = documentsDataRes?.data?.items;
      let hasMoreData = documentsDataResArray?.length + documentsDataRes?.data?.privateFileCount === LIMIT_ITEM;
      let isEnoughPublicDocCount =
        [...(!isLocationChange ? documentsData : []), ...documentsDataResArray]?.length >= LIMIT_ITEM;
      setHasMore(hasMoreData);
      setIsLoadingTable(false);
      if (isLoadMore) {
        setdocumentsData([...documentsData, ...documentsDataResArray]);
        checkLoadMorePublicDoc(isEnoughPublicDocCount, hasMoreData);
        if (documentsDataResArray?.length > 0) setCheckAll(false);
      } else {
        setdocumentsData(documentsDataResArray);
        checkLoadMorePublicDoc(isEnoughPublicDocCount, hasMoreData);
      }
    } catch (error: any) {
      setdocumentsData([]);
      setIsLoadingTable(false);
      if (error?.response?.status === 403) {
        toast({
          code: DEFINED_ERROR_CODE.ERR_CP_FILE_FE_ACCESS_DENIED,
          message: 'You don’t have permission to view this folder',
          type: 'error',
        });
        navigate('/management/documents');
      } else {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
      }
    }
  };

  const checkLoadMorePublicDoc = (isEnoughPublicDocCount: boolean, hasMoreLoad: boolean) => {
    if (!isEnoughPublicDocCount && hasMoreLoad) {
      setOffset(offset + LIMIT_ITEM);
    }
  };

  const handleSort = (event: any) => {
    const newSortBy = `${event.sortField}:${event.sortOrder === 1 ? 'asc' : 'desc'}`;
    setSortBy(newSortBy);
    setOffset(0);
  };

  const fetchMoreData = () => {
    setOffset(offset + LIMIT_ITEM);
  };

  // handle selector
  const handleCheckAll = (value: boolean) => {
    setCheckAll(value);
    if (value) {
      setSelectedDocuments(filter(documentsData, (docsItem: any) => !docsItem?.isFolder));
    } else {
      setSelectedDocuments([]);
    }
  };

  const checkRowIsChoosen = (id: string) => {
    return findIndex(selectedDocuments, (item: any) => item?.resourceId === id) > -1;
  };

  const setSelectedDocumentsArray = (value: any) => {
    setSelectedDocuments(value);
    const totalFile = filter(documentsData, (docsItem: any) => !docsItem?.isFolder).length;
    setCheckAll(totalFile === value?.length);
  };

  const handleCheckDocument = (e: any) => {
    let flagCheck = selectedDocuments?.find(function (file: any) {
      return file.resourceId === e.resourceId;
    });
    let selectedDocumentsArray = [...selectedDocuments] || [];
    if (flagCheck) {
      let filteredDocumentsArray = selectedDocumentsArray.filter(function (file: any) {
        return file.resourceId !== e.resourceId;
      });
      setSelectedDocumentsArray(filteredDocumentsArray);
      return;
    }
    let value = documentsData.find(function (file: any) {
      return file.resourceId === e.resourceId;
    });
    selectedDocumentsArray.push(value);
    setSelectedDocumentsArray(selectedDocumentsArray);
  };

  useEffect(() => {
    fetchData({ sortBy, offset }, offset > 0, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, offset]);

  useEffect(() => {
    if (accountSelected) {
      setOffset(0);
      setCheckAll(false);
      setSelectedDocuments([]);
      // @ts-ignore
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected]);

  const downloadMultipleFile = async (resourceIds: string[], wmsKey?: string) => {
    let url = `${FILE_MANAGEMENT_URL}/external/downloadMultiple/${ExtSystemKey.ZOHO_WORKDRIVE}/zipFileProgress`;
    try {
      const res = await axios.get(url, {
        params: wmsKey ? { resourceIds, wmsKey } : { resourceIds },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      });
      if (res?.data?.downloadLink) {
        let urlDownload = `${FILE_MANAGEMENT_URL}/external/downloadMultiple/${ExtSystemKey.ZOHO_WORKDRIVE}/zipFile?downloadLink=${res?.data?.downloadLink}`;
        downloadFile(urlDownload, token);
        setLoadingGetLinkZipFile(false);
        setGetLinkZipFileCount(0);
      } else {
        if (getLinkZipFileCount <= 60) {
          setTimeout(() => {
            downloadMultipleFile(resourceIds, res?.data?.wmsKey);
            setGetLinkZipFileCount(getLinkZipFileCount + 1);
          }, 2000);
          return;
        }
        toast({
          code: DEFINED_ERROR_CODE.ERR_CP_FILE_FE_DOWNLOAD_TIMEOUT,
          message: 'Download zip file time out',
          type: 'error',
        });
        setLoadingGetLinkZipFile(false);
      }
    } catch (error: any) {
      setLoadingGetLinkZipFile(false);
      handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
    }
  };

  const handleDownloadSelectedDocument = () => {
    setLoadingGetLinkZipFile(true);
    if (selectedDocuments.length === 1) {
      let url = `${FILE_MANAGEMENT_URL}/external/download/${ExtSystemKey.ZOHO_WORKDRIVE}/resources/${selectedDocuments[0]?.resourceId}`;
      try {
        downloadFile(url, token, selectedDocuments[0].name);
        setLoadingGetLinkZipFile(false);
      } catch (error: any) {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FILE_C_UNKNOWN, toast);
      }
    } else if (selectedDocuments.length > 1) {
      const listResourceIds = selectedDocuments.map((document: { resourceId: string }) => {
        return document?.resourceId || '';
      });
      downloadMultipleFile(listResourceIds);
    }
  };

  let DocumentColumns = [...DOCUMENTS_COLUMNS];

  if (folderType !== 'projects') {
    DocumentColumns.push({ field: TITLE_DOCUMENT_TABLE.ACTION, header: 'Actions' });
  }

  const listFormTemplateCol = DocumentColumns.map((acc) => {
    if (acc.field === TITLE_DOCUMENT_TABLE.SELECTOR)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={
            <Checkbox
              aria-label="checkbox-select-all-file"
              checked={checkAll}
              onChange={(e) => handleCheckAll(e.checked)}
              disabled={!canDownloadFile}
            />
          }
          body={({ isFolder, resourceId }) => (
            <div>
              {!isFolder && (
                <Checkbox
                  aria-label="checkbox-select-file"
                  onChange={(e) => handleCheckDocument({ e, resourceId })}
                  checked={checkRowIsChoosen(resourceId)}
                  disabled={!canDownloadFile}
                />
              )}
            </div>
          )}
          style={{ maxWidth: '50px', minWidth: '50px' }}
        />
      );

    if (acc.field === TITLE_DOCUMENT_TABLE.TYPE)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          body={({ isFolder }) => <div>{isFolder ? <FaRegFolder size={20} /> : <FaRegFile size={20} />}</div>}
          style={{ maxWidth: '60px', minWidth: '60px' }}
        />
      );

    if (acc.field === TITLE_DOCUMENT_TABLE.NAME)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          body={({ name }) => <TooltipBoostrap data={name}></TooltipBoostrap>}
          className="max-width-none"
        />
      );

    if (acc.field === TITLE_DOCUMENT_TABLE.SIZE)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          body={({ size, resourceId }) => <Tooltip data={size} id={`size-${resourceId}`} />}
          style={{ maxWidth: '150px', minWidth: '100px' }}
        />
      );

    if (acc.field === TITLE_DOCUMENT_TABLE.UPDATE_DATE)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          body={({ lastUpdated, resourceId }) => (
            <Tooltip data={convertTime(lastUpdated, DATE_FORMAT) || ''} id={`date-${resourceId}`} />
          )}
          style={{ maxWidth: '250px', minWidth: '250px' }}
        />
      );
    if (acc.field === TITLE_DOCUMENT_TABLE.ACTION)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          body={({ isFolder, name, extension }) => (
            <div className="d-flex justify-content-start">
              {!isFolder && canUploadNewVersion && (
                <ButtonInfoStyled
                  className="display-hover open-modal-update-files-nodal"
                  style={{ fontSize: '14px', height: '24px' }}
                  onClick={() => openUpdateFileModal(extension, name)}
                >
                  Upload New Version
                </ButtonInfoStyled>
              )}
            </div>
          )}
          style={{ maxWidth: '200px', minWidth: '200px' }}
          className="action-column"
        />
      );

    return <Column key={acc.field} field={acc.field} header={acc.header} sortable style={{ width: '150px' }} />;
  });

  const sortBySplit = sortBy.split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;

  const bulkActions = [
    {
      key: 'download',
      label: 'Download',
      isLoading: loadingGetLinkZipFile,
      disabled: selectedDocuments.length ? false : true,
      icon: 'mdi mdi-download',
      onClick: () => handleDownloadSelectedDocument(),
    },
  ];

  const HeaderUploadFile = (
    <div className="d-flex align-items-center">
      {modalTitleName === FILE_UPLOAD ? (
        FILE_UPLOAD
      ) : (
        <div>
          <FaRegFile className="me-1" size={27} /> {modalTitleName}
        </div>
      )}
    </div>
  );

  const breadcrumbItems = [
    { path: '/management/documents', name: 'Documents', active: false },
    { path: '', name: `${folderName}`, active: true },
  ];

  const folderNameRender = folderName;
  const isVisibleButton = canUploadFile && folderType !== 'projects';

  return (
    <>
      <CommonActionBar
        pageTitle={folderNameRender}
        buttonLabel="Upload Files"
        onClick={() => openUploadFileModal()}
        isVisibleButton={isVisibleButton}
        breadcrumbItems={breadcrumbItems}
        onChangePath={(path: string) => navigate(path)}
        iconPrefix={{
          name: 'dripicons-plus',
          className: 'addButton',
        }}
      />
      <div className="page-content">
        <InfiniteScroll dataLength={documentsData?.length} next={fetchMoreData} hasMore={hasMore} loader={false}>
          <DataTableStyled
            tablename="Files"
            resizableColumns
            totalRecords={documentsData?.length || 0}
            value={documentsData || []}
            rows={documentsData?.length || 0}
            columns={listFormTemplateCol}
            sortOrder={sortOrder}
            sortField={sortField}
            paginator={false}
            loading={isLoadingTable && !hasMore}
            onSort={handleSort}
            {...(canDownloadFile && { bulkactions: bulkActions })}
            selection={selectedDocuments}
            selectionMode="multiple"
            metaKeySelection={false}
            dataKey="resourceId"
            scrollable
          />
        </InfiniteScroll>
      </div>
      {hasMore && isLoadingTable && (
        <div className="d-flex justify-content-center">
          <ProgressSpinner style={{ width: '50px', height: '50px' }} />
        </div>
      )}
      <ConfirmDialogUploadFile
        header={HeaderUploadFile}
        show={isShowModalUploadFiles}
        aria-label="dialog-upload-file"
        onCancel={() => onCancelUploadModal()}
        onHide={() => onCancelUploadModal()}
        footer={
          <div className="d-flex flex-row-reverse justify-content-between  w-100">
            {!isUploadingFile && (
              <Button
                disabled={!selectedFiles.length}
                className="px-3"
                variant="success"
                onClick={() => handleUploadFile()}
              >
                {confirmModalText}
              </Button>
            )}
            {!(isUploadingFile && !isUploadedFiles) && (
              <Button className="px-3" variant="outline-danger" onClick={() => onCancelUploadModal()}>
                {!isUploadedFiles ? 'Cancel' : 'Close'}
              </Button>
            )}
          </div>
        }
      >
        <FileUploader
          helperText={instructionsUploadFile}
          required={false}
          multiple={isUploadMultiple}
          accept={acceptUploadFile}
          selectedFiles={selectedFiles}
          isUploadingFile={isUploadingFile}
          readOnly={isUploadedFiles || isUploadingFile}
          onFileChange={(files: any) => handleFileChange(files)}
        />
      </ConfirmDialogUploadFile>
    </>
  );
};

export default Documents;
