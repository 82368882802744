/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProjectList, useDispatch, useAccountSelected } from '../../context';
import {
  LIST_PROJECTS_COLUMNS,
  TITLE_PROJECT_TABLE,
  DATE_FORMAT,
  ROUTER,
  DEFAULT_PROJECT_STATUS_FILTER,
  PERMISSIONS,
  UNKNOWN_ERROR_CODE,
} from '../../constant';
import { Projects } from '../../projects-api-client/Projects';
import queryString from 'query-string';
import { Types } from '../../constant/action-type';
import {
  convertTime,
  getPerPageTable,
  handleErrorStatus,
  getValueOption,
  getSelectOptionAndArrayStringOption,
  handleErrorLogMessage,
} from '../../utils';
import StatusColor from '../../components/common/status-color';
import { ParametersSearchI } from '../account-page';
import { StatusDto } from '../../projects-api-client/data-contracts';
import { Settings } from '../../projects-api-client/Settings';
import { CommonActionBar, Datatable, FormReactSelect } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { Button, Row, Col } from 'react-bootstrap';
import { usePermissions } from '../../context/auth-context';
import useAccessibility from '../../utils/useAccessibility';
import { useToast } from '../../context/toast-context';

// eslint-disable-next-line prettier/prettier
function ListProject() { //NOSONAR
  const perPageTable = getPerPageTable();
  const accountSelected = useAccountSelected();
  const location = useLocation();
  const project = useProjectList();
  const { addAriaLabelToTable } = useAccessibility();
  const { permissionList } = usePermissions();
  const canViewDetails = permissionList.includes(PERMISSIONS.view_project_details);

  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '', status, ragStatus } = paramFromUrl; //NOSONAR
  const renderSort = (sortByFromUrl: string | (string | null)[] | null) => {
    if (sortByFromUrl !== '') {
      const sortBySplit = (sortByFromUrl as string).split(':');
      const sortField = sortBySplit[0];
      const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
      return { sortField, sortOrder };
    } else return { sortField: '', sortOrder: 1 };
  };
  const [showFilter, setShowFilter] = useState(false);
  const projectStatusOptions = getSelectOptionAndArrayStringOption(status);
  const ragStatusOptions = getSelectOptionAndArrayStringOption(ragStatus);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState<any>(
    projectStatusOptions.selectOption.length > 0
      ? projectStatusOptions.selectOption
      : [
          { value: DEFAULT_PROJECT_STATUS_FILTER.IN_FLIGHT, label: DEFAULT_PROJECT_STATUS_FILTER.IN_FLIGHT },
          { value: DEFAULT_PROJECT_STATUS_FILTER.ON_HOLD, label: DEFAULT_PROJECT_STATUS_FILTER.ON_HOLD },
        ]
  );
  const [selectedRAGStatus, setSelectedRAGStatus] = useState<any>(ragStatusOptions.selectOption || []);
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);
  const [isFilter, setIsFilter] = useState(false);
  const [listOptions, setListOptions] = useState<any>({
    projectStatus:
      projectStatusOptions.arrayStringOption.length > 0
        ? projectStatusOptions.arrayStringOption
        : [DEFAULT_PROJECT_STATUS_FILTER.IN_FLIGHT, DEFAULT_PROJECT_STATUS_FILTER.ON_HOLD],
    RAGStatus: ragStatusOptions.arrayStringOption.length > 0 ? ragStatusOptions.arrayStringOption : [],
  });
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: renderSort(sortBy).sortField || 'goLiveDate',
    sortOrder: renderSort(sortBy).sortOrder || 1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    status:
      projectStatusOptions.arrayStringOption.length > 0
        ? projectStatusOptions.arrayStringOption
        : [DEFAULT_PROJECT_STATUS_FILTER.IN_FLIGHT, DEFAULT_PROJECT_STATUS_FILTER.ON_HOLD],
    ragStatus: ragStatusOptions.arrayStringOption || [],
    accountIds: [],
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [keyDataTable, setKeyDataTable] = useState('');

  const projectApi = new Projects();

  const projectSettingApi = new Settings();

  const dispatch = useDispatch();

  let navigate = useNavigate();

  const toast = useToast();

  const getProjectAndRagStatus = async () => {
    let newListOption: any = {
      projectStatus: [],
      RAGStatus: [],
    };
    await projectSettingApi
      .getProjectStatuses()
      .then((res) => {
        if (res?.data?.length) {
          const newListOptions = res.data.map((option: StatusDto) => {
            return {
              value: option.displayValue,
              label: option.displayValue,
            };
          });
          newListOption = {
            ...newListOption,
            projectStatus: newListOptions,
          };
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
      });

    await projectSettingApi
      .getRagStatuses()
      .then((res) => {
        if (res?.data?.length) {
          /* eslint-disable-next-line prettier/prettier */
          const newListOptions = res.data.map((option: StatusDto) => getValueOption(option.displayValue));
          newListOption = {
            ...newListOption,
            RAGStatus: newListOptions,
          };
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
      });
    setListOptions(newListOption);
  };

  const listProjectCol = LIST_PROJECTS_COLUMNS.map((acc) => {
    if (acc.field === TITLE_PROJECT_TABLE.PROJECT_NAME)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        className: 'max-width-none',
        style: { minWidth: '168px' },
        body: (data: any) => (
          <>
            <span>{data.projectName}</span>
          </>
        ),
      };

    if (acc.field === TITLE_PROJECT_TABLE.TASK_CLIENT_ACTION_REQUIRED_COUNT)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '238px', minWidth: '238px' },
        body: (data: any) => data.taskClientActionRequiredCount,
      };
    if (acc.field === TITLE_PROJECT_TABLE.PROJECT_STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '172px', minWidth: '172px' },
        body: (data: any) => data.status,
      };

    if (acc.field === TITLE_PROJECT_TABLE.TERRITORY)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '130px', minWidth: '130px' },
        body: (data: any) => data.territory,
      };
    if (acc.field === TITLE_PROJECT_TABLE.GO_LIVE_DATE)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        style: { maxWidth: '165px', minWidth: '165px' },
        sortable: true,
        body: (data: any) => convertTime(data.goLiveDate, DATE_FORMAT),
      };

    if (acc.field === TITLE_PROJECT_TABLE.PROJECT_MANAGER)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        className: 'max-width-none',
        style: { minWidth: '190px' },
        body: (data: any) => data.pmName,
      };
    if (acc.field === TITLE_PROJECT_TABLE.RAG_STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '155px', minWidth: '155px' },
        body: (data: any) => (
          <>
            <StatusColor status={data.ragStatus} />
          </>
        ),
      };
    return {
      resizeable: true,
      field: acc.field,
      label: acc.header,
      className: 'max-width-none',
      sortable: true,
    };
  });

  const fetchData = (param?: any) => {
    setIsLoadingTable(true);
    let paramSubmit: any = {};
    if (param) {
      const {
        rows,
        page: pageParam,
        sortField,
        sortOrder,
        searchKey,
        status: statusParam,
        ragStatus: ragStatusParam,
        first,
      } = param;
      if (statusParam?.length > 0 || ragStatusParam?.length > 0) {
        setIsFilter(true);
      } else {
        setIsFilter(false);
      }
      localStorage.setItem('perPageTable', rows);
      const sortByParam = `${sortField}:${sortOrder === 1 ? 'asc' : 'desc'}`;
      paramSubmit = {
        limit: Number(rows) || 0,
        page: Number(pageParam) || 0,
        search: searchKey,
        sortBy: sortByParam,
        status: statusParam,
        ragStatus: ragStatusParam,
        accountIds: [accountSelected?.id || ''],
      };
      setParametersSearch({
        rows,
        page: pageParam,
        sortField,
        sortOrder,
        searchKey,
        status: statusParam,
        ragStatus: ragStatusParam,
        first,
      });
      updateUrlSearch(paramSubmit);
    }
    projectApi
      .getProjects(paramSubmit)
      .then((res) => {
        const listProject = res.data;
        dispatch({
          type: Types.GET_PROJECT,
          payload: { projects: listProject },
        });
        setIsLoadingTable(false);
      })
      .catch((error) => {
        setIsLoadingTable(false);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
      });
  };
  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    sessionStorage.setItem('urlSearch', urlSearch);
    navigate(`?${urlSearch}`);
  };

  const onSelectRow = (event: any) => {
    navigate(`${ROUTER.MANAGEMENT_PROJECT_DETAIL_OVERVIEW.replace(':id', event.data.id)}`);
  };
  const onReset = () => {
    onFilterSidebar({
      status: [],
      ragStatus: [],
    });
    setSelectedProjectStatus([]);
    setSelectedRAGStatus([]);
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };
  useEffect(() => {
    getProjectAndRagStatus();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [project]);

  const callBackFetchData = useCallback(
    (params: any) => {
      fetchData(params);
      setKeyDataTable(accountSelected?.id || '');
    },
    [accountSelected]
  );
  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);
  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );
  const renderFilterText = () => {
    let filterText = [
      selectedProjectStatus.length > 0 ? 'Project Status' : undefined,
      selectedRAGStatus.length > 0 ? ' RAG Status' : undefined,
    ];
    return filterText.filter((item) => item).join();
  };

  useEffect(() => {
    if (!showFilter) {
      setSelectedProjectStatus(projectStatusOptions.selectOption || []);
      setSelectedRAGStatus(ragStatusOptions.selectOption || []);
    }
  }, [showFilter, location]);

  return (
    <>
      <CommonActionBar pageTitle="Projects" isVisibleButton={false} breadcrumbItems={[]} />
      <div className="page-content">
        {isFilter && (
          <h4 className="mb-3 datatable-title">{`Projects (${
            project?.pageInfo?.total
          }) - Filtered by: ${renderFilterText()}`}</h4>
        )}
        <Datatable
          key={keyDataTable}
          fetchData={callBackFetchData}
          totalRecords={project?.pageInfo?.total || 0}
          data={project?.items || []}
          isLoading={isLoadingTable}
          {...(canViewDetails && { onRowSelect: onSelectRow })}
          columns={listProjectCol}
          tableName="projects"
          emptyMessage="No results found."
          initParams={parametersSearch}
          columnResizeMode="fit"
          sortField={parametersSearch.sortField}
          searchKey={parametersSearch.searchKey}
          isFilter
          isHideShowingAllLabel={isFilter}
          onClickFilterButton={onClickFilterButton}
          scrollable
        />
      </div>
      <RightSidebar
        title="Filters"
        setShowRightSidebar={(value) => {
          setShowFilter(value);
        }}
        showRightSidebar={showFilter}
      >
        <div className="rightsidebar-content-height">
          <FormReactSelect
            value={selectedProjectStatus}
            onChange={setSelectedProjectStatus}
            name="formStatus"
            label="Project Status"
            placeholder="Select Form Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.projectStatus}
            isMulti
          />
          <FormReactSelect
            value={selectedRAGStatus}
            onChange={setSelectedRAGStatus}
            name="formStatus"
            label="RAG Status"
            placeholder="Select Form Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions.RAGStatus}
            isMulti
          />
          <Row>
            <Col sm={6}>
              <Button
                className="w-100 justify-content-center"
                type="button"
                onClick={() => onReset()}
                variant="outline-primary"
              >
                Reset
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                type="button"
                className="w-100 justify-content-center"
                onClick={() => {
                  setShowFilter(false);
                  onFilterSidebar({
                    status: selectedProjectStatus.map((field: any) => field.value),
                    ragStatus: selectedRAGStatus.map((projectItem: any) => projectItem.value),
                  });
                }}
              >
                Apply filter
              </Button>
            </Col>
          </Row>
        </div>
      </RightSidebar>
    </>
  );
}

export default ListProject;
