import React, { useEffect, useState } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useProjectDetail, useTaskCount } from '../../context';
import { DATE_TIME_FORMAT, ROUTER, UNKNOWN_ERROR_CODE } from '../../constant';
import { Projects } from '../../projects-api-client/Projects';
import { Types } from '../../constant/action-type';
import LayoutDetail from '../../components/layout-detail';
import { OverView, ActionTracker, Milestones, IssueTracker, ActionTasks } from '../../components/project-detail';
import { convertTimeUTC, handleErrorLogMessage, handleErrorStatus } from '../../utils';
import DisplayPendingRTD from './display-pending-RTD';
import { TabsStyled } from '../../components/StyledComponent';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { useToast } from '../../context/toast-context';

function Detail() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [pathname, setPathName] = useState('');
  const [isPreviousPageHaveSearch, setIsPreviousPageHaveSearch] = useState(false);
  const activeKey = location.pathname.split('/')?.[4];
  let params = useParams();
  const project = useProjectDetail();
  const taskCount = useTaskCount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = params && params.id ? params.id : '';

  const projectApi = new Projects();

  const toast = useToast();

  useEffect(() => {
    if (location?.pathname) {
      setPathName(location?.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.search) {
      setIsPreviousPageHaveSearch(true);
      return;
    }
    setIsPreviousPageHaveSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const projectDetailTabs = [
    { key: 'overview', title: 'Overview', component: <OverView project={project} taskCount={taskCount} /> },
    // { key: 'gantt-chart', title: 'Gantt Chart', component: <GanttChart /> },
    { key: 'action-tracker', title: 'Key Actions', component: <ActionTracker project={project} /> },
    { key: 'milestones', title: 'Milestones', component: <Milestones projectId={project?.id || ''} /> },
    { key: 'action-tasks', title: 'Task List', component: <ActionTasks projectId={project?.id || ''} /> },
    { key: 'issue-tracker', title: 'Issue Tracker', component: <IssueTracker projectId={project?.id || ''} /> },
  ];

  const breadcrumbItems = [
    { path: `${ROUTER.MANAGEMENT_PROJECT}`, name: 'Projects', active: false },
    { path: '', name: `${project?.projectName}`, active: true },
  ];

  const LastUpdateRender = () => {
    if (project?.modifiedAt) return convertTimeUTC(project?.modifiedAt, DATE_TIME_FORMAT);
    return convertTimeUTC(project?.createdAt, DATE_TIME_FORMAT);
  };

  const onChangePath = (path: string) => {
    navigate(path);
  };

  const getProjectDetail = async (projectId: string) => {
    try {
      await projectApi
        .getProject(projectId)
        .then((res) => {
          dispatch({
            type: Types.GET_PROJECT_DETAIL,
            payload: {
              project: res.data,
            },
          });
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          navigate(ROUTER.ERROR_PAGE);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
    setIsLoading(false);
  };
  const getTasksRequired = async (projectId: string) => {
    try {
      await projectApi
        .getTaskCount(projectId)
        .then((res: any) => {
          dispatch({
            type: Types.GET_TASK_COUNT,
            payload: {
              taskCount: res.data,
            },
          });
        })
        .catch((error: any) => {
          handleErrorStatus(error.status);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
  };
  useEffect(() => {
    getProjectDetail(id);
    getTasksRequired(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {project?.publishedToPortal ? (
        <LayoutDetail
          breadcrumbItems={breadcrumbItems}
          pageTitle={`${project?.projectName}: View Details`}
          onChangePathBreadCrumb={(path: string) => onChangePath(path)}
          isLoading={isLoading}
          layoutWidth="100%"
        >
          <Card.Body>
            <>
              <TabsStyled
                fill
                variant="pills"
                justify
                className="bg-nav-pills tab-card"
                activeKey={activeKey}
                onSelect={(selectedKey: string) => {
                  setPathName(`${location?.pathname}`);
                  navigate(`/management/projects/${id}/${selectedKey}`);
                }}
              >
                {projectDetailTabs.map((tab, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                  </Nav.Item>
                ))}
              </TabsStyled>
              {projectDetailTabs.find((tab) => location.pathname.includes(tab.key))?.component}
              <Card.Text className="text-end mb-0 account-create">
                <small>Last updated on: {LastUpdateRender()}</small>
              </Card.Text>
            </>
          </Card.Body>
          <div className="form px-3">
            <div className="form-action d-flex flex-row align-items-center justify-content-between">
              <Button
                type="button"
                variant="outline-primary"
                onClick={() => {
                  setPathName(location?.pathname);
                  isPreviousPageHaveSearch ? navigate(pathname) : navigate(-1);
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </LayoutDetail>
      ) : (
        <DisplayPendingRTD isLoading={isLoading} name={project?.projectName} />
      )}
    </>
  );
}

export default Detail;
