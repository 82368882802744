import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { MODAL_TYPE } from '../../constant';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import styled from 'styled-components';
import { PreTitle, ModalFooterStyled, ModalHeadertyled } from '../StyledComponent';
import classNames from 'classnames';

export const SpinnerStyled = styled.div`
  display: flex;
  margin: 1.8px 0;
  justify-content: center;
  .avatar-sm {
    height: 18px;
    width: 18px;
  }
`;

export interface PopupModalProps {
  title?: string;
  modalType?: string;
  confirmText?: string;
  cancelText?: string;
  onCancel: () => void;
  onConfirm?: () => any | undefined;
  isShowModal: boolean;
  isRemoveConfirm?: boolean;
  isRemoveCancel?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
  isDisableConfirm?: boolean;
}

const ModalPortal = (props: PopupModalProps) => {
  const {
    title,
    confirmText,
    isLoading,
    isRemoveConfirm,
    isRemoveCancel,
    isDisableConfirm,
    cancelText,
    onCancel,
    onConfirm,
    isShowModal,
    modalType,
    children,
    className,
  } = props;

  const handleCancel = () => {
    if (isLoading) return;
    onCancel();
  };

  const renderTitleDefault = () => {
    let titleDefault = '';
    switch (modalType) {
      case MODAL_TYPE.WARNING:
        titleDefault = 'Warning';
        break;
      case MODAL_TYPE.CONFIRM:
        titleDefault = 'Confirmation';
        break;
      case MODAL_TYPE.SUCCESS:
        titleDefault = 'Success';
        break;
      default:
        break;
    }
    return titleDefault;
  };

  return (
    <Modal className={className} show={isShowModal} onHide={handleCancel}>
      <ModalHeadertyled className={classNames('modal-mb-2', 'bg-' + className)}>
        <Modal.Title className="m-0">{title || renderTitleDefault()}</Modal.Title>
      </ModalHeadertyled>
      <Modal.Body>
        <hr />
        <PreTitle>{children}</PreTitle>
      </Modal.Body>
      <ModalFooterStyled className="d-flex justify-content-between flex-row-reverse">
        {!isRemoveConfirm && (
          <Button
            onClick={onConfirm}
            // style={isDisableConfirm ? disableButtonStyle : buttonStyle}
            className={`${isDisableConfirm || isLoading ? 'disable-button-style' : 'button-style'}`}
          >
            {isLoading ? (
              <SpinnerStyled>
                <Spinner size="sm" color="#ced4da" />
              </SpinnerStyled>
            ) : (
              confirmText || 'Confirm'
            )}
          </Button>
        )}
        {!isRemoveCancel && (
          <Button
            variant={modalType === MODAL_TYPE.SUCCESS ? 'success' : 'light'}
            onClick={handleCancel}
            className={`${isLoading} ? 'disable-button-style' : ''`}
          >
            {cancelText || 'Cancel'}
          </Button>
        )}
      </ModalFooterStyled>
    </Modal>
  );
};

export default ModalPortal;
