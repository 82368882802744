/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ErrorDto,
  FormTemplateConfigurationDto,
  FormTemplateDto,
  FormTemplatePageDto,
  FormTemplateStateDto,
  FormTemplateVersionDto,
  FormTemplateVersionRequestDto,
  MapperDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FormTemplates<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Form Template List
   *
   * @tags formTemplates
   * @name GetFormTemplates
   * @summary Form Template List
   * @request GET:/formTemplates
   * @secure
   */
  getFormTemplates = (
    query?: {
      search?: string;
      /** @example "name:asc,state:desc,publishedDate:asc,status:desc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<FormTemplatePageDto, any>({
      path: `/formTemplates`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Form Template By Id
   *
   * @tags formTemplates
   * @name GetFormTemplate
   * @summary Form Template Detail
   * @request GET:/formTemplates/{id}
   * @secure
   */
  getFormTemplate = (
    id: string,
    query?: {
      /** identifier */
      includes?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<FormTemplateDto, ErrorDto>({
      path: `/formTemplates/${id}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Latest Form Template
   *
   * @tags formTemplates
   * @name GetLatestFormTemplate
   * @summary Latest Form Template
   * @request GET:/formTemplates/{id}/latest
   * @secure
   */
  getLatestFormTemplate = (id: string, params: RequestParams = {}) =>
    this.request<FormTemplateVersionDto, ErrorDto>({
      path: `/formTemplates/${id}/latest`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Latest Form Template
   *
   * @tags formTemplates
   * @name GetFormTemplateByVersions
   * @summary Latest Form Template
   * @request GET:/formTemplates/{id}/versions
   * @secure
   */
  getFormTemplateByVersions = (
    id: string,
    query?: {
      search?: string;
      /** @example "name:asc,state:desc,publishedDate:asc,status:desc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<FormTemplatePageDto, any>({
      path: `/formTemplates/${id}/versions`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Add From Template Version
   *
   * @tags templates, new version
   * @name AddNewTemplateVersion
   * @summary From Template Version
   * @request POST:/formTemplates/{id}/versions
   * @secure
   */
  addNewTemplateVersion = (id: string, data: FormTemplateVersionRequestDto, params: RequestParams = {}) =>
    this.request<FormTemplateVersionDto, ErrorDto>({
      path: `/formTemplates/${id}/versions`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Form Template Configuration
   *
   * @tags configurations
   * @name GetConfiguration
   * @summary Form Template Configuration
   * @request GET:/formTemplates/{id}/configuration
   * @secure
   */
  getConfiguration = (id: string, params: RequestParams = {}) =>
    this.request<FormTemplateConfigurationDto, ErrorDto>({
      path: `/formTemplates/${id}/configuration`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Update Form Template Configuration
   *
   * @tags form-template-configuration
   * @name UpdateFormTemplateConfiguration
   * @summary Update Form template configuration
   * @request PUT:/formTemplates/{id}/configuration
   * @secure
   */
  updateFormTemplateConfiguration = (id: string, data: FormTemplateConfigurationDto, params: RequestParams = {}) =>
    this.request<FormTemplateConfigurationDto, ErrorDto>({
      path: `/formTemplates/${id}/configuration`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Form Template Configuration
   *
   * @tags templates, state
   * @name GetFormTemplateState
   * @summary Form Template State
   * @request GET:/formTemplates/{id}/state
   * @secure
   */
  getFormTemplateState = (id: string, params: RequestParams = {}) =>
    this.request<FormTemplateStateDto, ErrorDto>({
      path: `/formTemplates/${id}/state`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Form Template State
   *
   * @tags templates, state
   * @name PutFormTemplateState
   * @summary From Template State
   * @request PUT:/formTemplates/{id}/state
   * @secure
   */
  putFormTemplateState = (id: string, data: FormTemplateStateDto, params: RequestParams = {}) =>
    this.request<FormTemplateStateDto, ErrorDto>({
      path: `/formTemplates/${id}/state`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Form Template Version By Version
   *
   * @tags formTemplates
   * @name GetFormTemplateVersionByVersion
   * @summary Get Form Template Version By Version
   * @request GET:/formTemplates/{id}/versions/{version}
   * @secure
   */
  getFormTemplateVersionByVersion = (id: string, version: number, params: RequestParams = {}) =>
    this.request<FormTemplateVersionDto, ErrorDto>({
      path: `/formTemplates/${id}/versions/${version}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Form Template Mapper
   *
   * @tags formMapper
   * @name GetFormMapper
   * @summary Form Template Mapper
   * @request GET:/formTemplates/{id}/versions/{version}/mapper
   * @secure
   */
  getFormMapper = (id: string, version: number, params: RequestParams = {}) =>
    this.request<MapperDto, ErrorDto>({
      path: `/formTemplates/${id}/versions/${version}/mapper`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
