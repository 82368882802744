/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorDto, UserAction, UserDto, UserPageDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description User List
   *
   * @tags users
   * @name GetUsers
   * @summary User List
   * @request GET:/users
   */
  getUsers = (
    query?: {
      search?: string;
      accountId?: string;
      role?: string[];
      status?: string[];
      emailStatus?: string[];
      type?: string[];
      sortBy?: string;
      page?: number;
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<UserPageDto, any>({
      path: `/users`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Get User By Id
   *
   * @tags user, detail
   * @name GetUserDetail
   * @summary User Detail
   * @request GET:/users/{id}
   */
  getUserDetail = (id: string, params: RequestParams = {}) =>
    this.request<UserDto, ErrorDto>({
      path: `/users/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Change User Status
   *
   * @tags users
   * @name UpdateUserStatus
   * @summary User Status Changed
   * @request POST:/users/{id}/actions
   */
  updateUserStatus = (id: string, data: { action?: UserAction }, params: RequestParams = {}) =>
    this.request<UserDto, ErrorDto>({
      path: `/users/${id}/actions`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Resend Verification Email
   *
   * @tags users
   * @name ResendVerificationEmail
   * @summary Resend Verification Email
   * @request POST:/users/{id}/resendVerificationEmail
   */
  resendVerificationEmail = (id: string, params: RequestParams = {}) =>
    this.request<void, ErrorDto>({
      path: `/users/${id}/resendVerificationEmail`,
      method: 'POST',
      ...params,
    });
}
