import React, { useState, useCallback, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountSelected } from '../../context';
import {
  LIST_USER_COLUMNS,
  TITLE_USER_TABLE,
  ROUTER,
  USER_STATUS_ACTION,
  USER_STATUS,
  USER_ROLE,
  ROLE_TYPE,
  ACTION_USER,
  PERMISSIONS,
  ERROR_MESSAGE_VIEW_CLIENT_PERMISSION,
  UNKNOWN_ERROR_CODE,
} from '../../constant';
import { Users as UsersApi } from '../../user-api-client/Users';
import queryString from 'query-string';
import {
  getPerPageTable,
  getSelectOptionAndArrayStringOption,
  getActionUserStatusText,
  handleErrorLogMessage,
} from '../../utils';
import { ButtonInfoStyled, ErrorMessageAccountSelected, SpinnerInButtonStyled } from '../../components/StyledComponent';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { ParametersSearchI } from '../account-page';
import Tooltip from '../../components/common/tooltip';
import { useAuthInfo, usePermissions } from '../../context/auth-context';
import { get, upperFirst, lowerCase } from 'lodash';
import { useToast } from '../../context/toast-context';
import { CommonActionBar, Datatable, FormReactSelect } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { UserAction } from '../../user-api-client/data-contracts';
import isEmpty from 'lodash/isEmpty';
import useAccessibility from '../../utils/useAccessibility';

function User() {
  const perPageTable = getPerPageTable();
  const accountSelected = useAccountSelected();
  const auth = useAuthInfo();
  const userRole = get(auth, 'role', '');
  const location = useLocation();
  const paramFromUrl = queryString.parse(location.search);
  const { addAriaLabelToTable } = useAccessibility();
  const { permissionList } = usePermissions();
  const canViewDetails = permissionList.includes(PERMISSIONS.view_client_user_details);
  const canActivateUser = permissionList.includes(PERMISSIONS.activate_deactivate_user);
  const { search, limit, page, sortBy = '', status } = paramFromUrl; //NOSONAR
  const renderSort = (sortByFromUrl: string | (string | null)[] | null) => {
    if (sortByFromUrl !== '') {
      const sortBySplit = (sortByFromUrl as string).split(':');
      const sortField = sortBySplit[0];
      const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
      return { sortField, sortOrder };
    } else return { sortField: '', sortOrder: 1 };
  };
  const statusOptions = getSelectOptionAndArrayStringOption(status);
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: renderSort(sortBy).sortField || 'name',
    sortOrder: renderSort(sortBy).sortOrder || 1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    status: statusOptions.arrayStringOption || [],
    accountId: '',
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [selectedUserStatus, setSelectedUserStatus] = useState<any>(
    statusOptions.selectOption.length > 0 ? statusOptions.selectOption : []
  );
  const [user, setUser] = useState<any>({});
  const [isFilter, setIsFilter] = useState(false);
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [newUser, setNewUser] = useState<any>({});
  const listOptions = {
    role: USER_ROLE,
    status: USER_STATUS,
  };
  const [idAction, setIdAction] = useState<string>('');
  const [keyDataTable, setKeyDataTable] = useState('');
  const [isAccountSelectedError, setIsAccountSelectedError] = useState(false);

  const userApi = new UsersApi();

  const toast = useToast();

  let navigate = useNavigate();

  const handleAction = async (statusAction: string, id: string) => {
    setIdAction(id);
    if (statusAction === ACTION_USER.ACTIVE || statusAction === ACTION_USER.INACTIVE) {
      const newStatus = statusAction === ACTION_USER.ACTIVE ? UserAction.DEACTIVATE : UserAction.ACTIVATE;
      await userApi
        .updateUserStatus(id, { action: newStatus })
        .then((response) => {
          const data = response?.data;
          setNewUser(data);
          toast({
            message: `User has been ${statusAction === ACTION_USER.ACTIVE ? 'deactivated' : 'activated'} successfully!`,
            type: 'success',
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    }
    if (statusAction === ACTION_USER.LOCKED) {
      await userApi
        .updateUserStatus(id, { action: UserAction.UNLOCKED })
        .then((response) => {
          const data = response?.data;
          setNewUser(data);
          toast({
            message: `User has been unlocked successfully!`,
            type: 'success',
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    }
    if (statusAction === ACTION_USER.UNCONFIRMED) {
      await userApi
        .resendVerificationEmail(id)
        .then(() => {
          toast({
            message: `Verification email has been sent successfully!`,
            type: 'success',
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    }
    setIdAction('');
  };

  useEffect(() => {
    if (isEmpty(newUser)) return;
    const newListUser = user?.items?.map((item: any) => {
      if (item?.id === newUser?.id) return newUser;
      return item;
    });
    const newUsers = {
      ...user,
      items: newListUser,
    };
    setUser(newUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser]);

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [user]);

  const listUserCol = LIST_USER_COLUMNS.map((acc) => {
    if (acc.field === TITLE_USER_TABLE.USER_NAME)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        className: 'max-width-none',
        body: (data: any) => (
          <>
            <Tooltip data={data?.name} id={`name-${data?.id}`} />
          </>
        ),
      };
    if (acc.field === TITLE_USER_TABLE.EMAIL)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        className: 'max-width-none',
        body: (data: any) => (
          <>
            <Tooltip data={`${data?.email}`} id={`email-${data?.id}`} />
          </>
        ),
      };

    if (acc.field === TITLE_USER_TABLE.STATUS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: true,
        style: { maxWidth: '200px', minWidth: '200px' },
        body: (data: any) => (
          <>
            <Tooltip data={upperFirst(lowerCase(data?.status))} id={`status-${data?.id}`} />
          </>
        ),
      };
    if (acc.field === TITLE_USER_TABLE.ACTIONS)
      return {
        resizeable: true,
        field: acc.field,
        label: acc.header,
        sortable: false,
        style: { maxWidth: '200px', minWidth: '200px' },
        body: (data: any) => (
          <>
            <div className="d-flex justify-content-start">
              {canActivateUser && USER_STATUS_ACTION?.includes(data?.status) && (
                <ButtonInfoStyled
                  className="display-hover open-modal-update-files-nodal"
                  style={{ fontSize: '14px', height: '24px' }}
                  onClick={() => handleAction(data?.status, data?.id)}
                  disabled={data?.id === idAction}
                >
                  {data?.id === idAction ? (
                    <SpinnerInButtonStyled>
                      <Spinner size="sm" />
                    </SpinnerInButtonStyled>
                  ) : (
                    getActionUserStatusText(data?.status)
                  )}
                </ButtonInfoStyled>
              )}
            </div>
          </>
        ),
      };

    return {
      resizeable: true,
      field: acc.field,
      label: acc.header,
      sortable: true,
      className: 'max-width-none',
    };
  });
  const fetchData = (param?: any) => {
    setIsLoadingTable(true);
    let paramSubmit: any = {};
    if (!accountSelected?.id) {
      return;
    }
    if (param) {
      const { rows, page: pageParam, sortField, sortOrder, searchKey, status: statusParam, first } = param;
      if (statusParam?.length > 0) {
        setIsFilter(true);
      } else {
        setIsFilter(false);
      }
      localStorage.setItem('perPageTable', rows);
      const sortByParam = `${sortField}:${sortOrder === 1 ? 'asc' : 'desc'}`;
      paramSubmit = {
        limit: Number(rows) || 0,
        page: Number(pageParam) || 0,
        search: searchKey,
        sortBy: sortByParam,
        status: statusParam,
        type: userRole === ROLE_TYPE.THG ? ['THG_USER'] : ['THG_CLIENT'],
        accountId: accountSelected?.id || '',
      };
      setParametersSearch({ rows, page: pageParam, sortField, sortOrder, searchKey, status: statusParam, first });
      updateUrlSearch(paramSubmit);
    }

    userApi
      .getUsers(paramSubmit)
      .then((res) => {
        const listUser = res.data;
        setUser(listUser);
        setIsLoadingTable(false);
      })
      .catch((error) => {
        setIsLoadingTable(false);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
      });
  };

  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    sessionStorage.setItem('urlSearch', urlSearch);
    navigate(`?${urlSearch}`);
  };

  const onSelectRow = (event: any) => {
    if (canViewDetails) {
      navigate(`${ROUTER.CONFIGURATION_USER_DETAIL.replace(':id', event.data.id)}`);
    }
  };

  const callBackFetchData = useCallback(
    (params: any) => {
      fetchData(params);
      setKeyDataTable(accountSelected?.id || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountSelected]
  );

  const getStatusFromUrl = () => {
    let newSelectedUserStatus: any = [];
    USER_STATUS.forEach((userStatus) => {
      if (status?.includes(userStatus.value))
        newSelectedUserStatus.push({ value: userStatus.value, label: userStatus.label });
    });
    setSelectedUserStatus(newSelectedUserStatus);
  };

  useEffect(() => {
    getStatusFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeoutAccount = setTimeout(() => {
      const accountSelectedFromLocal = localStorage.getItem('accountSelected');
      if (!accountSelectedFromLocal) setIsAccountSelectedError(true);
    }, 3000);
    return () => clearTimeout(timeoutAccount);
  }, []);

  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);

  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );

  const onReset = () => {
    onFilterSidebar({
      status: [],
    });
    setSelectedUserStatus([]);
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };

  const renderFilterText = () => {
    let filterText = [selectedUserStatus.length > 0 ? 'Status' : undefined];
    return filterText.filter((item) => item).join();
  };

  return (
    <>
      {isAccountSelectedError ? (
        <ErrorMessageAccountSelected>{ERROR_MESSAGE_VIEW_CLIENT_PERMISSION}</ErrorMessageAccountSelected>
      ) : (
        <>
          <CommonActionBar pageTitle="Users" isVisibleButton={false} breadcrumbItems={[]} />
          <div className="page-content">
            {isFilter && (
              <h4 className="mb-3 datatable-title">{`Users (${
                user?.pageInfo?.total
              }) - Filtered by: ${renderFilterText()}`}</h4>
            )}
            <Datatable
              key={keyDataTable}
              fetchData={callBackFetchData}
              totalRecords={user?.pageInfo?.total || 0}
              data={user?.items || []}
              isLoading={isLoadingTable}
              onRowSelect={onSelectRow}
              columns={listUserCol}
              tableName="users"
              emptyMessage="No results found."
              initParams={parametersSearch}
              columnResizeMode="fit"
              sortField={parametersSearch.sortField}
              searchKey={parametersSearch.searchKey}
              isFilter
              isHideShowingAllLabel={isFilter}
              onClickFilterButton={onClickFilterButton}
              scrollable
            />
          </div>
          <RightSidebar
            title="Filters"
            setShowRightSidebar={(value) => {
              if (!value) {
                if (status) {
                  getStatusFromUrl();
                } else setSelectedUserStatus([]);
              }
              setShowFilter(value);
            }}
            showRightSidebar={showFilter}
          >
            <div className="rightsidebar-content-height">
              <FormReactSelect
                value={selectedUserStatus}
                onChange={setSelectedUserStatus}
                name="userStatus"
                label="Status"
                placeholder="Select Status"
                labelContainerClass="mb-1"
                labelGrid={12}
                containerClass="mb-3 fit-width-label-container"
                options={listOptions.status}
                isMulti
              />
              <Row>
                <Col sm={6}>
                  <Button
                    className="w-100 justify-content-center"
                    type="button"
                    onClick={() => onReset()}
                    variant="outline-primary"
                  >
                    Reset
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    type="button"
                    className="w-100 justify-content-center"
                    onClick={() => {
                      setShowFilter(false);
                      onFilterSidebar({
                        status: selectedUserStatus.map((field: any) => field.value),
                      });
                    }}
                  >
                    Apply filter
                  </Button>
                </Col>
              </Row>
            </div>
          </RightSidebar>
        </>
      )}
    </>
  );
}

export default User;
