import { Types } from '../constant/action-type';

type ModalOnboardVideoMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type ModalOnboardVideoType = {
  isOpen: boolean | null;
};

type ModalOnboardVideoPayload = {
  [Types.SET_MODAL_ONBOARD_VIDEO_STYLE]: {
    isOpen: boolean;
  };
};

export type ModalOnboardVideoActions =
  ModalOnboardVideoMap<ModalOnboardVideoPayload>[keyof ModalOnboardVideoMap<ModalOnboardVideoPayload>];

export const modalOnboardVideoReducer = (state: ModalOnboardVideoType, action: ModalOnboardVideoActions) => {
  if (action.type === Types.SET_MODAL_ONBOARD_VIDEO_STYLE) {
    const isOpen = action.payload.isOpen || null;
    return { isOpen };
  }
  return state;
};
