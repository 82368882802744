import { ROUTER, PERMISSIONS, ROLE_TYPE } from '../constant';
import { Icon } from '@thg-harveynash/hyper-shared-components';
import { getRole } from '../utils';
export interface RouterProps {
  key: string;
  label: string;
  isTitle: boolean;
  iconElement: any;
  url?: string;
  permission?: string;
  children?: Array<{
    key: string;
    label: string;
    url: string;
    parentKey: string;
    permission?: string;
  }>;
}

export interface ChilProps {
  key: string;
  label: string;
  url: string;
  parentKey: string;
  permission?: string;
}

export type MenuItem = {
  key: string;
  label: string;
  isTitle?: boolean;
  iconElement?: JSX.Element;
  permissions?: any[];
  parentKey?: string;
  url?: string;
  children?: MenuItem[];
  roles?: string[];
};

export const MENU_ITEMS: MenuItem[] = [
  {
    key: 'management',
    label: 'Management',
    isTitle: false,
    iconElement: <Icon name="dripicons-suitcase" />,
    permissions: [
      PERMISSIONS.view_project_list,
      PERMISSIONS.view_client_form_list,
      PERMISSIONS.view_thg_form_list,
      PERMISSIONS.view_document_list,
    ],
    children: [
      {
        key: 'management-project',
        label: 'Projects',
        url: ROUTER.MANAGEMENT_PROJECT,
        parentKey: 'management',
        roles: [ROLE_TYPE.CLIENT],
        permissions: [
          PERMISSIONS.view_project_list,
          PERMISSIONS.view_client_form_list,
          PERMISSIONS.view_client_form_details,
          PERMISSIONS.view_thg_form_list,
          PERMISSIONS.view_thg_form_details,
          PERMISSIONS.approve_thg_form,
        ],
      },
      {
        key: 'management-forms',
        label: 'Forms',
        url: ROUTER.MANAGEMENT_FORMS,
        parentKey: 'management',
        roles: [ROLE_TYPE.THG, ROLE_TYPE.CLIENT, ROLE_TYPE.INITIAL_ACCESS],
        permissions: [
          { per: PERMISSIONS.view_client_form_list, role: ROLE_TYPE.CLIENT },
          { per: PERMISSIONS.view_thg_form_list, role: ROLE_TYPE.THG },
          { per: PERMISSIONS.view_thg_form_details, role: ROLE_TYPE.THG },
          { per: PERMISSIONS.approve_thg_form, role: ROLE_TYPE.THG },
          { per: PERMISSIONS.initial_access, role: ROLE_TYPE.CLIENT },
          { per: PERMISSIONS.initial_access, role: ROLE_TYPE.INITIAL_ACCESS },
        ],
      },
      {
        key: 'management-documents',
        label: 'Documents',
        url: ROUTER.MANAGEMENT_PRELOAD_DOCUMENTS,
        parentKey: 'management',
        roles: [ROLE_TYPE.CLIENT],
        permissions: [PERMISSIONS.view_document_list],
      },
    ],
  },
  {
    key: 'configuration',
    label: 'Configuration',
    isTitle: false,
    iconElement: <Icon name="dripicons-gear" />,
    permissions: [
      PERMISSIONS.view_account_list,
      PERMISSIONS.view_site_list_of_account,
      PERMISSIONS.view_site_list,
      PERMISSIONS.view_site_details,
      PERMISSIONS.view_thg_form_list,
      PERMISSIONS.view_thg_form_details,
      PERMISSIONS.approve_thg_form,
      PERMISSIONS.view_form_template_list,
      PERMISSIONS.view_client_user_list,
    ],
    children: [
      {
        key: 'configuration-account',
        label: 'Accounts',
        url: ROUTER.CONFIGURATION_ACCOUNT,
        parentKey: 'configuration',
        roles: [ROLE_TYPE.THG],
        permissions: [
          PERMISSIONS.view_account_list,
          PERMISSIONS.view_site_list_of_account,
          PERMISSIONS.view_site_list,
          PERMISSIONS.view_site_details,
          PERMISSIONS.view_thg_form_list,
          PERMISSIONS.view_thg_form_details,
          PERMISSIONS.approve_thg_form,
        ],
      },
      {
        key: 'configuration-site-list',
        label: 'Sites',
        url: ROUTER.CONFIGURATION_SITE_LIST,
        parentKey: 'configuration',
        roles: [ROLE_TYPE.THG],
        permissions: [PERMISSIONS.view_site_list],
      },
      {
        key: 'configuration-form',
        label: 'Form Templates',
        url: ROUTER.CONFIGURATION_FORM,
        parentKey: 'configuration',
        roles: [ROLE_TYPE.THG],
        permissions: [PERMISSIONS.view_form_template_list],
      },
      // {
      //   key: 'configuration-tgh-user',
      //   label: 'THG Users',
      //   url: ROUTER.CONFIGURATION_THG_USER,
      //   parentKey: 'configuration',
      //   roles: [ROLE_TYPE.THG],
      //   permissions: [PERMISSIONS.view_thg_form_list],
      // },
      {
        key: 'configuration-client-user',
        label: 'Users',
        url: ROUTER.CONFIGURATION_USER,
        parentKey: 'configuration',
        roles: [ROLE_TYPE.CLIENT],
        permissions: [PERMISSIONS.view_client_user_list],
      },
    ],
  },
];

export const getMenuItemByPermission = (permissionList: string[]) => {
  const role = getRole();
  const childrenItem = [
    ...MENU_ITEMS?.filter((menuItem: MenuItem) =>
      menuItem?.permissions?.some((permissionItem: string) => permissionList?.includes(permissionItem))
    ),
  ];

  //non permission
  const homePage = {
    key: 'home',
    label: 'Home',
    isTitle: false,
    iconElement: <Icon name="dripicons-home" />,
    url: ROUTER.HOME_PAGE,
  };

  const menuPermissions = childrenItem?.map((cItem: MenuItem) => {
    if (cItem?.children) {
      cItem.children = cItem.children?.filter((cPermission: MenuItem) => {
        const perms = cPermission?.permissions;
        const roles = cPermission?.roles;
        if (cPermission?.key === 'management-forms') {
          return perms?.some((per: any) => permissionList?.includes(per.per) && per.role === role);
        }
        return perms?.some((per: string) => permissionList?.includes(per)) && roles?.includes(role);
      });
    }
    return cItem;
  });

  const menuItems = menuPermissions?.filter((menu) => Array.isArray(menu.children) && menu.children.length > 0);

  return [homePage, ...menuItems];
};
