import React from 'react';
import { Modal } from 'react-bootstrap';
import LeftTabPanel from '../left-tab-panel';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { useKeyCloak } from '../../context/auth-context';
import { useNavigate } from 'react-router';
import { userInstance } from '../../services/instance';

type ModalTermOfUseProps = {
  isShowing: boolean;
  setShowTerms: (isShowTerms: boolean) => void;
  setShowVideo: (isShowVideo: boolean) => void;
};

const ModalTermOfUse = ({ isShowing, setShowTerms, setShowVideo }: ModalTermOfUseProps) => {
  const navigate = useNavigate();
  const keyCloak = useKeyCloak();

  const handleDecline = () => {
    navigate('/');
    localStorage.clear();
    keyCloak?.logout();
  };

  const handleAgree = async () => {
    const userId = localStorage.getItem('userId');
    await userInstance.put(`users/${userId}/termOfUseAgreementStatus`, {
      status: true,
    });
    localStorage.setItem('termsOfUseAgreementStatus', 'true');
    setShowTerms(false);
    setShowVideo(true);
  };

  return (
    <Modal show={isShowing} centered size="lg" className="modal-term-of-use-container">
      <Modal.Header style={{ borderBottom: '1px solid #ccc' }}>
        <Modal.Title>PORTAL TERMS OF USE</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-scroll">
        <LeftTabPanel />
      </Modal.Body>
      <Modal.Footer style={{ borderTop: '1px solid #ccc' }}>
        <div className="d-flex align-items-center" style={{ gap: '2.5rem' }}>
          <div className="reject-btn" onClick={handleDecline}>
            Decline
          </div>
          <Button onClick={handleAgree}>Agree and Continue</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTermOfUse;
