import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  FORM_PAGE_COLUMNS_THG,
  TITLE_FORM_PAGE_TABLE_THG,
  FORM_PAGE_COLUMNS_CLIENT,
  TITLE_FORM_PAGE_TABLE_CLIENT,
  FORM_STATUS,
  DATE_FORMAT,
  ROLE_TYPE,
  FORM_STATUS_OPTION,
  PERMISSIONS,
  ERROR_MESSAGE_VIEW_CLIENT_PERMISSION,
  UNKNOWN_ERROR_CODE,
} from '../../constant';
import {
  getPerPageTable,
  convertTime,
  getSelectOptionAndArrayStringOption,
  getRole,
  handleErrorLogMessage,
} from '../../utils';
import { ParametersSearchI } from '../account-page';
import queryString from 'query-string';
import { FormPageDto } from '../../form-api-client/data-contracts';
import Tooltip from '../../components/common/tooltip';
import { get } from 'lodash';
import { Forms } from '../../form-api-client/Forms';
import { Projects } from '../../projects-api-client/Projects';
import { useAccountSelected } from '../../context';
import { useHelpCenter } from '../../context/help-center-context';
import { Button, Row, Col } from 'react-bootstrap';
import { CommonActionBar, FormReactSelect, FormInputContainer } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar, Datatable } from '@thg-harveynash/cp-hyper-ui-comps';
import { useToast } from '../../context/toast-context';
import AsyncSelect from 'react-select/async';
import { usePermissions } from '../../context/auth-context';
import { ClearIndicator } from '../../components/common/ClearIndicator';
import { ErrorMessageAccountSelected } from '../../components/StyledComponent';
import { Filters } from '../../accounts-api-client/Filters';

const BreadcrumbStyled = styled.div`
  .breadcrumb-container {
    justify-content: space-between !important;
  }
`;

export interface ICol {
  field: string;
  header: string;
}

// eslint-disable-next-line prettier/prettier
function FormPage() {
  //NOSONAR
  const location = useLocation();
  const toast = useToast();
  const role = getRole();
  const perPageTable = getPerPageTable();
  const defaultSortField = role === ROLE_TYPE.THG ? 'accountName' : 'projectName';
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '', states, projectIds, accountIds } = paramFromUrl; //NOSONAR
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0] || defaultSortField;
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
  const newOption = getSelectOptionAndArrayStringOption(states, FORM_STATUS_OPTION);
  const newProjectIds = getSelectOptionAndArrayStringOption(projectIds);
  const newAccountIds = getSelectOptionAndArrayStringOption(accountIds);
  const formStatusOption: never[] = [];
  const formStatusValue: never[] = [];
  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField,
    sortOrder: sortBySplit[1] ? sortOrder : 1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    states: newOption.arrayStringOption.length > 0 ? newOption.arrayStringOption : formStatusValue,
    projectIds: newProjectIds.arrayStringOption || [],
    accountIds: newAccountIds.arrayStringOption || [],
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [formData, setFormData] = useState<FormPageDto>({});
  const [formPageColumns, setFormPageColumns] = useState<any>();
  const [titleFormPageTable, setTitleFormPageTable] = useState<any>();
  const [isFilter, setIsFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(
    newOption.selectOption.length > 0 ? newOption.selectOption : formStatusOption
  );
  const [selectedAccountIds, setSelectedAccountIds] = useState<any>([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState<any>([]);
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);
  const [keyDataTable, setKeyDataTable] = useState<string>('');
  const navigate = useNavigate();
  const accountSelected = useAccountSelected();
  const helpCenterControl = useHelpCenter();
  const { permissionList } = usePermissions();
  const canViewDetails =
    (role === ROLE_TYPE.THG && permissionList.includes(PERMISSIONS.view_thg_form_details)) ||
    (role === ROLE_TYPE.THG && permissionList.includes(PERMISSIONS.approve_thg_form)) ||
    (role === ROLE_TYPE.CLIENT && permissionList.includes(PERMISSIONS.view_client_form_details)) ||
    (role === ROLE_TYPE.CLIENT && permissionList.includes(PERMISSIONS.edit_client_form_details));

  const onlyInitialAccess = permissionList.length === 1 && permissionList.includes(PERMISSIONS.initial_access);

  const [isAccountSelectedError, setIsAccountSelectedError] = useState(false);
  const [isFetchingProject, setIsFetchingProject] = useState(false);
  const [isFetchingAccount, setisFetchingAccount] = useState(false);

  const formApi = new Forms();

  const projectApi = new Projects();

  const accountFilterApi = new Filters();

  const getTableData = async (params: any) => {
    setIsLoadingTable(true);
    const {
      rows,
      page: pageParam,
      sortField: sortFieldParam,
      sortOrder: sortOrderParam,
      searchKey,
      first,
      accountIds: accountIdsParam,
      states: statesParam,
      projectIds: projectIdsParam,
    } = params;
    if (role === ROLE_TYPE.THG) {
      if (statesParam?.length > 0 || projectIdsParam?.length > 0 || accountIdsParam?.length > 0) {
        setIsFilter(true);
      } else {
        setIsFilter(false);
      }
    }
    localStorage.setItem('perPageTable', rows);
    if (role === ROLE_TYPE.CLIENT) {
      if (statesParam?.length > 0 || projectIdsParam?.length > 0) {
        setIsFilter(true);
      } else {
        setIsFilter(false);
      }
    }
    const newParam = accountIdsParam?.length
      ? {
          rows,
          page: pageParam,
          sortField: sortFieldParam,
          sortOrder: sortOrderParam,
          searchKey,
          first,
          states: statesParam,
          projectIds: projectIdsParam,
          accountIds: accountIdsParam,
        }
      : {
          rows,
          page: pageParam,
          sortField: sortFieldParam,
          sortOrder: sortOrderParam,
          searchKey,
          states: statesParam,
          projectIds: projectIdsParam,
          first,
        };
    setParametersSearch(newParam);
    const sortByParam = `${sortFieldParam}:${sortOrderParam === 1 ? 'asc' : 'desc'}`;
    const paramSubmit = {
      search: searchKey,
      limit: rows,
      page: pageParam,
      sortBy: sortByParam,
      accountIds: accountIdsParam,
      states: statesParam,
      projectIds: projectIdsParam,
    };
    updateUrlSearch(paramSubmit);
    onlyInitialAccess
      ? await formApi
          .getInitialForms(paramSubmit)
          .then((res) => {
            setFormData(res.data);
            setIsLoadingTable(false);
          })
          .catch((error) => {
            handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
            setIsLoadingTable(false);
          })
      : await formApi
          .getForms(paramSubmit)
          .then((res) => {
            setFormData(res.data);
            setIsLoadingTable(false);
          })
          .catch((error) => {
            handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_FORM_C_UNKNOWN, toast);
            setIsLoadingTable(false);
          });
  };
  const fetchData = useCallback((params: any) => {
    getTableData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountFromUrl = async (arrayStringOption: string[]) => {
    if (arrayStringOption.length === 0) {
      return;
    }
    setisFetchingAccount(true);
    let query = {
      ids: arrayStringOption,
    };
    await accountFilterApi
      .getMiniAccounts(query)
      .then((res) => {
        if (res?.data?.items && res?.data?.items.length) {
          setSelectedAccountIds(res.data.items.map((item) => ({ value: item.id, label: item.name })));
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
      });
    setisFetchingAccount(false);
  };
  const getProjectFromUrl = async (arrayStringOption: string[]) => {
    if (arrayStringOption.length === 0) {
      return;
    }
    setIsFetchingProject(true);
    let query = {
      ids: arrayStringOption,
    };
    await projectApi
      .getProjects(query)
      .then((res) => {
        if (res?.data?.items && res?.data?.items.length) {
          setSelectedProjectIds(res.data.items.map((item) => ({ value: item.id, label: item.projectName })));
        }
      })
      .catch((error) => {
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_PROJECT_UNKNOWN, toast);
      });
    setIsFetchingProject(false);
  };

  const getStatusFromUrl = () => {
    if (!states) {
      return;
    }
    let newStatus = typeof states === 'string' ? [states] : states;
    let newListStatusOption: any = [];
    FORM_STATUS_OPTION.forEach((status) => {
      if (newStatus?.includes(status.value)) {
        newListStatusOption.push({
          value: status.value,
          label: status.label,
        });
      }
    });
    setSelectedOption(newListStatusOption);
  };

  useEffect(() => {
    if (location?.search) {
      getProjectFromUrl(newProjectIds?.arrayStringOption);
      getAccountFromUrl(newAccountIds?.arrayStringOption);
      getStatusFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.search) {
      getStatusFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter]);

  useEffect(() => {
    if (role === ROLE_TYPE.THG) {
      setFormPageColumns(FORM_PAGE_COLUMNS_THG);
      setTitleFormPageTable(TITLE_FORM_PAGE_TABLE_THG);
    }
    if (role === ROLE_TYPE.CLIENT && accountSelected) {
      setFormPageColumns(FORM_PAGE_COLUMNS_CLIENT);
      setTitleFormPageTable(TITLE_FORM_PAGE_TABLE_CLIENT);
      const accountIdsData = accountSelected.id ? [accountSelected.id] : [];
      const newParamSearch = {
        ...parametersSearch,
        accountIds: accountIdsData,
      };
      fetchData(newParamSearch);
      setKeyDataTable(accountSelected?.id || '');
    }
    if (permissionList.includes(PERMISSIONS.initial_access)) {
      setFormPageColumns(FORM_PAGE_COLUMNS_CLIENT);
      setTitleFormPageTable(TITLE_FORM_PAGE_TABLE_CLIENT);
      const accountIdsData = accountSelected?.id ? [accountSelected?.id] : [];
      const newParamSearch = {
        ...parametersSearch,
        accountIds: accountIdsData,
      };
      fetchData(newParamSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected]);
  const listSitesCol = formPageColumns?.map((site: ICol) => {
    switch (site.field) {
      case titleFormPageTable.NAME:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.title} id={`title-${data?.id}`} />,
          className: 'max-width-none',
          style: { minWidth: '150px' },
        };
      case titleFormPageTable.PROJECT:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.projectName} id={`projectName-${data?.id}`} />,
          className: 'max-width-none',
          style: { minWidth: '140px' },
        };
      case titleFormPageTable.UPDATE_BY:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => <Tooltip data={data?.modifiedBy} id={`modifiedBy-${data?.id}`} />,
          className: 'max-width-none',
          style: { minWidth: '200px' },
        };
      case titleFormPageTable.UPDATE_DATE:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => (
            <Tooltip data={convertTime(data?.modifiedAt, DATE_FORMAT) || ''} id={`modifiedAt-${data?.id}`} />
          ),
          style: { minWidth: '210px', maxWidth: '210px' },
        };
      case titleFormPageTable.STATUS:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          body: (data: any) => (
            <Tooltip lastColumn={true} data={get(FORM_STATUS, data?.state, '')} id={`state-${data?.id}`} />
          ),
          style: { maxWidth: '180px', minWidth: '180px' },
        };
      default:
        return {
          resizeable: true,
          field: site.field,
          label: site.header,
          sortable: true,
          className: 'max-width-none',
          style: { minWidth: '180px' },
        };
    }
  });

  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    navigate(`?${urlSearch}`);
  };

  const onRowSelect = (e: any) => {
    navigate(`/management/forms/${e.data.id}`);
  };

  const handleShowHelpCenter = () => {
    helpCenterControl({
      isOpen: true,
      formInformation: {
        isShow: true,
        activeIndex: [0, 1],
      },
      onboardingProcessInformation: {
        isShow: false,
        activeIndex: [],
      },
      isShowButtonAllHelpTopic: true,
    });
  };

  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);

  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );

  const onReset = () => {
    setSelectedOption([]);
    setSelectedProjectIds([]);
    setSelectedAccountIds([]);
    onFilterSidebar({
      states: [],
      projectIds: [],
      accountIds: role === ROLE_TYPE.THG ? [] : [accountSelected?.id || ''],
    });
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };
  const loadAccountOptions = (inputValue: string, callback: (options: any) => void) => {
    let params = {
      name: inputValue,
      limit: 25,
    };
    accountFilterApi.getMiniAccounts(params).then((response: any) => {
      const optionsItem: any = [];
      response.data.items?.forEach((account: any) => {
        optionsItem.push({
          value: account.id,
          label: account.name,
        });
      });
      setTimeout(() => {
        callback(optionsItem);
      }, 1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const loadProjectOptions = (inputValue: string, callback: (options: any) => void) => {
    let params = {
      search: inputValue,
      limit: 25,
      sortBy: 'projectName:asc',
      accountIds:
        role === ROLE_TYPE.THG ? selectedAccountIds.map((account: any) => account.value) : [accountSelected?.id || ''],
    };
    if (params?.accountIds?.length > 0) {
      projectApi.getProjects(params).then((response) => {
        const optionsItem: any = [];
        response.data.items?.forEach((project) => {
          optionsItem.push({
            value: project.id,
            label: project.projectName,
          });
        });
        setTimeout(() => {
          const projectsDependAccounts = selectedProjectIds?.filter((project: any) =>
            optionsItem?.some((projectList: any) => projectList?.value === project?.value)
          );
          setSelectedProjectIds(projectsDependAccounts);
          callback(optionsItem);
        }, 1000);
      });
    } else {
      callback([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const renderFilterText = () => {
    let filterText = [
      selectedAccountIds.length > 0 && role === ROLE_TYPE.THG ? 'Account' : undefined,
      selectedProjectIds.length > 0 ? ' Project' : '',
      selectedOption.length > 0 ? ' Form Status' : '',
    ];
    return filterText.filter((item) => item).join();
  };

  useEffect(() => {
    let timeoutAccount = setTimeout(() => {
      const accountSelectedFromLocal = localStorage.getItem('accountSelected');
      if (!accountSelectedFromLocal && role === ROLE_TYPE.CLIENT && !onlyInitialAccess) setIsAccountSelectedError(true);
    }, 3000);
    return () => clearTimeout(timeoutAccount);
  }, []);

  const isVisibleButtonActionbar = role === ROLE_TYPE.CLIENT ? true : false;

  return (
    <>
      {isAccountSelectedError ? (
        <ErrorMessageAccountSelected>{ERROR_MESSAGE_VIEW_CLIENT_PERMISSION}</ErrorMessageAccountSelected>
      ) : (
        <>
          <BreadcrumbStyled>
            <CommonActionBar
              wrapperClassName="breadcrumb-container"
              pageTitle="Forms"
              buttonLabel="Help"
              buttonVariant="outline-primary"
              isVisibleButton={isVisibleButtonActionbar}
              breadcrumbItems={[]}
              onClick={() => handleShowHelpCenter()}
              iconPrefix={{
                name: 'dripicons-question',
                style: {
                  marginRight: 12,
                },
              }}
            />
          </BreadcrumbStyled>
          {formPageColumns && (
            <div>
              <div className="page-content">
                {isFilter && (
                  <h4 className="mb-3 datatable-title">{`Forms (${
                    formData?.pageInfo?.total || 0
                  }) - Filtered by: ${renderFilterText()}`}</h4>
                )}
                <Datatable
                  key={keyDataTable}
                  fetchData={fetchData}
                  totalRecords={formData?.pageInfo?.total || 0}
                  data={formData?.items || []}
                  isLoading={isLoadingTable}
                  isDisabledFilter={isLoadingTable || isFetchingAccount || isFetchingProject}
                  {...((canViewDetails || onlyInitialAccess) && { onRowSelect: onRowSelect })}
                  columns={listSitesCol}
                  tableName="forms"
                  emptyMessage="No results found."
                  initParams={parametersSearch}
                  columnResizeMode="fit"
                  sortField={parametersSearch.sortField}
                  searchKey={parametersSearch.searchKey}
                  isFilter={true}
                  isHideShowingAllLabel={isFilter}
                  onClickFilterButton={onClickFilterButton}
                  scrollable
                />
              </div>
              <RightSidebar
                title="Filters"
                setShowRightSidebar={(value) => {
                  if (!value) {
                    if (projectIds) {
                      getProjectFromUrl(newProjectIds?.arrayStringOption);
                    } else setSelectedProjectIds([]);
                    if (accountIds) {
                      getAccountFromUrl(newAccountIds?.arrayStringOption);
                    } else setSelectedAccountIds([]);
                    setSelectedOption(newOption.selectOption);
                  }
                  setShowFilter(value);
                }}
                showRightSidebar={showFilter}
              >
                <div className="rightsidebar-content-height">
                  {role === ROLE_TYPE.THG && (
                    <FormInputContainer containerClass="form-react-select mb-3" labelGrid={12} label="Account">
                      <AsyncSelect
                        isMulti
                        id="select-account"
                        inputId="select-account-input"
                        instanceId="account"
                        value={selectedAccountIds}
                        onChange={setSelectedAccountIds}
                        cacheOptions={true}
                        defaultOptions={true}
                        loadOptions={loadAccountOptions}
                        placeholder="Select Account"
                        classNamePrefix="react-select"
                        components={{ ClearIndicator }}
                        className="form_group_control"
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 100000 }) }}
                        aria-label="select-account"
                      />
                    </FormInputContainer>
                  )}
                  <FormInputContainer containerClass="form-react-select mb-3" labelGrid={12} label="Project">
                    <AsyncSelect
                      key={selectedAccountIds}
                      isMulti
                      id="select-project"
                      inputId="select-project-input"
                      instanceId="project"
                      value={selectedProjectIds}
                      onChange={setSelectedProjectIds}
                      cacheOptions={true}
                      defaultOptions={true}
                      loadOptions={loadProjectOptions}
                      placeholder="Select Project"
                      classNamePrefix="react-select"
                      components={{ ClearIndicator }}
                      className="form_group_control"
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 100000 }) }}
                      aria-label="select-project"
                    />
                  </FormInputContainer>
                  <FormReactSelect
                    value={selectedOption}
                    onChange={setSelectedOption}
                    name="formStatus"
                    label="Form Status"
                    placeholder="Select Form Status"
                    labelContainerClass="mb-1"
                    labelGrid={12}
                    containerClass="mb-3 fit-width-label-container"
                    options={FORM_STATUS_OPTION}
                    isMulti
                  />
                  <Row>
                    <Col sm={6}>
                      <Button
                        className="w-100 justify-content-center"
                        type="button"
                        onClick={() => onReset()}
                        variant="outline-primary"
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        type="button"
                        className="w-100 justify-content-center"
                        onClick={() => {
                          setShowFilter(false);
                          onFilterSidebar({
                            states: selectedOption.map((field: any) => field.value),
                            projectIds: selectedProjectIds.map((project: any) => project.value),
                            accountIds:
                              role === ROLE_TYPE.THG
                                ? selectedAccountIds.map((account: any) => account.value)
                                : [[accountSelected?.id || '']],
                          });
                        }}
                      >
                        Apply filter
                      </Button>
                    </Col>
                  </Row>
                </div>
              </RightSidebar>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FormPage;
