/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DataTemplateDto,
  DataTemplateResponseDto,
  DownloadedZipFileProgressDto,
  ErrorDto,
  ExtFileDto,
  ExtFileInfoDto,
  UploadProgressDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class External<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Upload to External System
   *
   * @tags file, upload
   * @name UploadExt
   * @summary File
   * @request POST:/external/upload/{extSystemKey}/resources
   * @secure
   */
  uploadExt = (extSystemKey: string, data: File, params: RequestParams = {}) =>
    this.request<ExtFileDto, ErrorDto>({
      path: `/external/upload/${extSystemKey}/resources`,
      method: 'POST',
      body: data,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get file info by resourceId
   *
   * @tags file, upload
   * @name GetFileInfoByExt
   * @summary File
   * @request GET:/external/fileInfo/{extSystemKey}/resources/{extId}
   * @secure
   */
  getFileInfoByExt = (extSystemKey: string, extId: string, params: RequestParams = {}) =>
    this.request<ExtFileInfoDto, ErrorDto>({
      path: `/external/fileInfo/${extSystemKey}/resources/${extId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get upload progress of file uploaded
   *
   * @tags Upload Progress
   * @name GetUploadProgress
   * @summary Upload Progress
   * @request GET:/external/upload/{extSystemKey}/progress/{uploadId}
   * @secure
   */
  getUploadProgress = (extSystemKey: string, uploadId: string, params: RequestParams = {}) =>
    this.request<UploadProgressDto, ErrorDto>({
      path: `/external/upload/${extSystemKey}/progress/${uploadId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Create data template to associate the required files and folders with them
   *
   * @tags Data Template
   * @name CreateDataTemplate
   * @summary Create Data template
   * @request POST:/external/{extSystemKey}/dataTemplate
   * @secure
   */
  createDataTemplate = (extSystemKey: string, data: DataTemplateDto, params: RequestParams = {}) =>
    this.request<DataTemplateResponseDto, ErrorDto>({
      path: `/external/${extSystemKey}/dataTemplate`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Data Template
   *
   * @tags data template
   * @name GetDataTemplate
   * @summary Get Data Template
   * @request GET:/external/{extSystemKey}/dataTemplate/{id}
   * @secure
   */
  getDataTemplate = (extSystemKey: string, id: string, params: RequestParams = {}) =>
    this.request<DataTemplateResponseDto, ErrorDto>({
      path: `/external/${extSystemKey}/dataTemplate/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Data Template
   *
   * @tags data template
   * @name RenameDataTemplate
   * @summary Rename Data Template
   * @request PATCH:/external/{extSystemKey}/dataTemplate/{id}
   * @secure
   */
  renameDataTemplate = (extSystemKey: string, id: string, data: DataTemplateDto, params: RequestParams = {}) =>
    this.request<DataTemplateResponseDto, ErrorDto>({
      path: `/external/${extSystemKey}/dataTemplate/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Get download link of zip file
   *
   * @tags file, download
   * @name GetDownloadLinkOfZipFile
   * @summary File
   * @request GET:/external/downloadMultiple/{extSystemKey}/zipFileProgress
   * @secure
   */
  getDownloadLinkOfZipFile = (
    extSystemKey: string,
    query: {
      resourceIds: string[];
      /** Key to track progress of created zip on WorkDrive */
      wmsKey?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<DownloadedZipFileProgressDto, ErrorDto>({
      path: `/external/downloadMultiple/${extSystemKey}/zipFileProgress`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Download zip file
   *
   * @tags file, download
   * @name DownloadZipFile
   * @summary File
   * @request GET:/external/downloadMultiple/{extSystemKey}/zipFile
   * @secure
   */
  downloadZipFile = (
    extSystemKey: string,
    query: {
      /** download link to forward form CP Backend to Workdrive */
      downloadLink: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, ErrorDto>({
      path: `/external/downloadMultiple/${extSystemKey}/zipFile`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
}
