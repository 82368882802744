import { Column } from 'primereact/column';
import { LIST_TASK_COLUMNS, TITLE_TASK_TABLE, DATE_FORMAT } from '../../../constant';
import { convertTime } from '../../../utils';
import Tooltip from '../../../components/common/tooltip';
import StatusColor from '../../../components/common/status-color';

export const ActionTaskCol = (_paddingLevel: number) => {
  return LIST_TASK_COLUMNS.map((acc) => {
    if (acc.field === TITLE_TASK_TABLE.TASK_NAME)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          className="max-width-none"
          style={{ minWidth: '150px' }}
          body={({ name, id }) => (
            <div>
              <Tooltip data={name} id={`name-${id}`} />
            </div>
          )}
        />
      );
    if (acc.field === TITLE_TASK_TABLE.TASK_LIST)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          className="max-width-none"
          style={{ minWidth: '160px' }}
          body={({ taskListName, id }) => <Tooltip data={taskListName} id={`taskList-${id}`} />}
        />
      );
    if (acc.field === TITLE_TASK_TABLE.RESPONSIBLE_PARTY)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          style={{ maxWidth: '160px', minWidth: '160px' }}
          body={({ responsibleParty, id }) => <Tooltip data={responsibleParty} id={`responsibleParty-${id}`} />}
        />
      );
    if (acc.field === TITLE_TASK_TABLE.TASK_STATUS)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          style={{ maxWidth: '210px', minWidth: '210px' }}
          body={({ status, id }) => <Tooltip data={status} id={`status-${id}`} />}
        />
      );
    if (acc.field === TITLE_TASK_TABLE.START_DATE)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          style={{ maxWidth: '145px', minWidth: '145px' }}
          body={({ startDate, id }) => (
            <Tooltip data={convertTime(startDate, DATE_FORMAT) || ''} id={`startDate-${id}`} />
          )}
        />
      );
    if (acc.field === TITLE_TASK_TABLE.DUE_DATE)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          sortable
          style={{ maxWidth: '145px', minWidth: '145px' }}
          body={({ endDate, id }) => <Tooltip data={convertTime(endDate, DATE_FORMAT) || ''} id={`endDate-${id}`} />}
        />
      );
    if (acc.field === TITLE_TASK_TABLE.RAG_STATUS)
      return (
        <Column
          key={acc.field}
          field={acc.field}
          header={acc.header}
          body={({ ragStatus }) => <StatusColor status={ragStatus} />}
          style={{ maxWidth: '125px', minWidth: '125px' }}
        />
      );

    return <Column key={acc.field} field={acc.field} header={acc.header} sortable className="max-width-none" />;
  });
};

export const ActionTaskColHyper = LIST_TASK_COLUMNS.map((acc) => {
  if (acc.field === TITLE_TASK_TABLE.TASK_NAME)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      sortable: true,
      className: 'max-width-none',
      style: { minWidth: '150px' },
      body: (data: any) => <Tooltip data={data?.name} id={`name-${data?.id}`} />,
    };

  if (acc.field === TITLE_TASK_TABLE.TASK_LIST)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      className: 'max-width-none',
      style: { minWidth: '160px' },
      body: (data: any) => <Tooltip data={data?.taskListName} id={`taskList-${data?.id}`} />,
    };
  if (acc.field === TITLE_TASK_TABLE.RESPONSIBLE_PARTY)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      sortable: true,
      style: { maxWidth: '160px', minWidth: '160px' },
      body: (data: any) => <Tooltip data={data?.responsibleParty} id={`responsibleParty-${data?.id}`} />,
    };
  if (acc.field === TITLE_TASK_TABLE.TASK_STATUS)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      sortable: true,
      style: { maxWidth: '210px', minWidth: '210px' },
      body: (data: any) => <Tooltip data={data?.status} id={`status-${data?.id}`} />,
    };
  if (acc.field === TITLE_TASK_TABLE.START_DATE)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      sortable: true,
      style: { maxWidth: '145px', minWidth: '145px' },
      body: (data: any) => (
        <Tooltip data={convertTime(data?.startDate, DATE_FORMAT) || ''} id={`startDate-${data?.id}`} />
      ),
    };
  if (acc.field === TITLE_TASK_TABLE.DUE_DATE)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      sortable: true,
      style: { maxWidth: '145px', minWidth: '145px' },
      body: (data: any) => <Tooltip data={convertTime(data?.endDate, DATE_FORMAT) || ''} id={`endDate-${data?.id}`} />,
    };
  if (acc.field === TITLE_TASK_TABLE.RAG_STATUS)
    return {
      key: acc.field,
      field: acc.field,
      label: acc.header,
      header: acc.header,
      style: { maxWidth: '125px', minWidth: '125px' },
      body: (data: any) => <StatusColor status={data?.ragStatus} />,
    };

  return {
    key: acc.field,
    field: acc.field,
    label: acc.header,
    header: acc.header,
    sortable: true,
    className: 'max-width-none',
  };
});
