import { camelToTitle } from '../utils/helpers';

const useAccessibility = () => {
  const dropdownTrigger = () => {
    document.querySelectorAll('.p-dropdown-trigger').forEach((el) => {
      el.setAttribute('aria-label', 'Dropdown Trigger');
    });
    document.querySelectorAll('.p-dropdown input').forEach((el) => {
      el.setAttribute('aria-label', 'Dropdown input');
    });
  };

  const dropdownItemsTrigger = () => {
    document.querySelectorAll('.p-dropdown-items').forEach((el) => {
      el.setAttribute('aria-label', 'Dropdown items');
    });
  };

  const inputText = () => {
    document.querySelectorAll('.p-inputtext').forEach((el) => {
      const parentId = el?.parentElement?.getAttribute('id');
      const isNotSpan = el.tagName !== 'SPAN';
      if (parentId && isNotSpan) {
        el.setAttribute('aria-label', camelToTitle(parentId));
      }
    });
  };

  const inputSwitch = () => {
    document.querySelectorAll('[role="switch"]').forEach((el) => {
      el.setAttribute('aria-label', 'Switch Button');
    });

    document.querySelectorAll('.p-inputswitch').forEach((el) => {
      el.removeAttribute('role');
      el.removeAttribute('aria-label');
      el.removeAttribute('aria-checked');
    });
  };

  const menubarRootList = () => {
    document.querySelectorAll('.p-menubar-root-list').forEach((el) => {
      el.removeAttribute('role');
    });
  };

  const hiddenInput = () => {
    document.querySelectorAll('.p-hidden-accessible input').forEach((el) => {
      el.setAttribute('aria-label', 'Hidden Input');
    });
  };

  const sortColumnButton = () => {
    document.querySelectorAll('.dropdown-customize-column-toggle').forEach((el) => {
      el.setAttribute('aria-label', 'Sort Column');
    });
  };

  const selectPlaceholder = () => {
    document.querySelectorAll('.p-placeholder').forEach((el) => {
      el.setAttribute('aria-disabled', 'true');
    });
  };

  const userTourButton = () => {
    document.querySelectorAll('[data-tour-elem="right-arrow"]').forEach((el) => {
      el.setAttribute('aria-label', 'Next');
    });
    document.querySelectorAll('[data-tour-elem="left-arrow"]').forEach((el) => {
      el.setAttribute('aria-label', 'Prev');
    });
    document.querySelectorAll('[data-tour-elem="dot"]').forEach((el) => {
      el.setAttribute('aria-label', 'Dot');
    });
  };

  const addAriaLabelToInput = () => {
    hiddenInput();
  };

  const addAriaLabelToForm = () => {
    dropdownTrigger();
    selectPlaceholder();
  };

  const addAriaLabelToFilter = () => {
    dropdownTrigger();
    inputText();
    inputSwitch();
  };

  const addAriaLabelToTable = () => {
    hiddenInput();
    sortColumnButton();
  };

  const addAriaLabelToSetting = () => {
    inputSwitch();
  };

  const addAriaLabelToHeader = () => {
    dropdownTrigger();
    menubarRootList();
  };

  return {
    addAriaLabelToDropdown: dropdownTrigger,
    addAriaLabelToDropdownItems: dropdownItemsTrigger,
    addAriaLabelToTable,
    addAriaLabelToForm,
    addAriaLabelToFilter,
    addAriaLabelToSetting,
    addAriaLabelToHeader,
    addAriaLabelToInput,
    addAriaLabelToUserTour: userTourButton,
  };
};

export default useAccessibility;
