import { Types } from '../constant/action-type';

type CheckinVideoMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type CheckinVideoType = {
  isOpen: boolean;
  isDisplay: boolean;
  firstTimeLoginAt?: string | null;
};

type CheckinVideoPayload = {
  [Types.SET_SHOW_CHECK_IN_VIDEO]: {
    isOpen: boolean;
  };
  [Types.SET_SHOW_DISPAY_DEPEND_CHECK_IN_VIDEO]: {
    isDispay: boolean;
  };
  [Types.SET_FIRST_TIME_LOGIN]: {
    firstTimeLoginAt: string | null;
  };
};

export type CheckinVideoActions = CheckinVideoMap<CheckinVideoPayload>[keyof CheckinVideoMap<CheckinVideoPayload>];

export const checkinVideoReducer = (state: CheckinVideoType, action: CheckinVideoActions) => {
  switch (action.type) {
    case Types.SET_SHOW_CHECK_IN_VIDEO:
      const isOpen = action.payload.isOpen;
      return { ...state, isOpen };
    case Types.SET_SHOW_DISPAY_DEPEND_CHECK_IN_VIDEO:
      const isDispay = action.payload.isDispay;
      return { ...state, isDispay };
    case Types.SET_FIRST_TIME_LOGIN:
      const firstTimeLoginAt = action.payload.firstTimeLoginAt;
      return { ...state, firstTimeLoginAt };
    default:
      return state;
  }
};
