import React from 'react';
import { CardGroup, Card, Row, Col, Badge } from 'react-bootstrap';
import { DATE_FORMAT, STATUS_COLOR, ROADMAP_DELIVERY_INTRUCTION } from '../../constant';
import { ProjectDto, TaskCount } from '../../projects-api-client/data-contracts';
import { checkDynamicLink, convertTime, getPerPageTable } from '../../utils';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CircleProgressBar from '../circle-progress';
import { LabelInforNoneMargin, LinkUnderline, DetailInforCustomSize } from '../StyledComponent';
import styled from 'styled-components';

interface OverViewProps {
  project: ProjectDto;
  taskCount: TaskCount;
}

const LinkText = styled.span`
  :hover {
    cursor: pointer;
  }

  color: var(--ct-link-color) !important;
`;

interface StatusColor {
  [key: string]: any;
}

function OverView(props: OverViewProps | any) {
  const navigate = useNavigate();
  const perPageTable = getPerPageTable();
  const { project, taskCount } = props;
  const statusColor: StatusColor = {
    'In Flight': '#198754',
    'On Hold': '#ffc107',
    Cancelled: '#dc3545',
    Completed: '#0d6efd',
  };

  const showTasks = () => {
    navigate(
      `/management/projects/${project.id}/action-tasks?limit=${perPageTable}&page=1&sortBy=startDate%3Adesc&statuses=Client%20Action%20Required`
    );
  };

  const statusRAGColor = get(STATUS_COLOR, project?.ragStatus?.toUpperCase(), '').slice(3);

  const roadMapDeliveryFolderDocument = `/management/documents/folder?projectId=${project?.siteId}&folderName=Roadmap%20to%20Delivery`;

  const textColor = project?.status === 'On Hold' ? 'text-black' : 'text-white';
  const textRagColor = project?.ragStatus === 'Amber' ? 'text-black' : 'text-white';

  const setToSiteDocument = () => {
    navigate(roadMapDeliveryFolderDocument);
  };

  return (
    <Card className="shadow-none">
      <CardGroup className="d-flex flex-row mb-3">
        <CircleProgressBar sqSize={80} percent={project?.percent} color="green" />
        <div className="mx-2">
          <h3>{project?.projectName}</h3>
          <CardGroup>
            {project?.status && (
              <Badge
                as="div"
                className={`project-detail-badge p-1 project-status ${textColor}`}
                ref={(el: any) =>
                  el && el.style.setProperty('background-color', statusColor?.[project?.status], 'important')
                }
              >
                {project?.status}
              </Badge>
            )}
            {project?.ragStatus && (
              <Badge
                bg={statusRAGColor}
                as="div"
                className={`project-detail-badge mx-2 p-1 project-rag-status ${textRagColor}`}
              >
                {project?.ragStatus}
              </Badge>
            )}
          </CardGroup>
        </div>
      </CardGroup>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Project Manager</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{project?.pmName}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Go Live Date</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{convertTime(project?.goLiveDate, DATE_FORMAT)}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Territory</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{project?.territory}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Current Milestone</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{project?.currentMilestone}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Roll Out Type</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{project?.rollOutType}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Start Date</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 account-id">
          <DetailInforCustomSize>{convertTime(project?.startDate, DATE_FORMAT)}</DetailInforCustomSize>
        </Col>
      </Row>
      <Row className="project-info detail-info">
        <Col lg={3} className="p-2">
          <LabelInforNoneMargin>Launch URL</LabelInforNoneMargin>
        </Col>
        <Col lg={9} className="p-2 parent-account">
          <DetailInforCustomSize>
            <LinkUnderline
              href={checkDynamicLink(project?.launchUrl)}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="parent-account"
              role="link"
              className="link-redirect"
            >
              {project?.launchUrl}
            </LinkUnderline>
          </DetailInforCustomSize>
        </Col>
      </Row>
      {project?.rtdWorkdriveFolderId && project?.siteId && (
        <>
          <Row className="project-info detail-info">
            <Col lg={12} className="p-2">
              <LabelInforNoneMargin>{ROADMAP_DELIVERY_INTRUCTION}</LabelInforNoneMargin>
            </Col>
          </Row>
          <Row className="project-info detail-info">
            <Col lg={3} className="p-2">
              <LabelInforNoneMargin>Project plan</LabelInforNoneMargin>
            </Col>
            <Col lg={9} className="p-2 parent-account">
              <DetailInforCustomSize>
                <LinkText onClick={() => setToSiteDocument()} aria-label="parent-account" className="link-redirect">
                  Roadmap to Delivery
                </LinkText>
              </DetailInforCustomSize>
            </Col>
          </Row>
        </>
      )}

      <Card className="d-flex flex-row shadow-none pointer">
        {taskCount && Object.keys(taskCount).length > 0 && taskCount['Client Action Required'] ? (
          <Card
            role="button"
            onClick={showTasks}
            className="w-40 rounded mt-3 px-2 py-1 tasks-required-action"
            style={{ backgroundColor: '#FFFF80' }}
          >
            <Card.Text className="h2 text-task">{taskCount['Client Action Required']}</Card.Text>
            <Card.Text className="h4 text-task">Tasks required action</Card.Text>
          </Card>
        ) : (
          <Card
            role="button"
            onClick={showTasks}
            className="w-40 rounded mt-3 px-2 py-1 tasks-required-action"
            style={{ backgroundColor: '#FFFF80' }}
          >
            <Card.Text className="h2 text-task">0</Card.Text>
            <Card.Text className="h4 text-task">Tasks required action</Card.Text>
          </Card>
        )}
      </Card>
    </Card>
  );
}

export default OverView;
