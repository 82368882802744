import React from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import TermOfUseData from './data.json';

const LeftTabPanel = () => {
  return (
    <Tab.Container defaultActiveKey="first">
      <Row>
        <Col sm={4}>
          <Nav variant="pills" className="flex-column">
            {TermOfUseData?.map((termOfUseTitle) => (
              <Nav.Item key={termOfUseTitle?.title}>
                <Nav.Link eventKey={termOfUseTitle?.key}>{termOfUseTitle?.title}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={8}>
          <Tab.Content className="left-tab-content">
            {TermOfUseData?.map((termOfUseItem) => (
              <Tab.Pane eventKey={termOfUseItem?.key} key={termOfUseItem?.key}>
                <div dangerouslySetInnerHTML={{ __html: termOfUseItem?.content }} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default LeftTabPanel;
