import React, { useState, useMemo, ReactNode, ReactElement, useEffect } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { FormInput } from '@thg-harveynash/hyper-shared-components';
import { ReactSortable } from 'react-sortablejs';
import classNames from 'classnames';
import { IColumn } from '../../Datatable/datatable.type';
import { FormInputType } from './types';

type TCustomizeColumnDropdownProps = {
  onCustomizeColumn: (value: IColumn[]) => void;
  columnsDisplay: IColumn[];
  defaultColumns: IColumn[];
};

type TInitColumns = IColumn & {
  id: string;
};

const parseLabelToString = (label: string | ReactNode): string => {
  if (typeof label === 'object') {
    const labelElement = label as ReactElement;
    if (labelElement?.props?.children) {
      const children = labelElement.props.children;
      if (Array.isArray(children)) return children.join('');
      return children;
    }
  }
  return label as string;
};

export default function CustomizeColumnDropdown({
  onCustomizeColumn,
  columnsDisplay,
  defaultColumns,
}: TCustomizeColumnDropdownProps) {
  const initDefaultColumns = useMemo(
    () => defaultColumns.map((col) => ({ ...col, id: col.field, hidden: col?.isHidden })),
    [defaultColumns]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [sortColumns, setSortColumns] = useState<TInitColumns[]>(initDefaultColumns);

  const isDisabledSelectAll = useMemo(
    () => sortColumns.filter(({ hidden }) => !hidden).length === defaultColumns.length,
    [defaultColumns, sortColumns]
  );

  useEffect(() => {
    if (!isOpen) setSortColumns(columnsDisplay.map((col) => ({ ...col, id: col.field })));
    const dropdown = document.getElementById('sort-column-drop-down');
    dropdown?.blur();
  }, [isOpen, initDefaultColumns, columnsDisplay]);

  return (
    <>
      <Dropdown
        show={isOpen}
        onToggle={setIsOpen}
        align="end"
        autoClose="outside"
        data-testid="dropdown-customize-column"
      >
        <Dropdown.Toggle
          variant="outline-primary"
          type="button"
          className={`dropdown-customize-column-toggle ${isOpen && 'dropdown-customize-column-toggle--focus'}`}
          data-testid="dropdown-customize-column-toggle"
          aria-label="Sort column"
          id="sort-column-drop-down"
        >
          <div className="icon-column">
            <i className="mdi mdi-view-column-outline me-0" />
          </div>
          <i className="mdi mdi-chevron-down me-0 icon-down" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-customize-column-menu" data-testid="dropdown-customize-column-menu">
          <Dropdown.Header className="dropdown-customize-column-header" aria-level={1}>
            <span>Customise columns</span>
            <div
              data-testid="dropdown-customize-column-button-toggle-all"
              onClick={() => {
                if (isDisabledSelectAll) return;
                setSortColumns([
                  ...sortColumns.map((col) => ({
                    ...col,
                    hidden: false,
                  })),
                ]);
              }}
              className={classNames('dropdown-customize-column-header-button', {
                'dropdown-customize-column-header-button--disabled': isDisabledSelectAll,
              })}
            >
              Select all
            </div>
          </Dropdown.Header>
          <ReactSortable list={sortColumns} setList={setSortColumns} className="dropdown-content" handle=".icon-drag">
            {sortColumns.map((col) => (
              <div className="dropdown-item" key={col.field}>
                <FormInput
                  label={parseLabelToString(col.label)}
                  type={FormInputType.CHECKBOX}
                  name={`checkbox-${col.field}`}
                  checked={!col.hidden}
                  onChange={(e) => {
                    setSortColumns([
                      ...sortColumns.map((c) => {
                        if (col.field === c.field) {
                          return {
                            ...c,
                            hidden: !e.target.checked,
                          };
                        }
                        return c;
                      }),
                    ]);
                  }}
                  labelGrid={12}
                />
                <i className="mdi mdi-view-headline me-0 icon-drag" />
              </div>
            ))}
          </ReactSortable>

          <div className="dropdown-footer">
            <Button
              data-testid="dropdown-customize-column-button-restore"
              variant="outline-primary"
              onClick={() => setSortColumns(initDefaultColumns)}
              className="dropdown-customize-column-footer-button button-restore"
            >
              Restore defaults
            </Button>
            <Button
              data-testid="dropdown-customize-column-button-save"
              variant="primary"
              onClick={() => {
                onCustomizeColumn(sortColumns);
                setIsOpen(false);
              }}
              className="dropdown-customize-column-footer-button"
            >
              Save
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
