import React, { useEffect, useState } from 'react';
import { Types } from '../constant/action-type';
import { useDispatch, useIsShowTour } from '../context';
import { TourStyle } from '../components/StyledComponent';
import { usePermissions } from '../context/auth-context';
import { PERMISSIONS } from '../constant';
import useAccessibility from '../utils/useAccessibility';

function UserTour() {
  const [currentStep, setCurrentStep] = useState(-1);
  const isShowTour = useIsShowTour();
  const dispatch = useDispatch();
  const { addAriaLabelToUserTour } = useAccessibility();
  const { permissionList } = usePermissions();
  const showStep1 = permissionList.includes(PERMISSIONS.view_project_list);
  const showStep2 = permissionList.includes(PERMISSIONS.view_client_form_list);
  const showStep3 = permissionList.includes(PERMISSIONS.view_document_list);
  const showStep4 = [
    PERMISSIONS.view_project_list,
    PERMISSIONS.view_client_form_list,
    PERMISSIONS.view_document_list,
  ].some((el) => permissionList?.includes(el));
  const showStep5 = permissionList.includes(PERMISSIONS.view_client_user_list);
  const stepNum = [showStep1, showStep2, showStep3, showStep4, showStep5].filter(Boolean).length;

  useEffect(() => {
    setTimeout(() => {
      addAriaLabelToUserTour();
    }, 100);
  }, [isShowTour]);

  const createContent = (content: string, step: number) => {
    return (
      <div>
        <div>
          {step} of {stepNum}
        </div>
        <div className="mt-2">{content}</div>
      </div>
    );
  };

  const getStepNumber = (number: number) => {
    switch (number) {
      case 5:
        return [showStep1, showStep2, showStep3, showStep4].filter(Boolean).length + 1;
      case 4:
        return [showStep1, showStep2, showStep3].filter(Boolean).length + 1;
      case 3:
        return [showStep1, showStep2].filter(Boolean).length + 1;
      case 2:
        return [showStep1].filter(Boolean).length + 1;
      default:
        return 1;
    }
  };

  const stepsTour = [
    ...(showStep1
      ? [
          {
            selector: '[data-tut="management-project"]',
            content: createContent(
              'Here you can manage and view all your current Site Launch projects and see your historic project plans',
              1
            ),
          },
        ]
      : []),
    ...(showStep2
      ? [
          {
            selector: '[data-tut="management-forms"]',
            content: createContent(
              'Here you can view, upload, amend and comment all your onboarding documents that are required by THG Ingenuity',
              getStepNumber(2)
            ),
          },
        ]
      : []),
    ...(showStep3
      ? [
          {
            selector: '[data-tut="management-documents"]',
            content: createContent(
              'Here you can see all your documents THG Ingenuity have stored for you. You can also upload documents here too',
              getStepNumber(3)
            ),
          },
        ]
      : []),
    ...(showStep4
      ? [
          {
            selector: '[data-tut="management"]',
            content: createContent(
              'Here you can view and manage your projects, form upload and documents',
              getStepNumber(4)
            ),
          },
        ]
      : []),
    ...(showStep5
      ? [
          {
            selector: '[data-tut="configuration"]',
            content: createContent(
              'Here you can view users from your organisation and their permission levels',
              getStepNumber(5)
            ),
          },
        ]
      : []),
  ];

  const onRequestClose = () => {
    dispatch({
      type: Types.SET_SHOW_TOUR,
      payload: { showTour: false },
    });
    setCurrentStep(-1);
  };

  const createButton = (name: string) => {
    return <span className="btn btn-primary btn-sm">{name}</span>;
  };

  return (
    <div>
      <TourStyle
        isOpen={isShowTour}
        steps={stepsTour}
        onRequestClose={onRequestClose}
        startAt={0}
        prevButton={currentStep === 0 ? <></> : createButton('Previous')}
        nextButton={createButton('Next')}
        lastStepNextButton={createButton('Got it')}
        maskSpace={0}
        showNumber={false}
        getCurrentStep={(curr) => {
          setCurrentStep(curr);
        }}
        onAfterOpen={() => {
          setCurrentStep(0);
        }}
        disableInteraction
      />
    </div>
  );
}

export default UserTour;
