import React, { createContext, useReducer, useContext, Dispatch } from 'react';
import { productReducer, ProductActions } from '../reducers/product-reducer';
import { accountReducer, AccountActions, AccountType } from '../reducers/account-reducer';
import { LeftMenuActions, LeftMenuReducer, LeftMenuType } from '../reducers/left-menu-style-reducer';
import { SiteActions, siteReducer, SiteType } from '../reducers/site-reducer';
import { ProjectActions, projectReducer, ProjectType } from '../reducers/project-reducer';
import { FormTemplateActions, formTemplateReducer, FormTemplateType } from '../reducers/form-template-reducer';
import { formReducer, FormActions, FormType } from '../reducers/form-reducer';
import { TourActions, tourReducer, TourType } from '../reducers/tour-reducer';
import { helpCentreReducer, HelpCentreActions, HelpCentreType } from '../reducers/help-centre-reducer';
import {
  modalOnboardVideoReducer,
  ModalOnboardVideoActions,
  ModalOnboardVideoType,
} from '../reducers/modal-onboard-video-reducer';
import { checkinVideoReducer, CheckinVideoActions, CheckinVideoType } from '../reducers/checkin-video';
type AppProps = React.PropsWithChildren<{}>;

type ProductType = {
  id: number;
  name: string;
  price: number;
};

type InitialStateType = {
  products: ProductType[];
  account: AccountType;
  leftMenuStyle: LeftMenuType;
  site: SiteType;
  project: ProjectType;
  formTemplate: FormTemplateType;
  form: FormType;
  tour: TourType;
  helpCentreData: HelpCentreType;
  modalOnboardStyle: ModalOnboardVideoType;
  checkinVideo: CheckinVideoType;
};

const initialState = {
  products: [],
  account: {
    accountList: {
      items: [],
      pageInfo: {},
    },
    accountDetail: {},
    accountSelected: undefined,
  },
  leftMenuStyle: {
    isExpand: null,
  },
  site: {
    siteList: {
      items: [],
      pageInfo: {},
    },
    siteDetail: {},
  },
  project: {
    projectList: {
      items: [],
      pageInfo: {},
    },
    projectDetail: {},
    projectMilestones: {
      items: [],
      pageInfo: {},
    },
    projectIssues: {
      items: [],
      pageInfo: {},
    },
    taskCount: {},
  },
  formTemplate: {
    configuration: { templateId: '', level: '' },
    formTemplate: {},
  },
  form: {
    formDetail: {},
    isFormChanged: false,
  },
  tour: {
    showTour: false,
    showModalConfirmStartTour: false,
  },
  helpCentreData: {},
  modalOnboardStyle: {
    isOpen: null,
  },
  checkinVideo: {
    isOpen: false,
    isDisplay: false,
  },
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<
    | ProductActions
    | AccountActions
    | LeftMenuActions
    | SiteActions
    | ProjectActions
    | FormTemplateActions
    | FormActions
    | TourActions
    | HelpCentreActions
    | ModalOnboardVideoActions
    | CheckinVideoActions
  >;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  {
    products,
    account,
    leftMenuStyle,
    site,
    project,
    formTemplate,
    form,
    tour,
    helpCentreData,
    modalOnboardStyle,
    checkinVideo,
  }: InitialStateType,
  action: any
) => ({
  products: productReducer(products, action),
  account: accountReducer(account, action),
  leftMenuStyle: LeftMenuReducer(leftMenuStyle, action),
  site: siteReducer(site, action),
  project: projectReducer(project, action),
  helpCentreData: helpCentreReducer(helpCentreData, action),
  formTemplate: formTemplateReducer(formTemplate, action),
  form: formReducer(form, action),
  tour: tourReducer(tour, action),
  modalOnboardStyle: modalOnboardVideoReducer(modalOnboardStyle, action),
  checkinVideo: checkinVideoReducer(checkinVideo, action),
});

function AppProvider({ children }: AppProps) {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
}

const useState = () => {
  const { state } = useContext(AppContext);
  return state;
};

const useAccountDetail = () => {
  const { state } = useContext(AppContext);
  return state.account.accountDetail;
};

const useAccountSelected = () => {
  const { state } = useContext(AppContext);
  return state.account.accountSelected;
};

const useAccountList = () => {
  const { state } = useContext(AppContext);
  return state.account.accountList;
};

const useLeftMenuStyle = () => {
  const { state } = useContext(AppContext);
  return state.leftMenuStyle;
};

const useSiteDetail = () => {
  const { state } = useContext(AppContext);
  return state.site.siteDetail;
};

const useSiteList = () => {
  const { state } = useContext(AppContext);
  return state.site.siteList;
};

const useDispatch = () => {
  const { dispatch } = useContext(AppContext);
  return dispatch;
};

const useProjectList = () => {
  const { state } = useContext(AppContext);
  return state.project.projectList;
};
const useProjectDetail = () => {
  const { state } = useContext(AppContext);
  return state.project.projectDetail;
};

const useProjectMilestoneList = () => {
  const { state } = useContext(AppContext);
  return state.project.projectMilestones;
};

const useProjectIssueList = () => {
  const { state } = useContext(AppContext);
  return state.project.projectIssues;
};

const useTaskCount = () => {
  const { state } = useContext(AppContext);
  return state.project.taskCount;
};

const useFormTemplateConfig = () => {
  const { state } = useContext(AppContext);
  return state.formTemplate.configuration;
};
const useFormTemplateDetail = () => {
  const { state } = useContext(AppContext);
  return state.formTemplate.formTemplate;
};

const useFormDetail = () => {
  const { state } = useContext(AppContext);
  return state.form.formDetail;
};

const useIsFormChanged = () => {
  const { state } = useContext(AppContext);
  return state.form.isFormChanged;
};

const useIsShowTour = () => {
  const { state } = useContext(AppContext);
  return state.tour.showTour;
};
const useHelpCentre = () => {
  const { state } = useContext(AppContext);
  return state.helpCentreData.helpCentre;
};

const useIsModalConfirmStartUserTour = () => {
  const { state } = useContext(AppContext);
  return state.tour.showModalConfirmStartTour;
};

const useModalOnboardVideo = () => {
  const { state } = useContext(AppContext);
  return state.modalOnboardStyle;
};

const useCheckinVideo = () => {
  const { state } = useContext(AppContext);
  return state.checkinVideo;
};

export {
  AppProvider,
  AppContext,
  useState,
  useDispatch,
  useAccountDetail,
  useAccountList,
  useLeftMenuStyle,
  useSiteDetail,
  useSiteList,
  useProjectList,
  useProjectDetail,
  useProjectMilestoneList,
  useProjectIssueList,
  useTaskCount,
  useFormTemplateConfig,
  useFormTemplateDetail,
  useFormDetail,
  useAccountSelected,
  useIsFormChanged,
  useIsShowTour,
  useIsModalConfirmStartUserTour,
  useHelpCentre,
  useModalOnboardVideo,
  useCheckinVideo,
};
