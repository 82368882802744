import React, { useEffect, useState } from 'react';
import { ROLE_TYPE, HOME_BUTTON, LAYOUT_MODE, PERMISSIONS } from '../../constant';
import { getRole } from '../../utils';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { CenterStyled, HomePageStyled } from '../../components/StyledComponent';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useIsModalConfirmStartUserTour } from '../../context';
import { Types } from '../../constant/action-type';
import ModalVideo from '../../components/modal/ModalVideo';
import { usePermissions } from '../../context/auth-context';
import { useHelpCenter } from '../../context/help-center-context';
import ModalTermOfUse from '../../components/modal/ModalTermOfUse';
import { getsLayoutMode } from '../../utils/layout';

const TextStyled = styled.div`
  text-align: center;
`;

const SkipTourStyled = styled.div`
  cursor: pointer;
  text-align: center;
  color: #605bff;
  :hover {
    color: #524dd9;
  }
`;

const Home = () => {
  // added to disable welcome modal windows as per BGD-2267
  const [showStartingModals] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const navigate = useNavigate();
  const role = getRole();
  const dispatch = useDispatch();
  const { permissionList } = usePermissions();
  const helpCenterControl = useHelpCenter();
  const layoutMode = getsLayoutMode();

  const showStep1 = permissionList.includes(PERMISSIONS.view_project_list);
  const showStep2 = permissionList.includes(PERMISSIONS.view_client_form_list);
  const showStep3 = permissionList.includes(PERMISSIONS.view_document_list);
  const showStep4 = [
    PERMISSIONS.view_project_list,
    PERMISSIONS.view_client_form_list,
    PERMISSIONS.view_document_list,
  ].some((el) => permissionList?.includes(el));
  const showStep5 = permissionList.includes(PERMISSIONS.view_client_user_list);
  const stepNum = [showStep1, showStep2, showStep3, showStep4, showStep5].filter(Boolean).length;

  useEffect(() => {
    if (role === ROLE_TYPE.THG) return;
    const isFirstTimeLogin = localStorage.getItem('isFirstTimeLogin');
    const termsOfUseAgreementStatus = localStorage.getItem('termsOfUseAgreementStatus');
    setTimeout(() => {
      if (isFirstTimeLogin === 'true' || termsOfUseAgreementStatus === 'false') {
        setShowTerms(true);
      }
      localStorage.setItem('isFirstTimeLogin', 'false');
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRoleForButton = (roles: string[]) => {
    if (roles.includes(role)) {
      return '';
    }
    return 'd-none';
  };

  const isShowModalConfirmStartTour = useIsModalConfirmStartUserTour();

  const handleStartAQuickTour = () => {
    handleSkipTour();
    if (!stepNum) {
      navigate(`/`);
      return;
    }
    dispatch({
      type: Types.SET_SHOW_TOUR,
      payload: { showTour: true },
    });
  };

  const handleSkipTour = () => {
    helpCenterControl({
      isOpen: false,
      formInformation: {
        isShow: false,
        activeIndex: [],
      },
      onboardingProcessInformation: {
        isShow: false,
        activeIndex: [],
      },
      isShowButtonAllHelpTopic: false,
    });
    dispatch({
      type: Types.SET_SHOW_MODAL_CONFIRM_START_USER_TOUR,
      payload: { showModalConfirmStartTour: false },
    });
  };

  return (
    <HomePageStyled>
      <Container fluid className="welcome-session text-center">
        <h2 className="mb-1 display-2 fw-bold text-uppercase">W e l c o m e</h2>
        {role === ROLE_TYPE.CLIENT ? (
          <>
            <p className="p-title">to the THG Ingenuity Client Portal</p>
            <p className="mt-3 lh-lg p-des">
              Here you can find all your onboarding documents, project status and tasks that require action for your
              Site Launch with THG Ingenuity.
            </p>
            <p className="p-des">Please follow click one of the three buttons below to get started.</p>
            <p className="mb-4 p-des">If you have any queries, please select the help icon on top bar.</p>
          </>
        ) : (
          <>
            <p className="p-title">to the Client Portal</p>
            <p className="mt-3 p-des">Here you can view and work with your Client Accounts.</p>
            <p className="p-des">Please click on the left hand menu or the buttons below to get started.</p>
            <p className="mb-4 p-des">If you have any queries, please see your Client Portal Admin.</p>
          </>
        )}

        <Row>
          {HOME_BUTTON.map((button: any, id: any) => {
            const hasPerm = permissionList?.some((perm: any) => {
              const btnPerm = button?.permission;
              let buttonVisibility;
              if (btnPerm?.length >= 1) {
                if (role === ROLE_TYPE.CLIENT) {
                  buttonVisibility = btnPerm?.filter((item: string) => item?.includes('client'))?.includes(perm);
                } else {
                  buttonVisibility = btnPerm?.filter((item: string) => item?.includes('thg'))?.includes(perm);
                }
                if (permissionList.includes('initial_access')) {
                  buttonVisibility = btnPerm
                    ?.filter((item: string) => item?.includes('initial_access'))
                    ?.includes(perm);
                }
              } else {
                buttonVisibility = btnPerm?.includes(perm);
              }
              return buttonVisibility;
            });
            return (
              <>
                {hasPerm && (
                  <Col className={`${checkRoleForButton(button.roles)}`} key={id}>
                    <Button
                      variant="outline-primary"
                      className="w-100 home-button"
                      size="lg"
                      onClick={() => navigate(button.to)}
                      data-tut={button.dataTut}
                    >
                      {button.title}
                    </Button>
                  </Col>
                )}
              </>
            );
          })}
        </Row>
      </Container>

      <Modal show={isShowModalConfirmStartTour} centered className="modal-confirm-show-tour">
        <Modal.Body>
          <>
            <CenterStyled className="mt-3">
              <img
                src={layoutMode === LAYOUT_MODE.LIGHT ? 'Ingenuity_Logo_New.png' : 'Ingenuity_Logo_White.png'}
                alt="THG logo"
                height={75}
              />
            </CenterStyled>
            <TextStyled className="mt-3" style={{ fontSize: 21 }}>
              Welcome to the THG Ingenuity Client Portal User Tour
            </TextStyled>
            <TextStyled className="mt-2">
              Here we will give you tips on how to navigate and use your Client Portal
            </TextStyled>
            <CenterStyled className="mt-3">
              <Button className="ms-1" size="sm" variant="success" onClick={handleStartAQuickTour}>
                START A QUICK TOUR
              </Button>
            </CenterStyled>
            <SkipTourStyled className="mt-3 mb-3" onClick={handleSkipTour}>
              <u>Skip the tour</u>
            </SkipTourStyled>
          </>
        </Modal.Body>
      </Modal>
      <ModalTermOfUse
        isShowing={showTerms && showStartingModals}
        setShowVideo={setShowModalVideo}
        setShowTerms={setShowTerms}
      />
      <ModalVideo
        isShowModalVideo={showModalVideo && showStartingModals}
        setShowModalVideo={() => {
          setShowModalVideo(!showModalVideo);
        }}
      />
    </HomePageStyled>
  );
};

export default Home;
