import React from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { ProjectDto } from '../../projects-api-client/data-contracts';
import { LabelInforNoneMargin, ColInputForm } from '../StyledComponent';
function ActionTracker(props: ProjectDto | any) {
  const { project } = props;
  const noActionSentence = 'No action currently required';
  const getActionValue = (actionName: string) => {
    const actionNameSplit = actionName?.split(' ')?.map((item: string) => {
      return item?.toLocaleLowerCase();
    });
    const classNameUnique = actionNameSplit.join('-');
    let foundAction = project?.actionPoints?.find((action: any) => {
      return action?.actionName === actionName;
    });
    if (!!foundAction?.actionValue) {
      return (
        <ColInputForm lg={8} className={`col-input-form p-2 break-line project-${classNameUnique}`}>
          {foundAction?.actionValue}
        </ColInputForm>
      );
    }
    return (
      <ColInputForm lg={8} className={`col-input-form p-2 project-${classNameUnique}`}>
        {noActionSentence}
      </ColInputForm>
    );
  };
  return (
    <Form noValidate>
      <Card className="shadow-none">
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Commercial Framework</LabelInforNoneMargin>
          </Col>
          {getActionValue('Commercial Framework')}
        </Row>

        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Trading</LabelInforNoneMargin>
          </Col>
          {getActionValue('Trading')}
        </Row>
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Marketing</LabelInforNoneMargin>
          </Col>
          {getActionValue('Marketing')}
        </Row>
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Strategy</LabelInforNoneMargin>
          </Col>
          {getActionValue('Strategy')}
        </Row>
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Tech</LabelInforNoneMargin>
          </Col>
          {getActionValue('Tech')}
        </Row>
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Site Build</LabelInforNoneMargin>
          </Col>
          {getActionValue('Site Build')}
        </Row>
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Warehousing and Operations</LabelInforNoneMargin>
          </Col>
          {getActionValue('Warehousing Operations')}
        </Row>
        <Row className="action-tracker detail-info">
          <Col lg={4} className="p-2">
            <LabelInforNoneMargin>Other</LabelInforNoneMargin>
          </Col>
          {getActionValue('Other')}
        </Row>
      </Card>
    </Form>
  );
}
export default ActionTracker;
