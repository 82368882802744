import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardLayout from './dashboard-layout';
import PrivateRoute from './private-route';
import { ROUTER, PERMISSIONS, ROLE_TYPE } from '../constant';
import HomePage from '../containers/home-page';
import FormManagementPage from '../containers/form-management-page';
import FormTemplatePage from '../containers/form-template-page';
import RolePage from '../containers/role-page';
import THGUserPage from '../containers/thg-user-page';
import ClientUserPage from '../containers/client-user-page';
import ProjectPage from '../containers/project-page';
import PreLoadDocumentPage from '../containers/document-page/PreLoadDocumentPage';
import DocumentPage from '../containers/document-page/index';
import HelpPage from '../containers/help-page';
import ErrorPage from '../containers/error-page';
import NonPermissionPage from '../containers/error-page/non-permission';
import AccountDetailPage from '../containers/account-page/AccountDetailPage';
import ListAccounts from '../containers/account-page';
import ProjectDetailPage from '../containers/project-page/detail';
import SiteDetailPage from '../containers/site-page/SiteDetailPage';
import ListSitesByAccount from '../containers/site-page';
import SitesPage from '../containers/site-list-page';
import FormTemplateDetailPage from '../containers/form-template-page/FormTemplatDetailPage';
import FormTemplatePreviewPage from '../containers/form-template-page/FormTemplatePreviewPage';
import FormManagementDetailPage from '../containers/form-management-page/FormManagementDetailPage';
import AccountProfile from '../containers/account-profile';
import RelatedSites from '../containers/related-site-page';
import User from '../containers/user-page';
import UserDetail from '../containers/user-page/UserDetailPage';
import TermOfUse from '../containers/term-of-use-page';
import PrivacyPolicy from '../containers/privacy-policy';

const Layout = () => {
  return (
    <Routes>
      <Route path={ROUTER.HOME_PAGE} element={<DashboardLayout component={<HomePage />} />} />
      <Route path={ROUTER.HOME} element={<DashboardLayout component={<HomePage />} />} />
      <Route
        path={ROUTER.MANAGEMENT_FORMS}
        element={
          <PrivateRoute
            permissions={[
              PERMISSIONS.view_client_form_list,
              PERMISSIONS.view_thg_form_list,
              PERMISSIONS.view_thg_form_details,
              PERMISSIONS.approve_thg_form,
              PERMISSIONS.initial_access,
            ]}
          >
            <DashboardLayout component={<FormManagementPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_FORMS_DETAIL}
        element={
          <PrivateRoute
            permissions={[
              PERMISSIONS.view_client_form_details,
              PERMISSIONS.view_thg_form_details,
              PERMISSIONS.edit_client_form_details,
              PERMISSIONS.approve_thg_form,
              PERMISSIONS.initial_access,
            ]}
          >
            <DashboardLayout component={<FormManagementDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_ACCOUNT}
        element={
          <PrivateRoute
            permissions={[
              PERMISSIONS.view_account_list,
              PERMISSIONS.view_site_list_of_account,
              PERMISSIONS.view_site_list,
              PERMISSIONS.view_site_details,
              PERMISSIONS.view_thg_form_list,
              PERMISSIONS.view_thg_form_details,
              PERMISSIONS.approve_thg_form,
              PERMISSIONS.view_form_template_list,
              PERMISSIONS.view_client_user_list,
            ]}
            role={ROLE_TYPE.THG}
          >
            <DashboardLayout component={<ListAccounts />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_ACCOUNT_DETAIL}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_account_details]}>
            <DashboardLayout component={<AccountDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_SITE}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_site_list_of_account]}>
            <DashboardLayout component={<ListSitesByAccount />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_SITE_DETAIL}
        element={
          <PrivateRoute role={ROLE_TYPE.THG} permissions={[PERMISSIONS.view_site_details]}>
            <DashboardLayout component={<SiteDetailPage isFromAccountDetail={true} />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_SITE_PAGE_DETAIL}
        element={
          <PrivateRoute role={ROLE_TYPE.THG} permissions={[PERMISSIONS.view_site_details]}>
            <DashboardLayout component={<SiteDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_SITE_LIST}
        element={
          <PrivateRoute role={ROLE_TYPE.THG} permissions={[PERMISSIONS.view_site_list]}>
            <DashboardLayout component={<SitesPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_FORM}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_form_template_list]} role={ROLE_TYPE.THG}>
            <DashboardLayout component={<FormTemplatePage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_FORM_BUILDER}
        element={
          <PrivateRoute
            role={ROLE_TYPE.THG}
            permissions={[PERMISSIONS.view_form_template_configurations, PERMISSIONS.edit_form_template_configurations]}
          >
            <DashboardLayout component={<FormTemplateDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_FORM_CONFIGURATION}
        element={
          <PrivateRoute
            role={ROLE_TYPE.THG}
            permissions={[PERMISSIONS.view_form_template_configurations, PERMISSIONS.edit_form_template_configurations]}
          >
            <DashboardLayout component={<FormTemplateDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_FORM_MAPPER}
        element={
          <PrivateRoute
            permissions={[PERMISSIONS.view_form_template_configurations, PERMISSIONS.edit_form_template_configurations]}
          >
            <DashboardLayout component={<FormTemplateDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_FORM_PREVIEW}
        element={
          <PrivateRoute role={ROLE_TYPE.THG} permissions={[PERMISSIONS.preview_form_template]}>
            <DashboardLayout component={<FormTemplatePreviewPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_ROLES}
        element={
          <PrivateRoute permissions={[PERMISSIONS.configuration_role_read]}>
            <DashboardLayout component={<RolePage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_THG_USER}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_thg_form_list]}>
            <DashboardLayout component={<THGUserPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_CLIENT_USER}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_client_user_list]}>
            <DashboardLayout component={<ClientUserPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PROJECT}
        element={
          <PrivateRoute
            role={ROLE_TYPE.CLIENT}
            permissions={[
              PERMISSIONS.view_project_list,
              PERMISSIONS.view_client_form_list,
              PERMISSIONS.view_client_form_details,
              PERMISSIONS.view_thg_form_list,
              PERMISSIONS.view_thg_form_details,
              PERMISSIONS.approve_thg_form,
            ]}
          >
            <DashboardLayout component={<ProjectPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PROJECT_DETAIL_OVERVIEW}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_project_details]}>
            <DashboardLayout component={<ProjectDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PROJECT_DETAIL_ACTION_TRACKER}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_project_details]}>
            <DashboardLayout component={<ProjectDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PROJECT_DETAIL_MILESTONES}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_project_details]}>
            <DashboardLayout component={<ProjectDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PROJECT_DETAIL_ACTION_TASKS}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_project_details]}>
            <DashboardLayout component={<ProjectDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PROJECT_DETAIL_ISSUE_TRACKER}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_project_details]}>
            <DashboardLayout component={<ProjectDetailPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_PRELOAD_DOCUMENTS}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_document_list]}>
            <DashboardLayout component={<PreLoadDocumentPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.MANAGEMENT_DOCUMENTS}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_document_list]}>
            <DashboardLayout component={<DocumentPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.HELP}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_main_help]}>
            <DashboardLayout component={<HelpPage />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.ACCOUNT_PROFILE}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_account_profile]}>
            <DashboardLayout component={<AccountProfile />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.RELATED_SITES}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_related_sites]}>
            <DashboardLayout component={<RelatedSites />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_USER}
        element={
          <PrivateRoute permissions={[PERMISSIONS.view_client_user_list]} role={ROLE_TYPE.CLIENT}>
            <DashboardLayout component={<User />} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTER.CONFIGURATION_USER_DETAIL}
        element={
          <PrivateRoute role={ROLE_TYPE.CLIENT} permissions={[PERMISSIONS.view_client_user_details]}>
            <DashboardLayout component={<UserDetail />} />
          </PrivateRoute>
        }
      />
      <Route path={ROUTER.NON_PERMISSION_PAGE} element={<DashboardLayout component={<NonPermissionPage />} />} />
      <Route path="*" element={<DashboardLayout component={<ErrorPage />} />} />
      <Route path={ROUTER.TERM_OF_USE} element={<DashboardLayout component={<TermOfUse />} />} />
      <Route path={ROUTER.PRIVACY_POLICY} element={<DashboardLayout component={<PrivacyPolicy />} />} />
    </Routes>
  );
};

export default Layout;
