import { PROTOCOL, ROLE, DATE_FILTER_FORMAT, ROLE_TYPE, GENERAL_ERROR_MESSAGE } from '../constant';
import { get, findLast, isArray, upperFirst, lowerCase } from 'lodash';
import { CLIENT_ERRORS, SERVER_ERRORS } from '../constant/error-status';
import moment from 'moment';
import axios from 'axios';

export const getPermision = (role: string) => {
  if (!role) return [];
  return get(ROLE, role);
};

export const getInfoFromAccessToken = (token: string) => {
  const roles = token === 'THG' ? ROLE.THG : ROLE.CLIENT;
  const name = token === 'THG' ? 'THG User' : 'Client User';
  return {
    exp: 1653359384,
    iat: 1653359084,
    auth_time: 1653359083,
    jti: '6d797a54-61b4-4d59-b1ad-a436ef020bb0',
    iss: 'http://localhost:8080/auth/realms/keycloak-react-auth',
    aud: 'account',
    sub: '02f2f0ac-4a91-42e9-9eed-e0e152360428',
    typ: 'Bearer',
    azp: 'react-auth',
    nonce: '291135b6-1936-4bb3-975e-a5a064a4b261',
    session_state: '41512e7c-a1b2-4391-ae5e-faa956540c82',
    acr: '1',
    'allowed-origins': ['http://localhost:3000'],
    realm_access: {
      roles: [...roles],
    },
    resource_access: {
      account: {
        roles: ['manage_account', 'manage_account_links', 'view_profile'],
      },
      react_auth: {
        roles: ['Client_Role'],
      },
    },
    scope: 'openid profile email',
    sid: '41512e7c-a1b2-4391-ae5e-faa956540c82',
    email_verified: false,
    name,
    groups: ['/Admin'],
    preferred_username: 'nguyendanh2103@gmail.com',
    given_name: 'Nguyen',
    family_name: 'Danh',
    email: 'nguyendanh2103@gmail.com',
    role: token,
  };
};

export const checkDynamicLink = (link: string | undefined) => {
  if (link && link.substring(0, 8) !== PROTOCOL) {
    return `${PROTOCOL}${link}`;
  }
  return link;
};

export const getListIdShowTooltipInPrimeTable = (arrayIndex: number[]) => {
  //arrayIndex: list index of columns need to get Id for show tooltip, start from 0
  const bodyTable = document.getElementsByClassName('p-datatable-tbody');
  if (!arrayIndex || !arrayIndex.length || !bodyTable.length) return [];
  const trTable = bodyTable[0]?.children;
  const arrTr = Array.prototype.slice.call(trTable);
  let arrTooltipsActive: string[] = [];
  arrTr.forEach((element) => {
    arrayIndex.forEach((indexCol) => {
      let td = element?.children[indexCol];
      if (td && td.offsetWidth < td.scrollWidth) {
        arrTooltipsActive.push(`${td.children[0].id}-${indexCol}`);
        if (td.children[0].localName === 'a') {
          td.style.color = '#727cf5';
        }
      }
    });
  });

  return arrTooltipsActive;
};

export const handleErrorStatus = (status: number) => {
  if (CLIENT_ERRORS.includes(status)) {
    window.location.assign('/error');
  }
  if (SERVER_ERRORS.includes(status)) {
    window.alert('Server error!');
  }
};

export const handleErrorLogMessage = (error: any, defaultErrorCode: string, toast: Function) => {
  let dataError = error?.response?.data;
  toast({
    code: dataError?.code || defaultErrorCode,
    message: dataError?.message || GENERAL_ERROR_MESSAGE,
    type: 'error',
  });
};

export const convertTimeUTC = (time: string | undefined, formatType: string) => {
  if (!time) return '';
  return moment(time).utc().format(formatType);
};

export const convertTime = (time: string | undefined, formatType: string) => {
  if (!time) return '';
  return moment(time).format(formatType);
};

export const getPerPageTable = () => {
  const perPageTable = localStorage.getItem('perPageTable') || 10;
  return Number(perPageTable);
};

export const getSelectOptionAndArrayStringOption = (
  options: string | (string | null)[] | null,
  defaultOptions?: Array<string | any>
) => {
  if (!options) {
    return { selectOption: [], arrayStringOption: [] };
  }
  let newSelectOption: any[] = [];
  let newArrayStringOption: any[] = [];
  if (typeof options === 'string') {
    newSelectOption = [{ value: options, label: options }];
    newArrayStringOption = [options];
  } else {
    newSelectOption = options?.map((status: any) => {
      if (defaultOptions) {
        let findOption = defaultOptions.find((item) => {
          return item.value === status;
        });
        return { value: status, label: findOption?.label };
      }
      return { value: status, label: status };
    });
    newArrayStringOption = options;
  }

  return {
    selectOption: newSelectOption,
    arrayStringOption: newArrayStringOption,
  };
};
export const getSelectOptionAndArrayStringOptionForMultiple = (options: string | (string | null)[] | null) => {
  if (!options) {
    return { selectOption: [], arrayStringOption: [] };
  }
  let newSelectOption: any[] = [];
  let newArrayStringOption: any[] = [];
  if (typeof options === 'string') {
    newSelectOption = [{ name: options, code: options }];
    newArrayStringOption = [options];
  } else {
    newSelectOption = options?.map((status: any) => {
      return { name: status, code: status };
    });
    newArrayStringOption = options;
  }

  return {
    selectOption: newSelectOption,
    arrayStringOption: newArrayStringOption,
  };
};
export const getStringSearch = (string: any) => {
  if (!string) return '';
  return string.substring(1, string.length - 1);
};

export const getFilterValues = (data: any) => {
  return data.map((item: any) => item.value);
};

export const getDueDate = (dates: any, isFrom: boolean) => {
  if (!dates) return '';
  if (isFrom) {
    return moment(dates[0]).format(DATE_FILTER_FORMAT);
  }
  if (dates[0]) {
    return moment(dates[1]).format(DATE_FILTER_FORMAT);
  }
  return '';
};

export const getMilestoneByIds = (ids: any, milestones: any) => {
  if (!ids) return [];

  const idList = isArray(ids) ? [...ids] : [ids];
  const result: any[] = [];
  idList.forEach((id: any) => {
    const data = findLast(milestones, (milestone: any) => milestone.value === id);
    result.push(data);
  });

  return result;
};

export const getValueOption = (data: any) => {
  return {
    value: data,
    label: data,
  };
};

export const downloadFile = async (url: string, token: string, fileName?: string) => {
  const res: any = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: '*/*',
    },
    responseType: 'blob',
  });
  let href = URL.createObjectURL(res.data);
  if (!fileName) {
    fileName = `Zoho WorkDrive-${Date.now().toString()}`;
  }
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (const element of ca) {
    let c = element;
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getRole = () => {
  return localStorage.getItem('role') || ROLE_TYPE.CLIENT;
};

export const formatValueOptions = (array: string[]) => {
  let formatArray = [...array];
  formatArray.forEach((element: any) => {
    element.label = upperFirst(lowerCase(element.label));
  });
  return formatArray;
};

export const getActionUserStatusText = (status?: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'Deactivate';
    case 'LOCKED':
      return 'Unlock';
    case 'INACTIVE':
      return 'Activate';
    case 'UNCONFIRMED':
      return 'Resend';
    default:
      break;
  }
  return '';
};

export const getToken = () => {
  return localStorage.getItem('token') || '';
};

export const getDurationFromFirstLogin = () => {
  const firstTimeLoginAt = localStorage.getItem('firstTimeLoginAt');
  const diff = moment(moment(), 'DD/MM/YYYY HH:mm:ss').diff(moment(firstTimeLoginAt, 'DD/MM/YYYY HH:mm:ss'), 'ms');
  return moment.duration(diff);
};
