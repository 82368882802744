export enum Types {
  GET_PRODUCT = 'GET_PRODUCT',
  GET_ACCOUNT = 'GET_ACCOUNT_LIST',
  GET_ACCOUNT_DETAIL = 'GET_ACCOUNT_DETAIL',
  SET_ACCOUNT_SELECTED = 'SET_ACCOUNT_SELECTED',
  SET_SITE_DOCUMENT_SELECTED = 'SET_SITE_DOCUMENT_SELECTED',
  SET_LEFT_MENU_STYLE = 'SET_LEFT_MENU_STYLE',
  SET_HELP_CENTRE_STYLE = 'SET_HELP_CENTRE_STYLE',
  SET_MODAL_ONBOARD_VIDEO_STYLE = 'SET_MODAL_ONBOARD_VIDEO_STYLE',
  GET_SITE = 'GET_SITE_LIST',
  GET_SITE_DETAIL = 'GET_SITE_DETAIL',
  GET_FORM_TEMPLATE_DETAIL = 'GET_FORM_TEMPLATE_DETAIL',
  GET_FORM_TEMPLATE_CONFIG = 'GET_FORM_TEMPLATE_CONFIG',
  GET_PROJECT = 'GET_PROJECT',
  GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL',
  GET_PROJECT_MILESTONES = 'GET_PROJECT_MILESTONES',
  GET_PROJECT_ISSUES = 'GET_PROJECT_ISSUES',
  GET_TASK_COUNT = 'GET_TASK_COUNT',
  GET_FORM = 'GET_FORM_LIST',
  GET_FORM_DETAIL = 'GET_FORM_DETAIL',
  GET_FORM_DIRTY_STATUS = 'GET_FORM_DIRTY_STATUS',
  SET_SHOW_TOUR = 'SET_SHOW_TOUR',
  SET_SHOW_MODAL_CONFIRM_START_USER_TOUR = 'SET_SHOW_MODAL_CONFIRM_START_USER_TOUR',
  SET_SHOW_CHECK_IN_VIDEO = 'SET_SHOW_CHECK_IN_VIDEO',
  SET_SHOW_DISPAY_DEPEND_CHECK_IN_VIDEO = 'SET_SHOW_DISPAY_DEPEND_CHECK_IN_VIDEO',
  SET_FIRST_TIME_LOGIN = 'SET_FIRST_TIME_LOGIN',
}
