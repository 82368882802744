/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BrandPageDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Brands<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Brand List
   *
   * @tags sites, brand
   * @name GetBrands
   * @summary Brand List
   * @request GET:/brands
   * @secure
   */
  getBrands = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BrandPageDto, any>({
      path: `/brands`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
