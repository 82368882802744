/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CounterReportDto,
  ErrorDto,
  IssuePageDto,
  MileStonePageDto,
  ProjectDto,
  ProjectPageDto,
  TaskPageDto,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Projects<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Project List
   *
   * @tags projects
   * @name GetProjects
   * @summary Project List
   * @request GET:/projects
   * @secure
   */
  getProjects = (
    query?: {
      search?: string;
      name?: string;
      accountIds?: string[];
      ids?: string[];
      status?: string[];
      ragStatus?: string[];
      /** @example "status:asc,projectName:desc,ragStatus:asc,status:desc,goLiveDate:asc,startDate:desc,territory:asc,pmName:asc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProjectPageDto, any>({
      path: `/projects`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Project Overview
   *
   * @tags project
   * @name GetProject
   * @summary Project
   * @request GET:/projects/{id}
   * @secure
   */
  getProject = (id: string, params: RequestParams = {}) =>
    this.request<ProjectDto, ErrorDto>({
      path: `/projects/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Project By External Id
   *
   * @tags project
   * @name GetProjectByExternalId
   * @summary Get Project By External Id
   * @request GET:/projects/external/{extId}
   * @secure
   */
  getProjectByExternalId = (extId: string, params: RequestParams = {}) =>
    this.request<ProjectDto, ErrorDto>({
      path: `/projects/external/${extId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description MileStone List
   *
   * @tags milestones
   * @name GetMilestonesByProject
   * @summary MileStone List
   * @request GET:/projects/{projectId}/milestones
   * @secure
   */
  getMilestonesByProject = (
    projectId: string,
    query?: {
      search?: string;
      milestoneStatuses?: string[];
      /** @example "status:asc,milestoneName:desc,startDate:asc,endDate:asc,duration:asc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<MileStonePageDto, any>({
      path: `/projects/${projectId}/milestones`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Task List
   *
   * @tags tasks
   * @name GetTasksByProject
   * @summary Task List
   * @request GET:/projects/{projectId}/tasks
   * @secure
   */
  getTasksByProject = (
    projectId: string,
    query?: {
      search?: string;
      /** @format date */
      dueDateFrom?: string;
      /** @format date */
      dueDateTo?: string;
      milestoneExtIds?: string[];
      responsibleParties?: string[];
      statuses?: string[];
      ragStatuses?: string[];
      /** @example "name:desc,startDate:desc,dueDate:desc,responsibleParty:asc,status:asc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<TaskPageDto, any>({
      path: `/projects/${projectId}/tasks`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Issue List
   *
   * @tags issues
   * @name GetIssuesByProject
   * @summary Issue List
   * @request GET:/projects/{projectId}/issues
   * @secure
   */
  getIssuesByProject = (
    projectId: string,
    query?: {
      search?: string;
      milestoneIds?: string[];
      responsibleParties?: string[];
      severities?: string[];
      statuses?: string[];
      /** @example "name:desc,dueDate:desc,type:desc,milestoneName:asc,responsibleParty:asc,severity:asc,status:asc" */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<IssuePageDto, any>({
      path: `/projects/${projectId}/issues`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Get Task Count
   *
   * @tags task count
   * @name GetTaskCount
   * @summary Project Task Count
   * @request GET:/projects/{id}/reports/taskCount
   * @secure
   */
  getTaskCount = (id: string, params: RequestParams = {}) =>
    this.request<CounterReportDto, ErrorDto>({
      path: `/projects/${id}/reports/taskCount`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
