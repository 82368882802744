import React, { useState, useContext } from 'react';

type HelpCenterProps = React.PropsWithChildren<{}>;

interface HelpCenterData {
  isOpen: boolean;
  formInformation: {
    isShow: boolean;
    activeIndex: [];
  };
  onboardingProcessInformation: {
    isShow: boolean;
    activeIndex: [];
  };
  isShowButtonAllHelpTopic: boolean;
}

const initialState = {
  updateHelpCenter: (data: any) => data,
  helpCenter: {
    isOpen: false,
    formInformation: {
      isShow: false,
      activeIndex: [],
    },
    onboardingProcessInformation: {
      isShow: false,
      activeIndex: [],
    },
    isShowButtonAllHelpTopic: false,
  },
};

const HelpCenterContext = React.createContext(initialState);

function HelpCenterProvider({ children }: HelpCenterProps) {
  const updateHelpCenter = async (helpCenter: HelpCenterData) => {
    setState({ ...state, helpCenter });
  };

  const [state, setState] = useState({
    ...initialState,
    updateHelpCenter,
  });

  return <HelpCenterContext.Provider value={state}>{children}</HelpCenterContext.Provider>;
}

const useHelpCenter = () => {
  const { updateHelpCenter } = useContext(HelpCenterContext);
  return updateHelpCenter;
};

const useGetHelpCenter = () => {
  const { helpCenter } = useContext(HelpCenterContext);
  return helpCenter;
};

export { HelpCenterProvider, useGetHelpCenter, useHelpCenter };
export default HelpCenterContext;
