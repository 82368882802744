/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AccountProfileDto, ErrorDto, SitePageDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Profile<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get Profile a Account
   *
   * @tags account profile
   * @name GetAccountProfile
   * @summary Account Profile
   * @request GET:/profile/accounts/{id}
   * @secure
   */
  getAccountProfile = (id: string, params: RequestParams = {}) =>
    this.request<AccountProfileDto, ErrorDto>({
      path: `/profile/accounts/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Site list of my account
   *
   * @tags sites
   * @name GetSitesByAccountFromAccountProfile
   * @summary Site List
   * @request GET:/profile/accounts/{id}/sites
   * @secure
   */
  getSitesByAccountFromAccountProfile = (
    id: string,
    query?: {
      /** @example abcxyz */
      search?: string;
      /** @example name:asc,territory:desc,launchDomain,category */
      sortBy?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<SitePageDto, ErrorDto>({
      path: `/profile/accounts/${id}/sites`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
