import '@thg-harveynash/hyper-shared-components/build/scss/Saas.scss';
import React, { useEffect, useState } from 'react';
import './assets/scss/Global.scss';
import UpsUiModule from '@thg-harveynash/ups-ui-component';
import Layout from './layouts';
import { LayoutUps } from './layouts/layout-ups';
import { getRole } from './utils';
import { ROLE_TYPE } from './constant';
import { useGetUserPermissionQuery } from './apis/endpoints/authorizations';
import { useLocation } from 'react-router-dom';

const App = () => {
  const role = getRole();
  const [mode, setMode] = useState('cp');
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname?.includes('ups')) setMode('ups');
    else setMode('cp');
  }, [location]);

  const { data } = useGetUserPermissionQuery({ appCode: 'ups' });
  const permissionList = data?.data?.map((permission) => permission?.code);

  return (
    <>
      {mode === 'ups' && role === ROLE_TYPE.THG && data && data?.data?.length && permissionList?.length ? (
        <LayoutUps>
          <UpsUiModule permissionList={permissionList || []} />
        </LayoutUps>
      ) : (
        <>
          <Layout />
        </>
      )}
    </>
  );
};

export default App;
