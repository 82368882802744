import React from 'react';
import { Icon } from '@thg-harveynash/hyper-shared-components';
import { PERMISSIONS } from '../constant';

export type MenuItem = {
  key: string;
  label: string;
  isTitle: boolean;
  iconElement?: JSX.Element;
  permissions?: string[];
  parentKey?: string;
  url?: string;
  children?: MenuItem[];
};

export const MENU_ITEMS: MenuItem[] = [
  {
    key: 'application-setup',
    label: 'Application Setup',
    isTitle: false,
    iconElement: <Icon name="all_application" className="mdi mdi-cog" size={20} />,
    permissions: [
      PERMISSIONS.view_application_list,
      PERMISSIONS.view_module_list,
      PERMISSIONS.view_role_list,
      PERMISSIONS.view_permission_list,
    ],
    children: [
      {
        key: 'applications',
        parentKey: 'application-setup',
        label: 'Applications',
        isTitle: false,
        url: 'ups/applications',
        permissions: [PERMISSIONS.view_application_list],
      },
      {
        key: 'modules',
        parentKey: 'application-setup',
        label: 'Modules',
        isTitle: false,
        url: 'ups/modules',
        permissions: [PERMISSIONS.view_module_list],
      },
      {
        key: 'roles',
        parentKey: 'application-setup',
        label: 'Roles',
        isTitle: false,
        url: 'ups/roles',
        permissions: [PERMISSIONS.view_role_list],
      },
      {
        key: 'permissions',
        parentKey: 'application-setup',
        label: 'Permissions',
        isTitle: false,
        url: 'ups/permissions',
        permissions: [PERMISSIONS.view_permission_list],
      },
    ],
  },
  {
    key: 'organization-setup',
    label: 'Organization Setup',
    isTitle: false,
    iconElement: <Icon name="all_organization" className="mdi mdi-bag-checked" size={20} />,
    permissions: [PERMISSIONS.view_organization_list, PERMISSIONS.view_site_list, PERMISSIONS.view_locale_list],
    children: [
      {
        key: 'organizations',
        parentKey: 'organization-setup',
        label: 'Organizations',
        isTitle: false,
        url: 'ups/organizations',
        permissions: [PERMISSIONS.view_organization_list],
      },
      {
        key: 'sites',
        parentKey: 'organization-setup',
        label: 'Sites',
        isTitle: false,
        url: 'ups/sites',
        permissions: [PERMISSIONS.view_site_list],
      },
      {
        key: 'locales',
        parentKey: 'organization-setup',
        label: 'Locales',
        isTitle: false,
        url: 'ups/locales',
        permissions: [PERMISSIONS.view_locale_list],
      },
    ],
  },
  {
    key: 'user-management',
    label: 'User Management',
    isTitle: false,
    iconElement: <Icon name="all_organization" className="mdi mdi-card-account-details" size={20} />,
    permissions: [PERMISSIONS.view_user_list, PERMISSIONS.view_user_permissions_list],
    children: [
      {
        key: 'users',
        parentKey: 'user-management',
        label: 'Users',
        isTitle: false,
        url: 'ups/users',
        permissions: [PERMISSIONS.view_user_list],
      },
      {
        key: 'user-permissions',
        parentKey: 'user-management',
        label: 'User Permissions',
        isTitle: false,
        url: 'ups/user-permissions',
        permissions: [PERMISSIONS.view_user_permissions_list],
      },
      {
        key: 'roles-to-assign',
        parentKey: 'user-management',
        label: 'Roles to Assign',
        isTitle: false,
        url: 'ups/roles-to-assign',
        permissions: [PERMISSIONS.view_roles_to_assign_list],
      },
    ],
  },
];

export const getMenuItemByPermission = (permissionList: string[]) => {
  const childrenItem = MENU_ITEMS?.filter((menuItem: MenuItem) =>
    menuItem?.permissions?.some((permissionItem: string) => permissionList?.includes(permissionItem))
  );
  return childrenItem?.map((cItem: MenuItem) => {
    if (cItem?.children) {
      cItem.children = cItem.children?.filter((cPermission: MenuItem) =>
        cPermission?.permissions?.some((per: string) => permissionList?.includes(per))
      );
    }
    return cItem;
  });
};
