import { Types } from '../constant/action-type';
import { SiteDto, PageInfoDto } from '../accounts-api-client/data-contracts';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type SiteType = {
  siteList?: {
    items?: SiteDto[];
    pageInfo?: PageInfoDto;
  };
  siteDetail?: SiteDto;
};

type SitePayload = {
  [Types.GET_SITE_DETAIL]: {
    site: SiteDto;
  };
  [Types.GET_SITE]: {
    sites: {
      items?: SiteDto[];
      pageInfo?: PageInfoDto;
    };
  };
};

export type SiteActions = ActionMap<SitePayload>[keyof ActionMap<SitePayload>];

export const siteReducer = (state: SiteType, action: SiteActions) => {
  switch (action.type) {
    case Types.GET_SITE:
      const siteList = action.payload.sites || [];
      return { ...state, siteList };
    case Types.GET_SITE_DETAIL:
      const site = action.payload.site || {};
      return { ...state, siteDetail: site };
    default:
      return state;
  }
};
