import PTableSubRow from '../../../components/table/TableSubRow';
import RowExpand from './RowExpand';
import RowSubThird from './RowSubThird';

const RowSubSecond = (data: any, onClickSubTask: () => void, columns: any, isSub: any) => {
  return (
    <div className="p-sub-table">
      <PTableSubRow
        resizableColumns
        totalRecords={data.length}
        value={data}
        columns={columns}
        rows={1000}
        rowExpansionTemplate={RowExpand}
        rowSubTemplate={RowSubThird}
        isExpand={true}
        isSub={isSub}
        onClickSubTask={onClickSubTask}
        level={2}
      />
    </div>
  );
};

export default RowSubSecond;
