import classNames from 'classnames';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { IBulkAction } from './datatable.type';
import { SpinnerStyledBulkAction } from '../StyledComponent';

const BulkAction = ({ selectedRows, bulkactions }: { selectedRows: any[]; bulkactions: IBulkAction[] }) => {
  const selectedRender = () => {
    if (selectedRows.length) {
      return selectedRows.length > 1 ? `files` : `file`;
    }
    return ``;
  };
  return (
    <>
      {bulkactions.length > 0 && (
        <div className="w-100 pb-2">
          <div className="selection-action-group" data-testid="datatable-selection-action">
            <div className="me-2" style={{ color: '#6c6a7e' }}>{`${
              selectedRows.length
            } ${selectedRender()} selected`}</div>
            <ButtonGroup>
              {bulkactions.map((action, index) =>
                action.isLoading ? (
                  <div key={action.key || index} className="d-flex align-items-center">
                    <SpinnerStyledBulkAction />
                  </div>
                ) : (
                  <Button
                    key={action.key || index}
                    variant="link"
                    data-testid={action.dataTestId}
                    className={classNames('d-flex', 'align-items-center', action.className)}
                    onClick={() => action.onClick(selectedRows)}
                    disabled={action.disabled}
                  >
                    {action.icon && (
                      <i
                        className={classNames('d-flex align-items-center justify-content-center', action.icon)}
                        style={{ fontSize: 16, marginRight: 6 }}
                      />
                    )}
                    {action.iconSrc && (
                      <img src={action.iconSrc} width={16} alt={action.label} style={{ marginRight: 6 }} />
                    )}
                    <span style={{ textDecoration: 'underline' }}>{action.label}</span>
                  </Button>
                )
              )}
            </ButtonGroup>
          </div>
        </div>
      )}
    </>
  );
};

export default BulkAction;
