import { AxiosResponse } from 'axios';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import request from '../request';
import { QueryOptions } from '../types';

export type LocationContext = {
  organization: {
    id: string;
    erpId: string;
  };
};

export type AuthorizationPermission = {
  id: string;
  code: string;
  needOrganization?: boolean;
  needSite?: boolean;
  locationContexts?: LocationContext[];
};

type UserPermissionResponse = {
  getPermission: AxiosResponse<AuthorizationPermission[]>;
};

type UserPermissionParam = {
  appCode?: string;
};

type UserPermissionQueryKey = {
  getPermission: ['getAuthorizationPermission', UserPermissionParam];
};

type AuthorizationAPI = {
  getPermission: QueryFunction<UserPermissionResponse['getPermission'], UserPermissionQueryKey['getPermission']>;
};

const locale: AuthorizationAPI = {
  getPermission: ({ queryKey: [, params] }) => request.get(`authz/users/permissions`, { params }),
};

export const useGetUserPermissionQuery = (
  params: UserPermissionParam,
  options?: QueryOptions<UserPermissionResponse['getPermission'], UserPermissionQueryKey['getPermission']>
) => useQuery(['getAuthorizationPermission', params], locale.getPermission, options);
