import axios from 'axios';
import { getToken } from '../utils';

export const accountsInstance = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_PORTAL_API_PROJECT_URL,
});

export const projectsInstance = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_PORTAL_API_PROJECT_URL,
});

export const fileInstance = axios.create({
  baseURL: process.env.REACT_APP_FILE_MANAGEMENT_API_URL,
});

export const formInstance = axios.create({
  baseURL: process.env.REACT_APP_FORM_MANAGEMENT_API_URL,
});

export const userInstance = axios.create({
  baseURL: process.env.REACT_APP_USER_MANAGEMENT_API_URL,
});

export const upsInstance = axios.create({
  baseURL: process.env.REACT_APP_UPS_API_URL,
});

[accountsInstance, projectsInstance, fileInstance, formInstance, userInstance, upsInstance].forEach((instance) => {
  instance.interceptors.request.use(async (config) => {
    const token = getToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };
    return config;
  });
});
