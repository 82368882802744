import { ReactNode } from 'react';
import { ColumnProps } from 'primereact/column';

export enum FormInputType {
  SELECT = 'select',
  INPUT = 'input',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SWITCH = 'switch',
  PASSWORD = 'password',
  HIDDEN = 'hidden',
  TEXTAREA = 'textarea',
  TEXT = 'text',
}

export interface IColumn extends ColumnProps {
  label?: string | ReactNode;
  field?: string;
  isHidden?: boolean;
}
