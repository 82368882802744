import { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Users } from '../../user-api-client/Users';
import { useAccountSelected } from '../../context';
import { convertTimeUTC, handleErrorStatus, getActionUserStatusText, handleErrorLogMessage } from '../../utils';
import { DetailInforCustomSize, LabelInforNoneMargin, SpinnerInButtonStyled } from '../../components/StyledComponent';
import LayoutDetail from '../../components/layout-detail';
import { ACTION_USER, DATE_TIME_FORMAT, EMAIL_VERIFIED, PERMISSIONS, ROUTER, UNKNOWN_ERROR_CODE } from '../../constant';
import { upperFirst, lowerCase } from 'lodash';
import { useToast } from '../../context/toast-context';
import { UserAction } from '../../user-api-client/data-contracts';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import { usePermissions } from '../../context/auth-context';

const UserDetailPage = () => {
  const { permissionList } = usePermissions();
  const canActivateUser = permissionList.includes(PERMISSIONS.activate_deactivate_user);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const accountSelected = useAccountSelected();
  let params = useParams();
  const id = params && params.id ? params.id : '';
  let isUnconfirmedEmail = user?.status === EMAIL_VERIFIED.UNCONFIRMED;
  const userApi = new Users();
  const navigate = useNavigate();
  const toast = useToast();

  const breadcrumbItems = [
    { path: `${ROUTER.CONFIGURATION_USER}`, name: 'Users', active: false },
    { path: '', name: user.name, active: true },
  ];

  const getUserDetail = async (userId: string) => {
    try {
      await userApi
        .getUserDetail(userId)
        .then((res) => {
          setUser(res.data);
        })
        .catch((error) => {
          handleErrorStatus(error.status);
          navigate(ROUTER.ERROR_PAGE);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    } catch (e) {
      navigate('/error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionUser = async () => {
    setIsLoadingButton(true);
    if (user?.status === ACTION_USER.ACTIVE || user?.status === ACTION_USER.INACTIVE) {
      const status = user?.status === ACTION_USER.ACTIVE ? UserAction.DEACTIVATE : UserAction.ACTIVATE;
      await userApi
        .updateUserStatus(id, { action: status })
        .then((res) => {
          setUser(res.data);
          toast({
            message: `User has been ${status === UserAction.ACTIVATE ? 'activated' : 'deactivated'} successfully!`,
            type: 'success',
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    }
    if (user?.status === ACTION_USER.LOCKED) {
      await userApi
        .updateUserStatus(id, { action: UserAction.UNLOCKED })
        .then((res) => {
          setUser(res.data);
          toast({
            message: `User has been unlocked successfully!`,
            type: 'success',
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    }
    if (user?.status === ACTION_USER.UNCONFIRMED) {
      await userApi
        .resendVerificationEmail(id)
        .then(() => {
          toast({
            message: `Verification email has been sent successfully!`,
            type: 'success',
          });
        })
        .catch((error) => {
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_USER_C_UNKNOWN, toast);
        });
    }
    setIsLoadingButton(false);
  };
  const onChangePath = (path: string) => {
    navigate(path);
  };
  return (
    <LayoutDetail
      breadcrumbItems={breadcrumbItems}
      pageTitle={`${user?.name}: View Details`}
      onChangePathBreadCrumb={(path: string) => onChangePath(path)}
      isLoading={isLoading}
    >
      <Card.Body>
        <Row className="user-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Account</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 user-account">
            <DetailInforCustomSize>{accountSelected?.name}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="user-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Title</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 user-title">
            <DetailInforCustomSize>{user?.attributes?.title}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="user-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Name</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 user-name">
            <DetailInforCustomSize>{user?.name}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="user-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Email</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 user-email">
            <DetailInforCustomSize>{user?.email}</DetailInforCustomSize>
          </Col>
        </Row>
        <Row className="user-info detail-info">
          <Col lg={3} className="p-2">
            <LabelInforNoneMargin>Status</LabelInforNoneMargin>
          </Col>
          <Col lg={9} className="p-2 user-status">
            <DetailInforCustomSize>
              {upperFirst(lowerCase(isUnconfirmedEmail ? EMAIL_VERIFIED.UNCONFIRMED : user?.status))}
            </DetailInforCustomSize>
          </Col>
        </Row>
        <Card.Text className="text-end mb-0 user-create">
          <small>
            Created By: {user?.createdBy} | Created Date: {convertTimeUTC(user?.createdAt, DATE_TIME_FORMAT)}
          </small>
        </Card.Text>
        <Card.Text className="text-end user-modify">
          <small>
            Modified By: {user?.modifiedBy ? user?.modifiedBy : user?.createdBy} | Modified Date:{' '}
            {user?.modifiedAt
              ? convertTimeUTC(user?.modifiedAt, DATE_TIME_FORMAT)
              : convertTimeUTC(user?.createdAt, DATE_TIME_FORMAT)}
          </small>
        </Card.Text>
      </Card.Body>
      <div className="form px-3">
        <div className="form-action d-flex flex-row align-items-center justify-content-between">
          <Button type="button" variant="outline-primary" onClick={() => navigate(-1)}>
            Back
          </Button>
          {canActivateUser && (
            <Button type="button" variant="primary" onClick={handleActionUser} disabled={isLoadingButton}>
              {isLoadingButton ? (
                <SpinnerInButtonStyled>
                  <Spinner size="sm" />
                </SpinnerInButtonStyled>
              ) : (
                getActionUserStatusText(user?.status)
              )}
            </Button>
          )}
        </div>
      </div>
    </LayoutDetail>
  );
};

export default UserDetailPage;
