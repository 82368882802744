import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Tooltip as PrimereactTooltip } from 'primereact/tooltip';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Accounts } from '../../accounts-api-client/Accounts';
import { LIST_SITES_COLUMNS, PERMISSIONS, ROUTER, TITLE_SITE_TABLE, UNKNOWN_ERROR_CODE } from '../../constant';
import { Types } from '../../constant/action-type';
import { useAccountDetail, useDispatch, useLeftMenuStyle, useSiteList } from '../../context';
import {
  getListIdShowTooltipInPrimeTable,
  getPerPageTable,
  handleErrorStatus,
  getSelectOptionAndArrayStringOption,
  handleErrorLogMessage,
} from '../../utils';
import { ParametersSearchI } from '../account-page';
import queryString from 'query-string';
import { Settings } from '../../accounts-api-client/Settings';
import { CommonActionBar, Datatable, FormReactSelect } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { Button, Row, Col } from 'react-bootstrap';
import { BrandDto, SiteStatusDto } from '../../accounts-api-client/data-contracts';
import { usePermissions } from '../../context/auth-context';
import { Brands } from '../../accounts-api-client/Brands';
import { GlobalConfig } from '../../global';
import useAccessibility from '../../utils/useAccessibility';
import { useToast } from '../../context/toast-context';

export interface ICol {
  field: string;
  header: string;
}

// eslint-disable-next-line prettier/prettier
function ListSite() { //NOSONAR
  const perPageTable = getPerPageTable();
  const location = useLocation();
  const paramFromUrl = queryString.parse(location.search);
  const { search, limit, page, sortBy = '', siteStatus, brandName } = paramFromUrl; //NOSONAR
  const sortBySplit = (sortBy as string).split(':');
  const sortField = sortBySplit[0];
  const sortOrder = sortBySplit[1] === 'asc' ? 1 : -1;
  const newOption = getSelectOptionAndArrayStringOption(siteStatus);
  const newOptionBrand = getSelectOptionAndArrayStringOption(brandName);

  const [parametersSearch, setParametersSearch] = useState<any>({
    sortField: sortField || 'crmSiteRef',
    sortOrder: sortOrder || -1,
    page: page || 0,
    rows: Number(limit) || perPageTable,
    searchKey: search || '',
    siteStatus: newOption.arrayStringOption || [],
    brandName: newOptionBrand.arrayStringOption || [],
    first: limit && page ? Number(limit) * Number(page) : 0,
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [listTooltipActive, setListTooltipActive] = useState<Array<string>>([]);
  const [listOptions, setListOptions] = useState<any>([]);
  const [brandOptions, setBrandOptions] = useState<any>([]);
  const [listSelectedBrand, setListSelectedBrand] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(newOption.selectOption || []);
  const [showFilter, setShowFilter] = useState(false);
  const [onFilter, setOnFilter] = useState<((data: any) => void) | null>(null);
  const [isFilter, setIsFilter] = useState(false);

  const navigate = useNavigate();
  const { account_id } = useParams();
  const dispatch = useDispatch();
  const siteList = useSiteList();
  const accountDetail = useAccountDetail();
  const leftMenuStyle = useLeftMenuStyle();
  const { addAriaLabelToTable } = useAccessibility();
  const { permissionList } = usePermissions();
  const canViewDetails = permissionList.includes(PERMISSIONS.view_site_details);

  const accountApi = new Accounts();
  const brandApi = new Brands();

  const siteStatusApi = new Settings();
  const toast = useToast();

  const getSiteStatus = async () => {
    await siteStatusApi
      .getSiteStatus()
      .then((res) => {
        if (res?.data?.length) {
          const newListOptions = res.data.map((option: SiteStatusDto) => {
            return {
              value: option.displayValue,
              label: option.displayValue,
            };
          });
          setListOptions(newListOptions);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
      });
  };

  const getBrand = async () => {
    await brandApi
      .getBrands()
      .then((res) => {
        if (res?.data?.items?.length) {
          const newListOptions = res.data.items.map((option: BrandDto) => {
            return {
              value: option.name,
              label: option.name,
            };
          });
          setBrandOptions(newListOptions);
        }
      })
      .catch((error) => {
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
      });
  };

  const getAccountDetail = async (id: string) => {
    try {
      await accountApi
        .getAccount(id)
        .then((res) => {
          dispatch({
            type: Types.GET_ACCOUNT_DETAIL,
            payload: {
              account: res.data,
            },
          });
        })
        .catch((error) => {
          handleErrorStatus(error?.status);
          handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_ACCOUNT_UNKNOWN, toast);
        });
    } catch (error) {
      navigate('/error');
    }
  };

  const fetchData = (id: string, param?: any) => {
    setIsLoadingTable(true);
    let paramSubmit: any = {};
    if (param) {
      const {
        rows,
        page: pageParam,
        sortField: sortFieldParam,
        sortOrder: sortOrderParam,
        searchKey,
        siteStatus: siteStatusParam,
        first,
        brandName: brandNameParam,
      } = param;
      if (siteStatusParam.length > 0 || brandNameParam?.length > 0) {
        setIsFilter(true);
      } else {
        setIsFilter(false);
      }
      localStorage.setItem('perPageTable', rows);
      setParametersSearch({
        rows,
        page: pageParam,
        sortField: sortFieldParam,
        sortOrder: sortOrderParam,
        searchKey,
        siteStatus: siteStatusParam,
        first,
        brandName: brandNameParam,
      });
      const sortByParam = `${sortFieldParam}:${sortOrderParam === 1 ? 'asc' : 'desc'}`;
      paramSubmit = {
        search: searchKey || '',
        limit: Number(rows) || 0,
        page: Number(pageParam) || 0,
        sortBy: sortByParam,
        siteStatus: siteStatusParam,
        brandName: brandNameParam,
      };
      updateUrlSearch(paramSubmit);
    }
    accountApi
      .getSitesByAccount(id, paramSubmit)
      .then((res) => {
        const listSite = res.data;
        dispatch({
          type: Types.GET_SITE,
          payload: { sites: listSite },
        });
        setIsLoadingTable(false);
      })
      .catch((error) => {
        setIsLoadingTable(false);
        handleErrorStatus(error?.status);
        handleErrorLogMessage(error, UNKNOWN_ERROR_CODE.ERR_CP_SITE_UNKNOWN, toast);
      });
  };

  useEffect(() => {
    getBrand();
    getSiteStatus();
    if (account_id) {
      getAccountDetail(account_id);
      fetchData(account_id, parametersSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const newArrTooltipsActive = getListIdShowTooltipInPrimeTable([1, 2]);
    setListTooltipActive(newArrTooltipsActive);
  }, [siteList, leftMenuStyle]);

  const siteNameAndZohoProjectBodyTemplate = (rowData: any, isRenderSiteName?: boolean, siteField?: string) => {
    const fieldDisplay = isRenderSiteName ? rowData?.name : rowData?.projectId;
    const idActiveTooltip = `${rowData.id}-${isRenderSiteName ? '1' : '2'}`;
    return (
      <a
        href={
          isRenderSiteName
            ? `${GlobalConfig().config.REACT_APP_CRM_ZOHO_URL}${rowData?.organization?.domainName}/tab/${
                rowData?.moduleName
              }/${rowData?.crmSysId}`
            : rowData?.projectUrl || '#'
        }
        target="_blank"
        rel="noreferrer noopener"
        aria-label={rowData?.name}
        id={siteField ? `${rowData.id}-${rowData?.name}-${siteField}` : `${rowData.id}-${rowData?.name}`}
      >
        <PrimereactTooltip
          target={`.site-name-${idActiveTooltip}`}
          content={listTooltipActive.includes(idActiveTooltip) ? fieldDisplay : ''}
          position="top"
        />
        <u className={`site-name-${idActiveTooltip}`}>{fieldDisplay}</u>
      </a>
    );
  };

  // config column for Sites table
  const listSitesCol = LIST_SITES_COLUMNS.map((site: ICol) => {
    if (site.field === TITLE_SITE_TABLE.NAME) {
      return {
        resizeable: true,
        field: site.field,
        label: site.header,
        sortable: true,
        body: (data: any) => siteNameAndZohoProjectBodyTemplate(data, true, site.field),
        className: 'max-width-none',
        style: { minWidth: '220px' },
      };
    }
    if (site.field === TITLE_SITE_TABLE.BRAND_NAME) {
      return {
        resizeable: true,
        field: site.field,
        label: site.header,
        sortable: true,
        body: (data: any) => data?.brand?.name,
        className: 'max-width-none',
        style: { minWidth: '220px' },
      };
    }
    if (site.field === TITLE_SITE_TABLE.PROJECT_ID) {
      return {
        resizeable: true,
        field: site.field,
        label: site.header,
        sortable: true,
        body: (data: any) => siteNameAndZohoProjectBodyTemplate(data),
        className: 'max-width-none',
        style: { minWidth: '260px' },
      };
    }
    if (site.field === TITLE_SITE_TABLE.SITE_STATUS) {
      return {
        resizeable: true,
        field: site.field,
        label: site.header,
        sortable: true,
        style: { maxWidth: '160px', minWidth: '160px' },
      };
    }
    if (site.field === TITLE_SITE_TABLE.ACTIVE_STATUS) {
      return {
        resizeable: true,
        field: site.field,
        label: site.header,
        sortable: true,
        body: (data: any) => (
          <>
            <i
              className="mdi mdi-circle me-1"
              style={{ fontSize: 10, color: data?.activeStatus ? 'green' : '#C42C21' }}
            />
            {data?.activeStatus ? 'Active' : 'Inactive'}
          </>
        ),
        style: { maxWidth: '130px', minWidth: '130px' },
      };
    }

    return {
      resizeable: true,
      field: site.field,
      label: site.header,
      sortable: true,
      className: 'max-width-none',
      style: { minWidth: '220px' },
    };
  });

  const updateUrlSearch = (param: ParametersSearchI) => {
    const urlSearch = queryString.stringify(param, {
      skipEmptyString: true,
      skipNull: true,
    });
    if (urlSearch !== 'limit=10&page=0&sortBy=crmSiteRef%3Adesc') {
      navigate(`?${urlSearch}`);
    }
  };

  const onSelectRow = (event: any) => {
    navigate(`/configuration/accounts/${account_id}/sites/${event.data.id}`);
  };

  // trigger function whenever table change
  const customFetch = useCallback((params: any) => {
    if (account_id) {
      fetchData(account_id, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbItems = [
    {
      name: 'Accounts',
      path: ROUTER.CONFIGURATION_ACCOUNT,
    },
    {
      name: accountDetail?.name || '',
      path: `${ROUTER.CONFIGURATION_ACCOUNT}/${account_id}`,
    },
    {
      name: 'Sites',
      path: `${ROUTER.CONFIGURATION_ACCOUNT}/${account_id}/sites`,
      active: true,
    },
  ];

  const onClickFilterButton = useCallback((inputOnFilter: any) => {
    setOnFilter(() => inputOnFilter);
    setShowFilter(true);
  }, []);

  const onReset = () => {
    setSelectedOption([]);
    setListSelectedBrand([]);
    let paramSubmit: any = {};
    paramSubmit = {
      searchKey: search || '',
      sortOrder: sortOrder || -1,
      rows: Number(limit) || perPageTable,
      page: Number(page) || 0,
      sortField: sortField,
      siteStatus: [],
      first: limit && page ? Number(limit) * Number(page) : 0,
      brandName: [],
    };
    updateUrlSearch(paramSubmit);
    if (account_id) {
      fetchData(account_id, paramSubmit);
    }
    onFilter &&
      onFilter({
        siteStatus: [],
        brandName: [],
      });
    setTimeout(() => {
      setShowFilter(false);
    }, 100);
  };

  const onFilterSidebar = useCallback(
    (data: any) => {
      onFilter && onFilter(data);
    },
    [onFilter]
  );

  useEffect(() => {
    if (!showFilter) {
      setSelectedOption(newOption.selectOption || []);
      setListSelectedBrand(newOptionBrand.selectOption || []);
    }
  }, [showFilter, location]);

  const handleFilterField = () => {
    let filterText = [
      newOption.arrayStringOption.length > 0 ? 'Site Status' : '',
      newOptionBrand.arrayStringOption.length > 0 ? ' Brand Name' : '',
    ];
    return filterText.filter((item) => item).join();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      addAriaLabelToTable();
    }, 300);
    return () => clearTimeout(timer);
  }, [siteList]);

  return (
    <>
      <CommonActionBar
        pageTitle="Sites"
        isVisibleButton={false}
        breadcrumbItems={breadcrumbItems}
        onChangePath={(path: string) => navigate(path)}
      />
      <div className="page-content">
        {isFilter && (
          <h4 className="mb-3 datatable-title">{`Sites (${
            siteList?.pageInfo?.total
          }) - Filtered by: ${handleFilterField()}`}</h4>
        )}

        <Datatable
          fetchData={customFetch}
          totalRecords={siteList?.pageInfo?.total || 0}
          data={siteList?.items || []}
          isLoading={isLoadingTable}
          {...(canViewDetails && { onRowSelect: onSelectRow })}
          columns={listSitesCol}
          tableName="sites"
          emptyMessage="No results found."
          initParams={parametersSearch}
          columnResizeMode="fit"
          sortField={parametersSearch.sortField}
          searchKey={parametersSearch.searchKey}
          isFilter={true}
          isHideShowingAllLabel={isFilter}
          onClickFilterButton={onClickFilterButton}
          scrollable
        />
      </div>
      <RightSidebar
        title="Filters"
        setShowRightSidebar={(value) => {
          setShowFilter(value);
        }}
        showRightSidebar={showFilter}
      >
        <div className="rightsidebar-content-height">
          <FormReactSelect
            value={selectedOption}
            onChange={setSelectedOption}
            name="siteStatus"
            label="Site Status"
            placeholder="Select Site Status"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3 fit-width-label-container"
            options={listOptions}
            isMulti
          />
          <FormReactSelect
            value={listSelectedBrand}
            name="brandStatus"
            label="Brand Name"
            labelContainerClass="mb-1"
            labelGrid={12}
            containerClass="mb-3"
            options={brandOptions}
            placeholder="Select Brand Name"
            onChange={setListSelectedBrand}
            isMulti
          />
          <Row>
            <Col sm={6}>
              <Button
                className="w-100 justify-content-center"
                type="button"
                onClick={() => onReset()}
                variant="outline-primary"
              >
                Reset
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                type="button"
                className="w-100 justify-content-center"
                onClick={() => {
                  setShowFilter(false);
                  onFilterSidebar({
                    siteStatus: selectedOption.map((field: any) => field.value),
                    brandName: listSelectedBrand.map((option: { value: string }) => option.value),
                  });
                }}
              >
                Apply filter
              </Button>
            </Col>
          </Row>
        </div>
      </RightSidebar>
    </>
  );
}

export default ListSite;
