import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'primeicons/primeicons.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/auth-context';
import { GlobalConfig } from './global';
import { HelpCenterProvider } from './context/help-center-context';
import { ToastProvider } from './context/toast-context';
import { WarningModalProvider } from './context/warning-modal-context';
import { AppProvider } from './context';
import UserTour from './layouts/UserTour';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// create query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  },
});

fetch(`${window.location.origin}/config.json`)
  .then((res) => res.json())
  .then((data) => {
    GlobalConfig().config = data;
    root.render(
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <WarningModalProvider>
            <AuthProvider>
              <ToastProvider>
                <HelpCenterProvider>
                  <AppProvider>
                    <Routes>
                      <Route path="/*" element={<App />} />
                    </Routes>
                    <UserTour />
                  </AppProvider>
                </HelpCenterProvider>
              </ToastProvider>
            </AuthProvider>
          </WarningModalProvider>
        </BrowserRouter>
      </QueryClientProvider>
    );
  })
  .catch((error) => console.log(error));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
