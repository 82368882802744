/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type OrganizationDto = BaseDto & {
  /** @format uuid */
  id?: string;
  crmSysId?: string;
  name?: string;
  isDefault?: boolean;
  domainName?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string;
  /** @format date-time */
  modifiedAt?: string;
  modifiedBy?: string;
};

export interface SimpleAccountDto {
  /** @format uuid */
  id?: string;
  crmSysId?: string;
  crmAcctId?: string;
  name?: string;
  workdriveUrl?: string;
}

export type AccountDto = BaseDto & {
  /** @format uuid */
  id?: string;
  crmSysId?: string;
  crmAcctId?: string;
  name?: string;
  portalActive?: boolean;
  parent?: ParentAccountDto;
  organization?: OrganizationDto;
  website?: string;
  workdriveUrl?: string;
  workdriveFolderId?: string;
  industry?: string;
  mainContactEmail?: string;
  mainContactPhone?: string;
  billingStreet?: string;
  billingCity?: string;
  billingState?: string;
  billingCode?: string;
  billingCountry?: string;
  leadContact?: LeadContactDto;
  publicWorkdriveFolderId?: string;
};

export interface AccountProfileDto {
  /** @format uuid */
  id?: string;
  crmSysId?: string;
  name?: string;
  parent?: ParentAccountDto;
  industry?: string;
  mainContactEmail?: string;
  mainContactPhone?: string;
  billingStreet?: string;
  billingCity?: string;
  billingState?: string;
  billingCode?: string;
  billingCountry?: string;
  leadContact?: LeadContactDto;
}

export type SiteDto = BaseDto & {
  /** @format uuid */
  id?: string;
  account?: AccountDto;
  parent?: ParentSiteDto;
  organization?: OrganizationDto;
  crmSysId?: string;
  crmSiteRef?: string;
  name?: string;
  rollOutType?: string;
  siteStatus?: string;
  category?: string;
  territory?: string;
  launchLocale?: string;
  projectId?: string;
  projectUrl?: string;
  moduleName?: string;
  /** @format date */
  plannedLiveDate?: string;
  /** @format date */
  actualLiveDate?: string;
  /** @format date-time */
  rtdPublishedToPortal?: string;
  workdriveUrl?: string;
  workdriveFolderId?: string;
  portalActive?: boolean;
  pm?: PmInfoDto;
  seniorPm?: PmInfoDto;
  adHocFormsRequired?: string[];
  launchDomain?: string;
  brand?: BrandDto;
};

export interface PmInfoDto {
  id?: string;
  name?: string;
  email?: string;
}

export type BrandDto = BaseDto & {
  /** @format uuid */
  id?: string;
  crmSysId?: string;
  name?: string;
  originalSite?: SiteDto;
};

export interface ParentAccountDto {
  /** @format uuid */
  id?: string;
  name?: string;
  crmSysId?: string;
}

export interface LeadContactDto {
  name?: string;
}

export interface ParentSiteDto {
  /** @format uuid */
  id?: string;
  accountId?: string;
  name?: string;
  crmSysId?: string;
}

export interface AccountPageDto {
  items?: AccountDto[];
  pageInfo?: PageInfoDto;
}

export interface SitePageDto {
  items?: SiteDto[];
  pageInfo?: PageInfoDto;
}

export interface SimpleAccountPageDto {
  items?: SimpleAccountDto[];
  pageInfo?: PageInfoDto;
}

export interface PageInfoDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  total?: number;
  hasNext?: boolean;
}

export type DataMapperDto = BaseDto & {
  fieldKey?: string;
  type?: string;
  crmFieldKey?: string;
  dataType?: string;
  dataFormat?: string;
  subType?: string;
  mandatory?: boolean;
};

export type SiteStatusDto = BaseDto & {
  displayValue?: string;
  actualValue?: string;
  value?: string;
};

export type AdhocFormsRequiredDto = BaseDto & {
  displayValue?: string;
  actualValue?: string;
  value?: string;
};

export type UsersDepartmentsDto = BaseDto & {
  displayValue?: string;
  actualValue?: string;
  value?: string;
};

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortItemDto {
  field?: string;
  type?: SortType;
}

export interface QueryParamDto {
  search?: string;
  sortBy?: SortItemDto;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  limit?: number;
}

export interface ErrorDto {
  code?: string;
  message?: string;
  /** @format date-time */
  timestamp?: string;
}

export enum ExtSystemKey {
  ZOHO_CRM = 'ZOHO_CRM',
  ZOHO_PROJECTS = 'ZOHO_PROJECTS',
  ZOHO_WORKDRIVE = 'ZOHO_WORKDRIVE',
  ZOHO_ACCOUNT = 'ZOHO_ACCOUNT',
  ZOHO_DESK = 'ZOHO_DESK',
}

export interface PongMessage {
  message?: string;
}

export enum EventType {
  ORGANIZATION_SYNC_UP = 'ORGANIZATION_SYNC_UP',
  ACCOUNT_SYNC_UP = 'ACCOUNT_SYNC_UP',
  SITE_STATUS_SYNC_UP = 'SITE_STATUS_SYNC_UP',
  SITE_ADHOC_CONDITION_SYNC_UP = 'SITE_ADHOC_CONDITION_SYNC_UP',
}

export interface EventDto {
  event?: EventType;
  /** @format date-time */
  timestamp?: string;
}

export interface BaseDto {
  activeStatus?: boolean;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string;
  /** @format date-time */
  modifiedAt?: string;
  modifiedBy?: string;
}

export interface ProjectParamDto {
  projectExtId: string;
}

export interface GenerateFormForNewAccountRequestDto {
  account?: AccountDto;
  pageRequest?: PageRequestDto;
}

export interface PageRequestDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  limit?: number;
}

export interface BrandPageDto {
  items?: BrandDto[];
  pageInfo?: PageInfoDto;
}
