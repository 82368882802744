import React from 'react';
import { IDatatable } from './datatable.type';
import styled from 'styled-components';

const TitleTable = styled.h2`
  font-size: 20px;
  line-height: 28px;
`;

const ShowingAllLabel = (props: IDatatable) => {
  const { isHideShowingAllLabel, showingAllLabel, tablename } = props;
  return <>{!isHideShowingAllLabel && <TitleTable className="mb-3">{showingAllLabel || tablename}</TitleTable>}</>;
};

export default ShowingAllLabel;
