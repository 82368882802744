import React, { useState, useContext } from 'react';

type ToastProps = React.PropsWithChildren<{}>;

interface ToastData {
  code: string;
  message: string;
  type?: string;
}

const initialState = {
  updateToast: (data: any) => data,
  toast: {},
};

const ToastContext = React.createContext(initialState);

function ToastProvider({ children }: ToastProps) {
  const updateToast = async (toast: ToastData) => {
    setState({ ...state, toast });
  };

  const [state, setState] = useState({
    ...initialState,
    updateToast,
  });

  return <ToastContext.Provider value={state}>{children}</ToastContext.Provider>;
}

const useToast = () => {
  const { updateToast } = useContext(ToastContext);
  return updateToast;
};

const useGetToast = () => {
  const { toast } = useContext(ToastContext);
  return toast;
};

export { ToastProvider, useGetToast, useToast };
export default ToastContext;
