import React, { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { LeftSidebarHeaderProps, MenuItemType } from '@thg-harveynash/hyper-shared-components/build/types';
import { getMenuItemByPermission } from './router';
import { usePermissions } from '../context/auth-context';

interface LeftSidebarProps {
  setIsShowLeftMenu?: any;
  isShowLeftMenu?: boolean;
  hanldeClickHambuger?: any;
}

const LeftSidebarMenu = ({ setIsShowLeftMenu, isShowLeftMenu }: LeftSidebarProps): ReactElement<any> => {
  const location = useLocation();
  const navigate = useNavigate();

  const { permissionList } = usePermissions();

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('[data-menu-key="management"]').forEach((el) => {
        el.setAttribute('data-tut', 'management');
      });
      document.querySelectorAll('[data-menu-key="configuration"]').forEach((el) => {
        el.setAttribute('data-tut', 'configuration');
      });
    }, 100);
  }, []);

  const onClickMenuItem = (item: MenuItemType) => {
    // @ts-ignore
    navigate(item?.url);
  };

  const leftSidebarHeader: LeftSidebarHeaderProps = {
    isShowIconLeftWhenUnCollapsed: true,
    iconLeft: {
      name: 'logo',
      src: isShowLeftMenu ? '/THGi_logo.png' : '/Ingenuity_Logo_White.png',
      style: {
        width: isShowLeftMenu ? 25 : '95%',
      },
    },
    titleClassName: 'sidebar_header_text',
  };

  return (
    <>
      <aside className="layout_sidebar">
        <LeftSidebar
          currentPathname={location.pathname}
          menuItems={getMenuItemByPermission(permissionList)}
          header={{
            ...leftSidebarHeader,
            collapsed: isShowLeftMenu,
            onClickIconRight: () => setIsShowLeftMenu(!isShowLeftMenu),
          }}
          onClickItem={(item) => onClickMenuItem(item)}
        />
      </aside>
    </>
  );
};

export default LeftSidebarMenu;
