export { ROUTER } from './router';

export const TIMEOUT = 10000;

export const ROLE_TYPE = {
  THG: 'THG',
  CLIENT: 'Client',
  INITIAL_ACCESS: 'Initial Access',
};

export const ROLE = {
  THG: [
    'home_read',
    'management_read',
    'management_form_read',
    'configuration_read',
    'configuration_account_read',
    'configuration_form_read',
    'configuration_file_read',
    'configuration_role_read',
    'configuration_thg_user_read',
    'configuration_site_read',
    'initial_access',
  ],
  CLIENT: [
    'home_read',
    'management_read',
    'management_project_read',
    'management_form_read',
    'management_document_red',
    'configuration_read',
    'configuration_role_read',
    'configuration_user_read',
    'configuration_client_user_read',
    'help_read',
    'account_profile',
    'related_sites',
    'initial_access',
  ],
  INITIAL_ACCESS: ['initial_access'],
};

export const ACCESS_TOKEN = 'role';

export const LEFT_MENU_STYLE = {
  CONDENSED: 'CONDENSED',
  EXPANDED: 'EXPANDED',
};

export const RIGHT_MENU_STYLE = {
  CONDENSED: 'CONDENSED',
  EXPANDED: 'EXPANDED',
};

export const LAYOUT_MODE = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export const LEFT_MENU_MODE = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};
export const LIST_ACCOUNTS_COLUMNS = [
  { field: 'crmAcctId', header: 'CRM Account ID' },
  { field: 'name', header: 'Account Name' },
  { field: 'website', header: 'Website' },
  { field: 'activeStatus', header: 'Status' },
];

export const LIST_SITES_COLUMNS = [
  { field: 'crmSiteRef', header: 'CRM Site Reference' },
  { field: 'name', header: 'Site Name' },
  { field: 'brandName', header: 'Brand Name' },
  { field: 'projectId', header: 'Zoho Projects Project ID' },
  { field: 'siteStatus', header: 'Site Status' },
  { field: 'activeStatus', header: 'Status' },
];

export const TITLE_PROJECT_TABLE = {
  PROJECT_NAME: 'projectName',
  TASK_CLIENT_ACTION_REQUIRED_COUNT: 'taskClientActionRequiredCount',
  PROJECT_STATUS: 'status',
  TERRITORY: 'territory',
  GO_LIVE_DATE: 'goLiveDate',
  PROJECT_MANAGER: 'pmName',
  RAG_STATUS: 'ragStatus',
};
export const LIST_PROJECTS_COLUMNS = [
  { field: TITLE_PROJECT_TABLE.PROJECT_NAME, header: 'Project Name' },
  { field: TITLE_PROJECT_TABLE.TASK_CLIENT_ACTION_REQUIRED_COUNT, header: 'Client Action Required' },
  { field: TITLE_PROJECT_TABLE.PROJECT_STATUS, header: 'Project Status' },
  { field: TITLE_PROJECT_TABLE.TERRITORY, header: 'Territory' },
  { field: TITLE_PROJECT_TABLE.GO_LIVE_DATE, header: 'Go Live Date' },
  { field: TITLE_PROJECT_TABLE.PROJECT_MANAGER, header: 'Project Manager' },
  { field: TITLE_PROJECT_TABLE.RAG_STATUS, header: 'RAG Status' },
];

export const TITLE_MILESTONE_TABLE = {
  MILESTONE_NAME: 'name',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  DURATION: 'duration',
  STATUS: 'status',
};

export const LIST_MILESTONE_COLUMNS = [
  { field: TITLE_MILESTONE_TABLE.MILESTONE_NAME, header: 'Milestone Name' },
  { field: TITLE_MILESTONE_TABLE.START_DATE, header: 'Start Date' },
  { field: TITLE_MILESTONE_TABLE.END_DATE, header: 'End Date' },
  { field: TITLE_MILESTONE_TABLE.DURATION, header: 'Duration' },
  { field: TITLE_MILESTONE_TABLE.STATUS, header: 'Milestone Status' },
];

export const TITLE_ISSUE_TABLE = {
  ISSUE_NAME: 'name',
  DUE_DATE: 'dueDate',
  AREA: 'area',
  SUB_AREA: 'subArea',
  RESPONSIBLE_PARTY: 'responsibleParty',
  SEVERITY: 'severity',
  ISSUE_STATUS: 'status',
  RAG_STATUS: 'ragStatus',
};

export const LIST_ISSUE_COLUMNS = [
  { field: TITLE_ISSUE_TABLE.ISSUE_NAME, header: 'Issue Name' },
  { field: TITLE_ISSUE_TABLE.DUE_DATE, header: 'Due Date' },
  { field: TITLE_ISSUE_TABLE.AREA, header: 'Area' },
  { field: TITLE_ISSUE_TABLE.SUB_AREA, header: 'Sub-Area' },
  { field: TITLE_ISSUE_TABLE.RESPONSIBLE_PARTY, header: 'Responsible' },
  { field: TITLE_ISSUE_TABLE.SEVERITY, header: 'Severity' },
  { field: TITLE_ISSUE_TABLE.ISSUE_STATUS, header: 'Issue Status' },
  { field: TITLE_ISSUE_TABLE.RAG_STATUS, header: 'RAG Status' },
];
export const TITLE_TASK_TABLE = {
  TASK_NAME: 'name',
  TASK_LIST: 'taskListName',
  START_DATE: 'startDate',
  DUE_DATE: 'endDate',
  RESPONSIBLE_PARTY: 'responsibleParty',
  TASK_STATUS: 'statusSequence',
  RAG_STATUS: 'ragStatus',
};

export const LIST_TASK_COLUMNS = [
  { field: TITLE_TASK_TABLE.TASK_NAME, header: 'Task Name' },
  { field: TITLE_TASK_TABLE.TASK_LIST, header: 'Task List' },
  { field: TITLE_TASK_TABLE.START_DATE, header: 'Start Date' },
  { field: TITLE_TASK_TABLE.DUE_DATE, header: 'Due Date' },
  { field: TITLE_TASK_TABLE.RESPONSIBLE_PARTY, header: 'Responsible' },
  { field: TITLE_TASK_TABLE.TASK_STATUS, header: 'Task Status' },
  { field: TITLE_TASK_TABLE.RAG_STATUS, header: 'RAG Status' },
];

export const TITLE_SITE_TABLE = {
  CMR_SITE_REF: 'crmSiteRef',
  NAME: 'name',
  BRAND_NAME: 'brandName',
  PROJECT_ID: 'projectId',
  SITE_STATUS: 'siteStatus',
  ACTIVE_STATUS: 'activeStatus',
};

export const TITLE_SITES_PAGE_TABLE = {
  CMR_SITE_REF: 'crmSiteRef',
  NAME: 'name',
  ACCOUNT: 'accountName',
  BRAND_NAME: 'brandName',
  PROJECT_ID: 'projectId',
  SITE_STATUS: 'siteStatus',
  ACTIVE_STATUS: 'activeStatus',
};

export const SITES_PAGE_COLUMNS = [
  { field: TITLE_SITES_PAGE_TABLE.CMR_SITE_REF, header: 'CRM Site Reference' },
  { field: TITLE_SITES_PAGE_TABLE.NAME, header: 'Site Name' },
  { field: TITLE_SITES_PAGE_TABLE.ACCOUNT, header: 'Account' },
  { field: TITLE_SITES_PAGE_TABLE.BRAND_NAME, header: 'Brand Name' },
  { field: TITLE_SITES_PAGE_TABLE.PROJECT_ID, header: 'Zoho Projects Project ID' },
  { field: TITLE_SITES_PAGE_TABLE.SITE_STATUS, header: 'Site Status' },
  { field: TITLE_SITES_PAGE_TABLE.ACTIVE_STATUS, header: 'Status' },
];

export const TITLE_FORM_TEMPLATE_TABLE = {
  FORM_TEMPLATE: 'name',
  FORM_TEMPLATE_STATUS: 'state',
  LAST_UPDATE: 'publishedDate',
  ACTIVE_STATUS: 'activeStatus',
  PREVIEW: 'preview',
};

export const LIST_FORM_TEMPLATE_COLUMNS = [
  { field: TITLE_FORM_TEMPLATE_TABLE.FORM_TEMPLATE, header: 'Form Template' },
  { field: TITLE_FORM_TEMPLATE_TABLE.FORM_TEMPLATE_STATUS, header: 'Form Template Status' },
  { field: TITLE_FORM_TEMPLATE_TABLE.LAST_UPDATE, header: 'Last Published Date' },
  { field: TITLE_FORM_TEMPLATE_TABLE.ACTIVE_STATUS, header: 'Status' },
  // { field: TITLE_FORM_TEMPLATE_TABLE.PREVIEW, header: 'Actions' },
];

export const TITLE_FORM_PAGE_TABLE_THG = {
  NAME: 'title',
  ACCOUNT: 'accountName',
  PROJECT: 'projectName',
  UPDATE_BY: 'modifiedBy',
  UPDATE_DATE: 'modifiedAt',
  APPROVED_BY: 'approvedBy',
  STATUS: 'stateSequence',
};

export const FORM_PAGE_COLUMNS_THG = [
  { field: TITLE_FORM_PAGE_TABLE_THG.NAME, header: 'Form' },
  { field: TITLE_FORM_PAGE_TABLE_THG.ACCOUNT, header: 'Account' },
  { field: TITLE_FORM_PAGE_TABLE_THG.PROJECT, header: 'Project' },
  { field: TITLE_FORM_PAGE_TABLE_THG.UPDATE_BY, header: 'Last Updated By' },
  { field: TITLE_FORM_PAGE_TABLE_THG.UPDATE_DATE, header: 'Last Updated Date' },
  { field: TITLE_FORM_PAGE_TABLE_THG.APPROVED_BY, header: 'Approved By' },
  { field: TITLE_FORM_PAGE_TABLE_THG.STATUS, header: 'Form Status' },
];
export const TITLE_FORM_PAGE_TABLE_CLIENT = {
  NAME: 'title',
  PROJECT: 'projectName',
  UPDATE_BY: 'modifiedBy',
  UPDATE_DATE: 'modifiedAt',
  STATUS: 'stateSequence',
};

export const FORM_PAGE_COLUMNS_CLIENT = [
  { field: TITLE_FORM_PAGE_TABLE_CLIENT.NAME, header: 'Form' },
  { field: TITLE_FORM_PAGE_TABLE_CLIENT.PROJECT, header: 'Project' },
  { field: TITLE_FORM_PAGE_TABLE_CLIENT.UPDATE_BY, header: 'Last Updated By' },
  { field: TITLE_FORM_PAGE_TABLE_CLIENT.UPDATE_DATE, header: 'Last Updated Date' },
  { field: TITLE_FORM_PAGE_TABLE_CLIENT.STATUS, header: 'Form Status' },
];

export const TITLE_DOCUMENT_TABLE = {
  SELECTOR: 'selector',
  TYPE: 'type',
  NAME: 'name',
  SIZE: 'size',
  UPDATE_DATE: 'lastUpdated',
  ACTION: 'action',
};

export const DOCUMENTS_COLUMNS = [
  { field: TITLE_DOCUMENT_TABLE.SELECTOR },
  { field: TITLE_DOCUMENT_TABLE.TYPE },
  { field: TITLE_DOCUMENT_TABLE.NAME, header: 'Name' },
  { field: TITLE_DOCUMENT_TABLE.SIZE, header: 'Size' },
  { field: TITLE_DOCUMENT_TABLE.UPDATE_DATE, header: 'Last Updated Date' },
];

export const TITLE_RELATED_SITE_TABLE = {
  SITE_NAME: 'name',
  TERRITORY: 'territory',
  CATEGORY: 'category',
  LAUNCH_DOMAIN: 'launchDomain',
};

export const RELATED_SITE_COLUMN = [
  { field: TITLE_RELATED_SITE_TABLE.SITE_NAME, header: 'Site Name' },
  { field: TITLE_RELATED_SITE_TABLE.TERRITORY, header: 'Territory' },
  { field: TITLE_RELATED_SITE_TABLE.CATEGORY, header: 'Category' },
  { field: TITLE_RELATED_SITE_TABLE.LAUNCH_DOMAIN, header: 'Launch Domain' },
];

export const PAGE_LIST_OPTION = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const TITLE_ACC_TABLE = {
  NAME: 'name',
  CRM_ACC_ID: 'crmAcctId',
  WEBSITE: 'website',
  ACTIVE_STATUS: 'activeStatus',
};

export const PROTOCOL = 'https://';

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_CALENDAR_FORMAT = 'dd/mm/yy';

export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';

export const STATUS_COLOR = {
  RED: 'bg-danger',
  GREEN: 'bg-success',
  AMBER: 'bg-warning',
};

export const LIMIT_ACCOUNT_FILTER = 25;

export const DEFAULT_PROJECT_STATUS_FILTER = {
  IN_FLIGHT: 'In Flight',
  ON_HOLD: 'On Hold',
};

export const NUM_TOLERATED_ITEMS = 9;

export const TABLE_EXPAND_TYPE = {
  EXPAND: 'EXPAND',
  SUB: 'SUB',
};

export const TASK_LEVEL_SIZE = [15, 15, 15];
export const TASK_SUB_PADDING = [0, 2, 4];

export const RESPONIBLE = [
  { value: 'Client', label: 'Client' },
  { value: 'THG', label: 'THG' },
];

export const OPTIONS_YES_AND_NO = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const DOWNLOAD_AREA = 'download-area';

export const UPLOAD_AREA = 'upload-area';

export const FORM_STATUS = {
  ACTION_REQUIRED: 'Action required',
  DRAFT: 'Draft',
  NEED_TO_BE_UPDATED: 'Need to be updated',
  REQUESTED_TO_RESUBMIT: 'Requested to resubmit',
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
};

export const FORM_ACTION = {
  APPROVE: 'Approve Form',
  REJECT: 'Reject Form',
  UNLOCK: 'Unlock Form',
};

export const FORM_STATUS_OPTION = [
  {
    value: 'ACTION_REQUIRED',
    label: 'Action required',
  },
  {
    value: 'DRAFT',
    label: 'Draft',
  },
  {
    value: 'NEED_TO_BE_UPDATED',
    label: 'Need to be updated',
  },
  {
    value: 'REQUESTED_TO_RESUBMIT',
    label: 'Requested to resubmit',
  },
  {
    value: 'SUBMITTED',
    label: 'Submitted',
  },
  {
    value: 'APPROVED',
    label: 'Approved',
  },
];
export const FORM_LEVEL = {
  ACCOUNT: 'ACCOUNT',
  PROJECT: 'PROJECT',
};

export const MODAL_TYPE = {
  WARNING: 'WARNING',
  CONFIRM: 'CONFIRM',
  SUCCESS: 'SUCCESS',
  UPDATE_FILE: 'UPDATE_FILE',
};

export const FORM_STATE = {
  PUBLISHED: 'PUBLISHED',
  ACTION_REQUIRED: 'ACTION_REQUIRED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const CONFIRM_MESSAGE =
  '\nThis will publish saved configurations, please make sure that your configurations are saved.\nAfter a form template is published, you can not change form level and unpublish form template.';

export const WARNING_MESSAGE = '\nPlease select Approver in Approval Process section before publish form template.';

export const FORM_INSTRUCTION =
  'Welcome to your THG Ingenuity Onboarding Forms.\n\nThis is the area where you can complete any forms and documents we require you to complete as part of the onboarding process.\n\nFrom each page you will be able to download a copy of the document and then upload it to THG Ingenuity where a member of the Delivery Team will be able to review and approve.\n\nIf there is any missing information or we need further clarification, we will be able to add comments to these document pages for you to respond to.\n\nWe have also included guides and instructional documents where needed for you to download and review.\n\nCompleting these documents in a timely manner is important as we require this information to be able to set up your site, marketing and warehouse operations successfully and effectively on time.\n\nIf you have any questions – please contact us';

export const FILE_INSTRUCTION_ACCEPT_ALL = 'Allow all file types. Maximum upload file size: 100 MB';

export const FILE_INSTRUCTION_ACCEPT_SPECIFIC =
  'Use the same file extension for version upload. Maximum upload file size: 100 MB';

export const FILE_UPLOAD = 'File Upload';

export const UPLOAD_NEW_VERSION = 'Upload New Version';

export const FormStateDisplay = {
  ACTION_REQUIRED: 'action required',
  DRAFT: 'draft',
  NEED_TO_BE_UPDATED: 'need to be update',
  REQUESTED_TO_RESUBMIT: 'requested to resubmit',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
};

export const UPLOAD = 'Upload';

export const TITLE_USER_TABLE = {
  USER_NAME: 'name',
  EMAIL: 'email',
  STATUS: 'status',
  ACTIONS: 'actions',
};

export const LIST_USER_COLUMNS = [
  { field: TITLE_USER_TABLE.USER_NAME, header: 'Name' },
  { field: TITLE_USER_TABLE.EMAIL, header: 'Email' },
  { field: TITLE_USER_TABLE.STATUS, header: 'Status' },
  { field: TITLE_USER_TABLE.ACTIONS, header: 'Actions' },
];

export const USER_ROLE = [{ label: 'Admin', value: 'Admin' }];

export const SCREEN_DETAIL = ['users/'];

export const USER_STATUS = [
  { label: 'Unconfirmed', value: 'UNCONFIRMED' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Locked', value: 'LOCKED' },
  { label: 'Inactive', value: 'INACTIVE' },
];

export const USER_STATUS_ACTION = ['ACTIVE', 'LOCKED', 'INACTIVE', 'UNCONFIRMED'];

export const ACTION_USER = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  LOCKED: 'LOCKED',
  UNCONFIRMED: 'UNCONFIRMED',
};

export const EMAIL_VERIFIED = {
  CONFIRMED: 'CONFIRMED',
  UNCONFIRMED: 'UNCONFIRMED',
};

export const UPLOADING_FILE_MESSAGE =
  'Your file is being uploaded and scanned for viruses. Please wait - this can take up to 30 seconds dependent on file size.';

export const MAX_FILE_SIZE_IN_BYTES = 104857600;
export const MIN_FILE_SIZE_IN_BYTES = 1;

export const IDLE_TIME = 7200;

export const CHECK_IN_TIME = 28;

export const CURRENT_APP_CODE = 'cp';

export const PERMISSIONS = {
  home_read: 'home_read',
  management_read: 'management_read',
  management_project_read: 'management_project_read',
  management_form_read: 'management_form_read',
  management_document_red: 'management_document_red',
  configuration_read: 'configuration_read',
  configuration_account_read: 'configuration_account_read',
  configuration_user_read: 'configuration_user_read',
  configuration_form_read: 'configuration_form_read',
  configuration_file_read: 'configuration_file_read',
  configuration_role_read: 'configuration_role_read',
  configuration_thg_user_read: 'configuration_thg_user_read',
  configuration_client_user_read: 'configuration_client_user_read',
  help_read: 'help_read',
  account_detail_read: 'account_detail_read',
  configuration_site_read: 'configuration_site_read',
  account_profile: 'account_profile',
  related_sites: 'related_sites',
  view_welcome_video: 'view_welcome_video',
  view_checkin_video: 'view_checkin_video',
  view_user_tour: 'view_user_tour',
  // Account List on the top bar
  view_client_list: 'view_client_list',
  // Main Help Center
  view_main_help: 'view_main_help',
  // Account Profile
  view_account_profile: 'view_account_profile',
  view_related_sites: 'view_related_sites',
  // Projects
  view_project_list: 'view_project_list',
  view_project_details: 'view_project_details',
  // Client Forms
  view_client_form_list: 'view_client_form_list',
  view_client_form_details: 'view_client_form_details',
  edit_client_form_details: 'edit_client_form_details',
  // Documents
  view_document_list: 'view_document_list',
  upload_file: 'upload_file',
  upload_new_version: 'upload_new_version',
  download_file: 'download_file',
  // Client Users
  view_client_user_list: 'view_client_user_list',
  view_client_user_details: 'view_client_user_details',
  activate_deactivate_user: 'activate_deactivate_user',
  // Accounts
  view_account_list: 'view_account_list',
  view_account_details: 'view_account_details',
  // Site
  view_site_list: 'view_site_list',
  view_site_details: 'view_site_details',
  view_site_list_of_account: 'view_site_list_of_account',
  // Form Template
  view_form_template_list: 'view_form_template_list',
  preview_form_template: 'preview_form_template',
  view_form_template_configurations: 'view_form_template_configurations',
  edit_form_template_configurations: 'edit_form_template_configurations',
  // Forms in THG user view
  view_thg_form_list: 'view_thg_form_list',
  view_thg_form_details: 'view_thg_form_details',
  approve_thg_form: 'approve_thg_form',
  // UPS mode
  view_application_list: 'view_application_list',
  add_application: 'add_application',
  edit_application: 'edit_application',
  view_application_details: 'view_application_details',
  view_module_list: 'view_module_list',
  add_module: 'add_module',
  edit_module: 'edit_module',
  view_module_details: 'view_module_details',
  view_role_list: 'view_role_list',
  add_role: 'add_role',
  edit_role: 'edit_role',
  view_role_details: 'view_role_details',
  edit_role_permissions: 'edit_role_permissions',
  view_role_permissions: 'view_role_permissions',
  view_roles_to_assign_list: 'view_roles_to_assign_list',
  add_roles_to_assign: 'add_roles_to_assign',
  edit_roles_to_assign: 'edit_roles_to_assign',
  view_roles_to_assign_details: 'view_roles_to_assign_details',
  view_permission_list: 'view_permission_list',
  add_permission: 'add_permission',
  edit_permission: 'edit_permission',
  view_permission_details: 'view_permission_details',
  view_organization_list: 'view_organization_list',
  add_organization: 'add_organization',
  edit_organization: 'edit_organization',
  view_organization_details: 'view_organization_details',
  import_by_CSV: 'import_by_CSV',
  add_site: 'add_site',
  edit_site: 'edit_site',
  view_locale_list: 'view_locale_list',
  add_locale: 'add_locale',
  edit_locale: 'edit_locale',
  view_locale_details: 'view_locale_details',
  view_user_list: 'view_user_list',
  add_user: 'add_user',
  edit_user: 'edit_user',
  view_user_details: 'view_user_details',
  view_user_permissions_list: 'view_user_permissions_list',
  edit_user_permission: 'edit_user_permission',
  view_user_permission_details: 'view_user_permission_details',
  add_user_permission: 'add_user_permission',
  initial_access: 'initial_access',
};

export const HOME_BUTTON = [
  {
    title: 'Manage Projects',
    roles: [ROLE_TYPE.CLIENT],
    to: '/management/projects',
    dataTut: 'management-project',
    permission: [
      PERMISSIONS.view_project_list,
      PERMISSIONS.view_client_form_list,
      PERMISSIONS.view_client_form_details,
      PERMISSIONS.view_thg_form_list,
      PERMISSIONS.view_thg_form_details,
      PERMISSIONS.approve_thg_form,
    ],
  },
  {
    title: 'Manage Forms',
    roles: [ROLE_TYPE.THG, ROLE_TYPE.CLIENT, ROLE_TYPE.INITIAL_ACCESS],
    to: '/management/forms',
    dataTut: 'management-forms',
    permission: [
      PERMISSIONS.view_thg_form_list,
      PERMISSIONS.view_client_form_list,
      PERMISSIONS.view_thg_form_details,
      PERMISSIONS.approve_thg_form,
      PERMISSIONS.initial_access,
    ],
  },
  {
    title: 'Manage Documents',
    roles: [ROLE_TYPE.CLIENT],
    to: '/management/documents',
    dataTut: 'management-documents',
    permission: [PERMISSIONS.view_document_list],
  },
  {
    title: 'Form Templates',
    roles: [ROLE_TYPE.THG],
    to: '/configuration/form-templates',
    dataTut: 'configure-form-templates',
    permission: [PERMISSIONS.view_form_template_list],
  },
  {
    title: 'View Accounts',
    roles: [ROLE_TYPE.THG],
    to: '/configuration/accounts',
    dataTut: 'view-account',
    permission: [
      PERMISSIONS.view_account_list,
      PERMISSIONS.view_site_list_of_account,
      PERMISSIONS.view_site_list,
      PERMISSIONS.view_site_details,
      PERMISSIONS.view_thg_form_list,
      PERMISSIONS.view_thg_form_details,
      PERMISSIONS.approve_thg_form,
      PERMISSIONS.view_form_template_list,
      PERMISSIONS.view_client_user_list,
    ],
  },
  {
    title: 'View Sites',
    roles: [ROLE_TYPE.THG],
    to: '/configuration/sites',
    dataTut: 'view-site',
    permission: [PERMISSIONS.view_site_list],
  },
];

export const DENI_APPROVE_FORM_MESSAGE = 'You don’t have permission to approve this form';

export const INVALID_SELECT_APPROVERS = 'You need to select at least one approver for this form';

export const ErrorMessageUserProfile = 'AN UNEXPECTED ERROR HAS OCCURRED';

export const ERROR_MESSAGE_USER_PROFILE_DES =
  'This user account hasn’t been configured with enough information to be logged in successfully to the Client Portal application.';

export const ERROR_MESSAGE_VIEW_CLIENT_PERMISSION =
  'Failed to load this list because this user account hasn’t been assigned the “View Client List” permission.';

export const ROADMAP_DELIVERY_INTRUCTION =
  'Please click the below link to see the latest version of your ‘Roadmap to Delivery’ . All updated versions of this will be stored here by your Ingenuity Project Manger';

export const UNKNOWN_ERROR_CODE = {
  ERR_CP_ACCOUNT_UNKNOWN: 'ERR_CP_ACCOUNT_UNKNOWN',
  ERR_CP_SITE_UNKNOWN: 'ERR_CP_SITE_UNKNOWN',
  ERR_CP_PROJECT_UNKNOWN: 'ERR_CP_PROJECT_UNKNOWN',
  ERR_CP_FORM_C_UNKNOWN: 'ERR_CP_FORM_C_UNKNOWN',
  ERR_CP_FILE_C_UNKNOWN: 'ERR_CP_FILE_C_UNKNOWN',
  ERR_CP_USER_C_UNKNOWN: 'ERR_CP_USER_C_UNKNOWN',
  ERR_CP_UPS_C_UNKNOWN: 'ERR_CP_UPS_C_UNKNOWN',
  ERR_CP_ACP_C_UNKNOWN: 'ERR_CP_ACP_C_UNKNOWN',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
};

export const DEFINED_ERROR_CODE = {
  ERR_CP_FROM_FE_TRY_AGAIN: 'ERR_CP_FROM_FE_TRY_AGAIN',
  ERR_CP_FILE_FE_FILE_UPLOAD_FAILED: 'ERR_CP_FILE_FE_FILE_UPLOAD_FAILED',
  ERR_CP_FROM_FE_ILLEGAL_FORM_STATE: 'ERR_CP_FROM_FE_ILLEGAL_FORM_STATE',
  ERR_CP_FILE_FE_NO_WORDRIVE_FOLDER: 'ERR_CP_FILE_FE_NO_WORDRIVE_FOLDER',
  ERR_CP_FILE_FE_DOWNLOAD_TIMEOUT: 'ERR_CP_FILE_FE_DOWNLOAD_TIMEOUT',
  ERR_CP_FILE_FE_ACCESS_DENIED: 'ERR_CP_FILE_FE_ACCESS_DENIED',
};

export const GENERAL_ERROR_MESSAGE = 'There has been a technical error on this page.';
