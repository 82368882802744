import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ConfirmDialog, LeftSidebar } from '@thg-harveynash/hyper-shared-components';
import { LeftSidebarHeaderProps, MenuItemType } from '@thg-harveynash/hyper-shared-components/build/types';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { useGetWarningModal, useWarningModal } from '../context/warning-modal-context';
import { useDispatch } from '../context';
import { Types } from '../constant/action-type';
import Spinner from '@thg-harveynash/cp-hyper-react-package-ui/dist/components/Spinner';
import { getMenuItemByPermission } from '../common/menu';
import { useGetUserPermissionQuery, AuthorizationPermission } from '../apis/endpoints/authorizations';

type LayoutProps = {
  children: React.ReactNode;
};

interface WarningModalProps {
  message?: string;
  target?: any;
  changeAccount?: any;
}

export const LayoutUps = ({ children }: LayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenAllApplication, setIsOpenAllApplication] = useState(false);
  const [isExpandLeftMenu, setIsExpandLeftMenu] = useState(false);
  const [loadingSignOut, setLoadingSignOut] = useState<boolean>(false);

  const { data, isLoading } = useGetUserPermissionQuery({
    appCode: 'ups',
  });

  const permissionList = data?.data?.map((permission: AuthorizationPermission) => permission?.code);

  const modalData: WarningModalProps = useGetWarningModal();
  const modal = useWarningModal();
  const dispatch = useDispatch();

  const onClickMenuItem = (item: MenuItemType) => {
    if (item.key === 'all_application') {
      setIsOpenAllApplication(!isOpenAllApplication);
    } else {
      navigate(`${item.url}`);
    }
  };

  const leftSidebarHeader: LeftSidebarHeaderProps = {
    isShowIconLeftWhenUnCollapsed: true,
    iconLeft: {
      name: 'logo',
      src: isExpandLeftMenu ? '/THGi_logo.png' : '/Ingenuity_Logo_White.png',
      style: {
        width: isExpandLeftMenu ? 25 : '95%',
      },
    },
    titleClassName: 'sidebar_header_text',
  };

  return (
    <>
      {loadingSignOut || isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner />
        </div>
      ) : (
        <div
          className={classNames('layout', {
            'layout--collapsed': isExpandLeftMenu,
          })}
        >
          <aside className="layout_sidebar">
            {permissionList && permissionList?.length > 0 && (
              <>
                <LeftSidebar
                  currentPathname={location.pathname}
                  menuItems={getMenuItemByPermission(permissionList)}
                  header={{
                    ...leftSidebarHeader,
                    collapsed: isExpandLeftMenu,
                    onClickIconRight: () => setIsExpandLeftMenu(!isExpandLeftMenu),
                  }}
                  onClickItem={(item) => onClickMenuItem(item)}
                />
              </>
            )}
          </aside>
          <ConfirmDialog
            show={!!modalData.message}
            onCancel={() => modal({})}
            onHide={() => modal({})}
            onConfirm={() => {
              modal({});
              modalData.target && modalData.target?.retry();
              modalData.changeAccount && modalData.changeAccount();
              dispatch({
                type: Types.GET_FORM_DIRTY_STATUS,
                payload: {
                  isFormChanged: false,
                },
              });
            }}
            btnCancel={{
              label: undefined,
              icon: undefined,
              iconSrc: undefined,
              variant: undefined,
            }}
            btnConfirm={{
              label: 'Leave',
              icon: undefined,
              iconSrc: undefined,
              variant: 'danger',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: modalData.message || '' }} />
          </ConfirmDialog>
          <main className="layout_content">
            <DefaultHeader setLoadingSignOut={setLoadingSignOut} />
            <article className="content">{children}</article>
            <DefaultFooter />
          </main>
        </div>
      )}
    </>
  );
};
