import { LEFT_MENU_STYLE, RIGHT_MENU_STYLE, LAYOUT_MODE, LEFT_MENU_MODE } from '../constant';

const changeLeftMenuStyle = (isExpand: boolean): void => {
  if (document.body && isExpand) {
    document.body.setAttribute('data-layout', 'vertical');
    document.body.setAttribute('data-layout-mode', 'fluid');
    document.body.setAttribute('data-leftbar-compact-mode', 'fixed');
  }
  if (document.body && !isExpand) {
    document.body.setAttribute('data-layout', 'vertical');
    document.body.setAttribute('data-layout-mode', 'fluid');
    document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
  }
};

const changeRightMenuStyle = (isExpand: boolean): void => {
  if (document.body && isExpand) {
    document.body.classList.add('end-bar-enabled');
  }
  if (document.body && !isExpand) {
    document.body.classList.remove('end-bar-enabled');
  }
};

const changeRightPopupStyle = (isExpand: boolean): void => {
  if (document.body && isExpand) {
    document.body.classList.add('rightside-popup-enabled');
  }
  if (document.body && !isExpand) {
    document.body.classList.remove('rightside-popup-enabled');
  }
};

const changeLayoutStyle = (switchMode: string): void => {
  if (document.body && switchMode === LAYOUT_MODE.LIGHT) {
    document.body.setAttribute('data-layout-color', 'light');
  }
  if (document.body && switchMode === LAYOUT_MODE.DARK) {
    document.body.setAttribute('data-layout-color', 'dark');
  }
};

const changeLeftMenuMode = (switchMode: string): void => {
  if (document.body && switchMode === LEFT_MENU_MODE.LIGHT) {
    document.body.setAttribute('data-sidebar-theme', 'light');
    return;
  }
  if (document.body && switchMode === LEFT_MENU_MODE.DARK) {
    document.body.setAttribute('data-sidebar-theme', 'dark');
    return;
  }
  if (document.body && switchMode === LEFT_MENU_MODE.DEFAULT) {
    document.body.setAttribute('data-sidebar-theme', 'default');
  }
};

const storeLeftMenuStyle = (isExpand: boolean): void => {
  const leftMenuStyle = isExpand ? LEFT_MENU_STYLE.EXPANDED : LEFT_MENU_STYLE.CONDENSED;
  localStorage.setItem('leftMenuStyle', leftMenuStyle);
};

const getsLeftMenuStyle = () => {
  return localStorage.getItem('leftMenuStyle') || '';
};

const storeRightMenuStyle = (isExpand: boolean): void => {
  const rightMenuStyle = isExpand ? RIGHT_MENU_STYLE.EXPANDED : RIGHT_MENU_STYLE.CONDENSED;
  localStorage.setItem('rightMenuStyle', rightMenuStyle);
};

const getsRightMenuStyle = () => {
  return localStorage.getItem('rightMenuStyle') || '';
};

const storeLayoutMode = (layoutMode: string): void => {
  localStorage.setItem('layoutMode', layoutMode);
};

const storeLeftMenuMode = (leftMenuMode: string): void => {
  localStorage.setItem('leftMenuMode', leftMenuMode);
};

const getsLayoutMode = () => {
  return localStorage.getItem('layoutMode') || LAYOUT_MODE.LIGHT;
};
const getLeftMenuMode = () => {
  return localStorage.getItem('leftMenuMode') || LEFT_MENU_MODE.DARK;
};

const storeCurrentAccount = (account: any): void => {
  localStorage.setItem('accountSelected', JSON.stringify(account));
};

const getsCurrentAccount = () => {
  return localStorage.getItem('accountSelected');
};

export {
  changeLeftMenuStyle,
  storeLeftMenuStyle,
  getsLeftMenuStyle,
  changeRightMenuStyle,
  changeLayoutStyle,
  storeRightMenuStyle,
  getsRightMenuStyle,
  storeLayoutMode,
  getsLayoutMode,
  getLeftMenuMode,
  storeLeftMenuMode,
  changeLeftMenuMode,
  storeCurrentAccount,
  getsCurrentAccount,
  changeRightPopupStyle,
};
