import { Types } from '../constant/action-type';

type LeftMenuMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type LeftMenuType = {
  isExpand: boolean | null;
};

type LeftMenuPayload = {
  [Types.SET_LEFT_MENU_STYLE]: {
    isExpand: boolean;
  };
};

export type LeftMenuActions = LeftMenuMap<LeftMenuPayload>[keyof LeftMenuMap<LeftMenuPayload>];

export const LeftMenuReducer = (state: LeftMenuType, action: LeftMenuActions) => {
  if (action.type === Types.SET_LEFT_MENU_STYLE) {
    const isExpand = action.payload.isExpand || false;
    return { isExpand };
  }
  return state;
};
