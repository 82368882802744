import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import {
  getsLeftMenuStyle,
  changeLayoutStyle,
  getsLayoutMode,
  getLeftMenuMode,
  changeLeftMenuMode,
  changeRightPopupStyle,
  storeLeftMenuStyle,
} from '../utils/layout';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { CHECK_IN_TIME, LEFT_MENU_STYLE, ROLE_TYPE } from '../constant';
import { useGetToast, useToast } from '../context/toast-context';
import '../assets/scss/Global.scss';
import { useGetWarningModal, useWarningModal } from '../context/warning-modal-context';
import { useCheckinVideo, useDispatch, useModalOnboardVideo } from '../context';
import { Types } from '../constant/action-type';
import { FormInformation, OnboardingProcessInformation } from '../components/help-center-information';
import { useGetHelpCenter, useHelpCenter } from '../context/help-center-context';
import LeftSidebar from './LeftSidebarMenu';
import classNames from 'classnames';
import Header from './DefaultHeader';
import Footer from './DefaultFooter';
import './Default.scss';
import { ConfirmDialogStyled, SpinnerStyled } from '../components/StyledComponent';
import { Spinner } from '@thg-harveynash/cp-hyper-react-package-ui';
import ModalVideo from '../components/modal/ModalVideo';
import ModalCheckinVideo from '../components/modal/ModalCheckinVideo';
import { getDurationFromFirstLogin, getRole } from '../utils';
import { userInstance } from '../services/instance';
import moment from 'moment';
import styled from 'styled-components';

interface LayoutProps {
  component: any;
}

interface ToastProps {
  code?: string;
  summary?: string;
  detail?: string;
  severity?: string;
}

interface WarningModalProps {
  message?: string;
  target?: any;
  changeAccount?: any;
}

const DetailMessageStyled = styled.div`
  overflow-wrap: anywhere;
`;

const ToastStyled = styled(Toast)`
  z-index: 1000 !important;
  width: 450px;
  max-width: 450px;
  .p-toast-message {
    width: 450px;
  }
  .p-toast-detail {
    margin: 0 !important;
  }
`;

const DashboardLayout = ({ component }: LayoutProps) => {
  const toastData = useGetToast();
  const helpCenterData = useGetHelpCenter();
  const modalData: WarningModalProps = useGetWarningModal();
  const toast = useRef(null);
  const toastMes = useToast();
  const modal = useWarningModal();
  const isExpandLeftMenu = getsLeftMenuStyle() !== LEFT_MENU_STYLE.CONDENSED;
  const layoutMode = getsLayoutMode();
  const leftMenuMode = getLeftMenuMode();
  const [isShowLeftMenu, setIsShowLeftMenu] = useState(isExpandLeftMenu);
  const [isShowRightMenu, setIsShowRightMenu] = useState(false);
  const [isShowHelpCenter, setIsShowHelpCenter] = useState<boolean>(false);
  const [loadingSignOut, setLoadingSignOut] = useState<boolean>(false);
  const dispatch = useDispatch();
  const modalOnboardVideoStyle = useModalOnboardVideo();
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [showCheckinVideo, setShowCheckinVideo] = useState(false);
  const helpCenterControl = useHelpCenter();
  const { isOpen: isOpenCheckinVideo } = useCheckinVideo();
  const role = getRole();

  changeLayoutStyle(layoutMode);
  changeLeftMenuMode(leftMenuMode);
  const showToast = ({ summary, code, detail, severity = 'success' }: ToastProps) => {
    let content = null;
    if (severity === 'error') {
      content = (
        <div className="d-flex align-items-center" style={{ width: '95%' }}>
          <img src="/icon/error.svg" alt="error" />
          <div className="d-flex flex-column justify-start">
            <DetailMessageStyled className="mx-2">{detail}</DetailMessageStyled>
            <b className="mx-2">Error Code: {code}</b>
          </div>
        </div>
      );
    }
    // @ts-ignore
    toast?.current?.show({ severity, summary, detail, content, life: 3000 });
    toastMes({ type: '' });
  };

  useEffect(() => {
    // @ts-ignore
    const { code, message, type } = toastData;
    if (type) showToast({ code: code, detail: message, severity: type });
  }, [toastData]);

  useEffect(() => {
    if (helpCenterData?.isOpen) {
      setTimeout(() => {
        setShowHelpCenter(true);
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpCenterData]);

  const checkShowCheckinVideo = async () => {
    if (role === ROLE_TYPE.THG) return;
    const response = await userInstance.get('profile');
    const userInfo = await response.data;
    const firstShowCheckinVideoAt = userInfo?.shownSecondVideoAt;
    const duration = getDurationFromFirstLogin();
    if (Math.floor(duration.asDays()) >= CHECK_IN_TIME && !firstShowCheckinVideoAt) {
      dispatch({
        type: Types.SET_SHOW_CHECK_IN_VIDEO,
        payload: {
          isOpen: true,
        },
      });
      // after checkin video showed in the first time, need set showSecondVideoDate flag to now
      await userInstance.put(`users/${userInfo?.id}/shownSecondVideoDate`, {
        shownSecondVideoAt: moment(new Date()).utc(),
      });
    }
  };

  useEffect(() => {
    checkShowCheckinVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setShowHelpCenter = (isExpand: boolean) => {
    setIsShowHelpCenter(isExpand);
    changeRightPopupStyle(isExpand);
    setTimeout(() => {
      if (!isExpand) {
        helpCenterControl({
          isOpen: false,
          formInformation: {
            isShow: false,
            activeIndex: [],
          },
          onboardingProcessInformation: {
            isShow: false,
            activeIndex: [],
          },
          isShowButtonAllHelpTopic: false,
        });
      }
    }, 100);
  };

  const handleShowAlllHelpTopic = () => {
    setShowHelpCenter(false);
    setTimeout(() => {
      helpCenterControl({
        isOpen: true,
        formInformation: {
          isShow: true,
          activeIndex: [],
        },
        onboardingProcessInformation: {
          isShow: true,
          activeIndex: [],
        },
        isShowButtonAllHelpTopic: false,
      });
    }, 300);
  };

  const changeLeftMenu = () => {
    storeLeftMenuStyle(!isShowLeftMenu);
    setIsShowLeftMenu(!isShowLeftMenu);
  };

  useEffect(() => {
    if (modalOnboardVideoStyle?.isOpen) {
      setShowModalVideo(true);
      setIsShowHelpCenter(false);
    }
  }, [modalOnboardVideoStyle]);

  useEffect(() => {
    if (isOpenCheckinVideo) {
      setShowCheckinVideo(true);
      setIsShowHelpCenter(false);
    }
  }, [isOpenCheckinVideo]);

  const handleCloseModalOnboardVideo = () => {
    setShowModalVideo(false);
    setShowHelpCenter(false);
    dispatch({
      type: Types.SET_MODAL_ONBOARD_VIDEO_STYLE,
      payload: {
        isOpen: false,
      },
    });
  };

  const handleCloseCheckinVideo = () => {
    setShowCheckinVideo(false);
    setShowHelpCenter(false);
    dispatch({
      type: Types.SET_SHOW_CHECK_IN_VIDEO,
      payload: {
        isOpen: false,
      },
    });
  };
  return (
    <>
      {loadingSignOut ? (
        <SpinnerStyled style={{ height: '100vh' }}>
          <Spinner />
        </SpinnerStyled>
      ) : (
        <>
          <div
            className={classNames('layout', {
              'layout--collapsed': !isShowLeftMenu,
            })}
          >
            <LeftSidebar isShowLeftMenu={!isShowLeftMenu} setIsShowLeftMenu={changeLeftMenu} />
            <main className="layout_content">
              <Header
                isShowRightMenu={isShowRightMenu}
                setIsShowRightMenu={setIsShowRightMenu}
                handleShowAlllHelpTopic={handleShowAlllHelpTopic}
                setLoadingSignOut={setLoadingSignOut}
              />
              <article className="content">{component}</article>
              <Footer />
            </main>
          </div>
          {helpCenterData?.isOpen && (
            <RightSidebar
              title="Help Center"
              showRightSidebar={isShowHelpCenter}
              setShowRightSidebar={(value) => setShowHelpCenter(value)}
            >
              <div className="flex-1 help-content">
                {helpCenterData?.isShowButtonAllHelpTopic && (
                  <button onClick={() => handleShowAlllHelpTopic()} type="button" className="btn btn-link">
                    All help topics
                  </button>
                )}
                {helpCenterData.onboardingProcessInformation.isShow && (
                  <OnboardingProcessInformation
                    activeIndex={helpCenterData.onboardingProcessInformation.activeIndex}
                    setIsShowHelpCenter={setIsShowHelpCenter}
                    setShowHelpCenter={setShowHelpCenter}
                  />
                )}
                {helpCenterData.formInformation.isShow && (
                  <FormInformation activeIndex={helpCenterData.formInformation.activeIndex} />
                )}
              </div>
            </RightSidebar>
          )}
          <ToastStyled ref={toast} position="top-right" />
          <img src="THG_email_logo.png" alt="email-logo" style={{ display: 'none' }} />
          <ModalVideo isShowModalVideo={showModalVideo} setShowModalVideo={() => handleCloseModalOnboardVideo()} />
          <ModalCheckinVideo isShowVideo={showCheckinVideo} handleCloseVideo={handleCloseCheckinVideo} />
          <ConfirmDialogStyled
            show={!!modalData.message}
            onCancel={() => modal({})}
            onHide={() => modal({})}
            onConfirm={() => {
              modal({});
              modalData.target && modalData.target?.retry();
              modalData.changeAccount && modalData.changeAccount();
              dispatch({
                type: Types.GET_FORM_DIRTY_STATUS,
                payload: {
                  isFormChanged: false,
                },
              });
            }}
            btnCancel={{
              label: undefined,
              icon: undefined,
              iconSrc: undefined,
              variant: undefined,
            }}
            btnConfirm={{
              label: 'Leave',
              icon: undefined,
              iconSrc: undefined,
              variant: 'danger',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: modalData.message || '' }} />
          </ConfirmDialogStyled>
        </>
      )}
    </>
  );
};

export default DashboardLayout;
